import { fileAPI } from 'liankong-ui-api'

/**
 * h5 文件上传
 * @param {*} uploadTask 
 */
export default async function(uploadTask){
  const result = await fileAPI.upload(uploadTask)

  if(result.code != 0){
    throw message
  }

  return result
}