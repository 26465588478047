<template>
    <el-dialog title="扫码投屏" :visible.sync="dialogVisible"
    :append-to-body='true'
    :close-on-click-modal="false" width="450px">
    <template>
        <div>
            模块正在开发中，敬请期待
        </div>
    </template>
  </el-dialog>
</template>
<script>
export default {
    data(){
        return {
            dialogVisible:false
        }
    },
    methods:{
       
    },
   
}
</script>
<style lang='less' scoped>
</style>