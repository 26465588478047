/**
 * 全局安装过滤器
 */

//加载所有过滤器模块
const modulesFiles = require.context('.', true, /\.filter\.js$/)
export default function install(Vue) {
    modulesFiles.keys().map(modulePath => {
        const value = modulesFiles(modulePath)
        Object.keys(value).map(filterHandle => {
            Vue.filter(filterHandle, value[filterHandle])
        })
    })
}