/**
 * 权限管理、系统管理
 */
const menuRouter = {
    path: "/manager/menu",
    name: "MainMenu",
    component: () =>
        import ('@/views/manager/menu/Menu.vue')
}



const rolesRouter = {
    path: "/manager/roles",
    name: "MainRoles",
    component: () =>
        import ('@/views/manager/roles/Roles.vue')
}


const logsRestRouter = {
    path: "/manager/logs",
    name: "MainLogs",
    component: () =>
        import ('@/views/manager/logwork/LogsRest.vue')
}
const logsReadRouter = {
    path: "/manager/reads",
    name: "MainReads",
    component: () =>
        import ('@/views/manager/logwork/LogsRead.vue')
}
const dictsRouter = {
    path: "/manager/dicts",
    name: "MainDicts",
    component: () =>
        import ('@/views/manager/dict/Index.vue')
}
const severRouter = {
    path: "/manager/sermon",
    name: "MainSermon",
    component: () =>
        import ('@/views/manager/monitor/Server.vue')
}
const severDetailRouter = {
    path: "/manager/sermon/detail",
    name: "MainSermonDetail",
    component: () =>
        import ('@/views/manager/monitor/ServerDetail.vue')
}
const StatiRouter = {
    path: "/manager/statistics",
    name: "MainStatistics",
    component: () =>
        import ('@/views/manager/monitor/Statistics.vue')
}



// 系统配置
const systemSetting = {
    path: '/manager/systemSetting',
    name: 'MainStstemSetting',
    component: () =>
        import ('@/views/manager/new-setting/NewSetting.vue')
}


// 参数设置
const parameterSetting = {
    path: '/manager/parameter',
    name: 'MainParameter',
    component: () =>
        import ('@/views/manager/parameter/Index.vue')
}

// app升级服务
const appSetting = {
    path: '/manager/app',
    name: 'MainAppSetting',
    component: () =>
        import ('@/views/manager/appupgrade/Index.vue')
}

// 告警信息
const alarmlogSetting = {
    path: '/manager/alarmlog',
    name: 'ManagerAlarmlog',
    component: () =>
        import ('@/views/manager/alarmlog/index.vue')
}

// 关键字管理
const keywordsSetting = {
    path: '/manager/keywords',
    name: 'ManagerKeywords',
    component: () =>
        import ('@/views/manager/keywords/index.vue')
}

// 文档库管理
const fileManageRouter = {
    path: '/manager/fileManage',
    name: 'ManagerFiles',
    component: () =>
        import ('@/views/manager/fileManage/index.vue')
}

// 用户管理
const userManageRouter = {
    path: '/manager/user',
    name: 'ManagerUser',
    component: () =>
        import ('@/views/manager/user/index.vue')
}

// 存储空间
const storageManageRouter = {
    path: '/manager/storage',
    name: 'ManagerStorage',
    component: () =>
        import ('@/views/manager/storage/index.vue')
}

// 轮播图配置
const rotationSetting = {
    path: '/manager/rotation',
    name: 'MainRotationSetting',
    component: () =>
        import ('@/views/manager/rotation/index.vue')
}

// 工具分类
const tooltypeSetting = {
    path: '/manager/tooltype',
    name: 'ManageTooltype',
    component: () =>
        import ('@/views/manager/tooltype/index.vue')
}

// 最受欢迎
const mostPopularSetting = {
    path: '/manager/mostpopular',
    name: 'ManageMostpopular',
    component: () =>
        import ('@/views/manager/mostpopular/index.vue')
}

// 工具管理
const toolSetting = {
    path: '/manager/tool',
    name: 'ManageTool',
    component: () =>
        import ('@/views/manager/tool/index.vue')
}

// 版本管理
const toolVersionSetting = {
    path: '/manager/tool/version/:id',
    name: 'ManageToolVersion',
    component: () =>
        import ('@/views/manager/tool/version.vue')
}

// 问答管理
const toolQuestionSetting = {
    path: '/manager/tool/question/:id',
    name: 'ManageToolQuestion',
    component: () =>
        import ('@/views/manager/tool/question.vue')
}

// 会员管理
const memberSetting = {
    path: '/manager/member',
    name: 'ManageMember',
    component: () =>
        import ('@/views/manager/member/index.vue')
}

// 会员订单管理
const memberOrderSetting = {
    path: '/manager/order',
    name: 'ManageOrder',
    component: () =>
        import ('@/views/manager/member/order.vue')
}

// 会员定制管理
const customizationSetting = {
    path: '/manager/customization',
    name: 'ManageCustomization',
    component: () =>
        import ('@/views/manager/member/customization.vue')
}


// 报表统计
const reportStatistics = {
    path: '/manager/report',
    name: 'ManageReport',
    component: () =>
        import ('@/views/manager/report/report.vue')
}

// 意见反馈
const feedBackSetting = {
    path: '/manager/feedBackSetting',
    name: 'ManageFeedBackSetting',
    component: () =>
        import ('@/views/manager/feedback/index.vue')
}
// 工具公告
const notifySetting = {
    path: '/manager/notifySetting',
    name: 'ManageFeedBackSetting',
    component: () =>
        import ('@/views/manager/notify/index.vue')
}



export default [notifySetting,feedBackSetting,storageManageRouter,userManageRouter,fileManageRouter, menuRouter, rolesRouter, logsRestRouter, logsReadRouter, dictsRouter, severRouter, severDetailRouter, StatiRouter, systemSetting, parameterSetting, appSetting, alarmlogSetting, keywordsSetting,rotationSetting, tooltypeSetting, mostPopularSetting, toolSetting, toolVersionSetting, toolQuestionSetting, memberSetting, memberOrderSetting, customizationSetting, reportStatistics]