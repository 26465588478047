import BaseAPI from '../BaseAPI.js'

/**
 * 文件服务API
 * 
 * @author Daniel
 * @date 
 */
export default class FileAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/document"
    }

    /**
     * 获取上传任务key
     * @param {*} uploadTask 
     */
    uploadTaskKey(uploadTask) {
        const folderId = uploadTask.folderId
        const docType = uploadTask.docType
        if (uploadTask.orgId) {
            return this.ajax.post(`${this.moduleName}/upload/apply?parentId=${folderId}&name=${encodeURIComponent(uploadTask.name)}&docType=${docType}&orgId=${uploadTask.orgId}`)
        } else {
            return this.ajax.post(`${this.moduleName}/upload/apply?parentId=${folderId}&name=${encodeURIComponent(uploadTask.name)}&docType=${docType}`)
        }
    }

    /**
     * 获取上传任务进度信息
     * @param {*} uploadTask 
     */
    uploadTaskInfo(uploadTask) {
        const uploadKey = uploadTask.uploadKey
        const postData = {
            parentId: uploadTask.folderId,
            fileName: uploadTask.name,
            size: uploadTask.size,
            signKey: uploadTask.md5,
            attrs: uploadTask.attrs
        }
        if (uploadTask.orgId) {
            postData.orgId = uploadTask.orgId
        }
        return this.ajax.post(`${this.moduleName}/upload/check`, postData, {
            headers: {
                "X-Upload-Key": uploadKey
            }
        })
    }

    /**
     * 获取文件上传地址
     */
    getUploadServerURL() {
        return `${this.moduleName}/upload`
    }

    /**
     * 上传文件
     * @param {*} uploadTask 
     */
    upload(uploadTask) {
        const uploadKey = uploadTask.uploadKey

        const uploadFormData = new FormData()
        uploadFormData.append("file", uploadTask.raw)

        const uploadUrl = this.getUploadServerURL()
        return this.ajax.post(uploadUrl, uploadFormData, {
            headers: {
                "X-Upload-Key": uploadKey,
                'Content-Type': 'multipart/form-data'
            }
        })
    }


    /**
     * 分片上传  获取上传任务key
     * @param {*} uploadTask 
     */
    // mutipUploadTaskKey(uploadTask) {
    //     const folderId = uploadTask.folderId
    //     const docType = uploadTask.docType
    //     return this.ajax.post(`${this.moduleName}/multipart/upload/apply?parentId=${folderId}&name=${uploadTask.name}&docType=${docType}`)
    // }

    /**
     * 分片上传 获取上传任务进度信息
     * @param {*} uploadTask 
     */
    // mutipUploadTaskScross(uploadTask) {
    //     const uploadKey = uploadTask.uploadKey
    //     const postData = {
    //         parentId: uploadTask.folderId,
    //         fileName: uploadTask.name,
    //         size: uploadTask.size,
    //         signKey: uploadTask.md5,
    //         attrs: uploadTask.attrs
    //     }
    //     return this.ajax.post(`${this.moduleName}/multipart/upload/check`, postData, {
    //         headers: {
    //             "X-Upload-Key": uploadKey
    //         }
    //     })
    // }

    /**
     * 分片上传
     * @returns 
     */
    mutipUploadFile(uploadTask, uploadKey) {
        return this.ajax.post(`${this.moduleName}/multipart/upload`, uploadTask, {
            headers: {
                "X-Upload-Key": uploadKey
            }
        })
    }

    /**
     * 分片合并
     * @returns 
     */
    mutipUploadMergeFiles(uploadKey) {
        return this.ajax.put(`${this.moduleName}/file/merge`, {}, {
            headers: {
                "X-Upload-Key": uploadKey
            }
        })
    }

}