<template>
    <div>
        <el-dialog
            title="申请记录信息"
            :close-on-click-modal="false" 
            :append-to-body='true'
            :visible.sync="dialogVisible">
            <div class="apply-record-dialogs">
                <dl>
                    <dt>
                        <i class="el-icon-document"></i>
                    </dt>
                    <dd>
                        <div class="record-dialog-title">
                            <span>{{myApplyDetail.shareName}}</span>
                            <span :style="myApplyDetail.state|applyStateColor">状态：{{myApplyDetail.state|applyState}}</span>
                        </div>
                        <div>
                            <p>
                                <span>申请时间：{{myApplyDetail.createDate|date}}</span>
                                <span>申请原因：{{myApplyDetail.description}}</span>
                            </p>
                        </div>
                    </dd>
                </dl>
                <div class="apply-record-list">
                    <p>
                        <span>申请文件列表</span>
                    </p>
                    <ul>
                        <li v-for="(item,index) in viewer.showFiles" :key="index" @click="render(item)">
                            <el-image :src="item | thumbIcon"></el-image>
                            <span>{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button v-if='myApplyDetail && myApplyDetail.state == 0' size="small" type="warning" @click="doRepeal">撤 销</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
                
            </span>
        </el-dialog>
     
    </div>
</template>
<script>
  import { requestAPI } from 'liankong-ui-api' 
export default {
    data(){
        return {
            dialogVisible: false,
            verker:'shareDocList',
            myApplyDetail: {}
        }
    },
    computed: {
        viewer() {
            return this.$store.state.doc.viewer[this.verker] || {}
        }
    },
    methods: {
        onRecordInit(){
            this.myApplyDetail = this.$mapData.get('keepMyApplyDetail')
            this.$store.commit('doc/createViewer', {
                viewerKey: this.verker,
                defaultFiles: this.myApplyDetail.resources
            })
        },
        // 跳转ofd页面
        render(docInfo){
            docInfo.documentId ? docInfo.documentId = docInfo.documentId : docInfo.documentId = docInfo.id
            if(docInfo.type==0){
                this.$store.dispatch("doc/loadChildrenByShare", {
                    vkey: this.verker,
                    folder: docInfo
                })
                return
            }

            this.$ebus.$emit('publicReader',{
                shareId:this.myApplyDetail.shareId, 
                id: docInfo.documentId || docInfo.id,
                documentId: docInfo.documentId,
                name:docInfo.name,
                suffix:docInfo.suffix
            })
            
        },
        //撤销申请
      doRepeal(){
        this.loadingShow('处理中...')
        requestAPI.repeal(this.myApplyDetail)
          .then(({code, data, message}) => {
             this.loadingHide()
            if(code == 0){
              //更新数据
              this.$message.success('撤销成功！')
              this.$emit('getRequestList')
              this.myApplyDetail.state = 3;
            }else{
              this.$message.error("处理失败，请重试!")
            }
          }).catch(err => {
             this.loadingHide()
          })
      },
    }
}
</script>
<style lang="less" scoped>
.apply-record-dialogs {
    dl {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        dt {
            padding: 0;
            margin: 0;
            i {
                font-size: 32px;
                margin-right: 5px;
                color: #ffaf1d;
            }
        }
        dd {
            flex: 1;
            padding: 0;
            margin: 0;
            .record-dialog-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                span:nth-child(1){
                    font-size: 16px;
                    font-weight: 600;
                    color: #009DD9;
                }
            }
            p {
                padding: 0;
                margin: 0;
                span {
                    margin-right: 15px;
                }
            }
        }
    }
    .apply-record-list {
        p {
            display: flex;
            margin: 20px 0 5px 0;
            span{
                position: relative;
                padding-left: 20px;
                font-size: 15px;
                &::before{
                    content: '';
                    // width: 3px;
                    // height: 18px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #409eff;
                    position: absolute;
                    left: 0px;
                    top: 4px;
                }
            }
        }
        ul {
            margin: 0px;
            padding: 0px;
            li {
                list-style: none;
                height: 45px;
                line-height: 45px;
                border-bottom: 1px solid #f2f2f2;
                font-size: 14px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .el-image {
                    margin-right: 5px;
                    width: 21px;
                    height: 21px;
                    img {
                        display: block;
                    }
                }
            }
            li:hover {
                color: #409eff;
            }
        }
    }
}
</style>