/**
 * Client 服务
 */

export default class Client{
  constructor(){

    this.baseWSUrl = `wss://api.scfile.cn/ws`
    this.opened = false
    this.client = null
  }

  /**
   * 初始化连接
   */
  open(){

  }

  /**
   * 关闭连接
   */
  close(){
    console.log("关闭连接")
    if(this.opened){
      this.client.close()
    }
  }

  /**
   * 获取通道
   * @param {*} path 
   * @param {*} messageHandler 
   */
  getChannel(path, messageHandler){
    console.log("打开通道:", path)
    if(this.opened){
      const subscription = this.client.subscribe(path, (frame, headers) => {
        const body = JSON.parse(frame.body)
        //处理消息
        messageHandler(body)
      })

      //subscription.unsubscribe() 可以取消
      return subscription
    }else{
      console.log("当前未连接")
      return null
    }
  }
}