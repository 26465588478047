import BaseAPI from '../BaseAPI.js'

/**
 * 分享服务API
 * 
 * @author Daniel
 * @date 
 */
export default class ShareAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/share"
    }

    /**
     * 创建分享
     * @param {*} shareInfo 
     */
    create(shareInfo) {
        // return Promise.resolve({
        //   code: 0,
        //   data: '2f79537ba84f405d99110995a7550344',
        //   message: null
        // })
        return this.ajax.post(`${this.moduleName}/addShare`, shareInfo)
    }

    /**
     * 更新分享
     * @param {
     *  id: 分享ID，必填字段，根据ID进行更新
     * } shareInfo 
     */
    update(shareInfo) {
        return this.ajax.post(`${this.moduleName}/updateShare`, shareInfo)
    }

    /**
     * 更新分享时间
     * @param {
     *  id: 分享ID，必填字段，根据ID进行更新
     * } shareInfo 
     */
     updateShareExpireTime(shareInfo) {
        return this.ajax.post(`${this.moduleName}/updateShareExpireTime`, shareInfo)
    }

    /**
     * 通过分享ID获取分享
     * @param {*} shareId 
     */
    detailById(shareId) {
        return this.ajax.get(`${this.moduleName}/findShare/${shareId}`)
    }

    /**
     * 通过URL Key获取分享
     * @param {*} urlKey 
     */
    detailByUrl(urlKey) {
        return this.ajax.get(`${this.moduleName}/${urlKey}/detail`).then(res => {
            if (res.code == 0 && res.data.resources) {
                res.data.resources.map(item => item.name = item.documentName)
            }
            return Promise.resolve(res)
        })
    }

    /**
     * 删除分享
     * @param {*} shareId 
     */
    remove(shareId) {
        return this.ajax.delete(`${this.moduleName}/${shareId}`)
    }

    /**
     * 启用分享
     * @param {*} shareId 
     */
    enable(shareId) {
        return this.ajax.post(`${this.moduleName}/backShare/${shareId}/0`)
    }

    /**
     * 停止分享
     * @param {*} shareId 
     */
    disable(shareId) {
        return this.ajax.post(`${this.moduleName}/backShare/${shareId}/3`)
    }

    /**
     * 我的分享列表
     * @param {*} query 
     */
    myShareList(query = {}) {
        return this.ajax.post(`${this.moduleName}/queryShareWithMe`, query)
    }

    /**
     * 分享给我列表
     * @param {*} query 
     */
    receiveShareList(query) {
        return this.ajax.post(`${this.moduleName}/queryShareToMe`, query)
    }

    /**
     * 分享中阅读文件授权校验
     * @param {*} shareId 分享
     * @param {*} code 
     */
    /* readCheck(shareId, code){
      return this.ajax.post(`${this.moduleName}/shareIn?shareid=${shareId}&code=${code}`)
    } */

    /**
     * 分享中，点击文件阅读，获取阅读地址
     * @param {*} shareId 
     * @param {*} documentId 
     * @param {*} documentRootId 
     */
    getReaderUrl(shareId, documentId, documentRootId, documentName, suffix ,targetResourceId , model) {
        return this.ajax.post(`${this.moduleName}/reader/url`, {
            shareId,
            documentId,
            documentRootId,
            documentName,
            suffix,
            targetResourceId,
            model
        })
    }


    /**
     * 个人数据统计
     * @param {*} shareId 
     */
    myCount() {
        return this.ajax.get(`${this.moduleName}/request/myCount`)
    }

    /**
     * 修改分享权限--脱敏数据
     * @param {*} shareId 
     */
    resetEnvelop(shareId, query) {
        return this.ajax.post(`${this.moduleName}/${shareId}/update/envelops`, query)
    }

    /**
     * 设置遮掩区域
     * @param {*} shareId 
     */
    addShadeArea(query) {
        return this.ajax.post(`${this.moduleName}/add/shade/area`, query)
    }

    /**
     * 获取遮掩区域
     * @param {*} shareId 
     */
     getShadeAreaById(id) {
        return this.ajax.get(`${this.moduleName}/area/getByAreaId/${id}`)
    }
     /**
     * 删除遮掩区域
     * @param {*} shareId 
     */
      deleteShadeAreaById(id) {
        return this.ajax.delete(`${this.moduleName}/area/${id}`)
    }


    /**
     * 再次分享
     * @param {*} shareInfo 
     */
    shareAgain(shareInfo) {
        return this.ajax.post(`${this.moduleName}/multiShare`, shareInfo)
    }

     /**
     * 创建分享添加水印-水印列表
     * @param {*} shareInfo 
     */
      shareWatherList(shareInfo) {
        return this.ajax.post(`/water/query`, shareInfo)
    }

     /**
     * 创建分享添加水印-添加水印
     * @param {*} shareInfo 
     */
      addShareWather(shareInfo) {
        return this.ajax.post(`/water/save`, shareInfo)
    }

     /**
     * 创建分享添加水印-更新水印
     * @param {*} shareInfo 
     */
      updateShareWather(shareInfo) {
        return this.ajax.post(`/water/update`, shareInfo)
    }

     /**
     * 创建分享添加水印-删除水印
     * @param {*} shareInfo 
     */
      deleteShareWather(shareInfo) {
        return this.ajax.post(`/water/del?id=${shareInfo.id}`)
    }

    /**
     * 创建分享添加水印-阅读器查看水印
     * @param {*} shareInfo 
     */
    getShareWatherDetail(shareInfo) {
        return this.ajax.get(`/water/${shareInfo}`)
    }

    // 阅读器打印权限
    checkUserSharePermission(query){
        return this.ajax.post(`${this.moduleName}/checkUserSharePermission`,query)
    }
    


}