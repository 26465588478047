import BaseAPI from '../BaseAPI.js'

/**
 * 统计服务API
 * 
 * @author Daniel
 * @date 
 */
export default class StatisticsAPI extends BaseAPI{

  constructor(){
    super()

  }

  /**
   * 当前用户分享数
   * @return {
   *  
   * }
   */
  userShareCount(){
    return this.ajax.get(`/share/request/myCount`)
  }

  /**
   * 当前用户文档数
   * @return {
   *  data: {
   *    userUploadCount: 上传的总文档数
   *  }
   * }
   */
  userDocumentCount(){
    return this.ajax.get(`/document/user/statistics`)
  }

  /**
   * 当前用户收到的分享数
   */
  userShareToMeCount(){

  }

}