import BaseAPI from '../BaseAPI.js'

/**
 * 管理平台-用户模块
 */

export default class UserManagerAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/usercenter"
    }

    // 获取用户分页列表
    getUserList(data) {
        return this.ajax.post(`${this.moduleName}/user/list`, data)
    }

    // 删除
    deleteUser(userId) {
        return this.ajax.delete(`${this.moduleName}/user/${userId}`)
    }

    // 添加
    addUser(data) {
        return this.ajax.post(`${this.moduleName}/user`, data)
    }

    // 修改
    editUser(data) {
        return this.ajax.put(`${this.moduleName}/user`, data)
    }

    // 获取角色列表
    getRolesData(type) {
        return this.ajax.get(`${this.moduleName}/role/list/${type}`)
    }

    // 查询详情信息
    getDetailUserInfo(userId) {
        return this.ajax.get(`${this.moduleName}/user/${userId}`)
    }

}