import BaseAPI from '../BaseAPI.js'

/**
 * 群组服务API
 * 
 * @author Daniel
 * @date 
 */
export default class GroupAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/usercenter"
    }


    /**
     * 创建群组
     * @param {*} group 
     */
    create(group) {
        return this.ajax.post(`${this.moduleName}/group`, group)
    }

    /**
     * 获取子级群组(Tree结构)
     * @param {*} group 
     */
    queryChildrenTree(group) {
        return this.ajax.post(`${this.moduleName}/group/tree`, {
            parentId: group.id
        })
    }

    /**
     * 获取子级群组(List结构)
     * @param {*} group 
     */
    queryChildren(query) {
        return this.ajax.post(`${this.moduleName}/group/getUserGroup`, query)
    }

    /**
     * 移除群组
     * @param {*} group 
     */
    remove(query) {
        return this.ajax.delete(`${this.moduleName}/group/${query.id}`)
    }


    /**
     * 返回群组层级
     * @param {*} query 
     */
    groupTree(query) {
        return this.ajax.post(`${this.moduleName}/group/tree`, query)
    }

    /**
     * 群组重命名
     * @param {*} query 
     */
    renameGroup(query) {
        return this.ajax.put(`${this.moduleName}/group/rename`, query)
    }

    /**
     * 群组添加成员
     * @param {*} query 
     */
    addGroupMember(query) {
        return this.ajax.post(`${this.moduleName}/group/add/member`, query)
    }

    /**
     * 群组移除成员
     * @param {*} query 
     */
    removeGroupMember(query) {
        return this.ajax.delete(`${this.moduleName}/group/remove/member`, query)
    }

    /**
     * 组织删除成员
     * @param {*} query 
     * @returns 
     */
    removeOrgMembers(query) {
        return this.ajax.delete(`${this.moduleName}/organization/member/remove`, query)
    }

}