<template>
  <el-select
    v-model="value"
    size='small'
    class="share-user-search"
    :disabled="shareAccessType == 'PUBLIC'"
    filterable
    remote
    :remote-method="queryUser"
    placeholder="用户名"
    @change="selected"
    :loading="loading">
    <el-option v-if="phoneOption" :key="phoneOption.value" :label="phoneOption.label" :value="phoneOption"></el-option>
    <el-option-group
      v-for="group in options"
      :key="group.value"
      :label="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.value"
        :label="item.label"
        :value="item">
        <span style="float: left">{{ item.label }}</span>
        <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import {getGroupList} from '@/api/org.js'

export default {
  components: {},
  props: {
    shareAccessType: {
      type: String,
      default: 'PUBLIC'
    }
  },
  data () {
    return {
      value: null,
      loading: false,
      options: [],
      phoneOption: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    queryUser(value){
      this.loading = true

      this.$store.dispatch('auth/searchTarget', {
        name: value, 
        phoneNumber: value
      }).then(searchData => {
        
        this.options = [{
          label: '用户搜索结果',
          options: searchData
        }]

        this.loading = false
      })


      //直接选择
      /* if(/^1\d{10}$/.test(value)){
        this.phoneOption = {
          label: `添加手机授权：${value}`,
          type: "TARGET_PHONE",
          value
        }
      }else{
        this.phoneOption = null
      } */
      
      /* Promise.all([
        getGroupList({name: value}),
        this.$store.dispatch("user/loadAllUser", {name: value, phoneNumber: value})])
      .then(alldata => {
        const groupData = alldata[0]
        const data = alldata[1]

        this.loading = false

        let users = []
        if(data.code == 0){
          users = data.data.records.map(u => {
            return {
              faceUrl: u.faceUrl,
              label: `${u.name}(${u.phoneNumber})`,
              value: u.id,
              type: "TARGET_ID"
            }
          })
        }

        let group = []
        if(groupData.code == 0){
          group = groupData.data.records.map(g => {
            return {
              label: `${g.name}`,
              value: g.id,
              type: "TARGET_ORG"
            }
          })
        }
        
        this.options = [
          {
            label: '组织搜索结果',
            options: group
          },
          {
            label: '用户搜索结果',
            options: users
          }
        ]
      }) */
    },

    //选择目标
    selected(value){
      this.$emit("onUserSelected", value)

      this.value = null
      this.phoneOption = null
      this.options = []
    },
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.share-user-search{
  width: 100%;
  flex-grow: 2;
  
}
</style>