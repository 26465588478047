import BaseAPI from '../BaseAPI.js'

/**
 * 文档工具集
 */

export default class DocumentToolsAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/document"
    }

    /**
     * 上传
     * @param {*} query 
     * @returns 
     */
    documentToolUpload(query) {
        return this.ajax.post(`${this.moduleName}/tool/upload`, query, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    /**
     * 简单转换接口
     * @param {*} convertTempId 
     * @returns 
     */
    documentCovertList(convertTempId) {
        return this.ajax.get(`${this.moduleName}/tool/simple/convert/${convertTempId}`)
    }

    /**
     * 图片ocr识别
     * @param {*} convertTempId 
     * @returns 
     */
    documentOCRTips(convertTempId) {
        return this.ajax.get(`${this.moduleName}/tool/orc/${convertTempId}`)
    }

    /**
     * 文件操作
     * @param {*} query 
     * @returns 
     */
    documentFormatMerge(query) {
        return this.ajax.post(`${this.moduleName}/tool/file/operation`, query)
    }

    /**
     * 获取历史列表数据
     * @param {*} query 
     * @returns 
     */
    getHistoryPageList(query) {
        return this.ajax.post(`${this.moduleName}/tool/history/list`, query)
    }

    /**
     * 历史数据 下载
     * @param {*} convertFileId 
     * @returns 
     */
    historyDowmData(convertFileId, token) {
        const url = `${this.moduleName}/tool/download/${convertFileId}?access_token=${token}`
        window.location.href = `/prod-api` + url
    }

    /**
     * 历史数据 归档
     * @param {*} convertFileId 
     * @returns 
     */
    historyArchiveData(convertFileId,parentId) {
        return this.ajax.post(`${this.moduleName}/tool/archive/${convertFileId}/${parentId}`)
    }
    /**
     * 文档工具添加水印
     */
    addMask(query){
        return this.ajax.post(`${this.moduleName}/tool/add/mask`,query)
    }

}