import BaseAPI from '../BaseAPI.js'

/**
 * 文件转换
 */
export default class ToolConvertAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/tc"
        this.dictModuleName = '/dict' // 上传文件
    }

    /** pdf转word 支持:pdf */
    pdfConverWord(query) {
            return this.ajax.post(`${this.moduleName}/async/convert/2word`, query)
        }
        /** pdf转Excel 支持:pdf */
    pdfConverExcel(query) {
            return this.ajax.post(`${this.moduleName}/async/convert/2excel`, query)
        }
        /** pdf转Img 支持:pdf */
    pdfConverImage(query) {
            return this.ajax.post(`${this.moduleName}/async/convert/2image`, query)
        }
        /** pdf转html 支持:pdf */
    pdfConverHtml(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2html`, query)
    }

    /** pdf转ppt 支持:pdf */
    pdfConverPpt(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2ppt`, query)
    }

    /** ppt转pdf 支持:doc,docx,ppt,pptx,ofd,xls,xlms */
    pptConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2pdf`, query)
    }

    /** pfd提取图片 */
    pdfConverImg(query) {
        return this.ajax.post(`${this.moduleName}/async/content/extract/image`, query)
    }

    /** doc,docx,ppt,pptx,pdf转换ofd */
    pdfConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2ofd`, query)
    }

    /** pdf拆分 */
    pdfSpilt(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/split`, query)
    }

    /** pdf合并 */
    pdfMerge(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/merge`, query)
    }

    /** pdf添加水印 */
    pdfWaterMark(query) {
        return this.ajax.post(`${this.moduleName}/async/security/watermark`, query)
    }

    /** pdf去除水印 */
    pdfRemoveWater(query) {
        return this.ajax.post(`${this.moduleName}/async/security/watermark/remove`, query)
    }

    /** 裁剪图片 */
    cropPicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/cropping`, query)
    }

    /** 调整图片大小 */
    adjustPicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/resize`, query)
    }

    /** 旋转图片 */
    rotatePicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/rotate`, query)
    }
    /** 图片模糊 */
    vaguePicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/vague`, query)
    }

    /** 压缩图片 */
    crompressPicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/condense`, query)
    }

    /** 获取异步处理结果 */
    getOpertateResult(query) {
        return this.ajax.get(`${this.moduleName}/result`, query)
    }

    /** pdf加密 */
    pdfEncrypt(query) {
        return this.ajax.post(`${this.moduleName}/async/security/encrypt`, query)
    }

    /**pdf解密 */
    pdfDecrypt(query) {
        return this.ajax.post(`${this.moduleName}/async/security/decrypt`, query)
    }

    /** 图片转换ofd */
    imageConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/image2ofd`, query)
    }

    /** 图片转换pdf */
    imageConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/image2pdf`, query)
    }

    /** html转换ofd 上传压缩包 */
    htmlConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/html2ofd`, query)
    }

    /** html转换pdf 上传压缩包 */
    htmlConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/html2pdf`, query)
    }

    /** pdf删除页眉页脚 */
    pdfDelHeader(query) {
        return this.ajax.post(`${this.moduleName}/async/content/header/delete`, query)
    }

    /** pdf文件压缩 */
    pdfCondenseHandle(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/condense`, query)
    }

    /** html转换ofd 传字符串 */
    htmlStrConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/htmlStr2ofd`, query)
    }

    /** html转换pdf 传字符串 */
    htmlStrConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/htmlStr2pdf`, query)
    }

    /** pdf加页码 */
    pdfPageNumer(query) {
        return this.ajax.post(`${this.moduleName}/async/content/page`, query)
    }

    /** pdf旋转 */
    pdfRotate(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/doc/rotate`, query)
    }

    /** pdf加页眉/页脚 */
    pdfAddHeader(query) {
        return this.ajax.post(`${this.moduleName}/async/content/header`, query)
    }

    /** 获取所有的字体 */
    getAllFontFamily() {
        return this.ajax.get(`${this.moduleName}/font/select`)
    }

     /** onlyoffice配置接口 */
     getOnlyOfficeConfig(query) {
        return this.ajax.get(`${this.dictModuleName}/onlyOffice/config`,query)
    }

}