/**
 * 组织api
 */
import * as http from '../utils/http/index.js';

const moduleName = "/usercenter"

/**
 * 加载组织信息
 * @param {*} id 
 */
export function getOrgInfo(id){
  return http.get(`${moduleName}/organization/${id}`)
}

/**
 * 创建分组
 * @param {*} group 
 */
export function createGroup(group){
  return http.post(`${moduleName}/group`, group)
}

/**
 * 获取子级部门
 * @param {*} group 
 */
export function getChildren(group){
  return http.post(`${moduleName}/group/tree`, {
    parentId: group.id
  })
}

/**
 * 部门组织列表
 * @param {*} query 
 */
export function getGroupList(query){
  return http.post(`${moduleName}/group/list`, query)
}

/**
 * 删除分组
 * @param {*} group 
 */
export function removeGroup(group){
  return http.del(`${moduleName}/group/${group.id}`)
}

/**
 * 获取会员列表
 * @param {*} query 
 */
export function memberList(query){
  return http.post(`${moduleName}/group/member/list`, query)
}

/**
 * 组织添加用户
 * @param {*} orgId 
 * @param {*} userId 
 */
export function addOrgUser(orgId, userId){
  return http.post(`${moduleName}/organization/member/add`, {
    id: orgId,
    userId: userId
  })
}

/**
 * 分组添加用户
 * @param {*} groupId 
 * @param {*} userId 
 */
export function addGroupUser(groupId, userId){
  return http.post(`${moduleName}/group/add/member`, {
    id: groupId,
    userId: userId
  })
}