/**
 * 登陆状态管理
 */
import { orgAPI } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'
/**
 * 基础列表页面视图模型
 */
const BaseListPageViewer = {
    query: {
        pageNum: 1,
        pageSize: 20
    },
    paging: {
        total: 0,
        pages: 0
    },
    list: [],
    paths: [],
    depth: 0, //层级

    //加载更多
    more: {
        status: 'more',
        contentText: {
            contentdown: '加载更多数据',
            contentrefresh: '数据加载中',
            contentnomore: '没有更多数据了'
        }
    }
}

const state = {
    views: {
        list: [],
        departmentList: [],
        member: deepCopy(BaseListPageViewer),
        org: deepCopy(BaseListPageViewer),
        group: deepCopy(BaseListPageViewer),
        apply: deepCopy(BaseListPageViewer)
    }

}

const mutations = {
    //  更新组织列表 无分页
    updateUserOrgList(state, { data }) {
        const viewer = state.views;
        viewer.list = data
    },
    /**
     * 更新视图列表
     * @param {*} state 
     * @param {*} vkey
     * @param {*} rest
     * @param {*} data
     */
    updateList(state, { vkey, reset = false, current, pages, data, total }) {
        const viewer = state.views[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data);
    },

    /**
     * 更新视图查询条件
     * @param {*} state 
     */
    updateQuery(state, { vkey, query }) {
        const viewer = state.views[vkey]
        viewer.query = Object.assign(viewer.query, query)
    },

    // 更新部门列表 无分页 群组部门在group里
    updateDepartList(state, { data }) {
        const viewer = state.views;
        viewer.departmentList = data
    },

    //进入目录
    addPaths(state, { vkey, current }) {
        const viewer = state.views[vkey]

        if (!current.children) {
            current.children = []
        }

        viewer.current = current
        viewer.showFiles = []
        viewer.depth++
            viewer.paths.push(current)
        viewer.loading = true
    }

}

const actions = {
    /**
     * 
     * @param {*} id 
     */
    async orgInfoById({ commit }, { id }) {
        try {
            const { code, data } = await orgAPI.orgInfoById(id)
            return { data }
        } catch (error) {
            throw error
        }
    },

    //获取组织列表
    async getUserOrgList({ commit }) {
        try {
            const { code, data } = await orgAPI.getUserOrgList()
            if (code == 0) {
                commit('updateUserOrgList', { data })
            }
            return { code, data }
        } catch (error) {
            throw error
        }
    },

    /**
     * 创建组织
     * @param {*} query
     */
    async createOrg({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.createOrg(query)
            return { code, message }

        } catch (error) {
            throw error
        }
    },

    /**
     * 创建部门/群组
     * @param {*} id
     *  @param {*} name
     * organizationId 如果传组织id就是创建部门，如果不传就是创建群组
     */
    async createDepartment({ dispatch }, query) {
        try {
            const { code, data, message } = await orgAPI.createDepartment(query)
            return { code, message }

        } catch (error) {
            throw error
        }
    },

    /**
     * 获取群组列表
     * @param {*} id
     */
    async getGroupList({ dispatch }, id) {
        try {
            const { code, data, message } = await orgAPI.getGroupList(id)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 分页获取组成员列表
     * @param {*} query
     */
    async getGroupPeopleList({ commit, state }, { reset = false, query }) {

        // try {
        const viewerKey = "member"
        const viewer = state.views[viewerKey]
            //是否有更多查询

        if (!reset && viewer.more.status === 'noMore') {
            return
        }
        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })


        //重置条件
        if (reset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await orgAPI.getGroupPeopleList(query);
        if (code == 0) {
            commit("updateList", {
                vkey: viewerKey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
            // } catch (error) {
            //     throw error
            // }
    },

    /**
     * 组织重命名
     * @param {*} code 组织结构码
     * @param {*} name 组织名称
     * @param {*} id 组织id
     * 
     */
    async renameOrganization({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.renameOrganization(query)
            if (code == 0) {
                commit('updateDepartList', { data })
            }
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 删除组织
     * @param {*} id 组织id
     */
    async deletaOrganization({ commit }, id) {
        try {
            const { code, data, message } = await orgAPI.deletaOrganization(id)
            if (code == 0) {
                commit('updateDepartList', { data })
            }
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 添加组织用户
     * @param {*} id 组织id
     * @param {*} userId 用户id
     * 
     */
    async addOrgMember({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.addMember(query)
            if (code == 0) {
                commit('updateDepartList', { data })
            }
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 申请加入组织
     * @param {*} id 申请加入的 组织/群组id
     * @param {*} description 申请原因描述
     */
    async applyJoinOrg({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.applyJoinOrg(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 申请加入 部门
     * @param {*} id 申请加入的 组织/群组id
     * @param {*} description 申请原因描述
     */
    async applyJoinGroup({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.applyJoinGroup(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },


    /**
     * 管理员审批加入组织
     * @param {*} query
     */
    async approveJoin({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.approveJoin(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 组织转移
     * @param {*} id 组织id/组id
     * @param {*} targetUserId 目标用户id
     * @param {*} type 1是组织 2是群组
     */
    async orgTransfer({ commit }, query) {
        try {
            const { code, data, message } = await orgAPI.orgTransfer(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },
    /**
     * 未分配部门的成员列表
     * @param {*} id 组织id
     */
    async noGroupedMember({ commit, state }, { reset = false, query }) {
        const viewerKey = "member"
        const viewer = state.views[viewerKey]
            //是否有更多查询

        if (!reset && viewer.more.status === 'noMore') {
            return
        }
        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })


        //重置条件
        if (reset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await orgAPI.noGroupedMember(query);
        if (code == 0) {
            commit("updateList", {
                vkey: viewerKey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },
    /**
     * 检索组织
     * @param {*} 
     */
    async searchOrg({ commit }, { reset = false, query }) {
        const viewerKey = "org"
        const viewer = state.views[viewerKey]
            //是否有更多查询

        if (!reset && viewer.more.status === 'noMore') {
            return
        }
        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })


        //重置条件
        if (reset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await orgAPI.searchOrg(query);
        if (code == 0) {
            commit("updateList", {
                vkey: viewerKey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },

    /**
     * 根据组织id获取部门列表
     * @param {*} id 组织id
     */
    async groupList({ commit }, { reset = false, query }) {
        const viewerKey = "group"
        const viewer = state.views[viewerKey]
            //是否有更多查询

        if (!reset && viewer.more.status === 'noMore') {
            return
        }
        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })


        //重置条件
        if (reset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await orgAPI.groupList(query);
        if (code == 0) {
            commit("updateList", {
                vkey: viewerKey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },

    /**
     * 申请加入组织的列表
     */
    async getOrgApplyList({ commit }, { reset = false, query }) {
        const viewerKey = "apply"
        const viewer = state.views[viewerKey]
            //是否有更多查询

        if (!reset && viewer.more.status === 'noMore') {
            return
        }
        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })


        //重置条件
        if (reset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await orgAPI.getOrgApplyList(query);
        if (code == 0) {
            commit("updateList", {
                vkey: viewerKey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },

    //获取组织文档下 组织列表
    async getMyOrgTreeLists({ commit }) {
        try {
            const { code, data } = await orgAPI.getMyOrgTreeLists()
            if (code == 0) {
                commit('updateUserOrgList', { data })
            }
            return { code, data }
        } catch (error) {
            throw error
        }
    },

    // 导出
    async exportOrgInfo({ commit }) {
        try {
            const data = await orgAPI.exportOrgInfo()
            return data
        } catch (error) {
            throw error
        }
    },

    // 导入
    async exportUploadOrg({ commit }, query) {
        try {
            const data = await orgAPI.exportUploadOrg(query)
            return data
        } catch (error) {
            throw error
        }
    },

    // 退出组织
    async outOrgEvent({ commit }, query) {
        try {
            const data = await orgAPI.getOutOrg(query)
            return data
        } catch (error) {
            throw error
        }
    }


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}