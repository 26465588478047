import BaseAPI from '../BaseAPI.js'

/**
 * 管理平台-日志模块、字典
 */

export default class LogsManagerAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/usercenter"

    }

    // 查询日志
    getLogLists(data) {
        return this.ajax.post(`${this.moduleName}/log/page`, data)
    }

    // 字典类型 /dict/type
    dictTypes(data) {
        return this.ajax.post(`/dict/dict/type/list`, data)
    }

    // 字典数据
    dictList(data) {
        return this.ajax.get(`/dict/dict/data/type/${data}`)
    }

    // 添加字典类型
    dictTypeAdds(data) {
        return this.ajax.post(`/dict/dict/type`, data)
    }

    // 修改字典类型
    dictTypeEdits(data) {
        return this.ajax.put(`/dict/dict/type`, data)
    }

    // 删除字典类型
    deleteDictType(id) {
        return this.ajax.delete(`/dict/dict/type/${id}`)
    }

    // 添加字典数据
    dictListAdds(data) {
        return this.ajax.post(`/dict/dict/data`, data)
    }

    // 修改字典数据
    dictListEdits(data) {
        return this.ajax.put(`/dict/dict/data`, data)
    }

    // 删除字典数据
    deleteListEdits(id) {
        return this.ajax.delete(`/dict/dict/data/${id}`)
    }

}