/**
 * 本地存储缓存
 */
import mapData from './MapData.js'

const storeProvider = window.localStorage
const cacheProvider = window.sessionStorage

const encode = (data) => {
  return data ? JSON.stringify(data) : null
}

const decode = (data) => {
  return data ? JSON.parse(data) : null
}

export const Cache = {
  clear: () => cacheProvider.clear(),
  put: (key, value) => {
    if(value){
      value = encode(value)
    }
    cacheProvider.setItem(key, value)
  },
  get: (key) => {
    const value = cacheProvider.getItem(key)
    if(value){
      return decode(value)
    }
    return value
  },
  del: (key) => {
    cacheProvider.removeItem(key)
  }
}

export const  Store = {
  clear: () => storeProvider.clear(),
  put: (key, value) => {
    if(value){
      value = encode(value)
    }
    storeProvider.setItem(key, value)
  },
  get: (key) => {
    const value = storeProvider[key]
    if(value){
      return decode(value)
    }
    return value
  },
  del: (key) => {
    storeProvider.removeItem(key)
  }
}

export const MapData = mapData