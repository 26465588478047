<template>
    <div class="header" style="box-shadow: 0 2px 5px 0 rgb(0 21 41 / 8%);">
        <Logo></Logo>
        <Menu></Menu>
        <Tools></Tools>
    </div>
</template>

<script>
import Logo from "../Logo.vue"  
import Menu from '../Menu.vue'
import Tools from "../Tools.vue"

export default {
    components: {
        Logo,
        Menu,
        Tools
    },
    computed: {
      isApp(){
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
      }
    },
    watch: {},
    methods: {

    },
    created () {
      //TODO 公共
    if(this.$store.state.login.token){
        // this.$store.dispatch("upload/loadMijiRoles")
        // this.$store.dispatch("org/getOrgInfo")
        // this.$store.dispatch("org/getChildren")
        // this.$store.dispatch("message/myList")
      }
    },
    mounted() {
      // this.$store.dispatch("home/loadMessage");
    },
};
</script>
<style lang='less' scoped>
.header {
    display: flex;
    height: 60px;
    background: #fff;
    // border-bottom: 1px solid #f2f2f2;
}
</style>