import BaseAPI from '../BaseAPI.js'

/**
 * 用户服务API
 * 
 * @author Daniel
 * @date 
 */
export default class UserAPI extends BaseAPI{

  constructor(){
    super()

    this.moduleName = "/usercenter"
  }

  /**
   * 获取当前用户的个人信息
   * 
   */
  getInfo(){
    return this.ajax.get(`${this.moduleName}/user/getUserDetails`)
  }

  /**
   * 获取指定用户信息
   * @param {*} userName 
   */
  getInfoByUserName(userName){
    return this.ajax.get(`${this.moduleName}/user/getUserInfoByUserName?username=${userName}`)
  }

  /**
   * 获取用户列表
   * @param {*} query 
   */
  list(query){
    return this.ajax.post(`${this.moduleName}/user/list`, query)
  }

  /**
   * 重置密码
   * @param {*} data 
   */
  resetPassword(data){
    return this.ajax.post(`${this.moduleName}/user/bind/login/password`, data)
  }

   /**
   * 查询当前用户的个人消息
   * 
   */
  getInfoAll(){
    return this.ajax.get(`${this.moduleName}/user/queryAll`)
  }

  /**
   * 更新用户信息
   */
  update(userInfo){
    return this.ajax.put(`${this.moduleName}/user/update`, {
      username: userInfo.username,
      sex: userInfo.sex,
      birthday: userInfo.birthday,
      email: userInfo.email
    })
  }


  /**
   * 获取偏好设置
   */
   getPreference(){
    return this.ajax.get(`${this.moduleName}/preference`, {})
  }

  
  /**
   * 设置偏好
   */
  setPreference(query){
    return this.ajax.post(`${this.moduleName}/preference`, query)
  }

  // 获取当前用户使用空间
  getUserUsedDist(){
    return this.ajax.get(`document/user/dist/total`)
}
}