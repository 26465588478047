<template>
  <el-footer class="footer"></el-footer>
</template>

<script>

export default {
  components: {},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  methods: {

  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.footer{
  height: 34px!important;
  line-height: 34px;
  font-size: 12px;
  color: #000;
  text-align: center;
  font-size: 14px;
}
</style>