<template>
    <div class="file-version-list">
        <el-table
        size='small'
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange">
            <el-table-column v-if='viewerKey == "myCreate" && from !="detail"'
                type="selection"
                width="50">
            </el-table-column>
            <el-table-column
                width='150'
                prop="ownerName"
                label="姓名">
            </el-table-column>
            <el-table-column
                v-if='from !="merge" '
                width="60"
                align="center"
                label="状态">
                <template slot-scope="scope">
                    <span v-if='scope.row.state == 0' style="color:#E6A23C">编辑中</span>
                    <span v-else-if='scope.row.state == 1' style="color:#67C23A">已提交</span>
                </template>
            </el-table-column>
            <el-table-column
                width="140"
                align="center"
                label="授权类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.targetType == 'TARGET_PHONE'">手机授权</span>
                    <span v-else-if="scope.row.targetType == 'TARGET_ORG'">组织授权</span>
                    <span v-else-if="scope.row.targetType == 'TARGET_GROUP'">部门授权</span>
                    <span v-else>用户授权</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateDate"
                label="操作时间"
                align="center"
                width="150">
            </el-table-column>
            <el-table-column
                align="center"
                label="操作">
                <template slot-scope="scope">
                    <el-button size='mini' v-if='viewerKey == "myCreate" || scope.row.onwerUserId != String(userId)' type="success" @click="handleClick(scope.row,'read')">阅读</el-button>
                    <el-button size='mini' v-if='(viewerKey != "myCreate" && scope.row.onwerUserId == String(userId) && teamInfo && teamInfo.state == 0) || (viewerKey != "myCreate" && teamInfo && teamInfo.teamWorkType == 1 && teamInfo.state == 0)' type="warning" @click="handleClick(scope.row,'edit')">编辑</el-button>
                    <el-button size='mini' v-if='viewerKey != "myCreate" && scope.row.state == 0 && teamInfo && teamInfo.state == 0 ' type="primary" @click="handleClick(scope.row,'commit')">提交</el-button>
                    <el-button size='mini' v-if='viewerKey != "myCreate" && scope.row.state == 0 && scope.row.lastEditUserId != null && scope.row.onwerUserId != String(userId)  && teamInfo && teamInfo.state == 0 ' type="danger" @click="handleClick(scope.row,'reject')">驳回</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination" >
            <el-pagination
            class="pagebox"
            @current-change="pageChanged"
            :page-size="query.pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { teamWorkAPI } from 'liankong-ui-api' 
export default {
    props:{
        viewerKey:{
            type:String,
            default:''
        },
        from:{
            type:String,
            default:''
        }
    },
    data() {
		return {
			dialogVisible: false,
            teamInfo:null,
			query:{
				pageNum:1,
				pageSize:10,
				shareId:'',
                state:null //0编辑中  1已提交
			},
		};
    },
	computed: { 
        tableData(){
            return this.$store.state.team.views.replicationList.list || []
        },
        total(){
            return this.$store.state.team.views.replicationList.paging.total;
        },
        userId(){
            return this.$store.state.login.id
        },
        // 协同详情
        detailInfo(){
            return this.$store.state.team.detail;
        }
    },
    mounted(){
        
    },
    methods: {
        
		pageChanged(num){
			this.query.pageNum = num;
			this.getReplicationList()
		},
        show(rows,state,flag){
            this.teamInfo = rows;
            this.query.shareId = rows.id;
            this.query.state = state;
            if(flag){
                this.getReplicationList()
            }
            
        },
		// 获取副本文件列表
		getReplicationList(){
			this.$store.dispatch('team/loadByQuery',{
				viewerKey:'replicationList',
                reset:true,
                query:this.query,
                interfaceName:'getReplicationList'
			})
		},
        loadByQuery(){
            let interfaceName = this.viewerKey == 'myCreate' ? "getMyCreateList":"getMyJoinList"
            this.$store.dispatch('team/loadByQuery',{
                viewerKey:this.viewerKey,
                reset:true,
                interfaceName:interfaceName
            })
        }, 
		//统一事件处理
		handleClick(row,type){
			switch(type){
				case 'read':
					this.$ebus.$emit('publicReader',{
						shareId:this.teamInfo.id, 
						id:this.teamInfo.resourceList[0].documentId,
						documentId:this.teamInfo.resourceList[0].documentId,
                        targetResourceId:row.id,
                        model:0
					})
				break;
                case 'commit':
                    teamWorkAPI.commitReplicationId(row.id).then( res => {
                        if(res.code == 0){
                            this.$message.success('副本提交成功！');
                            this.getReplicationList();
                            if(this.detailInfo && this.detailInfo.teamWorkType == 1){
                                this.$emit('closeEvent')
                            }
                        }else{
                            this.$message.error('副本提交失败！')
                        }
                    })
				break;
                case 'edit':
                    this.$ebus.$emit('publicReader',{
						shareId:this.teamInfo.id, 
						id:this.teamInfo.resourceList[0].documentId,
						documentId:this.teamInfo.resourceList[0].documentId,
						model:1,
                        targetResourceId:row.id
					})
				break;
                case 'reject':
					 this.$confirm('是否确定要驳回此操作文件？','提示',{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(()=>{
                        teamWorkAPI.rejectReplicationId(row.id).then( res => {
                            if(res.code == 0){
                                this.$message.success('副本驳回成功！');
                                this.getReplicationList()
                            }else{
                                this.$message.error('副本驳回失败！')
                            }
                        })
					})
					break;
			}
		},
		// 多选文件
		handleSelectionChange(val){
            this.$emit('handleSelectionChange',val)
		},
    }
};
</script>