<template>
  <div class='share-user-info'>
    <div style="display: flex;align-items: center;">
      <el-radio-group v-model="shareAccessType" v-if='viewerKey != "teamWork" && from == "share"' size='small' style="width: 400px;display:flex;margin-right: 10px;">
        <el-radio-button label="PUBLIC">公开文档</el-radio-button>
        <el-radio-button label="AUTH">受限用户</el-radio-button>
      </el-radio-group>
      <UserSearch :shareAccessType="shareAccessType" @onUserSelected="onUserSelected"></UserSearch>
      <div style="position: relative;" ref="box">
         <el-button v-if='$isShowOrg' style="margin-left: 10px;" @click="handleSelectSystemUserBtn" :disabled="shareAccessType == 'PUBLIC'" size='small'>选择平台用户</el-button>
          <div class="org-user-pages" v-if="isShowFlag">
              <div class="org-user-dialog">
                <div class="org-user-body-left">
                  <el-tabs v-model="activeName">
                    <el-tab-pane label="同事" name="first">
                      <el-input
                        placeholder="搜索同事"
                        v-model="filterText">
                      </el-input>
                      <el-tree
                        :data="treeData"
                        show-checkbox
                        :load="loadNode"
                        lazy
                        node-key="id"
                        ref="tree"
                        class="filter-tree"
                        :check-strictly="true"
                        highlight-current
                        :filter-node-method="filterNode"
                        :props="defaultProps"
                        empty-text="暂无数据"
                        @check="treeCheckEvent">
                        <span slot-scope="{ node, data }">
                            <i class="el-icon-office-building" style="margin-right: 3px;" v-if="data.organizationId == undefined && data.icon == undefined"></i>
                            <i class="el-icon-s-home" style="margin-right: 3px;" v-else-if="data.organizationId"></i>
                            <i :class="data.icon" style="margin-right: 3px;" v-else-if="data.icon"></i>
                            <span>{{data.name||data.username}}</span>
                        </span>
                      </el-tree>
                    </el-tab-pane>
                  </el-tabs>
                </div>
                <div class="org-user-body-right">
                    <el-button type="text" @click="clearDataEvent">清空</el-button>
                    <el-button type="text" @click="clearDataEvent">重置</el-button>
                    <p>
                      <span>已选人员</span>
                      <span>类型<i class="el-icon-question"></i></span>
                    </p>
                    <ul>
                      <li v-for="(item,index) in alreadyCheckData" :key="index">
                        <i class="el-icon-office-building" style="margin-right: 3px;" v-if="item.organizationId == undefined && item.icon == undefined"></i>
                        <i class="el-icon-s-home" style="margin-right: 3px;" v-else-if="item.organizationId || item.organizationId == 0"></i>
                        <i :class="item.icon" style="margin-right: 3px;" v-else-if="item.icon"></i>
                        {{item.name||item.username}}
                      </li>
                    </ul>
                </div>
              </div>
              <div class="org-user-btn">
                  <el-button @click="btnCancleEvent">取消</el-button>
                  <el-button type="primary" @click="saveSelectOrgUser">保存</el-button>
              </div>
          </div>
      </div>
    </div>
    <el-table
      :data="targets"
      class="target_table"
      :border='viewerKey=="share"?false:true'
      size="small"
      row-key="value"
      :id='viewerKey=="share"?"showHeader":""'
      style="width: 100%; margin-top: 20px">
       <el-table-column
        type="index"
        label="序号"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        label="姓名/手机号">
        <template slot-scope="scope">
          <span>{{ scope.row.type == 'TARGET_PHONE' ? scope.row.value : scope.row.label }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="targetType"
        label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 'TARGET_PHONE'">手机授权</span>
          <span v-else-if="scope.row.type == 'TARGET_ORG'">组织授权</span>
          <span v-else-if="scope.row.type == 'TARGET_GROUP'">部门授权</span>
          <span v-else>用户授权</span>
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        label="操作">
        <template slot-scope="scope"><el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">移除</el-button>
        </template> 
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import UserSearch from '@/components/UserSearch.vue'
import { orgAPI } from 'liankong-ui-api'
import Sortable from 'sortablejs'

export default {
  props: {
    //已选用户列表，进行状态过滤
    targets: {
      type: Array,
      default: []
    },

    //共享信息
    shareInfo: {
      type: Object,
      default: {}
    },
    viewerKey:{
      type:String,
      default:""
    },
    // 是否是编辑
    optionType:{
      type:String,
      default:""
    },
    from:{
       type:String,
      default:"share"
    }
  },
  components: { UserSearch },
  data () {
    return {
        isShowFlag: false,
        activeName: 'first',
        treeData: [],
        filterText: '',
        defaultProps: {
          children: 'groupModelList',
          label: 'name',
          isLeaf: false
        },
        alreadyCheckData: []
    }
  },
  computed: {
    shareAccessType: {
      get(){
        return this.shareInfo.accessType || 'AUTH'
      },
      set(value){
        this.shareInfo.accessType = value
      }
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    // 表格拖拽排序
    initSortable() {
      const el = document.querySelector('.target_table>.el-table__body-wrapper > table > tbody');
			Sortable.create(el, {
				disabled: false,
				animation: 150, // 拖拽延时
        sort: true,
        draggable: '.el-table__row',
        forceFallback: true,
				onEnd: ({newIndex, oldIndex}) => { // 拖拽结束时的触发
          if(this.targets.length != 0){
            const row = this.targets.splice(oldIndex, 1)[0]; // 获取表数据
            this.targets.splice(newIndex, 0, row); // 数据处理，获取最新的表格数据
          }
         
				},
			});
    },
    //用户选择
    onUserSelected(user){
      if(this.viewerKey == 'teamWork'){
        if(this.optionType == 'update'){
          this.$emit('updateTargets',user,'add')
        }else{
          this.$store.commit("teamWork/addTarget", user)
        }
        
      }else{
        this.$store.commit("cart/addTarget", user)
      }
    },
    handleDelete(index, user){
      if(this.viewerKey == 'teamWork'){
        if(this.optionType == 'update'){
          this.$emit('updateTargets',user,'delete')
        }else{
          this.$store.commit("teamWork/removeTarget", user)
        }
      }else{
         this.$store.commit("cart/removeTarget", user)
      }
     
    },
    // 选择平台用户
    handleSelectSystemUserBtn(){
      this.isShowFlag = true;
      this.getOrgTreeList();
      
      // this.loadNode()
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 获取组织树
    async getOrgTreeList() {
        let res = await this.$store.dispatch('newOrg/getUserOrgList')
        if(res.code == 0) {
            res.data.map(item => {
              if(item.children.length != 0){
                item.children.pop()
              }
            })
            this.treeData = res.data;
            this.onLoadGroupDoc();
        } else {
            this.$message.error('获取组织数据失败')
        }
    },
    // 获取群组列表
    async onLoadGroupDoc(){
      let res = await this.$store.dispatch('newGroup/queryChildren', {
          vkey: 'allGroup',
          reset: true,
          query:{
              groupType: 1,
              pageNum: 1,
              pageSize: 1000
          } 
      })
      if(res) {
        let data =  this.$store.state.newGroup.views['allGroup'].list;
          this.treeData = this.treeData.concat(data);
      }
    },
    // 根据组织查找部门
    loadNode(node, resolve){
      if(node.data.children && !node.data.organizationId){
        resolve(node.data.children)
      }else{
        if(node.data.id && !node.data.username){
          // 获取部门或群组成员
          orgAPI.toolDepMemberList({
            id: node.data.id,
            pageSize: 999999,
            pageNum: 1
          }).then(res=>{
            if(res.code == 0) {
                let datas = res.data;
                datas.forEach(treeChilds => {
                  this.$set(treeChilds, 'icon', 'el-icon-s-custom')
                })
                resolve(datas);
                // 隐藏成员那一级的三角形
                    this.$nextTick(function() {
                      setTimeout(() => {
                        const childNodeArr = document.getElementsByClassName('el-icon-s-custom')
                        for (let i = 0; i < childNodeArr.length; i++) {
                          const loadNode = childNodeArr[i].parentNode.parentNode.firstChild;
                          if(loadNode.className == 'el-tree-node__expand-icon el-icon-caret-right'){
                              loadNode.style.display = 'none';
                          }
                          const checkLabel = loadNode.nextElementSibling;
                          const customNode = childNodeArr[i].parentNode;
                          if(customNode.parentNode.parentNode.firstChild.className == 'el-tree-node__content'){
                            customNode.parentNode.parentNode.setAttribute('class','el-tree-node');
                            if(customNode){
                              checkLabel.style.marginLeft = "20px";
                            }
                          }
                          
                          
                          
                        }
                      }, 10)
                    })
            } else {
                this.$message.error(res.message);
            }
          })
        }else{
          resolve([])
        }
        
      }
      
    },
    // 左侧树选中
    treeCheckEvent(checkedNodes,checkedKeys,halfCheckedNodes,halfCheckedKeys){
       this.alreadyCheckData = checkedKeys.checkedNodes;
    },
    // 取消
    btnCancleEvent(){
      this.isShowFlag = false;
      this.alreadyCheckData = [];
    },
    // 保存
    saveSelectOrgUser() {
      for(var i = 0; i < this.alreadyCheckData.length; i++) {
        if(this.alreadyCheckData[i].icon) { // 匹配用户、手机号授权
            this.$store.dispatch('auth/searchTarget', {
              name: this.alreadyCheckData[i].username, 
              phoneNumber: this.alreadyCheckData[i].username
            }).then(searchData => {
                this.onUserSelected(searchData[0]);
            })
        } else if(this.alreadyCheckData[i].organizationId==undefined){ // 组织
          let targets = {
            type:'TARGET_ORG',
            value: this.alreadyCheckData[i].id,
            label: this.alreadyCheckData[i].name
          }
          this.onUserSelected(targets);
        }  else if(this.alreadyCheckData[i].organizationId){ // 部门
            let targets = {
              type:'TARGET_GROUP',
              value: this.alreadyCheckData[i].id,
              label: this.alreadyCheckData[i].name
            }
            this.onUserSelected(targets);
        }
        //  else {
        //   let targets = {
        //     type:'TARGET_ORG',
        //     value: this.alreadyCheckData[i].id,
        //     label: this.alreadyCheckData[i].name
        //   }
        //   this.onUserSelected(targets);
        // }
      }
      this.isShowFlag = false;
      this.alreadyCheckData = [];
    },
    // 清空
    clearDataEvent() {
      this.$refs.tree.setCheckedKeys([]);
      this.alreadyCheckData = [];
    }
  },
  mounted(){
    if(this.viewerKey == 'teamWork'){
      this.$nextTick(function() {
        this.initSortable();
      });
    }
   
  },
  created () {
    // 全局点击事件
    document.addEventListener('mouseup',(e) =>{
        let tree = this.$refs.box
        if (tree) {
        // 判断鼠标点击的区域是否在tree里面，否则关闭弹窗
            if (!tree.contains(e.target)) {
                this.isShowFlag =false
            }
        }
    })  
  },
}
</script>

<style lang='less' scoped>
.share-user-info{
  padding-top: 5px;
  text-align: center;
}
.el-table{
  height: 300px;
  overflow: scroll;
}
.el-table::before{
  display: none;
}
.org-user-pages {
  width: 543px;
  height: auto;
  background: #fff;
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  // border: 1px solid #f2f2f2;
  -webkit-box-shadow: 0px 2px 16px 0px rgba(18, 55, 100, 0.2);
  padding: 15px;
  .org-user-dialog {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    .org-user-body-left {
      width: 50%;
      /deep/ .el-tabs {
        .el-tabs__nav-wrap::after {
         background: none;
        }
        .el-tabs__active-bar {
          width: 100px !important;
        }
        .el-tabs__item {
          width: 100px;
        }
        .el-tabs__content {
          text-align: left;
          height: 240px;
          overflow-y: scroll;
        }
        .el-tabs__content::-webkit-scrollbar {
          display: none;
        }
        .el-input {
          width: 95%;
          margin-bottom: 8px;
          .el-input__inner {
            height: 35px;
            line-height: 35px;
          }
        }
      }
    }
    .org-user-body-right {
        width: 50%;
        text-align: left;
        padding-left: 10px;
        p {
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          i {
            margin-left: 3px;
          }
        }
        ul {
          padding: 0;
          // margin: 0;
          li {
            list-style: none;
            margin-bottom: 8px;
            list-style: none;
          }
          li:last-child {
            margin-bottom: 0px;
          }
        }
    }
  }
  .org-user-btn {
    height: 40px;
    display: flex;
    justify-content: flex-end;
  }
 
}
/deep/.el-table--border{
  border:1px solid #EBEEF5;
}
/deep/.el-table--border::after, .el-table--group::after, .el-table::before{
  background-color: #fff ;
}
#showHeader{
  padding-bottom: 5px;
  /deep/.el-table__header-wrapper{
    display: none;
  }
  .td{
    border-bottom:1px solid #EBEEF5;
  }
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>