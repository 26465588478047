/**
 * 字典字段名称  保密级别
 * @param {*} value 
 */
export function confidentiality(value) {
    switch (value) {
        case 0:
            return "普通"
        case 1:
            return "保密"
        case 2:
            return "机密"
        case 3:
            return "绝密"
    }
}

/**
 * 字典字段名称  申请状态
 * @param {*} value 
 */
export function applyState(value) {
    switch (value) {
        case 0:
            return "待授权"
        case 1:
            return "已通过"
        case 2:
            return "已拒绝"
        case 3:
            return "已取消"
    }
}
/**
 * 字典字段名称  申请状态颜色
 * @param {*} value 
 */
export function applyStateColor(value) {
    switch (value) {
        case 0:
            return { "color": "black" }
        case 1:
            return { "color": "#85ce61" }
        case 2:
            return { "color": "red" }
        case 3:
            return { "color": "black" }
    }
}
/**
 * 字典字段名称  授权类型
 * @param {*} value 
 */
export function requesType(value) {
    switch (value) {
        case 0:
            return "阅读授权"
        case 1:
            return "请求授权"
    }
}
/**
 * 日期处理过滤器
 */
export function date(value, fmt = "yyyy-MM-dd HH:mm:ss") {

    if (!value) {
        return null
    }
    // console.log("time value", (value instanceof Date));
    //ios时间格式为 yyyy/MM/dd HH:mm:ss 不能带毫秒
    // value = value.replace(/T/g, " ").replace(/-/g,'/').split(".")[0]

    if (!(value instanceof Date)) {

        //IOS或低版本平台反序列化处理
        // if(value[4] === '-' && value[7] === '-' && value[10] === ' '){
        //   value = value.replace(/-/g,'/').split(".")[0]
        // }

        if (value.indexOf('T') != -1) {
            value = new Date(value)
        } else {
            value = new Date(value.replace(/-/g, '/'))
        }

    }


    let ret;
    // const opt = {
    //     "y+": value.getUTCFullYear().toString(),        // 年
    //     "M+": (value.getUTCMonth() + 1).toString(),     // 月
    //     "d+": value.getUTCDate().toString(),            // 日
    //     "H+": value.getUTCHours().toString(),           // 时
    //     "m+": value.getUTCMinutes().toString(),         // 分
    //     "s+": value.getUTCSeconds().toString()          // 秒
    //     // 有其他格式化字符需求可以继续添加，必须转化成字符串
    // };
    const opt = {
        "y+": value.getFullYear().toString(), // 年
        "M+": (value.getMonth() + 1).toString(), // 月
        "d+": value.getDate().toString(), // 日
        "H+": value.getHours().toString(), // 时
        "m+": value.getMinutes().toString(), // 分
        "s+": value.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };

    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

/**
 * 单独选时间段比较判断
 */
export function dateRengeCompare(startDate, endDate) {

    if (startDate) {
        switch (typeof startDate) {
            case 'string':
                startDate = new Date(startDate.replace(/-/g, '/').split(".")[0])
                break
            case 'number':
                startDate = new Date(startDate)
                break
        }
    } else {
        return true
    }

    if (endDate) {
        switch (typeof endDate) {
            case 'string':
                endDate = new Date(endDate.replace(/-/g, '/').split(".")[0])
                break
            case 'number':
                endDate = new Date(endDate)
                break
        }
    } else {
        return true
    }

    return startDate <= endDate
}