/**
 * 创建协同模块
 */
import { teamWorkAPI } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'

//协同视图key
const BaseCartViewer = {
    //文档列表
    docList: [],

    //选择文件数统计
    counts: {
        folder: 0,
        file: 0
    },

    //数据id缓存
    docIdMap: {}
}


const state = {
    views: {
        teamWork: deepCopy(BaseCartViewer)
    },

    //协同信息
    teamInfo: {
        name: null,
        description: null,
        accessType: 'AUTH', //访问类型，默认授权访问
        teamWorkType:0,//0协同处理 1顺序协同
        type:1//0是分享  1是协同
    },

    //授权目标
    targets: {},

    

}

const getters = {

    

    /**
     * 访问类型
     */
    accessTypes: {
        public: "PUBLIC", //公开访问
        auth: "AUTH", //授权访问
        reject: "REJECT" //拒绝访问
    },

}



const mutations = {

    /**
     * 创建协同视图
     * @param {*} state 
     * @param {*} viewerKey 
     * @param {*} defaultFiles 
     */
    createViewer(state, { viewerKey }) {
        //检查是否存在
        console.log('创建数据视图',viewerKey)
        if (!state.views.hasOwnProperty(viewerKey)) {
            //创建视图
            const newViewer = deepCopy(BaseCartViewer)
            this._vm.$set(state.views, viewerKey, newViewer)
        }
    },
    //更新协同方式
    updateReamType(state, value){
        state.teamInfo.teamWorkType = value;
    },

    /**
     * 销毁数据视图
     * @param {*} state 
     * @param {*} viewerKey 
     */
    destroyViewer(state, viewerKey) {
        console.log("销毁数据视图:", viewerKey)
        delete state.views[viewerKey]
       
    },

    //重置数据
    resetData(state) {
        if(state.views['teamWork']){
            const viewer = state.views['teamWork']
            viewer.docList = []
            viewer.counts.folder = 0
            viewer.counts.file = 0
            viewer.docIdMap = {}
        }
       

        state.targets = {}

        state.teamInfo = {
            name: null,
            description: null,
            accessType: 'AUTH', //访问类型，默认授权访问
            teamWorkType:0,//0协同处理 1顺序协同
            type:1//0是分享  1是协同
        }
    },


    /**
     * 添加文档
     * @param {*} state 
     * @param {*} doc 
     */
    push(state, { viewerKey, doc }) {
        const viewer = state.views[viewerKey]
        
        const cacheKey = doc.id

        //判断是否已添加
        if (!viewer.docIdMap[cacheKey]) {
            viewer.docIdMap[cacheKey] = doc
            viewer.docList.push(doc)

            //计数
            viewer.counts.file += 1
        }
    },
     /**
     * 清除所有文档
     * @param {*} state 
     * @param {*} doc 
     */
    clear(state, viewerKey) {
        const viewer = state.views[viewerKey]
        viewer.docList = [];
        viewer.counts.file = 0;
        viewer.docIdMap = {}

    },
      /**
     * 清除所有目标
     * @param {*} state 
     * @param {*} doc 
     */
    resetTargets(state, viewerKey) {
        state.targets = {}
    },


    /**
     * 移除文档
     * @param {*} state 
     * @param {*} doc 
     */
    remove(state, { viewerKey, doc }) {
        const viewer = state.views[viewerKey]
        const cacheKey = doc.id

        //判断是否已添加
        if (viewer.docIdMap[cacheKey]) {
            for (let itemIndex = 0, len = viewer.docList.length; itemIndex < len; itemIndex++) {
                if (viewer.docList[itemIndex].id == cacheKey) {
                    viewer.docList.splice(itemIndex, 1)
                    delete viewer.docIdMap[cacheKey]

                    //计数
                    viewer.counts.file -= 1

                    break
                }
            }
        }
    },

    /**
     * 添加用户
     */
    addTarget(state, user) {
        const key = 'key_'+user.value
        if (!state.targets[key]) {
            this._vm.$set(state.targets, key, user)
        }
    },
    /**
     * 移除用户
     */
    removeTarget(state, user) {
        const key = 'key_'+user.value

        if (state.targets[key]) {
            this._vm.$set(state.targets, key, null)
            delete state.targets[key]
        }
    },

}

const actions = {

    /**
     * 创建协同
     * @param {*} param0 
     */
    async createTeamWork({ commit, state },{ targetList }) {
        

        //权限值处理
        const teamInfo = state.teamInfo

        //资源数据
        const viewer = state.views['teamWork']
        teamInfo.resources = viewer.docList
            .map(doc => {
                return {
                    documentId: doc.id,
                    name: doc.name,
                    type: doc.type,
                    suffix: doc.suffix
                }
            })

        //授权目标数据
        teamInfo.targets = {}
        teamInfo.targets = targetList.map((target,index) => {
                return {
                    type: target.type,
                    value: target.value,
                    sort:index,
                    attrs: {
                        name: target.label
                    }
                }
            })

        //补充其他数据  
        if(teamInfo.name == null || teamInfo.name == ''){
            teamInfo.name = teamInfo.resources[0].name
            if (teamInfo.resources.length > 1) {
                teamInfo.name += `等${teamInfo.resources.length}个文件`
            }
        }
        
        //创建协同
        const { code, data, message } = await teamWorkAPI.createTeamWork(teamInfo)

        if (code == 0) {
            //重置
            commit("resetData")
        } 
        return { code, data, message }
    },

    // 添加用户
    async addTargets({ commit, state },{ id, target }) {
        try {
            const { code, data, message } = await teamWorkAPI.addTargets(id,target)
            if(code == 0){
                commit("addTarget",target)
            }else{
                throw message
            }
            return code == 0
        } catch (err) {
            throw err
        }
    }, 

    //移除用户
    async deleteTargets({ commit, state },{id, target }) {
        try {
            const { code, data, message } = await teamWorkAPI.deleteTargets(id,target)
            if(code == 0){
                commit("removeTarget",target)
            }else{
                throw message
            }
            return code == 0
        } catch (err) {
            throw err
        }
        
    },

    //获取文档详情
    async getDocumentDetail({ commit, state },{ id }) {
        try {
            const { code, data, message } = await teamWorkAPI.getDocumentDetail(id)
            if(code == 0){
                commit('clear', "teamWork")
                commit('push', {
                    viewerKey: 'teamWork',
                    doc: data,
                })
            }else{
                throw message
            }
            return code == 0
        } catch (err) {
            throw err
        }
        
    }  

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}