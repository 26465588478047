<template>
    <div class="teamwork-detail-container">
        <el-dialog
        title="协同信息浏览"
		width="850px"
		@closed="closeEvent"
		:append-to-body='true'
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
			<div class="teamwork-detail-info" v-if="detailInfo" >
				<div>
					<el-image v-if="docInfo" :src='docInfo.coverImg | thumbnailUrl'>
						<div slot="error" class="image-slot" style="background:#efefef;text-align:center;height:200px;box-sizing:border-box;padding-top:70px;">
							<p style="margin:5px 0;color:#999">暂未生成</p>
							<p style="margin:0;color:#999">缩略图</p>
						</div>
					</el-image>
					<el-image v-else :src='"" | thumbnailUrl'>
						<div slot="error" class="image-slot" style="background:#efefef;text-align:center;height:200px;box-sizing:border-box;padding-top:70px;">
							<p style="margin:5px 0;color:#999">暂未生成</p>
							<p style="margin:0;color:#999">缩略图</p>
						</div>
					</el-image>
				</div>
				<div>
					<h1>{{detailInfo.name}}</h1>
					<div class="info">
						<p>协同发起者：<span>{{detailInfo.ownerName}}</span></p>
						<p>文档类型：<span>{{detailInfo.resourceList[0].suffix}}</span></p>
						<p>发起时间：<span>{{detailInfo.createDate}}</span></p>
						<p v-if="docInfo">文档大小：<span>{{docInfo.size | byteSize}}</span></p>
						<p v-else>文档大小：<span>-</span></p>
						<p>参与者：<span>{{detailInfo.targetList.length}}</span></p>
						<p v-if="docInfo">文档页数：<span>{{docInfo.pageNumber}} 页</span></p>
						<p>协同方式：<span>{{detailInfo.teamWorkType == 0 ? '协同处理':'顺序协同'}}</span></p>
					</div>
					
					<el-button size='small' type='success' @click="handleClick('','read')">阅读</el-button>
					<el-button v-if='viewerKey != "myCreate" && detailInfo.state == 0' type='warning' @click="handleClick('','edit')" size='small'>编辑源文件</el-button>
				</div>
			</div>
			<DuplicateFileList ref='DuplicateFileList' @closeEvent='closeEvent' :viewerKey='viewerKey' from='detail'></DuplicateFileList>

				
            <span slot="footer" class="dialog-footer">
                <el-button  @click="closeEvent">关 闭 </el-button>
            </span>
        </el-dialog>


    </div>
</template>
<script>
import { teamWorkAPI } from 'liankong-ui-api' 
import DuplicateFileList from './duplicateFileList.vue';
export default {
	components:{
		DuplicateFileList
	},
    data() {
		return {
			dialogVisible: false,
			viewerKey:'',
			docInfo:null,
			query:{
				pageNum:1,
				pageSize:10,
				shareId:'',
                state:null //0编辑中  1已提交
			},
		};
    },
	computed: { 
        tableData(){
            return this.$store.state.team.views.replicationList.list || []
        },
		// 协同详情
        detailInfo(){
            return this.$store.state.team.detail;
        }
    },
	mounted(){
		 window.addEventListener('message', this.handleMessage)
	},
    methods: {
		handleMessage(event){
            let sendDataMessage = event.data
            if(sendDataMessage.cmd=='saveDocument'){
				if(this.detailInfo){
					this.getDetail(this.detailInfo.id)
				}
            }
        },
		// 清除数据
        closeEvent(){
			if(this.detailInfo && this.detailInfo.teamWorkType == 1){
				this.$emit('loadByQuery')
			}
            this.dialogVisible = false;
			this.docInfo = null;
			// 清除数据
			this.$store.commit('team/clearDetail');
			
        },
		// 初始化
        show(rows,viewerKey){
			this.dialogVisible = true;
			this.viewerKey = viewerKey;
			this.getDetail(rows.id)
        },
		//统一事件处理
		handleClick(row,type){
			switch(type){
				case 'read':
					this.$ebus.$emit('publicReader',{
						shareId:this.detailInfo.id, 
						id:this.detailInfo.resourceList[0].documentId,
						documentId:this.detailInfo.resourceList[0].documentId
					})
					break;
				case 'edit':
					this.$ebus.$emit('publicReader',{
						shareId:this.detailInfo.id, 
						id:this.detailInfo.resourceList[0].documentId,
						documentId:this.detailInfo.resourceList[0].documentId,
						model:1
					})
					break;
			}
		},
		// 分页
        pageChanged(num){
            this.query.pageNum = num;
            this.getReplicationList()
        },
		// 选择位置
		onSubmit(){
			this.$refs["SelectFolder"].show();
		},
		// 获取协同详情
		getDetail(id){
			this.query.shareId = id;
			this.$store.dispatch('team/teamWorkDetail',{ id, query:this.query}).then(result => {
				if(result){
					this.getDocumentDetail(this.detailInfo.resourceList[0].documentId)
					if(this.$refs['DuplicateFileList']){
						this.$refs['DuplicateFileList'].show(this.detailInfo,null,false)
					}
				}else{
					this.$message.error("加载协同信息失败，请重试!")
				}
			}).catch(err => {
				console.log(err)
				this.$message.error("加载协同信息失败，请重试!")
			})
		},
		// 获取文档详细信息
		getDocumentDetail(documentId){
			teamWorkAPI.getDocumentDetail(documentId).then(result => {
				if(result.code == 0){
					this.docInfo = {
						coverImg:result.data.coverImg,
						size:result.data.size,
						pageNumber:result.data.pageNumber
					}
				}
			})
		},
	
    }
};
</script>
<style lang="less" scoped>
.teamwork-detail-container{
	/deep/.el-dialog__body{
		padding-top: 10px;
	}
	.dialog-footer{
		display: flex;
		justify-content: center;
	}
	.file-version-list{
		
		h1{
			border-left: 4px solid #409EFF;	
			padding-left: 8px;
			margin-bottom: 5px;
		}
	}
	
	
	.teamwork-detail-info{
		display: flex;
		margin-bottom: 30px;
		h1{
            margin-top: 3px;
        }
		.el-image{
            border: 1px solid #efefef;
            width: 150px;
            height: auto;
            margin-right: 15px;
        }
		.info{
			overflow: hidden;
			margin-bottom: 10px;
			p{
				width: 50%;
				float: left;
				margin: 5px 0;
			}
		}
	}
}
</style>