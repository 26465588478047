import BaseAPI from '../BaseAPI.js'

/**
 * 工具服务API
 * @author Daniel
 * @date 
 */
export default class ToolsAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/tool"
    }

    /**获取工具分类列表 */
    getCategoryList(query) {
        return this.ajax.get(`${this.moduleName}/category/ls`, query)
    }
    /**获取工具分类列表 */
    getAllToolsList() {
        return this.ajax.get(`${this.moduleName}/tool`)
    }

    /**获取最受欢迎工具 */
    getMostToolList(query) {
        return this.ajax.get(`${this.moduleName}/popular/page/lk`, query)
    }

    /**获取工具列表 */
    getToolList(query) {
        return this.ajax.get(`${this.moduleName}/tool/page/lk`, query)
    }

    /** 获取格式分类的工具*/
    getGroupTool(query) {
        return this.ajax.get(`${this.moduleName}/tool/group/lk`, query)
    }

    /**获取工具详情*/
    getToolDetail(id) {
        return this.ajax.get(`${this.moduleName}/tool/${id}/lk`)
    }

    /** 添加收藏 */
    addCollect(query) {
        return this.ajax.post(`${this.moduleName}/collection`, query)
    }

    /** 删除收藏 */
    removeCollect(id) {
        return this.ajax.delete(`${this.moduleName}/collection/${id}`)
    }

    /** 打开工具统计接口 */
    openToolTracking(query) {
        return this.ajax.post(`${this.moduleName}/tracking/tool/open`,query)
    }

    /** 获取工具工具接口 */
    getToolPageTracking(query){
        return this.ajax.get(`${this.moduleName}/tracking/tool/page`,query)
    }

     /** 使用工具统计接口 */
     useToolTracking(query) {
        return this.ajax.post(`${this.moduleName}/tracking/tool/use`,query)
    }

     /** 意见反馈接口 */
     addFeedBack(query) {
        return this.ajax.post(`/operation/feedback/add`,query)
    }
    
}