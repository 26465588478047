/**
 * HttpClient 配置
 * 适用于客户端
 */
/*eslint-disable */
import FlyIO from 'flyio'
import { Store } from '@lk/cache'

const fly = new FlyIO()

const nowTime = (+new Date())

//获取当前自定义头
fly.getCustomHeader = () => {
    const accessToken = Store.get("access_token")
    return {
        Authorization: `Bearer ${accessToken}`,
        version: '1.0'
    }
}

//全局定义编译属性 API_BASEURL
fly.config.baseURL = API_BASEURL || process.env.API_BASEURL

fly.interceptors.request.use((request) => {

    request.headers["version"] = "1.0"

    //添加授权信息
    const accessToken = Store.get("access_token")
    const expiresTime = Store.get("expires") || 0

    const headerToken = request.headers["Authorization"]
    if (!headerToken && expiresTime > nowTime && accessToken) {
        request.headers["Authorization"] = `Bearer ${accessToken}`
    }
    if (Store.get("no_expires") == "no_expires" && accessToken) { // 没有过期时间情况
        request.headers["Authorization"] = `Bearer ${accessToken}`
    }

    //header 编码处理
    const contentType = request.headers['Content-Type'] || "application/json"
    const body = request.body

    if (contentType.indexOf('x-www-form-urlencoded') > -1) {
        //修改编码方式
        request.body = Object.keys(body).map(key => `${key}=${body[key]}`).join("&")
    }

    return request
})

fly.interceptors.response.use(response => {
    if (response.status == 200 && response.data.code == 401) { // 暂时这么写
        Store.del("access_token")
    }
    return response.data
}, error => {
    console.log(error)

    //401 移除Token
    if (error.status == 401) {
        Store.del("access_token")
        if (Store.get('pcLogin') == 'pcLogin' && (`${window.location.hash}`.indexOf('/share/') == -1)) {
            window.location.reload()
        }
    }

    if (error.response) {
        return error.response.data
    } else {
        console.error(error)
    }
})

export default fly