/**
 * md5处理函数
 */

import SparkMD5 from '../utils/spark-md5.min.js'

/**
 * 计算文件MD5
 * @param {*} file 
 */
export function fileMD5(file, progress = () => {}){
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
  const fileReader = new FileReader()
  const chunkSize = 2097152
  const chunks = Math.ceil(file.size / chunkSize)

  let currentChunk = 0
  const spark = new SparkMD5.ArrayBuffer()
  fileReader.onload = function (e) {
    spark.append(e.target.result)
    currentChunk++
    if (currentChunk < chunks) {
      loadNext()
    } else {
      const sparkEnd = spark.end()
        //计算后的结果
        progress(null, {
          state: "end",
          md5: sparkEnd
        })
    }
  }
  fileReader.onerror = function () {
    progress("读取文件出错")
  }
  function loadNext () {
    const start = currentChunk * chunkSize
    const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
    fileReader.readAsArrayBuffer(blobSlice.call(file.raw, start, end))
  }
  loadNext()
}

/**
 * 计算字节MD5值
 */
export function bufferBytesMD5(buffer){
  const spark = new SparkMD5.ArrayBuffer()
  spark.append(buffer)
  const hexHash = spark.end(false)
  return hexHash
}