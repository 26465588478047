<template>
  <div class='share-result'>
    <el-alert
      title="文件共享成功"
      type="success"
      description="文件共享已经创建成功，您可以复制链接，发送给自己的好友了"
      show-icon
      :closable="false">
    </el-alert>
    <!-- <div class="share-url">
      您共享的访问地址为：
      <a :href="shareUrl" target="_blank">{{shareUrl}}</a>
    </div> -->
    <div class="qr-code">
      <qr :margin="10" :text="shareUrl" colorDark="#67C23A" colorLight="#fff" :size="200"></qr>
      <div>请使用，扫一扫</div>
    </div>
  </div>
</template>

<script>
import qr from 'vue-qr'

export default {
  components: {qr},
  props: {
    shareUrl: {
      type: String,
      default: ""
    }
  },
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  methods: {

  },
  created () {

  },
}
</script>

<style lang='less' scoped>
.share-result{

  .share-url{
    margin-top: 20px;

    a{
      display: block;
      margin: 20px;
      color: #67C23A
    }
  }

  .qr-code{
    text-align: center;
    margin-top: 20px
  }
}
</style>