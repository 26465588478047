<template>
  <div class="logo-box" @click="handleJumpIndex">
    <img class="logo" src="@/assets/logo.png" alt="logo" />
    <span class="text">友虹文档中台</span>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    // 跳转首页
    handleJumpIndex(){
      this.$router.push({
        path: '/'
      })
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang='less' scoped>

.logo-box {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  .logo {
      width: 37px;
      height: auto;
      margin: 0 10px;
  }
  
  .text {
    color: #636363;
    font-size: 20px;
  }
}
</style>