import BaseAPI from '../BaseAPI.js'

/**
 * 一体机
 */

export default class IntebrageAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/document/convert"
        this.staticName = "/document/convert/statistics"
    }

    /**
     * 批量转换任务 节点列表数据
     * @param {*} query 
     * @returns 
     */
    getBatchConverPageList(query) {
        return this.ajax.post(`/document/node/list`, query)
    }

    /**
     * 批量转换 删除节点数据
     * @param {*} id 
     * @returns 
     */
    batchRemoveNodeList(id) {
        return this.ajax.delete(`/document/node/${id}`)
    }

    //部署任务
    taskDeploy(id) {
        // return this.ajax.post(`/collect/file/${taskCode}/deploy`)
        return this.ajax.get(`/document/node/task/deploy/${id}`)
    }

    //反部署任务
    taskUnDeploy(id) {
        // return this.ajax.post(`/collect/file/${taskCode}/undeploy`)
        return this.ajax.get(`/document/node/task/undeploy/${id}`)
    }

    //启动任务
    taskStart(id) {
        // return this.ajax.post(`/collect/file/${taskCode}/start`)
        return this.ajax.get(`/document/node/task/startTask/${id}`)
    }

    //停止任务
    taskStop(id) {
        // return this.ajax.post(`/collect/file/${taskCode}/stop`)
        return this.ajax.get(`/document/node/task/stopTask/${id}`)
    }

    /**
     * 转换日志 列表数据 
     * @param {*} query 
     * @returns 
     */
    getConverLogPageList(query) {
        return this.ajax.post('/document/node/syncLog', query)
    }

    /**
     * 手动转换 添加文件
     * @param {*} query 
     */
    handUpLoadList(query) {
        return this.ajax.post(`${this.moduleName}/upload`, query)
    }

    /**
     * 手动转换 列表数据
     * @param {*} query 
     */
    getHandFilePageList(query) {
        return this.ajax.post(`${this.moduleName}/pageList`, query)
    }

    /**
     * 手动转换 删除数据
     * @param {*} id 
     * @returns 
     */
    handRemoveList(id) {
        return this.ajax.delete(`${this.moduleName}/${id}`)
    }

    /**
     * 手动转换 清除列表
     * @param {*}
     * @returns 
     */
    handRemoveAllList() {
        return this.ajax.delete(`${this.moduleName}/removeAll`)
    }

    /**
     * 手动转换 开始转换
     * @param {*} query
     */
    handTransList(query) {
        return this.ajax.put(`${this.moduleName}`, query)
    }

    /**
     * 手动转换 批量转换合并文件
     * @param {*} data 
     */
    handBatchTransList(data) {
        return this.ajax.post(`${this.moduleName}/merge`, data)
    }

    /**
     * 手动转换 双层ofd
     * @param {*} data 
     */
    handTransOFDList(data) {
        return this.ajax.post(`${this.moduleName}/toDoubleLayerOFD`, data)
    }

    /**
     * 手动转换 双层pdf
     * @param {*} data 
     */
    handTransPdfList(data) {
        return this.ajax.post(`${this.moduleName}/toDoubleLayerPDF`, data)
    }

    /**
     * 转换统计 转换数据量
     * @param {*} type
     */
    getConverCount(type) {
        return this.ajax.get(`${this.staticName}/totalCount/${type}`)
    }

    /**
     * 转换统计 转换文件数统计
     * @param {*} type
     */
    getConverFileCount(type) {
        return this.ajax.get(`${this.staticName}/totalSize/${type}`)
    }

    /**
     * 转换统计 识别类型文件
     */
    getConverDistCount() {
        return this.ajax.get(`${this.staticName}/orcFileType`)
    }

    /**
     * 最近转换Top5 
     * @param {*} 
     */
    getLadelyList() {
        return this.ajax.get(`${this.moduleName}/getRecentConverList`)
    }

    /**
     * 最大识别Top5
     * @param {*} 
     */
    getLadelyDistList() {
        return this.ajax.get(`${this.moduleName}/getMaxOCRFile`)
    }

    /**
     * 转换统计 转换数据量图表
     */
    getConverCountEcharts() {
        return this.ajax.get(`${this.moduleName}/count/day`)
    }

    /**
     * 转换统计 转换文件数统计图表
     */
    getConverFileCountEcharts() {
        return this.ajax.get(`${this.moduleName}/size/day`)
    }

    /**
     * 提取文字
     * @param {*} documentId 
     * @returns 
     */
    getExtractTextList(documentId) {
        return this.ajax.get(`/document/content/${documentId}`)
    }

    /**
     * 转换页数据量
     * @returns 
     */
    getTransPageTotal() {
        return this.ajax.get(`${this.staticName}/convertPages`)
    }

    /**
     * 监控统计 节点统计
     * @returns 
     */
    getMonStaticNodeData() {
        return this.ajax.get('/document/syncLog/statistics')
    }

    /**
     * 批量转换 新建节点
     * @param {*} query 
     * @returns 
     */
    getAddNodeList(query) {
        return this.ajax.post('/document/node', query)
    }

    /**
     * 分配任务
     * @param {*} query 
     * @returns 
     */
    getNodeDistTaskList(query) {
        return this.ajax.post('/document/node/task/add', query)
    }

    /**
     * 获取任务列表
     * @param {*} nodeId 
     * @returns 
     */
    getTaskData(nodeId) {
        return this.ajax.get(`/document/node/task/list/${nodeId}`)
    }

    /**
     * 编辑任务
     * @param {*} query 
     * @returns 
     */
    getEditTaskDatas(query) {
        return this.ajax.put(`/document/node/task`, query)
    }

    /**
     * 删除任务列表数据
     * @param {*} id 
     */
    batchRemoveTaskList(id) {
        return this.ajax.delete(`/document/node/task/${id}`)
    }

    /**
     * 代理节点数 图表统计
     */
    proxyNodeEchartsList() {
        return this.ajax.get('/document/agent/node/state')
    }


}