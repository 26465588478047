import BaseAPI from '../BaseAPI.js'

/**
 * 采集管理API
 * 
 */
export default class GatherAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/collection"
    }

    /**
     *  获取数据库数据源列表
     * @param {*} query
     */
    getDatabase(query) {
        return this.ajax.post(`${this.moduleName}/dataSource/db/list`, query)
    }

    /**
     *  获取API接入列表
     * @param {*} query
     */
    getApi(query) {
        console.log(query)
            // return this.ajax.post(`${this.moduleName}/group`, query)
    }

    /**
     *  获取资源目录列表
     * @param {*} query
     */
    getDirectory(query) {
        console.log(query)
            // return this.ajax.post(`${this.moduleName}/group`, query)
    }

    /**
     *  获取模板列表
     * @param {*} query
     */
    getTemplate(query) {
        console.log(query)
        return this.ajax.post(`${this.moduleName}/template/list`, query)
    }

    /**
     *  获取电子印章列表
     * @param {*} query
     */
    getSeal(query) {
        return this.ajax.post(`${this.moduleName}/sign/list`, query)
    }

    /**
     *  删除数据库数据源
     * @param {*} id
     */
    removeDatabase(id) {
        return this.ajax.delete(`${this.moduleName}/dataSource/db/${id}`)
    }

    /**
     *  删除API
     * @param {*} id
     */
    removeApi(id) {
            console.log(id)
                // return this.ajax.delete(`${this.moduleName}/sign/${id}`)
        }
        /**
         *  删除资源目录
         * @param {*} id
         */
    removeDirectory(id) {
            console.log(id)
                // return this.ajax.delete(`${this.moduleName}/sign/${id}`)
        }
        /**
         *  删除模板
         * @param {*} id
         */
    removeTemplate(id) {
            return this.ajax.delete(`${this.moduleName}/template/${id}`)
        }
        /**
         *  删除数印章
         * @param {*} id
         */
    removeSeal(id) {
        return this.ajax.delete(`${this.moduleName}/sign/${id}`)
    }


    /**
     *   添加数据库数据源
     * @param {*} query
     */
    addDatabase(query) {
        return this.ajax.post(`${this.moduleName}/dataSource/db`, query)
    }

    /**
     *  添加API接入
     * @param {*} query
     */
    addApi(query) {
        console.log(query)
            // return this.ajax.post(`${this.moduleName}/dataSource/db`, query)
    }

    /**
     *  添加资源目录
     * @param {*} query
     */
    addDirectory(query) {
        console.log(query)
            // return this.ajax.post(`${this.moduleName}/group`, query)
    }

    /**
     *  添加模板
     * @param {*} query
     */
    addTemplate(query) {
        return this.ajax.post(`${this.moduleName}/template`, query)
    }

    /**
     *  添加电子印章
     * @param {*} query
     */
    addSeal(query) {
        return this.ajax.post(`${this.moduleName}/sign`, query)
    }


    /**
     *  编辑数据库数据源
     * @param {*} query
     */
    editDatabase(query) {
        return this.ajax.put(`${this.moduleName}/dataSource/db`, query)
    }

    /**
     *  编辑API接入
     * @param {*} query
     */
    editApi(query) {
        console.log(query)
            // return this.ajax.put(`${this.moduleName}/dataSource/db`, query)
    }

    /**
     *  编辑资源目录
     * @param {*} query
     */
    editDirectory(query) {
        console.log(query)
            // return this.ajax.put(`${this.moduleName}/group`, query)
    }

    /**
     *  编辑模板
     * @param {*} query
     */
    editTemplate(query) {
        return this.ajax.put(`${this.moduleName}/template`, query)
    }

    /**
     *  编辑电子印章
     * @param {*} query
     */
    editSeal(query) {
        return this.ajax.put(`${this.moduleName}/sign`, query)
    }


    /**
     *  上传文件
     * @param {*} query
     */
    uploadFile(query) {
        return this.ajax.post(`${this.moduleName}/base64/uploadFile`, query)
    }


    /**
     *  测试数据库数据源
     * @param {*} query
     */
    verification(query) {
        return this.ajax.post(`${this.moduleName}/dataSource/db/verify`, query)
    }



    /**
     *  查询字段详情
     * @param {*} id
     */
    queryTemplatePage(id) {
        return this.ajax.get(`${this.moduleName}/template/query/page/${id}`)
    }

    /**
     *  查询事例文件 xml
     * @param {*} id
     */
    parseDataXML(id) {
        return this.ajax.get(`${this.moduleName}/template/query/parseDataXML/${id}`)
    }

    /**
     *  获取文件实例阅读地址，配置签章
     */
    getReaderUrl(id) {
        return this.ajax.get(`${this.moduleName}/template/getReaderUrl/${id}`)
    }

    /**
     * 模板配置印章
     */
    addSignInfo(query) {
            return this.ajax.post(`${this.moduleName}/sign/template/addSignInfo`, query)
        }
        /**
         * 文件配置印章
         */
    addPersonDocumentSignInfo(query) {
        return this.ajax.post(`${this.moduleName}/sign/personal/docuement/addSignInfo`, query)
    }


    /**
     * 添加个人签名
     */
    addPersionSign(query) {
            return this.ajax.post(`${this.moduleName}/sign/add/persion/sign`, query)
        }
        /**
         * 编辑个人签名
         */
    updatePersionSign(query) {
            return this.ajax.post(`${this.moduleName}/sign/update/persion/sign`, query)
        }
        /**
         * 获取个人签名
         */
    getUserSign() {
        return this.ajax.get(`${this.moduleName}/sign/getUserSign`)
    }

    /**
     * 删除个人签名
     */
    deletePersionSign(signId) {
        return this.ajax.delete(`${this.moduleName}/sign/delete/person/sign/${signId}`)
    }

    /**
     * 印章模板列表
     */
    getSigntemplate(query) {
        return this.ajax.post(`${this.moduleName}/sign/list/signTemplate`, query)
    }

    /**
     * 添加印章模板
     */
    addSigntemplate(query) {
        return this.ajax.post(`${this.moduleName}/sign/addSignTemplate`, query)
    }

    /**
     * 修改印章模板
     */
    editSigntemplate(query) {
        return this.ajax.put(`${this.moduleName}/sign/updateSignTemplate`, query)
    }

    /**
     * 删除印章模板
     */
    removeSigntemplate(id) {
        return this.ajax.delete(`${this.moduleName}/sign/deleteSignTemplate/${id}`)
    }

    // 阅读pdf文件
    openPDF(ticket){
        return this.ajax.get(`/testreader/api/openPDF?ticket=${ticket}`)
    }

    // 编辑pdf模板
    configTemplateEditor(query){
        return this.ajax.post(`${this.moduleName}/template/configTemplateEditor`,query)
    }

    // 查询pdf编辑   回显
    getTemplateEditor(templateId){
        return this.ajax.get(`${this.moduleName}/template/getTemplateEditor/${templateId}`)
    }


    // 采集任务
    /**
     * 根据数据源id 查找sql普通模式的表数据
     * @param {*} id 
     * @returns 
     */
    getSqlSelectList(id) {
        return this.ajax.get(`${this.moduleName}/dataSource/db/queryDatasourceTables/${id}`)
    }

    /**
     * 根据sql 下拉框表 查找表格的字段数据
     * @param {*} query 
     * @returns 
     */
    getSqlTableList(query) {
        return this.ajax.get(`${this.moduleName}/dataSource/db/queryDatasourceTableDetails/${query.id}/${query.tableName}`)
    }

    /**
     * 测试执行
     * @param {*} query 
     * @returns 
     */
    getTestSqlList(query) {
        return this.ajax.post(`${this.moduleName}/dataSource/db/checkSql`, query)
    }

    /**
     * 任务配置-列表数据
     * @param {*} query 
     * @returns 
     */
    getList(query) {
        return this.ajax.post(`${this.moduleName}/task/pageList`, query)
    }

    /**
     * 任务配置-新建接口
     * @param {*} query 
     * @returns 
     */
    getSaveEvent(query) {
        return this.ajax.post(`${this.moduleName}/task/addTask`, query)
    }

    /**
     * 任务配置-删除
     * @param {*} taskId 
     * @returns 
     */
    getTaskDelete(taskId) {
        return this.ajax.delete(`${this.moduleName}/task/${taskId}`)
    }

    /**
     * 任务配置-编辑
     * @param {*} query 
     */
    getTaskUpdate(query) {
        return this.ajax.put(`${this.moduleName}/task/updateTask`, query)
    }

    /**
     * 业务配置-列表
     * @param {*} query 
     * @returns 
     */
    getPageList(query) {
        return this.ajax.post(`${this.moduleName}/sechedule/list`, query)
    }

    /**
     * 业务配置-新建
     * @param {*} query 
     * @returns 
     */
    getPageAdd(query) {
        return this.ajax.post(`${this.moduleName}/sechedule/add`, query)
    }

    /**
     * 制证证件列表
     * @returns 
     */
    getCertificatesList() {
        return this.ajax.get(`${this.moduleName}/task/list`)
    }

    /**
     * 制证证件-详情
     * @param {*} id 
     * @returns 
     */
    getCertifiDetail(id) {
        return this.ajax.get(`${this.moduleName}/task/getTaskDetail/${id}`)
    }

    /**
     * 业务配置-删除
     * @param {*} id 
     * @returns 
     */
    sechedRemoveList(id) {
        return this.ajax.delete(`${this.moduleName}/sechedule/remove/${id}`)
    }

    /**
     * 业务配置-暂停作业调度
     * @param {*} id 
     * @returns 
     */
    sechedPauseList(id) {
        return this.ajax.put(`${this.moduleName}/sechedule/pause/${id}`)
    }

    /**
     * 业务配置-恢复暂停作业调度
     * @param {*} id 
     * @returns 
     */
    sechedResumeList(id) {
        return this.ajax.put(`${this.moduleName}/sechedule/resume/${id}`)
    }

    /**
     * 业务配置-编辑
     * @param {*} query 
     * @returns 
     */
    sechedUpdateList(query) {
        return this.ajax.post(`${this.moduleName}/sechedule/update`, query)
    }

    /**
     * 个人证书管理-证件制作
     * @param {*} scheduleId 
     * @returns 
     */
    executionTask(scheduleId) {
        return this.ajax.get(`${this.moduleName}/sechedule/executionBizTask/${scheduleId}`)
    }

    /**
     * 个人证件套打记录
     * @param {*} data 
     * @returns 
     */
    getPersonLogList(data) {
        return this.ajax.post(`${this.moduleName}/log/person/list`, data)
    }

    /**
     * 阅读地址
     * @param {*} signInfo 
     * @returns 
     */
    getReaderUrlList(signInfo) {
        return this.ajax.get(`/document/getReaderUrl/${signInfo}`)
    }
}