import BaseAPI from '../BaseAPI.js'

/**
 * 收藏服务API
 * 
 * @author Daniel
 * @date 
 */
export default class CollectionAPI extends BaseAPI {
    constructor() {
        super()
        this.moduleName = "/dollection"
    }

    /**
     *收藏列表
     *pageNum 页码
     *pageSize 每页条数
     */
    dollectionList(query) {
        return this.ajax.post(`/document/favorite/list`, query)
    }

    /**
     *收藏文档ID集合
     */
    dollectionAll() {
        return this.ajax.get(`/document/favorite/ids`)
    }

    /**
     *添加收藏
     documentId 文档id
     */
    addDollection(documentId) {
        return this.ajax.post(`/document/favorite/add/${documentId}`)
    }

    /**
     *移除收藏
     */
    removeDollection(documentId) {
        // return this.ajax.delete(`/document/favorite/doc/${documentId}`)
        return this.ajax.delete(`/document/favorite/${documentId}`)
    }

    /**
     * 移除收藏 记录id删除
     * @param {*} id 
     * @returns 
     */
    removeRecoredId(id) {
        return this.ajax.delete(`/document/favorite/doc/${id}`)
    }
}