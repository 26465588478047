<template>
  <div>
    <!-- <el-avatar class="avatar" :size="30" :src="avatar || '/user.png'" v-popover:popover></el-avatar>
    <el-popover ref="popover" placement="top" width="400" trigger="hover">
      <div>
        <el-row type="flex">
          <el-col :span="10">
            <el-avatar :size="150" :src="avatar || '/user.png'"></el-avatar>
          </el-col>
          <el-col :span="14">
            <p>{{username}}</p>
            <p>注册用户</p>
            <p>注册时间：2021年01月12日</p>
            <p>最后登录：2021年04月10日</p>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-around">
          <template v-if="$store.state.login.token">
            <el-button type="primary">
              <router-link to="/user/resetpassword">修改密码</router-link>
            </el-button>
            <el-button type="primary">
              <router-link to="/user/mydetails">详细资料</router-link>
            </el-button>
            <el-button type="danger" @click="out">退出登录</el-button>
          </template>
          <template v-else>
            <el-button type="primary">
              <router-link to="/login">去登录</router-link>
            </el-button>
          </template>
        </el-row>
      </div>
    </el-popover> -->

    <el-dropdown trigger="click">
      <span class="el-dropdown-link">
        <dl class="user-phone">
            <dt>
                <el-avatar class="avatar" :size="30" :src="avatar || '/user.png'" v-popover:popover></el-avatar>
            </dt>
            <dd>
                <span>{{isLogin ? user.name : (user.username ? user.username : '注册用户')}}</span>
            </dd>
        </dl>
        <i class="el-icon-caret-bottom el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="boxs" v-if="isLogin">
        <el-dropdown-item v-if='isOrgManager' @click.native="()=>{$router.replace('/user/organizational')}">组织管理</el-dropdown-item>
        <!-- <el-dropdown-item v-if='isOrgManager' @click.native="()=>{$router.replace('/user/storage')}">存储空间管理</el-dropdown-item> -->
        <el-dropdown-item @click.native="()=>{$router.replace('/user/resetpassword')}">修改密码</el-dropdown-item>
        <el-dropdown-item @click.native="()=>{$router.replace('/user/mydetails')}">详细资料</el-dropdown-item>
        <el-dropdown-item @click.native="()=>{$router.replace('/user/preference')}">偏好设置</el-dropdown-item>
        <el-dropdown-item @click.native="out">退出登录</el-dropdown-item>
      </el-dropdown-menu>
      <el-dropdown-menu slot="dropdown" class="boxs" v-else>
        <el-dropdown-item @click.native="$router.push(`/login?redirect=${$route.path}`)">去登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { Store } from '@lk/cache'
  export default {
    data() {
      return {};
    },
    computed: {
      isLogin(){
        if(  this.$store.state.login.id && Store.get('access_token')){
          return true
        }else{
          return false
        }
      },
      user(){
        return this.$store.state.login
      },
      avatar(){
        return this.$store.state.login.avatar
      },
      isOrgManager(){
        let roles = this.$store.state.login.roles;
        let arr = roles.filter( m => m.authority == 'TENANT_MANAGER')
        return arr.length == 0 ? false:true
      },
     
    },
    watch: {},
    methods: {
      out() {
        this.$store.dispatch("login/logout").then(state =>{
          window.location.reload()
          window.localStorage.clear()
        })
      },
    },

    created() {
      this.$store.dispatch("login/loadLoginUserInfo");
      this.$store.dispatch("login/getUserUsedDist");
    },
  };
</script>
<style lang='less' scoped>
  @import "../../styles/index.less";

  // .avatar {
  //   margin-right: 5px;
  //   cursor: pointer;
  // }

  .user-box {
    padding: 0 10px;
  }

  .user-name {
    font-size: @h6;
  }

  .el-avatar {
    background: #fff;
    vertical-align: middle;
    margin-top: -6px;
  }

  .el-dropdown {
    .el-dropdown-link {
      display: flex;
      align-items: center;
      .user-phone {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          cursor: pointer;
          dt {
            margin: 0;
            padding: 0;
          }
          dd {
            margin: 0;
            padding: 0;
            span {
              display: inline-block;
              margin-bottom: 3px;
            }
          }
        }
    }
    // .el-dropdown-menu.boxs{
    //    .popper__arrow {
    //       display: none;
    //     }
    // }
  }
 
  
</style>