/**
 * 文档数据服务
 */
import {
    getChildren,
    createFolder,
    getQuickFolder,
    createQuickFolder,
    removeQuickFolder,
    removeDocument,
    download,
    reader,
    rename
} from '@/api/document.js'

const state = {
    //前端数据缓存
    databank: {
        show: "user",
        user: {
            id: 0,
            name: "个人资料库"
        },
        quick: {
            id: -3,
            name: "快速访问"
        },
        share: {
            id: -2,
            name: "共享资料库"
        },
        org: {
            id: -1,
            name: "组织资料库",
            group: null
        }
    },

    //浏览视图
    viewer: {
        loading: false,

        selection: false,
        selectionRows: [],

        root: null,
        current: null,
        files: [],
    },

    //quickBtn: true, //快捷图标
    paths: [],
    quick: []
}

const getters = {
    //是否是用户空间
    isUserWorkSpace(state) {
        return state.databank.show == "user"
    },

    isOrgWorkSpace(state) {
        return state.databank.show == "org"
    }
}

const mutations = {
    /**
     * 切换资料库
     * 切换当前显示资料库的key，并重置基础路径
     * @param {*} state 
     * @param {*} key 
     */
    switchDataBank(state, key) {
        state.databank.show = key
        state.paths = []
    },

    //设置当前目录
    setCurrent(state, folder) {
        state.viewer.current = folder
    },

    //设置组织的部门信息
    setCurrentGroup(state, group) {
        state.databank.org.group = group
    },

    setChildren(state, data) {
        state.viewer.files = data
        state.viewer.current.children = data
        state.viewer.current._init = true
        state.viewer.loading = false
    },

    addPaths(state, folder) {
        state.viewer.files = []
        state.paths.push(folder)
        state.viewer.current = folder
        state.viewer.loading = !folder._init
    },

    goPaths(state, index) {
        const folder = state.paths[index]
        state.paths = state.paths.splice(0, index + 1)
        state.viewer.current = folder
        state.viewer.loading = !folder._init
    },

    setQuickFolder(state, folders) {
        state.quick = folders
    },

    //文件多选
    setSelection(state, { value, item }) {
        state.viewer.selection = value

        if (value) {
            if (item) {
                if (Array.isArray(item)) {
                    state.viewer.selectionRows = item
                } else {
                    state.viewer.selectionRows.push(item)
                }
            }
        } else {
            state.viewer.selectionRows = []
        }
    }
}

const actions = {

    /**
     * 进入文件夹
     * @param {*} param0 
     */
    async enterFolder({ commit, dispatch, state }, folder) {
        /* if(!state.showFolder){
          commit("switchDataBank", 'user')
        } */
        if (!folder) {
            folder = state.databank[state.databank.show]
        }

        commit("addPaths", folder)
        dispatch("loadChildren")
    },

    //从组织树进入文件级
    async enterGroup({ commit, dispatch }, group) {
        const basePath = {
            id: group.id,
            name: group.name
        }

        commit("switchDataBank", 'org')
        commit("setCurrentGroup", group)
        commit("addPaths", basePath)
        dispatch("loadChildren")
    },

    //从快速访问进入
    async enterQuick({ commit, dispatch }, folder) {
        commit("switchDataBank", 'quick')
        commit("addPaths", folder)
        dispatch("loadChildren")
    },

    /**
     * 跳转到路径节点
     * @param {*} param0 
     * @param {*} param1 
     */
    async jumpPaths({ commit, dispatch }, { folder, index }) {
        commit("goPaths", index)
        dispatch("loadChildren")
    },

    /**
     * 获取子级文件
     * @param {*} param0 
     */
    async loadChildren({ commit, state }) {
        const currentFolder = state.viewer.current
        const { code, data, message } = await getChildren(currentFolder ? currentFolder.id : 0)
        if (code == 0) {
            commit("setChildren", data)
        }
    },


    /**
     * 新建文件夹
     * @param {*} param0 
     * @param {*} name 
     */
    async createFolder({ commit, dispatch, state }, { name, share }) {
        const currentFolder = state.viewer.current
        const data = await createFolder(currentFolder ? currentFolder.id : 0, name, share)
        if (data.code == 0) {
            dispatch("loadChildren")
        }
        return data
    },

    /**
     * 重命名
     * @param {*} param0 
     * @param {*} param1 
     */
    async renameFolder({ dispatch }, { id, name }) {
        const data = await rename(id, name)
        if (data.code == 0) {
            dispatch("loadChildren")
        }
        return data
    },

    /**
     * 移除文档
     * @param {*} param0 
     * @param {*} documentId 
     */
    async removeDocument({ commit }, documentId) {
        const { code, data, message } = await removeDocument(documentId)
        return code == 0
    },

    /**
     * 加载快捷文件夹
     * @param {*} param0 
     */
    async getQuickFolder({ commit }) {
        const { code, data, message } = await getQuickFolder()
        if (code == 0) {
            commit("setQuickFolder", data)
        }
    },


    /**
     * 添加快捷文件夹
     * @param {*} param0 
     * @param {*} dirId 
     */
    async createQuickFolder({ dispatch }, dirId) {
        const { code, data, message } = await createQuickFolder(dirId)
        if (code == 0) {
            dispatch("getQuickFolder")
            return true
        }
        return null
    },

    /**
     * 移除快捷文件夹
     */
    async removeQuilcFolder({ dispatch }, dirId) {
        const { code, data, message } = await removeQuickFolder(dirId)
        if (code == 0) {
            dispatch("getQuickFolder")
            return true
        }
        return null
    },

    //下载文件
    async downLoadFile({}, params) {
        // const { code, data, message } = await download(params)
        await download(params)
    },

    //阅读
    async reader({}, document) {
        const { code, data, message } = await reader(document.id)

        if (code == 0) {
            window.open(`/screader${data}`)
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}