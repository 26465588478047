/**
 * 文档工具集
 */
import { documenttoolsAPI } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'
import LoginModule from 'liankong-ui-server/login/Login.Store.js'

const BaseListPageViewer = {
    query: {
        pageNum: 1,
        pageSize: 20
    },
    paging: {
        total: 0,
        pages: 0
    },
    list: [],

    //加载更多
    more: {
        status: 'more',
        contentText: {
            contentdown: '加载更多数据',
            contentrefresh: '数据加载中',
            contentnomore: '没有更多数据了'
        }
    }
}

const state = {
    views: {
        documentToolsHistoryPage: deepCopy(BaseListPageViewer), // 历史列表
    }
}

const mutations = {
    updateList(state, { vkey, reset = false, current, pages, data, total }) {
        const viewer = state.views[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data);
    },

    /**
     * 更新视图查询条件
     * @param   query
     * @return  data
     */
    updateQuery(state, { vkey, query }) {
        const viewer = state.views[vkey]
        viewer.query = Object.assign(viewer.query, query)
    },
}

const actions = {
    // 列表
    async list({ commit, state }, { vkey, reset = false, query, interfaceName }) {
        const viewer = state.views[vkey]

        // 是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }

        if (reset) {
            viewer.query.pageNum = 1
        }
        //更新查询条件
        commit('updateQuery', { vkey: vkey, query })

        const { code, current, pages, size, total, data, message } = await documenttoolsAPI[`${interfaceName}`](viewer.query);

        if (code == 0) {
            commit("updateList", {
                vkey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },
    /**
     * 历史列表 归档
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    async historyArchiveList({ dispatch }, { convertFileId,parentId }) {
        try {
            const data = await documenttoolsAPI.historyArchiveData(convertFileId,parentId);
            return data
        } catch (error) {
            throw error
        }
    },
    /**
     * 历史列表 下载
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    async historyDownList({ dispatch }, { convertFileId }) {
        try {
            const data = await documenttoolsAPI.historyDowmData(convertFileId, LoginModule.state.token);
            return data
        } catch (error) {
            throw error
        }
    },
    /**
     * 历史列表 下载
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
     async addMask({ dispatch }, query) {
        try {
            const data = await documenttoolsAPI.addMask(query);
            return data
        } catch (error) {
            throw error
        }
    },
    /**
     * 上传
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    async documentUploadEvent({ dispatch }, { query }) {
        try {
            const data = await documenttoolsAPI.documentToolUpload(query)
            return data
        } catch (error) {
            throw error
        }
    },

    /**
     * 文档处理 简单转换
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    async documentCoverEvents({ dispatch }, { convertTempId }) {
        try {
            const data = await documenttoolsAPI.documentCovertList(convertTempId);
            return data
        } catch (error) {
            throw error
        }
    },

    /**
     * 图片ocr识别
     * @param {*} param0 
     * @param {*} param1 
     */
    async documentOcrTipEvent({ dispatch }, { convertTempId }) {
        try {
            const data = await documenttoolsAPI.documentOCRTips(convertTempId)
            return data
        } catch (error) {
            throw error
        }
    },

    /**
     * 文件操作
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    async documentFormatMergeEvent({ dispatch }, { query }) {
        try {
            const data = await documenttoolsAPI.documentFormatMerge(query)
            return data
        } catch (error) {
            throw error
        }
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}