<template>
    <div>
        <el-dialog
            title="阅读授权信息"
            :close-on-click-modal="false"
            :append-to-body='true' 
            :visible.sync="dialogVisible">
            <div class="apply-record-dialogs">
                <dl>
                    <dt>
                        <i class="el-icon-document"></i>
                    </dt>
                    <dd>
                        <div class="record-dialog-title">
                            <span>{{myApplyDetail.shareName}}</span>
                            <p v-if="myApplyDetail.state!==1&&myApplyDetail.state!==2&&myApplyDetail.state!==3"> 
                                <el-button type="success" size="mini" @click="changeType(myApplyDetail.id,1)">通过</el-button>
                                <el-button type="danger" size="mini" @click="changeType(myApplyDetail.id,2)">拒绝</el-button>
                            </p>
                            <span v-else :style="myApplyDetail.state|applyStateColor">状态：{{myApplyDetail.state|applyState}}</span>
                        </div>
                        <div>
                            <p>
                                <span>申请人：{{myApplyDetail.targetName}}</span>
                                <span>申请时间：{{myApplyDetail.createDate|date}}</span>
                                <span>申请原因：{{myApplyDetail.description}}</span>
                            </p>
                        </div>
                    </dd>
                </dl>
                <div class="apply-record-list">
                    <p>
                        <span>申请文件列表</span>
                        <i title="返回上一级" class="el-icon-back" v-if="viewer.depth > 0" @click="back">返回上级</i>
                    </p>
                    <ul>
                        <li v-for="(item,index) in viewer.showFiles" :key="index" @click="render(item)">
                            <el-image :src="item | thumbIcon"></el-image>
                            <span>{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { shareAPI,requestAPI } from 'liankong-ui-api' 
export default {
    data(){
        return {
            dialogVisible: false,
            viewerKey: 'sender',
            myApplyDetail: {},
            verker: 'shareDocList',
        }
    },
    computed: {
        viewer() {
            return this.$store.state.doc.viewer[this.verker] || {}
        }
    },
    methods: {
        back(){
            this.$store.dispatch('doc/backFolder', {
                vkey: this.verker
            })
            },
        onLoadReadInfo(){
            this.myApplyDetail = this.$mapData.get('MyConferDateil')
            this.$store.commit('doc/createViewer', {
                viewerKey: this.verker,
                defaultFiles: this.myApplyDetail.resources
            })
        },
      // 跳转ofd页面
        render(docInfo){
            docInfo.documentId ? docInfo.documentId = docInfo.documentId : docInfo.documentId = docInfo.id
            if(docInfo.type==0){
                this.$store.dispatch("doc/loadChildrenByShare", {
                    vkey: this.verker,
                    folder: docInfo
                })
                return
            }

            this.$ebus.$emit('publicReader',{
                shareId:this.myApplyDetail.shareId, 
                id:docInfo.documentId || docInfo.id,
                documentId: docInfo.documentId,
                suffix:docInfo.suffix
            })
           
        },
        changeType(id,state) {
            const loading = this.$loading({
                lock: true,
                text: '正在审核中请等待。。。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            requestAPI.apply(id,{
                state
            }).then(({code,data,message})=>{
                if(code == 0){
                    loading.close();
                    this.$emit('refreshListRequest')
                    this.$message.success('审核成功')
                } else {
                    loading.close();
                    this.$message.error(message)
                }
            })
            this.dialogVisible = false;
            this.$emit('refreshList')
        },
    }
}
</script>
<style lang="less" scoped>
.apply-record-dialogs {
    dl {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        dt {
            padding: 0;
            margin: 0;
            i {
                font-size: 32px;
                margin-right: 5px;
                color: #ffaf1d;
            }
        }
        dd {
            flex: 1;
            padding: 0;
            margin: 0;
            .record-dialog-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                span:nth-child(1){
                    font-size: 16px;
                    font-weight: 600;
                    color: #009DD9;
                }
            }
            p {
                padding: 0;
                margin: 0;
                span {
                    margin-right: 15px;
                }
            }
        }
    }
    .apply-record-list {
        p {
            display: flex;
            margin: 20px 0 5px 0;
            justify-content: space-between;
            align-items: center;
            span{
                position: relative;
                padding-left: 20px;
                font-size: 15px;
                &::before{
                    content: '';
                    // width: 3px;
                    // height: 18px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #409eff;
                    position: absolute;
                    left: 0px;
                    top: 4px;
                }
            }
            i {
                cursor: pointer;
            }
        }
        ul {
            margin: 0px;
            padding: 0px;
            height: 400px;
            overflow-y: auto;
            li {
                list-style: none;
                height: 45px;
                line-height: 45px;
                border-bottom: 1px solid #f2f2f2;
                font-size: 14px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .el-image {
                    margin-right: 5px;
                    width: 21px;
                    height: 21px;
                    img {
                        display: block;
                    }
                }
            }
            li:hover {
                color: #409eff;
            }
        }
    }
}
</style>