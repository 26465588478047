let fontFamily = [{value:'Helvetica Neue'},{
value:'Helvetica',
},{
    value:'PingFang SC',
},{
    value:'Hiragino Sans GB',
},{
    value:'Microsoft YaHei',
},{
    value:'微软雅黑',
},{
    value:'Arial',
},{value:'sans-serif'},{value:'宋 体'}]
let fontSize = [{
    value:12,
    label:"12px"
},{
    value:13,
    label:"13px"
},{
    value:14,
    label:"14px"
},{
    value:15,
    label:"15px"
},{
    value:16,
    label:"16px"
},{
    value:17,
    label:"17px"
}]
let rotate =[{
    value:45,
    label:"45度"
},{
    value:90,
    label:"90度"
},{
    value:135,
    label:"135度"
},{
    value:180,
    label:"180度"
},{
    value:225,
    label:"225度"
},{
    value:270,
    label:"270度"
},{
    value:315,
    label:"315度"
},{
    value:360,
    label:"360度"
}]
export {
    fontFamily,
    fontSize,
    rotate
}