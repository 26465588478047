import BaseAPI from '../BaseAPI.js'

/**
 * 授权链服务API
 * 
 * @author Daniel
 * @date 
 */
export default class ChainAPI extends BaseAPI {
  constructor() {
      super()

      this.moduleName = "/share"
  }

  /**
   * 分享链
   * @param {*} node 
   */
  chainByShare(shareId) {
    return this.ajax.get(`${this.moduleName}/shareChain/${shareId}`, )
  }

  /**
   * 文档链
   * @param {*} docId 
   */
  chainByDoc(docId){ 
    return this.ajax.get(`${this.moduleName}/chain/document/${docId}`)
  }
 /**
   * 最新文档链
   * @param {*} docId 
   */
  chainByDocs(docId){ 
    return this.ajax.get(`${this.moduleName}/document/chain/${docId}`)
  }
}