import BaseAPI from '../BaseAPI.js'

/**
 * 管理平台-角色模块
 */

export default class RoleManagerAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/usercenter"
    }


    // 查询角色列表
    getRolesList(data) {
        return this.ajax.post(`${this.moduleName}/role/page/list`, data)
    }

    // 删除角色
    deleteRoles(id) {
        return this.ajax.delete(`${this.moduleName}/role/${id}`)
    }

    // 添加角色
    addRoles(data) {
        return this.ajax.post(`${this.moduleName}/role`, data)
    }

    // 修改角色
    editRoles(data) {
        return this.ajax.put(`${this.moduleName}/role`, data)
    }

    // 树形角色菜单列表
    getTreeRoleList(data) {
        return this.ajax.get(`${this.moduleName}/menu/tree?lazy=${data}`)
    }

    // 树形角色菜单列表默认数据选中
    treeRoleInitChecked(roleId) {
        return this.ajax.get(`${this.moduleName}/menu/tree/${roleId}`)
    }

    // 树形角色菜单 更新
    updateTreeRoleMenu(data) {
        return this.ajax.put(`${this.moduleName}/role/menu`, data)
    }

}