<template>
    <el-dialog class="share-dialog" title="设置水印" :visible.sync="dialogVisible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false" width="800px">
      <div class="add-mask"><el-button type="primary" size="small" @click="showAddMaskLoding">添加水印</el-button></div>
      <div class="change-mask">
        <el-table
          :data="watherList.list"
          style="width: 100%"
          @row-click="singleElection"
          highlight-current-row
        >
          <el-table-column align="center" width="30">
            <template slot-scope="scope">
              <el-radio class="radio" v-model="templateSelection" :label="scope.row.id"></el-radio>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="name" label="水印名称"> </el-table-column>
          <el-table-column align="center" prop="text" label="水印内容"> </el-table-column>
          <el-table-column align="center" prop="fontFamily" label="水印字体"> </el-table-column>
          <el-table-column align="center" prop="fontSize" label="水印字号"> </el-table-column>
          <el-table-column align="center" prop="color" label="水印颜色"></el-table-column>
          <el-table-column align="center" prop="rotate" label="旋转角度"></el-table-column>
          <el-table-column align="center"  label="操作" width="150">
            <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click.stop="handleEditMask(scope.row)">编辑</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click.stop="handleDeleteMask(scope.row)">删除</el-button>
              </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button @click="cacel">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </span>
      <AddShareMaskVue ref="addShareMaskVue"  @updataList='loadShareWatherList' />
    </el-dialog>
  </template>
  
<script>
  import AddShareMaskVue from './addShareMask.vue'
  export default {
    components: {
      AddShareMaskVue
    },
    data() {
			return {
				dialogVisible:false,
      //   当前选择的行的id
      templateSelection: "",
      //   当前选择的行的数据
      checkList: [],
			}
    },
    computed: {
      watherList(){
        return this.$store.state.shares.views.watherList || {}
      }
    },
    watch: {},
    methods: {
      cacel(){
        this.dialogVisible = false
      },
      submit(){
        if(this.templateSelection==''){
          this.$message.warning('请您选择一条水印信息!')
          return
        }
        this.cacel()
        this.$emit('watherInfo', this.templateSelection,this.checkList);
      },
      // 显示弹框
			showMakeLoding(value){
				this.dialogVisible = true
        this.loadShareWatherList()
        this.templateSelection = value
			},
      // 水印列表数据
      loadShareWatherList(){
        this.$store.dispatch("shares/List", {
          reset: true,
          noReset:true,
          viewerKey:'watherList',
          queryName:'shareWatherList'
        }).then(data => {
          // this.loading = false
        })
      },
      // 添加水印
      showAddMaskLoding(){
        this.$refs['addShareMaskVue'].showAddMask('add',{})
      },
      singleElection(row) {
        this.templateSelection = row.id
        this.checkList = this.watherList['list'].filter((item) => item.id === row.id)
      },
      // 编辑水印
      handleEditMask(row){
        this.$refs['addShareMaskVue'].showAddMask('update',row)
      },
      // 删除水印 
      handleDeleteMask(row){
         this.$confirm('您确定要删除当前水印吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.$store.dispatch('shares/shareWatherState',{
                queryName:'delete',
                query:{
                    id:row.id
                }
            }).then((res)=>{
                if(res.code==0){
                   this.loadShareWatherList()
                   this.$message.success('删除水印成功')
                } else {
                    this.$message.error('删除水印失败')
                }
            })
        }).catch(()=>{})
      }

    },
    created() {
    },
    beforeDestroy() {
    },
  }
</script>
<style lang='less' scoped>
  .share-dialog {
   /deep/ .el-dialog__body{
      flex-direction: column;
    }
  }
.add-mask {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
/deep/ .el-radio{
  .el-radio__label{
    display: none;
  }
}
/deep/ .el-table{
  .cell{
    text-overflow: initial;
  }
}
</style>