/**
 * 授权状态管理
 */
import { shareAPI, authAPI, userAPI } from 'liankong-ui-api'

const state = {
  //编辑的share
  editShare: {}
}

const getters = {

}

const mutations = {
  /**
   * 添加管理的share
   * @param {*} state 
   * @param {*} share 
   */
  addShare(state, share) {
    /**
     * 数据格式处理
     * 后端返回的值统一为String，UI控件的判断统一使用Number的0,1判断
     * 2020-06-11@Daniel
     */
    if(share.permissions){
      share.permissions
        .filter(p => p.value === '0' || p.value === '1')
        .map(p =>  p.value = Number(p.value))
    }
    state.editShare = share
  },

  /**
   * 更新分享资源
   * @param {*} state 
   * @param {*} resources 
   */
  updateResources(state, {shareId, resources}){
    state.editShare.resources = resources
  },
  
  /**
   * 更新目标资源
   * @param {*} state 
   * @param {*} param1 
   */
  updateTargets(state, {shareId, targets}){
    state.editShare.targets = targets
  }
}

const actions = {
  /**
   * 加载分享的信息
   *@param {*} shareId 
   */
  async loadShare({ commit }, shareId) {
    try{
      const { code, data, message } = await shareAPI.detailById(shareId)

      if(code == 0) {
        commit('addShare', data)
        return true
      }else{
        throw message
      }
    }catch(err){
      throw err
    }
  },

 /**
   * 分享目标搜索
   * @param {*} param0 
   * @param {*} payload 
   */
  async searchTarget({}, {name, phoneNumber}){
    const {code, data, message } = await userAPI.list({pageSize: 30, name: name, phoneNumber: phoneNumber})
    
    const searchData = []

    if(code == 0){
      //如果搜索结果为0，并且是手机号搜索
      if(data.records.length == 0 && /^1\d{10}$/.test(phoneNumber)){
        searchData.push({
          label: phoneNumber,
          value: phoneNumber,
          faceUrl: null,
          type: "TARGET_PHONE",
          selected: false
        })
      }

      data.records.map(user => {
        searchData.push({
          label: user.name,
          value: user.id,
          faceUrl: user.faceUrl,
          type: "TARGET_ID",
          // selected: this.users[user.id] ? true : false
        })
      })
    }else{
      throw message
    }

    return searchData
  },

  /**
   * 添加分享目标
   * @param {*} shareId 
   * @param {
   *  type 目标类型
   *  value 目标类型值
   * } 
   */
  async addShareTarget({ commit, state }, {shareId, target}) {
    try{
      //数据处理
      target.attrs = {
        name: target.label
      }

      const {code, data, message} = await authAPI.addShareTarget(shareId, [target])
      
      if(code == 0){
        //移除目标完成
        //预留多个分享操作逻辑
        const shareInfo = state.editShare
        const targets = shareInfo.targets
        console.log(Object.assign(target, data))
        //添加本地资源
        targets.push(Object.assign(target, data))

        //更新资源
        commit("updateTargets", {
          shareId: shareInfo.id,
          targets
        })
        return true
      }else{
        throw message
      }
    }catch(err){
      throw err
    }
  },

  /**
   * 删除分享目标
   * @param {*} shareId 
   * @param {
   *  type 目标类型
   *  value 目标类型值
   * } 
   */
  async removeShareTarget({ commit, state }, {shareId, target}) {
    try{
      const {code, data, message} = await authAPI.removeShareTarget(shareId, [target])
      
      if(code == 0){
        //移除目标完成
        //预留多个分享操作逻辑
        const shareInfo = state.editShare
        const targets = shareInfo.targets

        //移除本地资源
        for(let i=0, ilen = targets.length; i<ilen; i++){
          if(targets[i].id == target.id){
            targets.splice(i, 1)
            break
          }
        }

        //更新资源
        commit("updateTargets", {
          shareId: shareInfo.id,
          targets
        })
        return true
      }else{
        throw message
      }
    }catch(err){
      throw err
    }
  },

  /**
   * 添加分享资源
   * @param {*} data 
   */
  async addShareResources({state,commit }, resources) {
    const { code,message,data } = await authAPI.addShareResources(resources.id, resources.resources)

    if (code == 0) {
      const shareInfo = state.editShare
      let resources = shareInfo.resources
      resources.push(...data)
      let obj = {}
      resources = resources.reduce((cur,next) => {
        obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
        return cur;
      },[]) 
        //更新资源
        commit("updateResources", {
          shareId: shareInfo.id,
          resources
        })
    }
  
    return code == 0
  },

  /**
   * 删除分享资源
   * @param {*} shareId 
   * @param {*} resourceId 
   */
  async removeShareResource({ commit, state }, {shareId, resource}) {
    try{
      const {code, data, message} = await authAPI.removeShareResource(shareId, resource.id)
      
      if(code == 0){
        //移除资源完成
        //预留多个分享操作逻辑
        const shareInfo = state.editShare
        const resources = shareInfo.resources

        //移除本地资源
        for(let i=0, ilen = resources.length; i<ilen; i++){
          if(resources[i].id == resource.id){
            resources.splice(i, 1)
            break
          }
        }

        //更新资源
        commit("updateResources", {
          shareId: shareInfo.id,
          resources
        })
        return true
      }else{
        throw message
      }
    }catch(err){
      throw err
    }
  },

  /**
   * 添加分享权限
   * @param shareId 分享id
   * @param {
   * id 权限记录id
   * name 权限名
   * ownerType 	授权所属
   * value 	权限值
   *} 
   */
  async addSharePermissiont({ dispatch, state }, permissions) {
    console.log(permissions)
    await authAPI.addSharePermissiont(state.authShare.id, permissions)
    dispatch('getShare', state.authShare.id)
  },

  /**
   * 删除分享权限
   * @param {*} shareId 
   * @param {*} permissionId 
   */
  async removeSharePermission({ dispatch, state }, targets) {
    if(!targets.permission.id){
      const shareInfo = state.editShare
      const permissions = shareInfo.permissions
      for(let i=0, ilen = permissions.length; i<ilen; i++){
        const item = permissions[i]
        if(item.name == targets.permission.name){
          targets.permission.id = item.id
          break
        }
      }
    }
    const {code, data, message}  = await authAPI.removeSharePermission(targets.shareId, targets.permission.id);
    return code == 0
  },

  /**
   * 更新或新增权限
   * @param {*} param0 
   */
  async updateOrAddPermission({state}, {shareId, permission}){
    /**
     * 如果存在id则直接进行更新
     * 如果不存在id，则通过权限name匹配是否重复有，进行更新
     */
    if(!permission.id){
      //通过name进行匹配
      //直接获取，可通过shareId兼容多个处理
      const shareInfo = state.editShare
      const permissions = shareInfo.permissions

      if(permissions){
        for(let i=0, ilen = permissions.length; i<ilen; i++){
          const item = permissions[i]
          if(item.name == permission.name){
            permission.id = item.id
            break
          }
        }
      }

      console.log("权限变更日志：", permissions, permission)

      if(permission.id){
        //存在id则进行更新
        const {code, data, message} = await authAPI.updateSharePermission(shareId, [permission])
        return {code,data,message}
      }else{
        //不存在id进行新增
        const {code, data, message} = await authAPI.addSharePermissiont(shareId, [permission])
        return code == 0
      }
    }
  },

  /**
   * 订阅检查
   * @param {*} shareId 
   */
  async subscribe(shareId) {
    await authAPI.subscribe(shareId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}