import { groupAPI } from 'liankong-ui-api'
const state = {
    views: {
        // 我创建的
        group: {
            //查询器
            query: {
                pageNum: 1,
                pageSize: 20
            },
            // 数据列表
            list: [],

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            //加载指示器
            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多记录',
                    contentrefresh: '记录加载中',
                    contentnomore: '已加载全部记录'
                }
            }
        },
        // 我加入的
        join: {
            //查询器
            query: {
                pageNum: 1,
                pageSize: 20
            },
            // 数据列表
            list: [],
            //分页器
            paging: {
                total: 0,
                pages: 0
            },
            //加载指示器
            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多记录',
                    contentrefresh: '记录加载中',
                    contentnomore: '已加载全部记录'
                }
            }
        },
        // 所有的
        allGroup: {
            //查询器
            query: {
                pageNum: 1,
                pageSize: 20
            },
            // 数据列表
            list: [],
            //分页器
            paging: {
                total: 0,
                pages: 0
            },
            //加载指示器
            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多记录',
                    contentrefresh: '记录加载中',
                    contentnomore: '已加载全部记录'
                }
            }
        },
        allGroups: {
            //查询器
            query: {
                pageNum: 1,
                pageSize: 20
            },
            // 数据列表
            list: [],
            //分页器
            paging: {
                total: 0,
                pages: 0
            },
            //加载指示器
            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多记录',
                    contentrefresh: '记录加载中',
                    contentnomore: '已加载全部记录'
                }
            }
        }



    }
}

const mutations = {
    /**
     * 追加数据
     * @param {*} state  
     */
    appendList(state, { vkey, reset = false, current, pages, data, total }) {
        const viewer = state.views[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data)
    },
    updateQuery(state, { vkey, query }) {
        const viewer = state.views[vkey]
        viewer.query = Object.assign(viewer.query, query)
    },

    //更新更多状态
    updateMore(state, { vkey, moreStatus }) {
        const viewer = state.views[vkey]
        viewer.more.status = moreStatus
    },
}

const actions = {
    async queryChildren({ commit, state }, { vkey, reset = false, query }) {
        const viewer = state.views[vkey]

        //是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }

        if (reset) {
            viewer.query.pageNum = 1
        }

        //加载数据
        const { code, current, pages, size, total, data, message } = await groupAPI.queryChildren(query)

        if (code == 0) {
            commit("appendList", {
                vkey,
                reset,
                current,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },



    /**
     * 返回群组层级
     * @param {*} organizationId 组织id
     */
    async groupTree({ commit }, { vkey, query }) {
        try {
            const { code, data, message } = await groupAPI.groupTree(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 修改群组/部门名称
     * @param {*} id 群组id
     */
    async renameGroup({ commit, dispatch }, { vkey, query }) {
        try {
            const { code, data, message } = await groupAPI.renameGroup(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 删除群组/部门名称
     * @param {*} id 群组id
     */
    async remove({ commit }, { vkey, query }) {
        try {
            const { code, data, message } = await groupAPI.remove(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 添加群组成员
     * @param {*} id 群组id
     */
    async addGroupMember({ commit }, { vkey, query }) {
        try {
            const { code, data, message } = await groupAPI.addGroupMember(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },

    /**
     * 删除群组成员
     * @param {*} id 群组id
     * @param {*} userId 成员id
     * 
     */
    async removeGroupMember({ commit }, { vkey, query }) {
        try {
            const { code, data, message } = await groupAPI.removeGroupMember(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    },


    /**
     * 删除组织成员
     * @param {*} param0 
     * @param {*} param1 
     */
    async removeOrgMember({ commit }, { vkey, query }) {
        try {
            const { code, data, message } = await groupAPI.removeOrgMembers(query)
            return { code, message }
        } catch (error) {
            throw error
        }
    }





}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}