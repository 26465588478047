import BaseAPI from '../BaseAPI.js'

/**
 * 协同服务API
 * 
 * @author Daniel
 * @date 
 */
export default class TeamWorkAPI extends BaseAPI{
	constructor(){
		super()

		this.moduleName = "/teamwork"
	}

    /**
     * 待处理的协同列表
     * @param {*} query 
     */
    getMyPendingList(query){
        return this.ajax.post(`${this.moduleName}/queryPendingTeamWork`, query)
    }

    /**
     * 我创建的协同列表
     * @param {*} query 
     */
    getMyCreateList(query){
        return this.ajax.post(`${this.moduleName}/queryTeamWorkWithMe`, query)
    }

    /**
     * 我加入的协同列表
     * @param {*} query 
     */
    getMyJoinList(query){
        return this.ajax.post(`${this.moduleName}/queryTeamWorkToMe`, query)
    }

	/**
     * 查看副本列表
     * @param {*} query 
     */
    getReplicationList(query){
        return this.ajax.post(`${this.moduleName}/replicationList`, query)
    }

	/**
     * 查看协同文档详情
     * @param {*} id 
     */
	findTeamWorkDetail(id){
        return this.ajax.post(`${this.moduleName}/findTeamWork/${id}`, id)
    }

	/**
     * 创建协同
     * @param {*} teamInfo 
     */
	createTeamWork(teamInfo){
		return this.ajax.post(`${this.moduleName}/add`, teamInfo)
	}

	/**
     * 合并
     * @param {*} query 
     */
	mergeFile(query){
		return this.ajax.post(`${this.moduleName}/mergeFile`, query)
	}

	/**
     * 获取文档详情
     * @param {*} documentId 
     */
	getDocumentDetail(documentId){
		return this.ajax.get(`/document/info/${documentId}`, {})
	}



	/**
     * 副本提交
     * @param {*} query 
     */
	commitReplicationId(id){
		return this.ajax.post(`${this.moduleName}/commit/${id}`, {})
	}
	
	/**
     * 副本驳回
     * @param {*} query 
     */
	rejectReplicationId(id){
		return this.ajax.post(`${this.moduleName}/reject/${id}`, {})
	}

    // 从客户端获取设备信息
    getClientInfo(pin){
        return this.ajax.post(`/client/${pin}/info`, {})
    }
    // 向客户端获发送信息
    sendClientInfo(pin,query){
        return this.ajax.post(`/client/${pin}/send`, query)
    }
    // 获取协同动态
    getMessageByType(query){
        return this.ajax.post(`/msg/user/getMessageByType`, query)
    }
    // 添加协同授权目标
    addTargets(shareId,query){
        return this.ajax.post(`${this.moduleName}/${shareId}/add/targets`, [query])
    }
    // 删除协同授权目标
    deleteTargets(shareId,query){
        return this.ajax.post(`${this.moduleName}/${shareId}/remove/targets`, [query])
    }


    // 在线编辑列表
    getOnlineList(query){
        return this.ajax.post(`/document/tool/temp/file/list`, query)
    }
    // 在线编辑文档下载
    download(id){
        return this.ajax.get(`/document/tool/download/origin/${id}`, {})
    }
    // 从我的文档中选择文件进行在线编辑
    getOnlyOfficeFromDocument(documentId,type){
        return this.ajax.get(`/document/onlyOffice/config/${type}/${documentId}`,{})
    }
    // 创建文档
    createNewFile(query){
        return this.ajax.get(`/document/onlyOffice/createNewFile`,query)
    }
    // 在线编辑-另存为
    saveFileToMyDocument(parentId,Id){
        return this.ajax.post(`/document/onlyOffice/saveFileToMyDocument`,{
            convertTempId:Id,
            doucmenParentId:parentId
        })
    }

}