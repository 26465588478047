import BaseAPI from '../BaseAPI.js'

/**
 * 工具 上传文件服务 http://192.168.1.45:8092
 * 含：文件上传、文件删除、获取省市区、文件转换
 */
export default class ToolUploadAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/dict"
    }

    /** 获取省市区列表 */
    getAreaList(query) {
        return this.ajax.get(`${this.moduleName}/area/select`, query)
    }

    /** 上传临时文件多文件 返回附加信息 */
    uploadMultiFile(query) {
        return this.ajax.post(`${this.moduleName}/file/upload/multi/temp`, query)
    }

    /** 删除上传的文件 */
    removeUploadFile(query) {
        return this.ajax.post(`${this.moduleName}/file/delete`, query)
    }

    /** 上传临时文件单文件 */
    uploadLinFile(query) {
        return this.ajax.post(`${this.moduleName}/file/upload/temp`, query)
    }

    /** 工具处理页面批量下载 */
    fileDownloadOpertate(query) {
        return this.ajax.get(`${this.moduleName}/file/download/tool/batch?ids=${query.ids}&uuid=${query.uuid}`)
    }
}