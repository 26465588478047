<template>
    <div>
        <el-dialog
            title="申请授权信息"
            :close-on-click-modal="false" 
            :append-to-body='true'
            :visible.sync="dialogVisible">
            <div class="apply-record-dialogs">
                <dl>
                    <dt>
                        <i class="el-icon-document"></i>
                    </dt>
                    <dd>
                        <div class="record-dialog-title">
                            <span>{{dataList.shareName}}</span>
                            <div class="datil-btus" v-if="dataList.state!==1&&dataList.state!==2&&dataList.state!==3">
                                <el-button size="mini" type="primary" @click="changeType(dataList.id,1)">通过</el-button>
                                <el-button size="mini" @click="changeType(dataList.id,2)">拒绝</el-button>
                            </div>
                            <span v-else :style="dataList.state|applyStateColor">状态：{{dataList.state|applyState}}</span>
                        </div>
                        <div>
                            <p>
                                <span>申请人：{{dataList.targetName}}</span>
                                <span>申请时间：{{dataList.createDate|date}}</span>
                                <!-- <span>联系电话：{{dataList.targetValue}}</span> -->
                                <span>申请原因：{{dataList.description}}</span>
                            </p>
                        </div>
                    </dd>
                </dl>
                <div class="apply-record-list">
                    <p>
                        <span>阅读范围</span>
                    </p>
                    <ul>
                        <li v-for="(item,index) in dataList.resources" :key="index" @click="render(item)">
                            <div>
                                <el-image :src="item | thumbIcon"></el-image>
                                <span>{{item.name}}</span>
                            </div>
                            <div class="right" v-if="dataList.state!==1&&dataList.state!==2&&dataList.state!==3" @click.stop="delect(item)"><i class="el-icon-delete-solid"></i></div>
                        </li>
                    </ul>
                </div>
                <div class="apply-record-list" v-if="dataList.state!==1&&dataList.state!==2&&dataList.state!==3">
                    <p>
                        <span>共享目标</span>
                    </p>
                    <ul>
                        <li>
                            <div>
                                <span>{{dataList.targetName}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="apply-record-list" v-if="dataList.state!==1&&dataList.state!==2&&dataList.state!==3">
                    <p>
                        <span>操作授权</span>
                    </p>
                   <el-form ref="form" label-width="80px">
                        <el-form-item label="生效时间: ">
                            <el-date-picker
                                v-model="form.expireStartTime"
                                type="datetime"
                                size="small"
                                :picker-options="expireTimeOption"
                                @change="onChange('','',true)"
                                value-format='yyyy-MM-dd HH:mm:ss'
                                placeholder="选择日期时间">
                            </el-date-picker> - 
                            <el-date-picker
                                v-model="form.expireEndTime"
                                type="datetime"
                                size="small"
                                :picker-options="expireTimeOption"
                                @change="onChange('','',true)"
                                value-format='yyyy-MM-dd HH:mm:ss'
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="可共享: ">
                            <el-switch  @change="onChange('share',$event)" v-model="permissions.share" :active-value="1" :inactive-value="0" :disabled="dataList.state==1||dataList.state==2||dataList.state==3"></el-switch>
                        </el-form-item>
                        <el-form-item label="不可复制: ">
                            <el-switch @change="onChange('copy',$event)" v-model="permissions.copy" :active-value="1" :inactive-value="0" :disabled="dataList.state==1||dataList.state==2||dataList.state==3"></el-switch>
                        </el-form-item>
                        <el-form-item label="可打印: ">
                            <el-switch @change="onChange('print',$event)" v-model="permissions.print" :active-value="1" :inactive-value="0" :disabled="dataList.state==1||dataList.state==2||dataList.state==3"></el-switch>
                        </el-form-item>
                        <!-- <el-form-item label="可下载: ">
                            <el-switch @change="onChange('download',$event)" :active-value="1" :inactive-value="0" :disabled="dataList.state==1||dataList.state==2" v-model="permissions.download"></el-switch>
                        </el-form-item> -->
                        <el-form-item label="阅后即焚: ">
                            <el-switch @change="onChange('havaReadFire',$event)" :active-value="1" :inactive-value="0" :disabled="dataList.state==1||dataList.state==2||dataList.state==3" v-model="permissions.havaReadFire"></el-switch>
                        </el-form-item>
                        <el-form-item label="隐写溯源">
                            <el-switch  @change="onChange('hideWrite',$event)"  :active-value="1" :inactive-value="0"  v-model="permissions.hideWrite" :disabled='configValue == "0"'></el-switch>
                        </el-form-item>
                        <el-form-item label="动态水印: ">
                            <el-switch @change="onChange('isDynamic',$event)" :active-value="1" :inactive-value="0" :disabled="dataList.state==1||dataList.state==2||dataList.state==3" v-model="permissions.isDynamic"></el-switch>
                        </el-form-item>
                        <el-form-item label="设置水印: ">
                                <el-button size='small' @click="doSetWather" v-show="JSON.stringify(changWatherMsg)=='{}'"  >设置水印</el-button>
                                <p style="margin: 0;" v-show="JSON.stringify(changWatherMsg)!='{}'"><el-tag
                                    closable
                                    @close="restChangeWather" >
                                    水印名称:{{`${changWatherMsg.name}`.length>5?`${changWatherMsg.name}`.substr(0,5)+'...':changWatherMsg.name}} 水印内容: {{changWatherMsg.text}}
                                </el-tag></p>
                                <!-- <el-switch @change="onChange($event,'water')" v-model="permissions.water" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch> -->
                            </el-form-item >
                        <el-form-item label="数据脱敏" v-if="showRead">
                            <el-button size='small' @click="doSemantics">语义遮掩<i class="el-icon-star-on" v-if='dataList.envelops.length != 0'></i></el-button>
                            <el-button size='small' @click="doKeyword">关键字遮掩<i class="el-icon-star-on" v-if='permissions.shadeKeyword'></i></el-button>
                            <el-button size='small' @click="doSetEnvelop">画区域遮掩<i class="el-icon-star-on" v-if='permissions.shadeAreaId != 0'></i></el-button>
                            <SetEnvelop  ref="setEnvelop" :docInfo='docInfo' @doPermissionSet='doPermissionSet' ></SetEnvelop>
                            <SetSemantics ref='SetSemantics' :docInfo='docInfo'  vkey='edit'   @doPermissionSet='commitEnvelops'></SetSemantics>
                            <SetKeyword ref='SetKeyword' :docInfo='docInfo'  @doPermissionSet='doPermissionSet'></SetKeyword>
                            <!-- <setEnvelop  ref="setEnvelop" vkey='edit'  :docInfo='docInfo' @handleCheckedChange='commitEnvelops' @doPermissionSet='doPermissionSet'></setEnvelop> -->
                        </el-form-item>
                    </el-form>
                </div>
                <div class="apply-record-list" v-if="showRead&&dataList.state!==1&&dataList.state!==2&&dataList.state!==3">
                    <p>
                        <span>阅读范围</span>
                    </p>
                    <el-collapse v-model="activeNames" @change="handleChange" accordion>
                        <el-collapse-item name="1">
                            <template slot="title">
                                <el-row type="flex" justify="space-between" class="document-title">
                                <el-col :span="18"
                                    >{{this.dataList.resources.length == 1&& this.dataList.resources[0].type!=0 ? this.dataList.resources[0].name : ''}}</el-col
                                >
                                <el-col :span="8" class="el-readios">
                                    <div class="document-title-right">
                                    <el-radio-group
                                        v-model="radioSelect"
                                        class="document-title-right-radiu"
                                        @change="radioChangeEvent"
                                    >
                                        <el-radio :label="1">全部页面</el-radio
                                        ><el-radio :label="2">部分页面</el-radio>
                                    </el-radio-group>

                                    <!-- <el-input @change="editChecked($event)" v-model="page" style="width: auto"></el-input> -->
                                    </div>
                                </el-col>
                                </el-row>
                            </template>
                            <div class="container">
                                <el-card class="document"
                                v-for="(item,i) in showReadList"
                                :key="i"
                                :body-style="{ padding: '0px' }"
                                @click.native="onPageSelectEvent(item)"
                                >
                                <div class="document-top">
                                    <img :src="item.path|thumbnailUrl"
                                    :alt="item.path | thumbnailUrl"
                                    />
                                </div>
                                <div class="document-middle">{{ i + 1 + '页'}}</div>
                                <img src="@/assets/select.png" alt="" class="select" v-if="item.selected"/>
                                </el-card>
                            </div>
                            <div class="pagination">
                                <el-pagination
                                    background
                                    @current-change="pageChanged"
                                    layout="prev, pager, next"
                                    :page-size="pages.pageSize"
                                    :total="pages.total">
                                </el-pagination>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <ShareWatemarkDialog ref="shareWatemark" @watherInfo="onWatemarkCreated"/> 
    </div>
</template>
<script>
import { requestAPI,authAPI,documentAPI,shareAPI } from 'liankong-ui-api' 

import ShareWatemarkDialog from '@/packages/FileCart/src/ShareWatemarkDialog.vue'
import SetEnvelop from '@/packages/FileCart/src/SetEnvelop.vue'
 import SetSemantics from '@/packages/FileCart/src/SetSemantic.vue'
 import SetKeyword from '@/packages/FileCart/src/SetKeywords.vue'
export default {
    data(){
        return {
            dialogVisible: false,
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                }
            },
            showRead:false,
            dataList:{},
            activeNames:'1',
            form: {
                expireStartTime:'',
                expireEndTime:''
            },
            selectCount:0,
            docList:[],
            showReadList:[],  
            radioSelect: '',
            date:'',
            permissions: {
                readPageType: 0,
                copy: 0, //复制
                download: 0, // 下载
                edit: 0, //编辑
                print: 0, // 打印
                share: 0, // 共享
                isDynamic:0,
                readPage:null,
                shadeAreaId:0,
                shadeKeyword:''
            },
            pages:{
                pageSize:20,
                total:0
            },
            query:{
                pageNum: 1,
                pageSize: 20
            },
            changWatherMsg:{},
            docInfo:null
        }
    },
    computed: {
        configValue(){
            return this.$store.state.share.configValue
        } 
    },
    components:{ShareWatemarkDialog,SetEnvelop,SetSemantics,SetKeyword},
    methods: {
         //提交脱敏信息
        commitEnvelops(key,list){
            shareAPI.resetEnvelop(this.dataList.shareId,list).then(res=>{
                if(res.code==0){
                    this.$message.success('变更成功！')
                }else{
                    this.$message.error('变更失败！')
                }
            }).catch(message => {
                this.$message.error('变更失败！')
            })
        },
        // 设置数据脱敏
        doSetEnvelop(){
            this.$refs['setEnvelop'].dialogVisible = true;
            if(this.permissions.shadeAreaId){
                this.$refs['setEnvelop'].shadeAreaId = this.permissions.shadeAreaId;
            }
            this.$refs['setEnvelop'].getIframeUrl();
        },
        doPermissionSet(key,value){
            this.permissions[`${key}`] = value
        },
        restChangeWather(){
            this.changWatherMsg = {}
            this.permissions.water = 0
        },
        onWatemarkCreated(val,row){
            this.permissions.water = val
            this.changWatherMsg = row[0]
        },
        doSetWather(){
            this.$refs['shareWatemark'].showMakeLoding(this.permissions.water)
        },
        onInitEdit(){
           this.dataList = this.$mapData.get('MyConferEdit')
           this.dataList.envelops = this.dataList.envelops || []
            
            const resources = this.dataList.resources || []
            if(resources.length == 1){
                //如果是文件，则可以选择，就算只有一个且是文件夹，也不行
                const doc = resources[0];
                doc.id = doc.documentId;
                this.docInfo = doc.type === 1 ?  doc: null
            } else {
                this.docInfo = null
            }       
         let permission =  this.dataList.permissions ? this.dataList.permissions.reduce((map, p) => {
                    map[p.name] = p.value
                    return map
                }, {}) : null
           if(permission && JSON.stringify(permission)!=='{}'){
            if(permission.water&&permission.water!=0&&permission.water!=1){
                shareAPI.getShareWatherDetail(permission.water).then(res=>{
                        if(res.code==0){
                            this.changWatherMsg = res.data
                        } else {
                            this.changWatherMsg = {}
                            this.$message.error('获取水印信息失败')
                            }
                    })
            } else {
                this.changWatherMsg = {}
            }
                } else {
                    this.changWatherMsg = {}
                }
            if(this.dataList.resources.length==1&&this.dataList.resources[0].type!==0){
                this.documentList()
            }
        },
        // 授权页面
        documentList(){
            const resources = this.dataList.resources
            let id = resources[0].documentId || resources[0].id
            let {pageSize,pageNum} = this.query
            documentAPI.thumbnail(id,pageSize,pageNum).then(({code, data, message}) => {
                if(code == 0){
                this.showRead = true
                const { pages, records, total, current } = data
                this.pages.total = total
                if(this.radioSelect==1 || this.permissions.readPageType==0){
                    // 选中全部页面
                    this.showReadList = records.map(page=>{
                        page.selected = true
                        return page
                    })
                    // 第一次加载
                    if(this.docList.length==0){
                        this.docList = [...this.showReadList]
                        this.radioSelect = this.selectCount == this.pages.total || this.permissions.readPageType==0 ? 1 : 2
                    } else {
                        this.selectCount = 0
                        records.forEach((items,i)=>{
                            const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                            if(Index==-1){
                                items.selected = true
                                this.docList.push(items)
                            }
                        })
                        records.forEach(item=>(
                            this.docList.forEach(ele=>{
                                if(item.path==ele.path){
                                    item.selected = ele.selected
                                }
                            })
                        ))
                        this.showReadList = [...records]
                        this.docList.forEach(item=>{
                            if(item.selected){
                                this.selectCount++
                            }
                        })
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    }
                } else if(this.permissions.readPageType==0){
                    // 第一次加载
                    if(this.docList.length==0){
                        this.showReadList = records.map(page=>{
                            page.selected = true
                            return page
                        })
                        this.docList = [...this.showReadList]
                    } else {
                        this.selectCount = 0
                        records.forEach((items,i)=>{
                            const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                            if(Index==-1){
                                items.selected = true
                                this.docList.push(items)
                            }
                        })
                        records.forEach(item=>(
                            this.docList.forEach(ele=>{
                                if(item.path==ele.path){
                                    item.selected = ele.selected
                                }
                            })
                        ))
                        this.showReadList = [...records]
                        this.docList.forEach(item=>{
                            if(item.selected){
                                this.selectCount++
                            }
                        })
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    }
                } else {
                    const readPage = this.permissions.readPage
                    //数据打标
                    const readPageList =typeof(readPage)=='number'?readPage:( readPage ? readPage.split(',') : null)
                    if(this.docList.length==0){
                        this.showReadList = records.map(page => {
                            page.selected = readPageList ? readPageList.indexOf("" + page.pageIndex) > -1 : false
                            return page
                        })
                        this.docList = [...this.showReadList]
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    } else {
                        this.selectCount = 0
                        records.forEach((items,i)=>{
                            const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                            if(Index==-1){
                                items.selected = readPageList ? readPageList.indexOf("" + items.pageIndex) > -1 : false
                                this.docList.push(items)
                            }
                        })
                        records.forEach(item=>(
                            this.docList.forEach(ele=>{
                                if(item.path==ele.path){
                                    item.selected = ele.selected
                                }
                            })
                        ))
                        this.showReadList = records
                        this.docList.forEach(item=>{
                            if(item.selected){
                                this.selectCount++
                            }
                        })
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    }
                }
                }else{
                    this.showRead = false
                    this.$message.error(message)
                }
            })
        },
        // 跳转ofd页面
        render(docInfo){
            if(docInfo.type==0){
                this.$store.dispatch("doc/loadChildrenByShare", {
                    vkey: this.verker,
                    folder: docInfo
                })
                return
            }

            this.$ebus.$emit('publicReader',{
                shareId:this.dataList.shareId, 
                id: docInfo.documentId || docInfo.id,
                documentId: docInfo.documentId,
                suffix:docInfo.suffix
            })
           
        },
        // 审批
        changeType(id,state) {
            let {expireStartTime,expireEndTime} = this.form
            if(!this.$options.filters['dateRengeCompare'](expireStartTime, expireEndTime)){
                this.$message.error("结束时间不应在开始时间之前");
                return
            }
            this.permissions.readPageType = this.selectCount == 0 || this.radioSelect == 1 ? 0 : 1
            if(this.permissions.readPageType==0){
                this.permissions.readPage = null
            } else {
                this.permissions.readPageType = 1
                let readPageType = this.docList.filter(p => p.selected).map(p => p.pageIndex)
                this.permissions.readPage = readPageType.length>0? readPageType.join(',') : 0
            }
            let permissions = []
            for (let [key, value] of Object.entries(this.permissions)) {
                permissions.push({
                    name:key,
                    value
                })
            }

            const loading = this.$loading({
                lock: true,
                text: '正在审核中请等待。。。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            requestAPI.apply(id,{
                state
            },{
                expireEndTime,
                expireStartTime,
                permissions
            }).then(({code,data,message})=>{
                if(code == 0){
                    loading.close();
                    this.$message.success('审核成功')
                    this.$store.commit('request/updateState',{
                        vkey:'receiver',
                        stateValue:'申请授权'
                    })
                    // 刷新列表
                    this.$emit('refreshList')
                } else {
                    loading.close();
                    this.$message.error(message)
                }
                this.dialogVisible = false;
            })
        },
        // 删除共享文件
        // shareId
        delect(val){
            let resource = val
            authAPI.removeShareResource(this.dataList.shareId,resource.id).then(res=>{
                if(res.code==0){
                    this.dataList.resources = this.dataList.resources.filter(item=>item.id != resource.id)
                    this.$mapData.put('MyConferEdit',this.dataList)
                    this.$message.success('文件删除成功')
                    this.$emit('refreshList')
                    if(this.dataList.resources.length==1&&this.dataList.resources[0].type!==0){
                        this.documentList()
                    }
                } else {    
                    this.$message.error(res.message)
                }
            })
        },
        // 变更状态
        onChange(val,$event){
            this.permissions[val] = $event ? 1 : 0
        },
        handleChange(){
            this.activeNames = 'close'
        },
        radioChangeEvent(value){
            if(value == 1) { // 点击全部页面
                this.selectCount = this.pages.total;
                this.docList.forEach(listItem=>{
                    this.$set(listItem,'selected',true)
                })
                this.showReadList.forEach(item=>{
                    this.$set(item,'selected',true)
                })
            }
        },
        pageChanged(num){
            this.query.pageNum = num
            this.documentList()
        },
        onPageSelectEvent(item){
            // item.selected = !item.selected
            this.showReadList.forEach(items=>{
                if(items.path==item.path){
                    items.selected = !items.selected
                }
            })
            this.$nextTick(() => this.$forceUpdate())
            this.docList.forEach(items=>{
                if(items.path==item.path){
                    items.selected = items.selected 
                }
            })
            if (item.selected) {
                this.selectCount++;
                this.radioSelect =  this.selectCount == this.pages.total ? 1 : 2
            } else {
                this.selectCount--;
                this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
            }
        },
         // 语义遮掩
        doSemantics(){
            this.$refs.SetSemantics.dialogVisible = true;
            this.$refs.SetSemantics.getEnvelopField()
        },
        // 关键字遮掩
        doKeyword(){
            if(this.permissions.shadeKeyword){
                this.$refs.SetKeyword.keyWordList = this.permissions.shadeKeyword.split(',')
            }
                this.$refs.SetKeyword.dialogVisible = true;
            },
    }
}
</script>
<style lang="less" scoped>
.apply-record-dialogs {
    dl {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        dt {
            padding: 0;
            margin: 0;
            i {
                font-size: 32px;
                margin-right: 5px;
                color: #ffaf1d;
            }
        }
        dd {
            flex: 1;
            padding: 0;
            margin: 0;
            .record-dialog-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                span:nth-child(1){
                    font-size: 16px;
                    font-weight: 600;
                    color: #009DD9;
                }
            }
            p {
                padding: 0;
                margin: 0;
                span {
                    margin-right: 15px;
                }
            }
        }
    }
    .apply-record-list {
        p {
            display: flex;
            margin: 20px 0 5px 0;
            span{
                position: relative;
                padding-left: 20px;
                font-size: 15px;
                &::before{
                    content: '';
                    // width: 3px;
                    // height: 18px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #409eff;
                    position: absolute;
                    left: 0px;
                    top: 4px;
                }
            }
        }
        ul {
            margin: 0px;
            padding: 0px;
            // height: 400px;
            overflow-y: auto;
            li {
                list-style: none;
                height: 45px;
                line-height: 45px;
                border-bottom: 1px solid #f2f2f2;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    .el-image {
                        margin-right: 5px;
                        width: 21px;
                        height: 21px;
                        img {
                            display: block;
                        }
                    }
                }
                span {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                 }
            }
            li:hover {
                color: #409eff;
            }
        }
        .el-form {
            .el-form-item {
                margin-bottom: 0px;
                .el-tag::before {
                    background: none;
                }
            }
        }
        /deep/ .el-collapse {
            border: none;
            .el-collapse-item__header {
                display: flex;
                height: 30px;
                line-height: 30px;
                border: none;
                .document-title {
                    flex: 1;
                }   
            }
            .el-collapse-item__wrap {
                border: none;
                .el-collapse-item__content {
                    padding: 0px;
                    .container {
                        display: grid;
                        grid-template-columns: repeat(10, 1fr);
                        grid-row-gap: 10px;
                        grid-column-gap: 10px;
                        padding: 16px;
                        background: #f0f1f4;
                    }
                    .document {
                        text-align: center;
                        box-sizing: border-box;
                        position: relative;
                    }
                    .document-select{
                        border: 1px solid #409eff;
                    }
                    .document img.select {
                        position: absolute;
                        right: 0;
                        bottom: 0px;
                        font-size: 20px;
                    }
                    .document-top {
                        height: 40px;
                        overflow: hidden;
                    }
                    .document-top img {
                        width: 100%;
                        height: 40px !important;
                        overflow: hidden;
                        
                    }
                    .document-middle{
                        text-align: center;
                        margin-top: 5px;
                    }
                    .pagination {
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}
</style>