/**
 * 登陆状态管理
 */
import Vue from 'vue'
import { loginAPI, userAPI } from 'liankong-ui-api'
import { Cache, Store } from '@lk/cache'

const AccessTokenKey = "access_token"
const RefreshTokenKey = "refresh_token"
const userInfoKey = "user"
const ExpiresKey = "expires"

//Refactor#C#拆分为getter来使用@Daniel 2021-06
const storeUserInfo = Cache.get(userInfoKey) || {}
const state = {
  userInfo: storeUserInfo,
  loginName: storeUserInfo[""],
  name: storeUserInfo["username"],
  avatar: storeUserInfo["avatar"],
  roles: storeUserInfo["authorities"] || [],
  id: storeUserInfo["id"],
  phoneNumber: storeUserInfo["phoneNumber"],

  //是否登陆
  isLogin: false,
  token: Store.get(AccessTokenKey),
  dist:0
}

const mutations = {
  /**
   * 前端保存登陆信息，包括
   * - 会话信息
   * - 用户信息
   * @param {*} state 
   * @param {*} param1 
   */
  setLoginInfo(state, { access_token, refresh_token, user_info, expires_in = 0 }) {

    //处理token
    if (access_token) {
      state.token = access_token
      
      Store.put(AccessTokenKey, access_token)
      Store.put(RefreshTokenKey, refresh_token)
      
      //计算过期时间
      const expiresTime = (+ new Date()) + (expires_in * 1000)
      Store.put(ExpiresKey, expiresTime)
    }
    /* else{
          Store.del(AccessTokenKey)
          Store.del(RefreshTokenKey)
        } */

    //处理用户信息
    if (user_info) {
      state.userInfo = user_info
      state.username = user_info["username"]
      state.name = user_info["name"]
      state.avatar = user_info["avatar"]
      state.phoneNumber = user_info["phoneNumber"]
      state.roles = user_info["authorities"] || []
      state.id = user_info["id"]

      Cache.put(userInfoKey, user_info)
    } else {
      state.userInfo = null

      state.name = null
      state.username = null
      state.avatar = null
      state.roles = []
      state.isAdmin = false
      state.id = null

      Cache.del(userInfoKey)
    }

    //登陆状态, 通知
    state.isLogin = state.id != null
    if (state.isLogin && this._vm.$ebus) {
      this._vm.$ebus.$emit("sys:login")
    }
  },

  //退出登陆
  logout(state){
    Store.del(AccessTokenKey)
    Store.del(RefreshTokenKey)

    state.userInfo = null

    state.name = null
    state.avatar = null
    state.roles = []
    state.isAdmin = false
    state.id = null
    state.dist = 0

    Cache.del(userInfoKey)
  },

  /**
   * 手动设置登陆状态
   * @param {*} state 
   * @param {*} value 
   */
  setLoginState(state, value) {
    state.isLogin = value
  }
}

const actions = {
  /**
   * 使用账号密码登陆
   * @param {*} param0 
   * @param {*} param1 
   */
  async loginByPwd({ commit }, { username, password }) {
    try {
      const { code, data } = await loginAPI.loginByPassword(username, password)

      commit("setLoginInfo", data)

      return code
    } catch (error) {
      throw error
    }
  },
  /**
   * 发送短信验证码
   * @param {*} phoneNumber 
   */
  async sendLoginCode({ commit }, { phoneNumber }) {
    try {
      const { code, data, message } = await loginAPI.sendLoginCode(phoneNumber)
      if (code == 0) {
        // commit("setLoginInfo", data)
      } else {
        throw Error(message)
      }
      return code
    } catch (error) {
      throw error
    }
  },
  /**
   * 使用短信验证码登陆
   * @param {*} param0 
   * @param {*} param1 
   */
  async loginBySMS({ commit }, { phoneNumber, codeNumber }) {
    try {
      const { code, data, message } = await loginAPI.loginByPhone(phoneNumber, codeNumber)
      if (code == 0) {
        commit("setLoginInfo", data)
      } else {
        throw Error(message)
      }
      return code
    } catch (error) {
      throw error
    }
  },

  /**
   * 使用client code进行认证登陆
   * @param {*} param0 
   * @param {*} param1 
   */
  async loginByCode({ commit }, payload) {
    try {
      const { code, data, message } = await loginAPI.loginBySNSCode(payload.code)

      if (code == 0) {
        commit("setLoginInfo", data)
      } else {
        throw Error(message)
      }

      return code == 0
    } catch (error) {
      throw error
    }
  },

  /**
   * 微信小程序登陆
   * @param {*} param0 
   * @param {*} paylod 
   */
  async loginByWXMP({ commit }, paylod) {
    try {
      const { code, data, message } = await loginAPI.loginByWXMP(paylod.code,
        paylod.ivData, paylod.encrypData, paylod.userEncryptedData)

      if (code == 0) {
        commit("setLoginInfo", data)
      } else {
        throw Error(message)
      }

      return code == 0
    } catch (error) {
      throw error
    }
  },

  /**
   * 加载当前用户的信息
   * @param {*} param0 
   */
  async loadLoginUserInfo({ commit }) {
    try {
      const { code, data, message } = await userAPI.getInfo()
      if (code == 0) {
        
        commit("setLoginInfo", {
          user_info: data
        })
      } else {
        throw Error(message)
      }
      return code == 0
    } catch (error) {
      throw error
    }
  },

  /**
   * 更新用户信息
   * @param {*} param0 
   * @param {*} userInfo 
   */
  async updateUserInfo({state, commit}, userInfo){
    try{
      const { code, data, message } = await userAPI.update(userInfo)

      if(code == 0){
        //更新用户信息
        userInfo = Object.assign(state.userInfo, userInfo)
        commit('setLoginInfo', {
          user_info: userInfo
        })
      }else{
        return {message,code}
      }

      return code == 0
    }catch(error){
      throw error
    }
  },


    /**
   * 获取用户使用空间
   */
     async getUserUsedDist({state, commit}, ){
      try{
        const { code, data, message } = await userAPI.getUserUsedDist()
  
        if(code == 0){
          state.dist = data;
        }else{
          return {message,code}
        }
  
        return code == 0
      }catch(error){
        throw error
      }
    },




  /**
     * 刷新token
     * @param {*} param0 
     */
  async refreshToken({ commit }) {
    const refreshToken = Store.get(RefreshTokenKey)

    const { code, data, message } = await loginAPI.refreshToken(refreshToken)

    if (code == 0) {
      commit("setLoginInfo", data)
      return Promise.resolve(code)
    } else {
      return Promise.reject(message)
    }
  },


  /**
   * 修改密码
    * @param {string} phone 
    * @param {string} newPassword 
    * @param {string} vcode 
   */
  async resetPassword({commit}, { phone, newPassword, vcode }) {
    try {
      const { code, data, message } = await loginAPI.resetPassword(phone, newPassword, vcode)
      if (code == 0) {
        commit("setLoginInfo", { access_token: false, refresh_token: false, user_info: false })
      } else {
        throw Error(message)
      }
      return code
    } catch (error) {
      throw error
    }
  },

  // 注销登录
  async logout({ commit }) {
    try {
      const { code, data, message } = await loginAPI.logout()
      if (code == 0) {
        commit("logout")
      } else {
        throw Error(message)
      }
      return code == 0
    } catch (error) {
      throw error
    }
  },


  // 检查code是否有效
  async checkCode({ commit },{cacheCode}) {
    try {
      const { code, data, message } = await loginAPI.checkCode(cacheCode);
      return code == 0 && data == true
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}