import { fileAPI } from 'liankong-ui-api'
import uploadFile from '@lk/upload'

let id = 1;

function getid() {
    return id++
}

const state = {
    //上传任务
    uploadList: [],
    saveUkey: '', // key
}

const getters = {}

const mutations = {
    saveUkey(state, val) {
        state.saveUkey = val;
    },
    saveUploadList(state, { files, reset = false}){
        if(reset){
            state.uploadList = [];
        }
        files.forEach( (e) => {
            e.status = 0;
            e.id = null;
            state.uploadList.push(e)
        })
    }
}

const actions = {
    /**
     * 上传文件
     * @param {*} param0 
     * @param {*} file 
     */
    async addUploadTask({ commit, dispatch }, file) {

        file.id = getid()
        file.progress = {
            value: 0,
            start: 0,
            state: 1
        }

        //Develop#C#任务列表，记录执行@Daniel 2021-06
        return await dispatch("execUploadTask", file)
    },

    /**
     * 执行上传任务
     * @param {*} param0 
     * @param {*} uploadTask 
     */
    async execUploadTask({ commit, dispatch }, uploadTask) {
        //获取上传任务Key
        const uploadKeyRes = await fileAPI.uploadTaskKey(uploadTask)
            //Develop#A#完成文件追加逻辑@刘薇 2021-07-15
        if (uploadKeyRes.code == 1) {
            throw (uploadKeyRes.message)
        }

        commit('saveUkey', uploadKeyRes.data)
        uploadTask.uploadKey = uploadKeyRes.data
        uploadTask.progress.state = 2

        console.log("【上传任务】执行完第一阶段：", uploadTask)
            //Develop#C#任务列表，更新进度@Daniel 2021-06

        //获取上传任务进度
        const uploadInfoRes = await fileAPI.uploadTaskInfo(uploadTask)
        console.log("【上传任务】", uploadInfoRes)
        const uploadInfo = uploadInfoRes.data

        console.log("【上传任务】执行完第二阶段：", uploadInfo)

        //需要上传 pc小文件走这个
        if (uploadInfo.needSplit == false && uploadTask.isPcFlag) {

            //上传处理
            const uploadRes = await uploadFile(uploadTask)

            console.log("上传任务，执行完上传：", uploadRes)
            uploadTask.progress.state = 3
            uploadTask.progress.value = 100

            //Develop#C#任务列表，更新进度@Daniel 2021-06
            return uploadRes

        } else if ((uploadInfo.needSplit || uploadInfo.needSplit == false) && uploadTask.isPcFlag == undefined) { // 小程序上传

            //上传处理
            const uploadRes = await uploadFile(uploadTask)

            console.log("上传任务，执行完上传：", uploadRes)
            uploadTask.progress.state = 3
            uploadTask.progress.value = 100

            //Develop#C#任务列表，更新进度@Daniel 2021-06

            return uploadRes

        } else if (uploadInfo.needSplit && uploadTask.isPcFlag) { // pc大文件做分片处理
            return uploadInfoRes
        } else if (uploadInfo.upload == false) { // 刷新列表
            return uploadInfo
        }
    },

    /**
     * 分片上传第一步
     * @param {*} param0 
     * @param {*} file 
     */
    // async mutipUploadFileTask({ commit, dispatch }, file) {
    //     file.id = getid()

    //     return await dispatch("mutipStartUpload", file)
    // },

    /**
     * 分片检查
     * @param {*} param0 
     * @param {*} file 
     */
    // async mutipStartUpload({ commit, dispatch }, file) {
    //     //获取上传任务Key
    //     const uploadKeyData = await fileAPI.mutipUploadTaskKey(file)
    //     if (uploadKeyData.code == 1) {
    //         throw (uploadKeyData.message)
    //     }

    //     commit('saveUkey', uploadKeyData.data)
    //     file.uploadKey = uploadKeyData.data

    //     //获取上传任务进度
    //     const uploadInfoData = await fileAPI.mutipUploadTaskScross(file)
    //     return uploadInfoData
    // },

    /**
     * 分片上传
     * @param {*} param0 
     * @param {*} file 
     */
    async mutipUploadFile({ commit, dispatch }, { uploadTask, uploadKey }) {
        const res = await fileAPI.mutipUploadFile(uploadTask, uploadKey)
        return res;
    },

    /**
     * 分片合并
     * @param {*} param0 
     * @returns 
     */
    async mutipUploadMergeFiles({ commit, dispatch }, uploadKey) {
        const datas = await fileAPI.mutipUploadMergeFiles(uploadKey)
        return datas;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}