/**
 * 管理端3.0、工具平台端
 */
import { manageAPI } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'

const BaseListPageViewer = {
    query: {
        pageNum: 1,
        pageSize: 10
    },
    paging: {
        total: 0,
        pages: 0
    },
    list: [],

    //加载更多
    more: {
        status: 'more',
        contentText: {
            contentdown: '加载更多数据',
            contentrefresh: '数据加载中',
            contentnomore: '没有更多数据了'
        }
    }
}

const state = {
    views: {
        rotationPage: deepCopy(BaseListPageViewer), // 轮播图管理页面
        tooltypePage: deepCopy(BaseListPageViewer), // 工具分类页面
        mostpopularPage: deepCopy(BaseListPageViewer), // 最受欢迎页面
        toolPage: deepCopy(BaseListPageViewer), // 工具管理页面
        versionPage: deepCopy(BaseListPageViewer), // 版本管理
        questionPage: deepCopy(BaseListPageViewer), // 问答管理
        memberPage: deepCopy(BaseListPageViewer), // 会员管理
        memberOrderPage: deepCopy(BaseListPageViewer), // 会员订单管理
        customizaPage: deepCopy(BaseListPageViewer), // 会员定制管理
        tenantPage: deepCopy(BaseListPageViewer), // 租户管理
        toolsCountPage:deepCopy(BaseListPageViewer), // 工具使用统计
        documentLibrary:deepCopy(BaseListPageViewer), // 文档库管理
        toolsFeedBack:deepCopy(BaseListPageViewer), // 工具反馈列表
        pageNotify:deepCopy(BaseListPageViewer), // 工具公告列表
    }
}

const mutations = {
    updateList(state, { vkey, reset = false, current, pages, data, total }) {
        const viewer = state.views[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data);
    },

    /**
     * 更新视图查询条件
     * @param   query
     * @return  data
     */
    updateQuery(state, { vkey, query }) {
        const viewer = state.views[vkey]
        viewer.query = Object.assign(viewer.query, query)
    },
}

const actions = {
    // 列表
    async list({ commit, state }, { vkey, reset = false, query, interfaceName }) {
        const viewer = state.views[vkey]

        // 是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }

        if (reset) {
            viewer.query.pageNum = 1
        }
        //更新查询条件
        commit('updateQuery', { vkey: vkey, query })

        const res = await manageAPI[`${interfaceName}`](viewer.query);

        if (res.code == 0) {
            commit("updateList", {
                vkey,
                reset,
                current: res.data.current || res.current,
                pages: res.data.pages || res.pages,
                size: res.data.size || res.size,
                total: res.data.total || res.total,
                data: res.data.records ? res.data.records : res.data
            })
        }

        return res.code == 0
    },
    /**
     * 新建
     * @param {*} vkey  
     * @param {*} query 参数 
     * @param {*} requestInterfaceName 请求的接口名字
     * @param {*} listInterfaceName 刷新列表的名字
     * @returns 
     */
    async add({ dispatch }, { vkey, query, requestInterfaceName, listInterfaceName }) {
        try {
            const data = await manageAPI[`${requestInterfaceName}`](query);
            if (data.code == '0') {
                dispatch("list", { vkey: vkey, reset: true, interfaceName: listInterfaceName });
            }
            return data
        } catch (error) {
            throw error
        }
    },
    /**
     * 编辑
     * @param {*} vkey  
     * @param {*} query 参数 
     * @param {*} requestInterfaceName 请求的接口名字
     * @param {*} listInterfaceName 刷新列表的名字
     * @returns 
     */
    async edit({ dispatch }, { vkey, query, requestInterfaceName, listInterfaceName }) {
        try {
            const data = await manageAPI[`${requestInterfaceName}`](query);
            if (data.code == '0') {
                dispatch("list", { vkey: vkey, reset: true, interfaceName: listInterfaceName });
            }
            return data
        } catch (error) {
            throw error
        }
    },
    /**
     * 删除
     * @param {*} vkey 
     * @param {*} query 参数
     * @param {*} requestInterfaceName 请求的接口名字
     * @param {*} listInterfaceName 刷新列表的接口名字
     * @returns 
     */
    async remove({ dispatch }, { vkey, query, requestInterfaceName, listInterfaceName }) {
        try {
            const data = await manageAPI[`${requestInterfaceName}`](query);
            if (data.code == '0') {
                dispatch("list", { vkey: vkey, reset: true, interfaceName: listInterfaceName });
            }
            return data
        } catch (error) {
            throw error
        }
    },

    /**
     * 公共方法
     * @param {*} query  传参
     * @param {*} requestInterfaceName 请求接口的名字 
     * @returns 
     */
    async public({ dispatch }, { query, requestInterfaceName }) {
        try {
            const data = await manageAPI[`${requestInterfaceName}`](query);
            return data
        } catch (error) {
            throw error
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}