<template>
    <div>
        <el-dialog
            title="共享信息"
            :close-on-click-modal="false" 
            :append-to-body='true'
            :visible.sync="dialogVisible">
            <div>
                <div class="share-top-wrap">
                    <dl class="share-head-box">
                        <dt>
                            <img src="http://app.xunjiepdf.com/ContentMain/home/pdf2word.png" alt="">
                        </dt>
                        <dd>
                            <h3>{{shareInfo.name}}</h3>
                            <div class="share-text">
                                <p>
                                    <span style="color:#afafaf">共享时间：</span>
                                    <span>{{shareInfo.createDate|date}}</span>
                                </p>
                                <p>
                                    <span style="color:#afafaf">共享人：</span>
                                    <span>{{shareInfo.ownerName}}</span>
                                </p>
                            </div>
                        </dd>
                    </dl>
                    <el-button type='primary' plain size="medium" v-if='shareInfo.canShare&&showShare' @click="shareAgain">再次共享</el-button>
                </div>
                <el-tabs v-model="shareActiveName" class="share-msg">
                    <el-tab-pane label="文件资源" name="first">
                        <el-button size="small" v-if="viewer.depth > 0" @click="back">返回上一级</el-button>
                        <el-table
                            :data="viewer.showFiles"
                            style="width: 100%"
                            class="table-file"
                            height="400"
                            row-class-name="document-item">
                            <el-table-column
                            prop="name"
                            label="文件名称">
                            <template slot-scope="scope">
                                <div class="file-column-name" @click="render(scope.row)">
                                <span style="cursor: pointer; line-height: 26px;">
                                    <i class="el-icon-folder-opened" v-if="scope.row.type == 0" style="font-size: 16px; margin-right: 5px;"></i> 
                                    <i class="el-icon-document" v-if="scope.row.type == 1" style="font-size: 16px; margin-right: 5px;"></i> 
                                    {{scope.row.name}}
                                </span>
                                </div>
                            </template>
                            </el-table-column>
                            <el-table-column
                            label="类型"
                            width="120">
                            <template slot-scope="scope">
                                <span>{{scope.row.type|fileType(scope.row)}}</span>
                            </template>
                            </el-table-column>
                            <!-- <el-table-column
                            label="大小"
                            width="130">
                            <template slot-scope="scope">
                                <span>{{ scope.row.size | byteSize }}</span>
                            </template>
                            </el-table-column> -->
                            <el-table-column
                            label="操作"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-button
                                v-if="scope.row.type == 1"
                                size="mini"
                                type="primary"
                                @click="render(scope.row)">阅读</el-button>
                                <el-button
                                v-if="scope.row.type == 1 && shareRowInfos.canArchive && shareRowInfos.state !=3"
                                size="mini"
                                type="primary"
                                @click="handleToFile(scope.row)">保存</el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="共享目标" name="second">
                        <div class="share-bottom-left">
                        <ul>
                            <li v-for="(datas,ind) in shareInfo.targets" :key="ind">
                                <span>{{datas.attrs.name || datas.value}}</span>
                            </li>
                        </ul>
                    </div>
                    </el-tab-pane>
                    <el-tab-pane label="操作权限" name="third">
                    <div class="share-bottom-right">
                       <el-form ref="form" label-width="80px">
                            <el-form-item label="可保存: " >
                                <el-switch  v-model="archivableFlag"  :disabled="true" :active-value="1" :inactive-value="0"></el-switch>
                            </el-form-item>
                            <el-form-item label="保存文件: " v-if="archivableFlag == 1">
                                <el-radio  v-model="permissions.archivable" :label="1"  :disabled="true" >源文件</el-radio>
                                <el-radio  v-model="permissions.archivable" :label="2"  :disabled="true">OFD版式文件</el-radio>
                            </el-form-item>
                            <el-form-item label="生效时间: ">
                                {{shareInfo.expireStartTime&&shareInfo.expireEndTime
                                    ? shareInfo.expireStartTime+'-'+shareInfo.expireEndTime : 
                                    shareInfo.expireEndTime
                                    ?'结束时间'+ shareInfo.expireEndTime: shareInfo.expireStartTime?shareInfo.expireStartTime+' - 永久':'永久'}}
                            </el-form-item>
                            <el-form-item label="可共享: ">
                                <el-switch v-if='permissions'  v-model="permissions.share" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
                            </el-form-item>
                            <el-form-item label="不可复制: ">
                                <el-switch v-if='permissions' v-model="permissions.copy" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
                            </el-form-item>
                            <el-form-item label="可打印: ">
                                <el-switch v-if='permissions' v-model="permissions.print" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
                            </el-form-item>
                            <!-- <el-form-item label="可下载: ">
                                <el-switch v-model="permissions.download" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
                            </el-form-item> -->
                            <el-form-item label="阅后即焚: ">
                                <el-switch v-if='permissions' v-model="permissions.havaReadFire" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
                            </el-form-item>
                            <el-form-item label="隐写溯源">
                                <el-switch v-if='permissions' :active-value="1" :inactive-value="0"  v-model="permissions.hideWrite"  :disabled='true'></el-switch>
                            </el-form-item>
                            <el-form-item label="动态水印: ">
                                <el-switch v-if='permissions' v-model="permissions.isDynamic" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
                            </el-form-item>
                            <el-form-item label="设置水印: ">
                                <p style="margin: 0;" v-show="JSON.stringify(changWatherMsg)!='{}'"><el-tag>
                                    水印名称:{{`${changWatherMsg.name}`.length>5?`${changWatherMsg.name}`.substr(0,5)+'...':changWatherMsg.name}} 水印内容: {{changWatherMsg.text}}
                                </el-tag></p>
                                <!-- <el-switch v-model="permissions.water" :active-value="1" :inactive-value="0" :disabled="true"></el-switch> -->
                            </el-form-item>
                            <el-form-item label="可读页数: ">
                                 <span v-if='permissions'>{{permissions.readPageType==0 || permissions.readPageType == undefined ? '全部' :`${permissions.readPage}`.indexOf(',')!=-1 ? `${permissions.readPage}`.split(',').length + "页" : `${permissions.readPage}`.split(',').length + "页"  }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <!-- <el-button type="primary" size="small" @click="dialogVisible = false">确 定</el-button> -->
            </span>
        </el-dialog>

        <AddShareAgain  :shareInfo="shareInfo"  ref='addShareAgain'></AddShareAgain>
        <ReadFile ref="readFile" />
        <!-- 选择我的文档 -->
         <SelectMyDocument ref='SelectMyDocument' @SelectMyDocument='SelectMyDocument'></SelectMyDocument>
    </div>
</template>
<script>
import { requestAPI, shareAPI, qrReadAPI, documentAPI} from 'liankong-ui-api' 
import AddShareAgain from '@/packages/FileCart/src/addShareAgain.vue'
import ReadFile from "@/components/readFile.vue"
import { Store } from '@/utils/LocalCache.js'
import SelectMyDocument from '../../onlineEditing/components/SelectMyDocument.vue'
export default {
    data() {
        return {
            dialogVisible: false,
            verker: 'shareDocList',
            shareRowInfos: {},
            showShare:false,
            documentType:'',
            shareActiveName:'first',
            changWatherMsg:{},
            archivableFlag:0,
            currentDocument:{
                documentId:null,
                documentRootId:null,
                shareId:null,
                userDocumentFolderId:null
            }
        };
    },
    components: {
        AddShareAgain,ReadFile,SelectMyDocument
    },
    computed: {
        //共享信息
        shareInfo(){
            return this.$store.state.auth.editShare
        },
        viewer() {
            return this.$store.state.doc.viewer[this.verker] || {}
        },
        // //权限信息
        permissions(){
            return this.shareInfo.permissions ? this.shareInfo.permissions.reduce((map, p) => {
                map[p.name] = p.value
                return map
            }, {}) : null
        },
        configValue(){
            return this.$store.state.share.configValue
        } 
    },
    methods: {
        onLoadInit(row,showShare) {
            this.archivableFlag = 0 
            this.shareRowInfos = row;
            this.showShare = showShare;
            
            this.onload();
        },
        // 归档
        handleToFile(row){
            let rootId = this.documentType != '' ?  this.documentType:row.documentId;
            this.currentDocument = {
                documentId:row.documentId || row.id,
                documentRootId:rootId,
                shareId:this.shareRowInfos.id,
                userDocumentFolderId:null
            }
            this.$refs.SelectMyDocument.show('folder',row.documentId || row.id,'shareToMe');
        },
        // 保存到我的文档
        SelectMyDocument(parentId){
            if(parentId != null){
                 this.currentDocument.userDocumentFolderId = parentId;
                documentAPI.shareArchiveDocument(this.currentDocument).then(res => {
                    if(res.code == 0){
                    this.$message.success('保存成功，请到我的文档中查看!')
                    }else{
                    this.$message.error(res.message)
                    }
                })
            }
           
        },

        onload(){
            this.$store.dispatch("auth/loadShare", this.shareRowInfos.id).then(data => {
                // this.shareDetail = data.data
                let { resources } = this.$store.state.auth.editShare;
                this.archivableFlag  = this.permissions.archivable == 0?0:1;
                if(this.permissions.water){
                    if(this.permissions.water!=0&&this.permissions.water!=1){
                        shareAPI.getShareWatherDetail(this.permissions.water).then(res=>{
                            if(res.code==0){
                                this.changWatherMsg = res.data
                            } else {
                                this.changWatherMsg = {}
                                this.$message.error('获取水印信息失败')
                            }
                        })
                    } else {
                        this.changWatherMsg = {}
                    }
                }
                
                this.$store.commit('doc/createViewer', {
                    viewerKey: this.verker,
                    defaultFiles: resources
                })
            })
        },
        // 再次共享
        shareAgain(){
            this.$refs['addShareAgain'].dialogVisible = true;
        },
        back(){
            this.$store.dispatch('doc/backFolder', {
                vkey: this.verker
            })
        },
        // 跳转ofd页面
        render(docInfo){
            docInfo.documentId ? docInfo.documentId = docInfo.documentId : docInfo.documentId = docInfo.id
            
            if(docInfo.type==0){
                if(this.documentType==''&&this.viewer.depth == 0){
                    this.documentType = docInfo.documentId
                } 
                this.$store.dispatch("doc/loadChildrenByShare", {
                    vkey: this.verker,
                    folder: docInfo
                })
                return
            }
            let suffix='ofd'
            //this.shareInfo.id, docInfo.id || docInfo.documentId, this.documentType||docInfo.documentId,'',suffix
            shareAPI.getReaderUrl(this.shareInfo.id,docInfo.documentId, this.documentType||docInfo.documentId,'',suffix)
                .then(({ code, data, message }) => {
                if (code == 0) {
                    //获取到阅读地址，跳转阅读服务
                    let shareInfo = `&shareIds=${this.shareInfo.id}&documentId=${docInfo.documentId}&documentRootId=${this.documentType||docInfo.documentId}`
                    if(Store.get("myPreference")){
                        if(Store.get("myPreference").reader_model == '1'){
                            window.open(this.$options.filters['readerUrl'](data.url,shareInfo));
                        }else{
                            this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfo))
                        }
                    }else{
                        this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfo))
                    }

                } else if(code == 1) {
                    // this.$message.error('权限不足，请申请阅读')
                     this.$message.error(message) 
                } else{
                    this.$message.error(message) 
                }
            })
        },
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    .el-dialog__body {
        padding: 10px 20px;
    }
}
.share-top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .share-head-box {
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0px;
        dt {
            width: 38px;
            height: 38px;
            img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 2px;
            }
            margin-right: 10px;
        }
        dd {
            padding: 0px;
            margin: 0px;
            h3 {
                padding: 0px;
                margin: 0px 0px 4px 0px;
                font-size: 18px;
                max-width: 800px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .share-text {
                display: flex;
                p {
                    padding: 0px;
                    margin: 0px;
                    span {
                        font-size: 13px;
                    }
                }
                p:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
.share-file-box {
    p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0 5px 0;
        span:nth-child(1) {
            position: relative;
            padding-left: 20px;
            font-size: 15px;
            &::before{
                content: '';
                // width: 3px;
                // height: 18px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #409eff;
                position: absolute;
                left: 0px;
                top: 4px;
            }
        }
        span:nth-child(2) {
            cursor: pointer;
        }
    }
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
            height: 35px;
            line-height: 35px;
            border-bottom: 1px solid #f2f2f2;
            font-size: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .el-image {
                margin-right: 5px;
                width: 21px;
                height: 21px;
                img {
                    display: block;
                }
            }
        }
        li:hover {
            color: #409eff;
        }
    }
}
.share-bottom-left {
    width: 100%;
    margin-right: 10px;
    ul {
        margin: 0px;
        padding: 0px;
        height: 400px;
        overflow-y: auto;
        li {
            list-style: none;
            height: 25px;
            line-height: 25px;
            // border-bottom: 1px solid #f2f2f2;
            font-size: 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .el-image {
                margin-right: 5px;
                width: 21px;
                height: 21px;
                img {
                    display: block;
                }
            }
        }
        li:hover {
            color: #409eff;
        }
    }
}
.share-bottom-right {
    width: 100%;
    margin-left: 10px;
    height: 400px;
    .el-form {
        .el-form-item {
            border-bottom: 1px dashed #e7eef4;
            margin-bottom: 0px;
        }
    }
}
.share-msg {
    margin-top: 10px;
   /deep/ .el-tabs__header {
        .el-tabs__nav-wrap::after {
            width: auto;
        }
    }
    
}
</style>