<template>
  <el-drawer
    custom-class="yh-dialog"
    :title="title"
    :visible.sync="showState"
    direction="rtl"
    :size="width"
    @closed="closed">
    <div class="dialog-content">
      <slot></slot>
    </div>
  </el-drawer>
</template>

<script>

export default {
  name: "FormDialog",
  model: {
    prop: "showState",
    // event: "update"
  },
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "30%"
    },
    visible: {
      type: Boolean
    }
  },
  data () {
    return {
      showState: false
    }
  },
  computed: {},
  watch: {
    visible(n, o){
      if(this.showState != n){
        this.showState = n
      }
    }
  },
  methods: {
    closed(){
      this.$emit("update:visible", false)
    }
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang="less">
.yh-dialog{
  .el-drawer__header > span{
    outline: none;
  }
}
</style>
<style lang='less' scoped>
.dialog-content{
  padding: 0 10px;
}
</style>