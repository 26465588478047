/**
 * 授权状态管理
 */
import { dollectionAPI } from 'liankong-ui-api'

const state = {
    dollectionList: [],

    dollectionAll: [],

    query: {
        pageNum: 1,
        pageSize: 20
    },

    paging: {
        total: 0,
        pages: 0
    },
}
const mutations = {
    // 更新收藏列表
    onDollectionListChange({}, { reset, data: { current, pages, data, total } }) {
        state.query.pageNum = current
        state.paging.total = total
        state.paging.pages = pages

        state.dollectionList = reset ? data : state.dollectionList.concat(data)
    },
    //更新和收藏相关列表
    updataAboutDollcetion({}, data) {
        data.forEach(function(item) {
            state.dollectionAll.forEach(function(id, k, arr) {
                if (id == item.id) {
                    arr[k].isDollection = true
                }
            })
        })
    },



    // 保存收藏id
    setAboutDollcetionIds({}, data) {
        state.dollectionAll = data
    }
}

const actions = {
    /**
     * 获取收藏列表
     */
    async dollectionList({ commit, state }, { reset = false, query ,isPc}) {
        // 兼容pc
        if (query) {
            state.query = Object.assign({}, state.query, query)
        }
        if(reset && !isPc){
            state.query.pageNum = 1;
        }

        const data = await dollectionAPI.dollectionList(state.query)

        commit('onDollectionListChange', { reset, data })
    },

    /**
     *获取收藏文档ID集合
     */
    async dollectionAll({ commit, rootState }) {
        const { code, data } = await dollectionAPI.dollectionAll()
        if (code == 0) {
            commit('setAboutDollcetionIds', data)
            commit('updataAboutDollcetion', rootState.index.viewer.my.showFiles)
        }
    },

    /**
     *添加收藏
     */
    async addDollection({ state }, documentId) {
        const data = await dollectionAPI.addDollection(documentId)
        return data
    },

    /**
     *移除收藏
     */
    async removeDollection({ dispatch, state }, documentId) {
        const { code, data, message } = await dollectionAPI.removeDollection(documentId)

        if (code == 0) {
            dispatch('dollectionList', {})
        }

        return code == 0
    },
    /**
     * 下一页
     */
    async doLoadNextPage({ dispatch, state }, { reset = false }) {
        if (state.query.pageNum < state.paging.pages) {
            state.query.pageNum = state.query.pageNum++
                dispatch("dollectionList", { reset })
        }
    },
    /**
     * 上一页
     */
    async doLoadPrevPage({ commit, dispatch }, { reset = false }) {
        if (state.query.pageNum > 0) {
            state.query.pageNum = state.query.pageNum--
                dispatch("dollectionList", { reset })
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}