
import { statisticsAPI } from 'liankong-ui-api'
import { userAPI } from 'liankong-ui-api'
const state = {
  counts: {
    doc: 0,
    share: 0,
    request: 0,
    shareToMe: 0,
    auth:0
  }
}

const mutations = {
  //更新统计数值
  updateCounts(state, {name, value}){
    state.counts[name] = value
  }
}

const actions = {
  /**
   * 初始化加载用户统计数据
   * @param {*} param0 
   */
  async initLoad({commit}){
    //加载分享信息
    statisticsAPI.userShareCount().then(({code, data, message}) => {
      if(code == 0){
        commit('updateCounts', {name: 'share', value: data.shareCount})
        commit('updateCounts', {name: 'request', value: data.myRequestCount})
        commit('updateCounts', {name: 'shareToMe', value: data.shareMeCount})
        commit('updateCounts', {name: 'auth', value: data.myAuthCount})
      }
    })

    //加载文档信息
    statisticsAPI.userDocumentCount().then(({code, data, message}) => {
      if(code == 0){
        commit('updateCounts', {name: 'doc', value: data.userUploadCount})
      }
    })
  },

  async getInfo({commit}){
    //加载分享信息
    userAPI.getInfo().then(({code, data, message}) => {
      if(code == 0){
        // console.log(data)
      }
    })

  },

  
   /**
     * 加载用户列表
     * @param {*} param0 
     */
    async loadAllUser({ commit }, query) {
      // const { code, data, message } = await userList(query)
      return userAPI.list(query)
  },

  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}