/**
 * 深拷贝
 */
export function deepCopy(obj,cache = []){
  if (obj===null || typeof obj !== "object"){
      return obj
  }

  const hit = cache.filter(c=>c.original===obj)[0]
  if(hit){
      return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  cache.push[{
      original:obj,
      copy
  }]

  Object.keys(obj).forEach(key=>{
      copy[key]=deepCopy(obj[key],cache)
  })
  return copy
}


/**
 * 获取参数
 * @param {*} name 
 */
 export function getQueryParams(str , name){
    let reg = `(^|&)${name}=([^&]*)(&|$)`
    let r = str.substr(1).match(reg)
    if (r != null) return unescape(r[2]); return null
  }