const shareRouters = [
    // {
    //     icon: 'el-icon-paperclip',
    //     name: '我的共享',
    //     path: '/sharetion/shares',
    //     children: []
    // }, {
    //     icon: 'el-icon-user', // 与我共享页面
    //     name: '他人共享',
    //     path: '/sharetion/othershare',
    //     children: []
    // }, 
    {
        icon: 'el-icon-s-check',
        name: '授权审批',
        path: '/sharetion/confer',
        children: []
    },
    // {
    //     icon: 'el-icon-finished',
    //     name: '授权记录',
    //     path: '/sharetion/reqrecord',
    //     children: []
    // },
    // {
    //     icon: 'el-icon-document',
    //     name: '申请记录',
    //     path: '/sharetion/record',
    //     children: []
    // },
    {
        icon: 'el-icon-finished',
        name: '授权记录',
        path: '/sharetion/authrecord',
        children: []
    },{
        icon: 'el-icon-share',
        name: '共享文档',
        path: '/sharetion/sharedoc',
        children: []
    }
]

export default shareRouters;