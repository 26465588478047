/**
 * 字符串相关函数
 */

export function mask(value){
  if(value){
    const mCount = Number.parseInt(value.length / 2)
    const spIndex = Number.parseInt((value.length - mCount) / 2)

    const p = value.substring(0, spIndex)
    let maskValue = ""
    for(let i =0;i<mCount;i++){
      maskValue+="*"
    }
    return p + maskValue + value.substring(spIndex + mCount)
  }
  return null
}


export function encryption(value){
  if(value){
    const mCount = Number.parseInt(value.length)
    let maskValue = ""
    for(let i =0;i<mCount;i++){
      maskValue+="*"
    }
    return maskValue
  }
  return null
}