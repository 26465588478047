import routes from '@/router/routers.js'

//构建上级菜单
function fullParent(menu, parent){
  if(!menu.meta){
    menu.meta = {}
  }

  menu.meta._parent = parent

  if(menu.children){
    menu.children.forEach(m => fullParent(m, menu))
  }
}

routes.forEach(m => fullParent(m, null))

const mainMenuChecker = m => m.meta && m.meta.menu && m.meta.menu.indexOf('main') > -1
const subMenuChecker = m => m.meta && m.meta.menu && m.meta.menu.indexOf('sub') > -1

const state = {
  //当前主菜单
  activeMain: null,

  //当前子菜单
  activeSub: null,

  //自己菜单列表
  activeChildren: []
}

const getters = {
  //菜单
  mainMenuList: (state) => routes.filter(mainMenuChecker)
}

const mutations = {
  //更新菜单
  updateMenu(state, {main, sub}){

    state.activeMain = main
    state.activeSub = sub

    if(main.children){
      state.activeChildren = main.children.filter(subMenuChecker)
    }else{
      state.activeChildren = []
    }

  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}