import BaseAPI from '../BaseAPI.js'

/**
 * 索引服务API
 * 
 * @author Daniel
 * @date 
 */
export default class DictAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/index"

        //定义默认字典类型
        this.defaultMap = {
            doc: '1397022363232772097',
            miji: '1397022049230397441'
        }
    }

    /**
     * 获取筛选条件
     *
     */
    loadDictByType(type) {
        const defaultKey = this.defaultMap[type] || defaultKey
        return this.ajax.get(`/dict/dict/data/type/${defaultKey}`)
    }

    /**
     * 获取自定义标签分类列表
     * @param {*} query 
     */
    getTagList(query) {
        return this.ajax.post(`/document/tag/list`, query)
    }

    /**
     * 添加自定义标签
     * @param {*} query 
     * @returns 
     */
    addTagData(query) {
        return this.ajax.post(`/document/tag/add`, query)
    }

    /**
     * 删除自定义标签
     * @param {*} id 
     * @returns 
     */
    removeTagData(id) {
        return this.ajax.delete(`/document/tag/${id}`)
    }

    /**
     * 给文档分配(绑定)标签
     * @param {*} query 
     * @returns 
     */
    allocationDocTagData(query) {
        return this.ajax.post(`/document/tag/allocation/tag`, query)
    }

    /**
     * 解绑标签
     * @param {*} query 
     * @returns 
     */
    unnioDocTagData(query) {
        return this.ajax.post(`/document/tag/unbind`, query)
    }

    /**
     * 查看被分配的标签
     * @param {*} documetId 
     * @returns 
     */
    lookDocTag(documetId) {
        return this.ajax.get(`/document/tag/${documetId}`)
    }

    /**
     * 系统标签
     * @param {*} query 
     * @returns 
     */
    getSystemLabelList(query) {
        return this.ajax.post(`/document/tag/system/tag/list`, query)
    }

     /**
     * 获取他人分标签类列表
     * @param {*} query 
     */
      shareGetTagList() {
        return this.ajax.get(`/share/getUserTagList`)
    }

    /**
     * 添加他人分享自定义标签
     * @param {*} query 
     * @returns 
     */
     shareAddTagData(query) {
        return this.ajax.post(`/share/addUserTag`, query)
    }

    /**
     * 删除他人分享自定义标签
     * @param {*} id 
     * @returns 
     */
     shareRemoveTagData(id) {
        return this.ajax.delete(`/share/deleteUserTag/${id}`)
    }

    /**
     * 给他人分享文档分配(绑定)标签
     * @param {*} query 
     * @returns 
     */
    ShareAllocationDocTagData(query) {
        return this.ajax.post(`/share/allocation/UserTag`, query)
    }

    /**
     * 他人分享解绑标签
     * @param {*} query 
     * @returns 
     */
     shareUnnioDocTagData(query) {
        return this.ajax.post(`/share/unbind`, query)
    }

    /**
     * 查看他人分享被分配的标签
     * @param {*} documetId 
     * @returns 
     */
    ShareLookDocTag(documetId) {
        return this.ajax.get(`/share/getUserTagByShareId/${documetId}`)
    }
}