import BaseAPI from './BaseAPI.js'

//相关业务模块
import UserAPI from './modules/UserAPI.js'
import LoginAPI from './modules/LoginAPI.js'
import OrgAPI from './modules/OrgAPI.js'
import ShareAPI from './modules/ShareAPI.js'
import RoleAPI from './modules/RoleAPI.js'
import DocumentAPI from './modules/DocumentAPI.js'
import AuthAPI from './modules/Auth.js'
import FileAPI from './modules/FileAPI.js'
import IndexAPI from './modules/IndexAPI.js'
import RequestAPI from './modules/RequestAPI.js'
import DictAPI from './modules/DictAPI.js'
import CollectionAPI from './modules/CollectionAPI.js'
import ChainAPI from './modules/ChainAPI.js'
import MsgAPI from './modules/MsgApi.js'
import StatisticsAPI from './modules/StatisticsAPI.js'
import QRReadAPI from './modules/QRReadAPI.js'
import LogsAPI from './modules/LogsAPI.js'
import GroupAPI from './modules/GroupAPI.js'
import GatherAPI from './modules/GatherAPI.js'
import TeamWorkAPI from './modules/TeamWorkAPI.js'
// import CollectInfoAPI from './modules/CollectInfoAPI'

import UserManagerAPI from './modules/UserManagerAPI'
import MenusAPI from './modules/MenusAPI'
import RoleManagerAPI from './modules/RoleManagerAPI'
import LogsManagerAPI from './modules/LogsManagerAPI'
import MonitorManagerAPI from './modules/MonitorManagerAPI'

import IntebrageAPI from './modules/IntebrageAPI' // 一体机

import DocumentToolsAPI from './modules/DocmentToolsAPI' // 文档工具集

import ManageAPI from './modules/ManageAPI.js' // 管理端3.0及工具平台端

import AdvertAPI from './modules/Advert.js' //广告模块
import ToolsAPI from './modules/ToolsAPI.js' //工具模块
import ToolsConvertAPI from './modules/ConvertAPI.js' //工具转换模块
import ToolUploadAPI from './modules/ToolUploadAPI' // 工具上传文件模块
import CustomAPI from './modules/CustomAPI' // 1205（爱树）对接文档

//install HttpClient
import httpClient from './HttpClient.js'
BaseAPI.prototype.ajax = httpClient
BaseAPI.prototype.baseURL = httpClient.config.baseURL

//export modules
export const userAPI = new UserAPI()
export const loginAPI = new LoginAPI()
export const shareAPI = new ShareAPI()
export const roleAPI = new RoleAPI()
export const documentAPI = new DocumentAPI()
export const authAPI = new AuthAPI()
export const orgAPI = new OrgAPI()
export const fileAPI = new FileAPI()
export const indexAPI = new IndexAPI()
export const requestAPI = new RequestAPI()
export const dictAPI = new DictAPI()
export const chainAPI = new ChainAPI()
export const msgAPI = new MsgAPI()
export const dollectionAPI = new CollectionAPI()
export const statisticsAPI = new StatisticsAPI()
export const qrReadAPI = new QRReadAPI()
export const logsAPI = new LogsAPI()
export const groupAPI = new GroupAPI()
export const gatherAPI = new GatherAPI()
export const usermanagerAPI = new UserManagerAPI()
export const menusAPI = new MenusAPI()
export const rolemanagerAPI = new RoleManagerAPI()
export const logsmanagerAPI = new LogsManagerAPI()
export const monitormanagerAPI = new MonitorManagerAPI()

// export const collectinfoAPI = new CollectInfoAPI()
export const intebrageAPI = new IntebrageAPI()
export const documenttoolsAPI = new DocumentToolsAPI()
export const teamWorkAPI = new TeamWorkAPI()
export const manageAPI = new ManageAPI()
export const advertAPI = new AdvertAPI()
export const toolsAPI = new ToolsAPI();
export const toolUploadAPI = new ToolUploadAPI();
export const toolsConvertAPI = new ToolsConvertAPI();
export const customAPI = new CustomAPI();