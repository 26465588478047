<template>
  <el-form label-width="100px">
    <el-form-item label="申请理由" prop="description">
      <el-input v-model="note" type="textarea" :rows="10" maxlength="250"
  show-word-limit></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
  export default{
    data(){
      return {
        note: null
      }
    }
  }
</script>