import BaseAPI from '../BaseAPI.js'

/**
 * 授权处理API
 * 
 * @author lzh
 * @date 
 */
export default class AuthAPI extends BaseAPI {
  constructor() {
    super()
    this.moduleName = "/share"
  }
  
  /**
   * 添加分享授权目标
   * @param {*} shareId 
   * @param {*} targetList 
   */
  addShareTarget(shareId, targetList) {
    return this.ajax.post(`${this.moduleName}/${shareId}/add/targets`, targetList)
  }

  /**
   * 移除分享授权目标
   * @param {*} shareId 
   * @param {*} targetList 
   */
  removeShareTarget(shareId, targetList) {
    return this.ajax.post(`${this.moduleName}/${shareId}/remove/targets`, targetList)
  }

  /**
   * 添加分享授权资源
   * @param {*} resources 
   */
  addShareResource(shareId, resources) {
    return this.ajax.post(`${this.moduleName}/${shareId}/add/resource`, resources)
  }

  /**
   * 删除分享授权资源
   * @param {*} shareId 
   * @param {*} resourceId 
   */
  removeShareResource(shareId, resourceId) {
    return this.ajax.delete(`${this.moduleName}/${shareId}/remove/resource/${resourceId}`)
  }

  /**
   * 添加分享授权权限
   * @param shareId 分享id
   * @param {
   * id 权限记录id
   * name 权限名
   * ownerType 	授权所属
   * value 	权限值
   *} 
   */
  addSharePermissiont(shareId, permissions) {
    return this.ajax.post(`${this.moduleName}/${shareId}/add/permission`, permissions)
  }

  /**
   * 删除分享授权权限
   * @param {*} shareId 
   * @param {*} data 
   */
  removeSharePermission(shareId, permissionId) {
    return this.ajax.delete(`${this.moduleName}/${shareId}/remove/permission/${permissionId}`)
  }

  /**
   * 更新分享授权权限
   * @param {*} shareId 
   * @param {*} permission 
   */
  updateSharePermission(shareId, permission){
    return this.ajax.post(`${this.moduleName}/${shareId}/update/permission`, permission)
  }
  
  /**
   * 订阅检查
   * @param {*} shareId 
   */
  subscribe(shareId) {
    return this.ajax.get(`/share/request/subscribeCheck/${shareId}`)
  }

  grantLog(page) {
    return this.ajax.post(`/share/request/apply/list`, page)
  }

  /**
   * 添加分享授权资源
   * @param {*} resources 
   */
  addShareResources(shareId, resources) {
    return this.ajax.post(`${this.moduleName}/${shareId}/add/resource`, resources)
  }


  getPermissionsByReadTicket(prefix, ticket){
    return this.ajax.get(`${prefix}/api/permission?ticket=${ticket}`)
  }
}