import BaseAPI from '../BaseAPI.js'

/**
 * 二维码扫描服务API
 * 
 * @author Daniel
 * @date 
 */
export default class QRReadAPI extends BaseAPI {
  constructor() {
      super()

      this.moduleName = "/share"
  }

  /**
   * 阅读时，无权限的扫码授权
   * @param {*} shareId 
   * @param {*} type 
   * @param {*} code 
   */
  grantReadPolicy(shareId, type, code,uid){
    return this.ajax.post(`${this.moduleName}/request/policy`, {
      shareId,
      auditType: type == 1 ? "audit_forever" : "audit_short",
      targetValue: code,
      userId:uid
    })
  }

  /**
   * 分享申请地址
   */
  /* shareCode(clientId){
    // targetType:"SHARE_CLIENT",
    return http.post(`${this.moduleName}/auth/apply`, {
      codeTypes:"auth_code",
      targetValue: clientId
    })
  } */

  /**
   * client申请地址
   * @param {*} clientId 
   */
  /* clientCode(clientId){
    // targetType:"SHARE_CLIENT",
    return http.post(`${moduleName}/auth/apply`, {
      codeTypes:"share_code_after",
      targetValue: clientId
    })
  } */

  
  /**
   * 快速分享 - A1.生成二维码
   * @param files
   */
  quickCreateQR(files){
    return this.ajax.post(`${this.moduleName}/quickShareBefore`, files)
  }

  /**
   * 快速分享 - A2.扫码获取二维码对应的文件列表
   * @param {*} ticket 
   */
  quickScanQR(ticket){
    return this.ajax.get(`${this.moduleName}/scanTicket/${ticket}`)
  }

  /**
   * 快速分享 - A3.通过二维码的Ticket获取指定文档阅读地址
   * @param {*} ticket 二维码有效票据
   * @param {*} docId 文档ID
   */
  getReadUrlByTicket(ticket, docId){
    return this.ajax.post(`${this.moduleName}/scanTicket/${ticket}/reader/${docId}`)
  }

  /**
   * 快速分享 - B1.扫码推送文件
   * @param {*} clientId 
   * @param {*} files //Develop#A#与后端确认，冗余文档数据
   */
  quickPushFile(clientId, filesIds){
    return this.ajax.post(`${this.moduleName}/quickShareAfter`, {
      // targetValue: clientId,
      codeTicket: clientId,
      docIds: filesIds
    })
  }

  /**
   * 快速分享 - 退出会话，销毁ticket
   * @param {*} ticket 
   */
  closeTicket(ticket){
    return this.ajax.get(`${this.moduleName}/close/${ticket}`)
  }

  /**
   * 快速请求授权 - A1. 获取二维码
   */
  quickRequestCreateQR(files){
    /* return Promise.resolve({
      code: 0,
      data: 'f361598b28964bdc93f2cc34d0b55538',
      message: null
    }) */
    return this.ajax.post(`${this.moduleName}/request/policy/request`, {
      docIds: files,
      type: 'auth_code_request'
    })
  }

  /**
   * 快速请求授权 - A2. 获取文件资源列表
   * @param {*} ticket 
   */
  quickRequestDocListByTicket(ticket){
    return this.ajax.get(`${this.moduleName}/request/policy/${ticket}`)
  }

  /**
   * 快速请求授权 - A3. 快速审批通过
   * @param {*} files 
   * @param {*} ticket 
   */
  quickRequestGrant(files, ticket){
    return this.ajax.post(`${this.moduleName}/request/policy/auth`, {
      docIds: files,
      ticket,
      type: 'auth_code_request'
    })
  }

}