import BaseAPI from '../BaseAPI.js'

/**
 * 1205 爱树页面需要对接
 */
export default class CustomAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = '/admin'
    }

    /**
     * 获取用户信息
     * @param {*} query 
     * @returns 
     */
    getThirdAsUserInfo(query) {
        return this.ajax.post(`${this.moduleName}/third/as/user/info?token=${query.token}`)
    }

}