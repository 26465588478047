/**
 * ajax请求
 */

import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { Store } from '../LocalCache.js'
import router from '@/router/index.js'

export const baseURL = "/prod-api"

const instance = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {}
});

instance.interceptors.request.use(function (request) {
  //简单加个日志输出，测试拦截器

  //添加授权信息
  const accessToken = Store.get("access_token")
  const headerToken = request.headers["Authorization"]
  if(!headerToken && accessToken){
    request.headers["Authorization"] = `Bearer ${accessToken}`
  }
  
  //header 编码处理
  const contentType = request.headers['Content-type'] || "application/json"
  const data = request.data

  if(contentType.indexOf('x-www-form-urlencoded') > -1){
    //修改编码方式
    const newData =  Object.keys(data).map(key => `${key}=${data[key]}`).join("&")
    request.data = newData
  }

  return request;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  //拿到后端接口的响应数据，然后根据统一的规则做些处理，比如后端返回的code=0 表示成功 1表示失败就都提示
  const data = response.data

  if(data.code !== 0){
    //错误处理
    Message({
      type: "error",
      message: data.message
    })
    return Promise.reject(data);
  }

  return data;
}, function (error) {
  // console.log("RPC Resp:", error.response)
  const response = error.response

  if(response){
    const status = response.status

    if(status == 401){
      //重新登录
      Store.del("access_token")
      Store.del("refresh_token")
      window.location.reload()
    }
  }

  return Promise.reject(response ? response.data : error);
});

export function get(url, query, config){
  return instance.get(url, {
    params: query
  }, config)
}

export function post(url, data, config){
  return instance.post(url, data, config)
}

export function del(url, data, config){
  return instance.delete(url, data, config)
}

export function put(url, data, config){
  return instance.put(url, data, config)
}