/**
 * 后台管理路由
 */

 import ManagerLayout from '@/layout/manager/Index.vue'
 import PermsRouter from './sys-manager.js'
 
 // import MenuRouter from './sys-menu.js'
 // import SettingRouter from './sys-setting.js'
 // import RoleRouter from './sys-role.js'
 // import UserRouter from './sys-user.js'
 // import PrintRouter from './sys-print.js'
 // import LogRouter from './sys-logs.js'
 // import orgRouter from './sys-org.js'
 // import fileRouter from './sys-file.js'
 
 // import CollctRouter from './sys-collect.js'
 
 
 const managerRouter = {
     path: '/manager',
     name: 'Main',
     component: ManagerLayout,
     children: [{
             path: '/',
             redirect: '/manager/home'
         },
         {
             path: "/manager/home",
             name: "MainHome",
             component: () =>
                 import ('@/views/manager/evidence/index.vue')
         },
 
         ...PermsRouter,
 
         // MenuRouter,
         // RoleRouter,
         // SettingRouter,
         // UserRouter,
         // ...PrintRouter,
         // LogRouter,
         // ...orgRouter,
         // fileRouter,
 
         // CollctRouter,
 
         //404
         {
             path: '*',
             component: () =>
                 import ('@/views/404.vue')
         }
     ]
 }
 
 export default managerRouter;