import { render, staticRenderFns } from "./ConferEditDialog.vue?vue&type=template&id=81981be0&scoped=true&"
import script from "./ConferEditDialog.vue?vue&type=script&lang=js&"
export * from "./ConferEditDialog.vue?vue&type=script&lang=js&"
import style0 from "./ConferEditDialog.vue?vue&type=style&index=0&id=81981be0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81981be0",
  null
  
)

export default component.exports