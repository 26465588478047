<template>
    <el-dialog custom-class="share-dialog" 
     :visible.sync="dialogVisible"
    :append-to-body='true'
    :close-on-click-modal="false" width="800px"
    :show-close="false">
    <!-- 共享模式 -->
    <span slot="title" class="dialog-title">
        <div class="header">
          <span class="read">高级设置</span>
          <p class="btn">
            <i class="el-icon-close" @click="closeDialog"></i>
          </p>
        </div>
      </span>
    <template >
        <div>
            <div class="share-modular">
                <h1 class="share-modular-title">共享用户或组织</h1>
                <StepUserInfo :shareInfo="shareInfo" :viewerKey='viewerKey' :targets="targetList" ref="stepUserInfo"></StepUserInfo>
            </div>
            <div class="share-modular">
                <h1 class="share-modular-title">共享权限设置</h1>
                 <StepPermissionInfo :accessType='accessType'  ref='StepPermissionInfo' :docInfo="docForSetReadPages" :permissions="permissions" @onPermissionSet="onPermissionSet" ></StepPermissionInfo>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <div>
            <el-button  @click='closeDialog'>取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import StepUserInfo from './StepUserInfo.vue'
import StepPermissionInfo from './StepPermissionInfo.vue'
export default {
    props:{
        viewerKey:{
            type:String,
            default:'my'
        },
        permissions:{
            type:Object,
            default:{}
        }
    },
    components: {
        StepUserInfo,
        StepPermissionInfo
    },
    data(){
        return {
            dialogVisible:false
        }
    },
    computed: {
        //当前共享任务
        shareInfo() {
            return this.$store.state.cart.shareInfo
        },
        accessType(){
            return this.shareInfo.accessType
        },
        //目标列表
        targetList() {
            const targets = this.$store.state.cart.targets
            return Object.keys(targets).map(k => targets[k])
        },
        //权限列表
        // permissions() {
        //     return this.$store.state.cart.permissions
        // },
        //获取文档Id，看是否可设置阅读范围，如果资源不是多个或不是文件夹
        docForSetReadPages() {
            const resources = this.$store.state.cart.views[this.viewerKey].docList || []
            if(resources.length == 1){
            //如果是文件，则可以选择，就算只有一个且是文件夹，也不行
            const doc = resources[0]
            return doc.type === 1 ?  doc: null
            }
            return null       
        },
    },
    methods:{
        show(){
            this.dialogVisible = true;
        },
        onPermissionSet(key,value){
            this.$emit('onPermissionSet',key,value)
        },
        closeDialog(){
            // 如果未保存则重置数据
            this.$store.commit("cart/resetPermissionShare");
            this.$refs['StepPermissionInfo'].changeChildData()
            this.dialogVisible = false;
        },
        onSubmit(){
            // if(this.$store.state.cart.permissions['readPageType']==1){
            //     if(this.$store.state.cart.permissions['readPage']!=''&&this.$store.state.cart.permissions['readPage']){
            //         const nums = this.$store.state.cart.permissions['readPage'].split(',')
            //         let value = []
            //         nums.forEach(i => {
            //             if(Number(i)-1>=0&&value.indexOf(Number(i)-1)==-1){
            //             value.push(Number(i)-1)
            //             }
            //         })
            //         if(value.length>0){
            //             this.$store.state.cart.permissions['readPage'] = value.join(',')
            //         } else {
            //             this.$store.state.cart.permissions['readPage'] = ''
            //         }
                
            //     } else {
            //         this.$message.warning('请输入指定页面!')
            //         return
            //     }
            // }
            this.dialogVisible = false;
        }
        
    }
}
</script>
<style  lang='less' scoped>
  .share-modular{
        margin-bottom: 10px;
        .share-modular-title{
            border-left: 4px solid #0099cc;
            padding-left: 6px;
            text-align: left;
            color: #333;
            font-size: 16px;
            span{
                font-size: 14px;
                color: #999;
                font-weight: normal;
                margin-left: 10px;
            }
        
        }
    }
    .dialog-title{
        .header{
            line-height: 24px;
            font-size: 18px;
            color: #303133;
        }
        .btn{
            margin: 0;
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 0;
            background: 0 0;
            border: none;
            outline: 0;
            cursor: pointer;
            font-size: 16px;
        }
    }
    .dialog-footer{
        text-align: center;
    }
</style>
