<template>
  <el-container direction="vertical" class="layout-wrap">
    <div class="fixed" v-if='!isApp && !$isRunInsert'>
      <Header></Header>
    </div>
    <el-main class="main-box" :style="!$isRunInsert? 'height:calc(100vh - 60px);margin-top: 60px;':'height:100vh'">
        <el-container>
            <div class="layout-left" v-if="noShowMenuFlag" :style="!$isRunInsert? 'height:calc(100vh - 60px)':'height:100vh'">
                <el-menu :default-openeds="defaultOpeneds" :unique-opened="true" :default-active="$route.path" background-color="#fff"  text-color="#8895a2"
                active-text-color="#409EFF">
                    <NewLeft :data="directory" first @defaultTabValue="defaultTabValue" @restSearch="restSearch"></NewLeft>
                </el-menu>
            </div>
            <el-main class="main-body" :style="{'display': $route.path == '/file/orgdoc' || $route.path == '/file/groupdoc' || $route.path == '/persnnel/mygroup' || $route.path == '/persnnel/orgstructuremanage' ? 'flex' : 'block', 'margin': $route.path == '/doclibrary' || $route.path == '/doctool'  ? '0px' : '10px'}">
                <!-- 我的组织 - 文档  -->
                <div class="main-org-left" v-if="$route.path == '/file/orgdoc'" >
                    <h4>
                        <i class="el-icon-office-building"></i>
                        我的组织
                    </h4>
                    <div class="mytree">
                        <el-tree
                            :data="treeData"
                            default-expand-all
                            node-key="id"
                            ref="tree"
                            highlight-current
                            @node-click="handleClickOrgTreeLeft($event,treeData)"
                            :props="defaultProps"
                            :current-node-key="currentNodeKey">
                            <span slot-scope="{ node, data }">
                                <span class="org-icons" v-if="data.organizationId == undefined">企</span>
                                <i class="el-icon-folder-remove" style="margin-right: 3px;" v-else-if="data.organizationId"></i>
                                <span class="org-title" :title="data.name">{{data.name}}</span>
                            </span>
                        </el-tree>
                    </div>
                </div>

                <!-- 我的群组 - 文档 -->
                <div class="main-group-left" v-if="$route.path == '/file/groupdoc'" style="min-width: 260px;">
                    <h4>
                        <i class="el-icon-user"></i>
                        我的群组
                    </h4>
                    <div class="structure-tree infinite-list-wrapper">
                        <ul class="lists list"  
                        
                        v-if="group.length > 0">
                            <li v-for="(item,index) in group" :key="index">
                                <div :class="{'active': item.name == activeName}" @click="handleClickGroupTreeLeft(item, index)">
                                    <p>
                                        <span :style="{'background': item.colors}">{{item.name.substr(0,1)}}</span>
                                        <span class="tree-title" :title="item.name">{{item.name}}</span>
                                    </p>
                                    <span v-if="index == hoverIndex">加入的</span>
                                </div>
                            </li>
                        </ul>
                        <p class="loadingdoc" v-if="(onLoadGroupDocIndex<groupPage.paging.pages)" @click="onLoadGroupDoc">更多加载</p>
                        <p v-if="group.length == 0" style="color:#909399;margin:0">暂无数据</p>
                    </div>
                </div>

                <!-- 我的群组 -->
                <div class="main-group-left" v-if="$route.path == '/persnnel/mygroup'" style="min-width: 260px;" >
                    <h4>
                        <i class="el-icon-user"></i>
                        我的群组
                    </h4>
                    <div class="my-group-btn">
                    <el-button type="text" icon="el-icon-circle-plus-outline" title="创建群组" @click="addGroupBtn(false)">创建群组</el-button>
                    <el-button type="text" icon="el-icon-edit" title="群组重命名" v-if="activeGroupInfo.owner == $store.state.login.id" @click="addGroupBtn(true)">重命名</el-button>
                    <el-button type="text" icon="el-icon-delete" title="删除群组" v-if="activeGroupInfo.owner == $store.state.login.id" @click="removeGroupBtn">删除</el-button>
                    </div>

                    <div class="structure-tree infinite-list-wrapper" style="padding: 10px 15px !important;">
                        <ul class="lists list" v-if="group.length > 0">
                            <li v-for="(groupItem,ind) in group" :key="ind">
                                <div :class="{'active': ind == hoverGroupIndex}" @click="handleClickMyGroupTreeLeft(groupItem, ind)">
                                    <p>
                                        <span :style="{'background': groupItem.colors}">{{groupItem.name.substr(0,1)}}</span>
                                        <span class="tree-title" :title="groupItem.name">{{groupItem.name}}</span>
                                    </p>
                                    <span v-if="ind == hoverGroupIndex">{{groupItem.owner == userId ? '我创建的' : '我加入的'}}</span>
                                </div>
                            </li>
                        </ul>
                        <p class="loadingdoc" v-if="(onLoadGroupDocIndex<groupPage.paging.pages)" @click="onLoadGroupDoc">更多加载</p>
                        <p v-if="group.length == 0" style="color:#909399;margin:0;height:40px;line-height:40px;">暂无数据</p>
                    </div>
                </div>

                <!-- 人员管理 -->
                <div class="main-org-left" v-if="$route.path == '/persnnel/orgstructuremanage'" >
                    <h4>
                        <i class="el-icon-office-building"></i>
                        我的组织
                    </h4>
                    <div class="my-org-btn">
                    <el-button type="text" icon="el-icon-circle-plus-outline" title="创建组织" @click="addTeamBtn(false)">创建组织</el-button>
                    <el-button type="text" icon="el-icon-edit" title="组织重命名" v-if="handleTreeInfo.owner == $store.state.login.id" @click="addTeamBtn(true)">重命名</el-button>
                    <el-button type="text" icon="el-icon-delete" title="删除组织" v-if="handleTreeInfo.owner == $store.state.login.id" @click="removeTeamBtn">删除</el-button>
                    </div>
                    <div class="mytree">
                        <el-tree
                            :data="orgStructureTree"
                            default-expand-all
                            node-key="id"
                            ref="tree"
                            highlight-current
                            @node-click="handleClickOrgTreeLeft($event, orgStructureTree)"
                            :props="defaultProps"
                            :current-node-key="currentStructureNodeKey"
                            @current-change="handleOrgManagerChange">
                            <span slot-scope="{ node, data }">
                                <span class="org-icons" v-if="data.organizationId == undefined && data.name != '未分配部门'">企</span>
                                <i class="el-icon-folder-remove" style="margin-right: 3px;" v-else-if="data.organizationId && data.name != '未分配部门'"></i>
                                <span class="org-title" :title="data.name">{{data.name}}</span>
                            </span>
                        </el-tree>
                    </div>
                </div>


                <div  :style="{'width': $route.path == '/file/orgdoc' || $route.path == '/file/groupdoc' || $route.path == '/persnnel/mygroup' || $route.path == '/persnnel/orgstructuremanage' ? 'calc(100% - 16%)' : '100%'}">
                    <div class="main-condition">
                        <div class="doc-search-head" v-if="$route.path == '/file/mydoc' || $route.name == 'my_document_child' ">
                            <div style="display:flex;align-items:center;">
                                <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleMyDocClickEvent('upload')">上传</el-button>
                                <el-button type="primary" size="small" icon="el-icon-plus" @click="handleMyDocClickEvent('new')">新建</el-button>
                                <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="createFile('new')">新建文件</el-button> -->
                                <el-button type="primary" size="small" icon="el-icon-delete" @click="handleMyDocRemoveClickEvent">删除</el-button>
                                <el-button type="primary" v-if='!$isRunInsert' size="small" icon="el-icon-share" @click="handleMyShareEvent">共享</el-button>
                            </div>
                            <div class="search-wrap">
                                <el-form :inline="true" :model="formOnline" @submit.native.prevent class="demo-form-inline-mydoc">
                                    <el-form-item label="文件类型">
                                        <el-select v-model="formOnline.type" placeholder="请选择" size="small" @change="changeRadioEvent">
                                            <el-option label="全部" value="全部"></el-option>
                                            <el-option label="文档类型" value="文件类型"></el-option>
                                            <el-option label="图片类型" value="图片类型"></el-option>
                                            <el-option label="视频类型" value="视频类型"></el-option>
                                            <el-option label="音频类型" value="音频类型"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- label="文件名称" -->
                                    <el-form-item >
                                        <el-input size='small' class="inputKeyword" v-model="formOnline.title" @clear="changeInputEvent" clearable placeholder="请输入搜索关键字" @keyup.enter.native="changeInputEvent">
                                            <el-select      
                                                v-model="formOnline.target"
                                                filterable slot="prepend" placeholder="高级筛选">
                                                <el-option
                                                    v-for="item in optionSearch"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-input>
                                    </el-form-item>
                                    <!-- <el-form-item>
                                        <el-input v-model="formOnline.title" clearable placeholder="请输入搜索关键字" @clear="changeInputEvent" size="small" @keyup.enter.native="changeInputEvent"></el-input>
                                    </el-form-item> -->
                                    <el-form-item>
                                        <el-button type="primary" size="small" icon="el-icon-search" @click="changeInputEvent">搜索</el-button>
                                    </el-form-item>
                                    <el-form-item>
                                        <i :style="{'color': tabPattern == 1 ? '#409eFF' : ''}" class="el-icon-set-up icons" @click="changeStyle(1)"></i>
                                        <i :style="{'color': tabPattern == 2 ? '#409eFF' : ''}" class="el-icon-menu icons" @click="changeStyle(2)"></i>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="org-doc-searchtion" v-if="$route.path == '/file/orgdoc' || $route.path == '/file/groupdoc'">
                            <div  v-if="($route.path == '/file/orgdoc'&&treeData.length>0) || ($route.path == '/file/groupdoc'&&group.length > 0)" style="display:flex;justify-content: space-between">
                                <span></span>
                                <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleMyDocClickEvent('upload')" v-if="$route.path != '/file/groupdoc'">上传</el-button>
                                <el-button type="primary" size="small" icon="el-icon-plus" @click="handleMyDocClickEvent('new')">新建</el-button>
                                <el-button type="primary" size="small"  @click="handleMyDocClickEvent('myDocument')">从我的文档中上传</el-button>
                            </div>
                            <div v-else></div>
                            <div>
                                <el-form :inline="true" @submit.native.prevent class="demo-form-inline-mydoc">
                                    <el-form-item label="文件名称">
                                        <el-input v-model="orgSearchValue" clearable placeholder="请输入搜索关键字" size="small" @keyup.enter.native="changeSearchOrgDocList"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" size="small" icon="el-icon-search" @click="changeSearchOrgDocList">搜索</el-button>
                                    </el-form-item>
                                    <el-form-item>
                                        <i :style="{'color': tabPattern == 1 ? '#409eFF' : ''}" class="el-icon-set-up icons" @click="changeStyle(1)"></i>
                                        <i :style="{'color': tabPattern == 2 ? '#409eFF' : ''}" class="el-icon-menu icons" @click="changeStyle(2)"></i>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="persnnel-orgstru-search" v-if="$route.path == '/persnnel/orgstructuremanage'">
                            <div style="display:flex;align-items: center;">
                                <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="addDepEvent(false)" v-if="orgStructureTree.length>0&&handleTreeInfo.organizationId == undefined && handleTreeInfo.name != '未分配部门'&&handleTreeInfo.owner == $store.state.login.id">添加部门</el-button>
                                <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="addDepEvent(false)" v-if="handleTreeInfo.organizationId && handleTreeInfo.name != '未分配部门' && handleTreeInfo.owner == $store.state.login.id">添加子部门</el-button>
                                <el-button size="mini" type="primary" icon="el-icon-edit" @click="addDepEvent(true)" v-if="handleTreeInfo.organizationId && handleTreeInfo.name != '未分配部门' && handleTreeInfo.owner == $store.state.login.id">修改部门</el-button>
                                <el-button size="mini" type="primary" icon="el-icon-delete" @click="removeDepBtn" v-if="handleTreeInfo.organizationId && handleTreeInfo.name != '未分配部门' && handleTreeInfo.owner == $store.state.login.id">删除部门</el-button>
                                <el-button type="primary" icon="el-icon-coordinate" size="mini" @click="applyAddOrgBtn" v-if="handleTreeInfo.name != '未分配部门'&&orgStructureTree.length>0">申请加入</el-button>
                                <!-- <el-upload
                                    v-if="handleTreeInfo.name != '未分配部门'"
                                    class="upload-demo"
                                    style="margin: 0 10px 3px 10px;"
                                    action="none"
                                    accept=".xls,.xlsx"
                                    :auto-upload="false"
                                    :on-change="onUploadChanged"
                                    :show-file-list="false">
                                    <el-button type="primary" size="mini" v-if="orgStructureTree.length>0" icon="el-icon-upload">导入通讯录</el-button>
                                </el-upload> -->
                                <!-- <el-button type="primary" size="mini" icon="el-icon-download" @click="exportOrgBtn" v-if="handleTreeInfo.name != '未分配部门'">下载模板</el-button> -->
                                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleNewMemberEvents" v-if="orgStructureTree.length>0&&handleTreeInfo.name != '未分配部门'">添加成员</el-button>
                                <el-button size="mini" type="primary" icon="el-icon-connection" v-if="orgStructureTree.length>0&&handleTreeInfo.organizationId == undefined && handleTreeInfo.name != '未分配部门'" @click="handleQrcodeEvent">邀请成员</el-button>
                                <el-button size="mini" type="primary" icon="el-icon-circle-close" v-if="handleTreeInfo.owner == $store.state.login.id && handleTreeInfo.name != '未分配部门'&&orgStructureTree.length>0&&handleTreeInfo.organizationId == undefined" @click="disableOrgBtn">解散组织</el-button>
                                <el-button size="mini" type="primary" icon="el-icon-switch-button" v-if="orgStructureTree.length>0&&handleTreeInfo.organizationId == undefined && handleTreeInfo.name != '未分配部门'" @click="handleWithdrawFrom">退出组织</el-button>
                            </div>
                            <div>
                                <el-form :inline="true" @submit.native.prevent :model="formInline" class="demo-form-inline">
                                    <el-form-item label="成员名称">
                                        <el-input v-model="formInline.memberName" @keyup.enter.native="handleAuditSearchBtn" clearable placeholder="请输入成员名称" size="small"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" size="small" icon="el-icon-search" @click="handleAuditSearchBtn">搜索</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="main-bread-heads" :style="{'margin-top': $route.path == '/file/favorite' || $route.path == '/file/othershare' || $route.path == '/persnnel/auditmember' || $route.path == '/persnnel/invitamember' || $route.path == '/persnnel/mygroup' ? '15px' : '0px'}" v-if="$route.path == '/sharetion/sharedoc'  || $route.path == '/sharetion/confer' || $route.path == '/sharetion/authrecord' || $route.path == '/persnnel/orgstructuremanage' || $route.path.indexOf('teamWork') !=-1 || $route.path == '/onlineEditing'|| $route.path == '/user/organizational' || $route.path == '/user/storage'  ? false : true">
                        <Bread v-if="noShowMenuFlag" v-show="$route.path == '/persnnel/auditmember' || $route.path == '/persnnel/invitamember' || $route.path == '/persnnel/mygroup'|| $route.path == '/onlineEditing' ? false : true"></Bread>
                        <div v-if="$route.path == '/file/favorite' || $route.path == '/file/othershare'">
                            <el-form :inline="true" @submit.native.prevent :model="favoriteFormInline" class="demo-form-inline">
                                <el-form-item label="文件名称">
                                    <el-input @keyup.enter.native="favoriteSearch"  v-model="favoriteFormInline.fileName" clearable placeholder="请输入搜索关键字" size="small" @clear="clearSearch"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="small" icon="el-icon-search" @click="favoriteSearch">搜索</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <i :style="{'color': favoriteTabNum == 1 ? '#409eFF' : ''}" class="el-icon-set-up icons" @click="favoriteTabEvent(1)"></i>
                                    <i :style="{'color': favoriteTabNum == 2 ? '#409eFF' : ''}" class="el-icon-menu icons" @click="favoriteTabEvent(2)"></i>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="persnnel-audit-search" style="position: absolute; right: 20px;" v-if="$route.path == '/persnnel/auditmember'">
                            <el-form @submit.native.prevent :inline="true" ref="ruleForm" :model="formInline" class="demo-form-inline">
                                <el-form-item label="申请人">
                                    <el-input @keyup.enter.native="handleAuditSearchBtn" v-model="formInline.username" placeholder="请输入申请人的名称" size="small" clearable></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="small" icon="el-icon-search" @click="handleAuditSearchBtn">搜索</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="persnnel-audit-search" style="position: absolute; right: 20px;" v-if="$route.path == '/persnnel/invitamember'">
                            <el-form @submit.native.prevent :inline="true" ref="ruleForm" :model="formInline" class="demo-form-inline" style="margin-top: 3px;">
                                <el-form-item label="邀请人">
                                    <el-input @keyup.enter.native="handleAuditSearchBtn" v-model="formInline.names" placeholder="请输入邀请人的名称" size="small" clearable></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="small" icon="el-icon-search" @click="handleAuditSearchBtn">搜索</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;" v-if="$route.path == '/persnnel/mygroup'">
                            <div>
                                <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" v-if="group.length>0" @click="addMemberBtn">添加成员</el-button>
                                <el-button type="primary" size="small" icon="el-icon-circle-close" v-if="activeGroupInfo.owner == $store.state.login.id&&group.length>0" @click="disbandTheGroupBtn">解散本群</el-button>
                            </div>
                            <el-form @submit.native.prevent :inline="true" ref="ruleForm" :model="formInline" class="demo-form-inline" style="margin-top: 3px;">
                                <el-form-item label="成员名称">
                                    <el-input v-model="formInline.menber" placeholder="请输入成员的名称" size="small" @keyup.enter.native="handleAuditSearchBtn" clearable></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="small" icon="el-icon-search" @click="handleAuditSearchBtn">搜索</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="layout-put" :style="{'padding': $route.path == '/doclibrary' || $route.path == '/doctool'|| $route.path == '/onlineEditing' ? '0px' : '15px 20px' }">
                        <router-view></router-view>
                    </div>
                </div>
        
            </el-main>
      </el-container>

      <!-- 购物车 -->
      <FileCart ref="fileCart"></FileCart>
      
    </el-main>
    <!-- <Footer></Footer> -->
  </el-container>
</template>

<script>
import Header from "./Header.vue";
import Footer from "../Footer.vue";
import NewLeft from './NewLeft.vue'
import Bread from './Bread.vue'
// import UploadFile from "@/components/UploadFile.vue";

import FileCart from '@/packages/FileCart/src/ShareForm.vue'

import fileRouter from '../../router/fileRouter.js' // 文件管理的左侧菜单数据 先模拟的
import orgPersonData from '../../router/personRouter' // 人员管理的左侧菜单数据
import shareRouters from '../../router/shareRouter.js' // 共享授权左侧菜单数据
import teamWorkRouter from '../../router/teamWorkRouter.js' // 协同左侧菜单数据

import { documentAPI, dictAPI } from 'liankong-ui-api'
const directory = fileRouter.directory

export default {
    components: {
        Header,
        Footer,
        NewLeft,
        Bread,

        FileCart
        // UploadFile,
    },
    computed: {
        groupPage(){
            return this.$store.state.newGroup.views['allGroups']
        },
        group(){
            let newGroupArray = this.$store.state.newGroup.views['allGroups'].list || []
            // 设置背景颜色
            newGroupArray.forEach((datas, ind) => {
                this.$set(datas, 'colors', this.colorArray[ind])
            })
            this.onLoadGroupDocList = [...newGroupArray] 
            var obj = {};
            return this.onLoadGroupDocList.reduce(function (item, next) {
                    obj[next.name] ? '' : obj[next.name] = true && item.push(next);
            return item;
            }, []);
        },
        isApp(){
            return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
        },
        userId(){
            return this.$store.state.login.id
        },
        
        //购物车视图key
        viewerKey() {
            return this.$store.state.cartForm.viewerKey
        },
        //文件列表
        files() {
            const viewer = this.$store.state.cart.views[this.viewerKey]
            return viewer ? viewer.docList : []
        },
    },
    watch: {
        $route(to) {
            this.$ebus.$emit('hideMaximize')
            this.orgSearchValue = ''
            this.favoriteFormInline.fileName =  ''
            this.handleLeftAsideEvent(to);
            if(to.path == '/persnnel/mygroup') {
                this.refreshListMyGroup();
            } else if(to.path == '/persnnel/orgstructuremanage') {
                this.refreshLeftMyOrg();
            } else if(to.path == '/file/mydoc') {
                this.$store.commit('isActiveNameLeftMenu', '我的文档')
                 this.$ebus.$emit('enterMyDocData', '我的文档')
            } else if(to.path=='/file/groupdoc'){
                this.onLoadGroupDocIndex=0
                this.onLoadGroupDoc(true);
            } else if(to.path == '/file/orgdoc') {
                this.getOrgTreeList();
            }
        },
        group(newvalue, oldvalue) {
            if(newvalue.length > 0) {
                // 默认选中第一个
                this.activeName = newvalue[0].name;
                this.activeInfo = newvalue[0];
                this.hoverIndex = 0;
                this.hoverGroupIndex = 0;
                this.activeGroupInfo = newvalue[0];
            }else{
                this.activeInfo = null;
                
            }
        }
    },
    data: function () {
        return {
            onLoadGroupDocList:[],
            vkey: "find",
            defaultOpeneds: [],
            directory: [],
            noShowMenuFlag: false,
            // 我的文档
            formOnline: {
                title: '',
                type: '全部',
                target:''
            },
            tabPattern: 1,
            formInline: {
                username: '',
                names: '',
                menber: '',
                memberName: '',
                start: '',
                end: ''
            },
            orgSearchValue: '',
            favoriteFormInline: {
                fileName: ''
            },
            favoriteTabNum: 1,
            treeData: [],
            currentNodeKey: '',
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            activeName: '', // 群组文档 选中群组名称
            activeInfo: {}, // 群组文档 选中群组数据
            hoverIndex: 0, // 群组文档 选中群组显示标识
            colorArray: ['#C7CBE1', '#D8C5B9', '#E4A88A', '#7FCB97', '#DBA9E0', '#C28DAC', '#F58686', '#EBEA95', '#69A07F', '#E43434'], // 颜色
            activeGroupInfo: {}, // 人员管理 我的群组tree选中获取的信息
            hoverGroupIndex: 0, // 人员管理 我的群组tree选中样式
            orgStructureTree: [],
            currentStructureNodeKey: '', // 人员管理 组织架构页面tree选中标识
            currentStructureNodeData: null, // 人员管理 组织架构页面tree选中信息
            handleTreeInfo: {},
            onLoadGroupDocIndex:0,
            optionSearch:[{value:'',label:'所有'},{value:'1',label:'所有者'},{value:'2',label:'标签'},{value:'3',label:'文档标题'},{value:'4',label:'文档内容'}],
        };
    },
    provide: function () {
        return {
            changevkey: this.changevkey,
            setQuery: this.setQuery,
            editRefreshLeftMenu: this.editRefreshLeftMenu, // 刷新菜单
            refreshListMyGroup: this.refreshListMyGroup, // 刷新左侧群组
            refreshLeftMyOrg: this.refreshLeftMyOrg, // 刷新左侧组织
            withdrawFromOrg: this.withdrawFromOrg, // 退出组织成功的操作
        };
    },
    mounted(){
        this.handleLeftAsideEvent(this.$route);
        this.getOrgTreeList();
        this.onLoadGroupDoc(true);
        this.getOrgStructureTreeList();

        if(this.$route.path == '/file/mydoc') {
            this.$store.commit('isActiveNameLeftMenu', '我的文档')
        }
    },
    methods: {
        // 清除文档搜索条件
        restSearch(){
            this.formOnline={
                title: '',
                type: '全部'
            }
        },
        //菜单选择
        onMenuSeleted(index){
            this.$router.push({
                name: index
            })
        
        },
        changevkey(vkey) {
            this.vkey = vkey;
        },
        //目前先设置成个人文档，后期可以传个参数过来判断替换'my'
        setQuery(query, parameter, visibleFlag) {
            let queryInfo= this.$store.state.index.viewer['my'].query;
            
            // query.miji.filter((item,index,arr)=>{
            //     queryInfo.miji= query.miji.length==0?null:arr;
            // })
            // query.systemTag.filter((item,index,arr)=>{
            //     queryInfo.systemTag= query.systemTag.length==0?null:arr;
            // })
            queryInfo.systemTag = query.systemTag.length==0?null:query.systemTag;
            queryInfo.miji = query.miji.length==0?null:query.miji;
            queryInfo.parentId = query.parentId
            queryInfo.target = query.target
            // 如果传入就走这个 控制分页
            if(parameter) {
                if(visibleFlag) {
                    queryInfo.systemTag = null;
                    query.tag.filter((item,index,arr)=>{
                        queryInfo.tag = query.tag.length == 0 ? null : arr;
                    })
                } else {
                    queryInfo.tag = null;
                }
                if(query.miji.length>0 || query.systemTag.length>0 || (visibleFlag&&query.tag.length > 0) || query.keyword !='' ){
                    queryInfo.keyword = query.keyword
                    this.$store.dispatch('index/loadByQuery',{
                        vkey: 'my',
                        reset: true,
                        query:queryInfo,
                        parameter: parameter
                    }).then(res=>{
                        this.$store.state.doc.viewer['my'].showFiles = this.$store.state.index.viewer['my'].showFiles ;
                        this.$store.state.doc.viewer['my'].paging = this.$store.state.index.viewer['my'].paging;
                    })
                }else{
                    return this.$store.dispatch("doc/loadChildren", {
                        vkey: 'my',
                        reset: true,
                        pageNum: parameter.pageNum,
                        pageSize: parameter.pageSize
                    })
                }
            } else if(parameter == undefined && visibleFlag == undefined){
                if(query.miji.length>0 || query.systemTag.length>0 || query.keyword || query.keyword !=''){
                    this.$store.dispatch('index/loadByQuery',{
                        vkey: 'my',
                        reset: true,
                        query:queryInfo
                    }).then(res=>{
                        this.$store.state.doc.viewer['my'].showFiles = this.$store.state.index.viewer['my'].showFiles ;
                        this.$store.state.doc.viewer['my'].paging = this.$store.state.index.viewer['my'].paging;
                    })
                }else{
                    this.loadAndRefresh();
                }
            }
        },
        //加载或刷新当前文件夹
        loadAndRefresh() {
            return this.$store.dispatch("doc/loadChildren", {
                vkey: 'my',
                reset: true,
                pageSize:10
            })
        },
        // 获取组织文档的组织
        async getOrgTreeList() {
            let res = await this.$store.dispatch('newOrg/getMyOrgTreeLists')
            if(res.code == 0) {
                this.treeData = res.data;
                // 默认选中第一个
                if(res.data.length != 0){
                    this.currentNodeKey = this.treeData[0].id;
                    this.$nextTick(()=>{
                        if(this.$refs.tree) {
                            this.$refs.tree.setCurrentKey(this.currentNodeKey);
                        }
                    })
                    this.handleClickOrgTreeLeft(this.treeData[0], this.treeData);
                }
                
            } else {
                this.$message.error('获取组织数据失败')
            }
        },
        // 获取组织列表
        async getOrgStructureTreeList() {
            let res = await this.$store.dispatch('newOrg/getUserOrgList')
            if(res.code == 0) {
                this.orgStructureTree = res.data;
                // this.getOrgTreeList();//更新文档树
                // 默认选中第一个
                if(res.data.length != 0){
                    this.currentStructureNodeKey = this.currentStructureNodeKey ? this.currentStructureNodeKey : this.orgStructureTree[0].id;
                    this.$nextTick(()=>{
                        if(this.$refs.tree) {
                            this.$refs.tree.setCurrentKey(this.currentStructureNodeKey);
                        } 
                    })
                    this.handleClickOrgTreeLeft(this.currentStructureNodeData ? this.currentStructureNodeData : this.orgStructureTree[0], this.orgStructureTree);
                }
               
            } else {
                this.$message.error('获取组织数据失败')
            }
        },
        // 获取群组数据
        onLoadGroupDoc(state){
            
            if(this.onLoadGroupDocIndex>this.groupPage.paging.page){
                return
            } else {
                this.onLoadGroupDocIndex++
            }
            this.$store.dispatch('newGroup/queryChildren', {
                vkey: 'allGroups',
                reset: true,
                query:{
                    groupType: 1,
                    pageNum: this.onLoadGroupDocIndex,
                    pageSize: 10
                } 
            }).then(res=>{
                this.handleClickGroupTreeLeft(this.activeInfo, this.hoverIndex);
                this.handleClickMyGroupTreeLeft(this.activeGroupInfo, this.hoverGroupIndex);
            })
        },
        handleOrgManagerChange(node, data) {
            this.currentStructureNodeKey = node.id;
            this.currentStructureNodeData = node;
            this.$nextTick(()=>{
                if(this.$refs.tree) {
                    this.$refs.tree.setCurrentKey(this.currentStructureNodeKey);
                } 
            })
        },
        refreshListMyGroup() {
            this.onLoadGroupDocIndex=0
            this.onLoadGroupDoc()
        },
        refreshLeftMyOrg() {
            this.getOrgStructureTreeList()
        },
        withdrawFromOrg() {
            this.getOrgStructureTreeList();
            this.currentStructureNodeKey = '';
            this.currentStructureNodeData = null;
        },
        // 不显示左侧菜单
        async handleLeftAsideEvent(to) {
            if(to.matched[0].path == '/file') { // 文件管理
                this.noShowMenuFlag = true;
                
                // 获取我的文档接口数据
                let res = await documentAPI.queryChildren({
                    id: 0,
                    pageNum: 1,
                    pageSize: 10
                });
                if(res.code == 0) {
                    // 过滤出来是文件夹的数据
                    let newDatas = []
                    let docRes = res.data;
                    docRes.forEach(viewerItem => {
                        if(viewerItem.type == 0) {
                            // this.$set(viewerItem, 'icon', 'el-icon-folder-opened');
                            // this.$set(viewerItem, 'children', []);
                            // newDatas.push(viewerItem);
                            newDatas.push({
                                name: viewerItem.name,
                                icon: 'el-icon-folder-opened',
                                id: viewerItem.id,
                                path: `/file/mydoc1/${viewerItem.id}`,
                                type: viewerItem.type,
                                children: []
                            });
                        }
                    });
                    // 放到我的文档菜单下
                    directory.forEach(fileItem => {
                        if(fileItem.name == '我的文档') {
                            fileItem.children = newDatas
                        }
                    });
                }
                this.directory = directory;
            } else if(to.path == '/' || to.path == '/docollabor' || to.path == '/persnnel/mygroup' || to.path == '/doclibrary' || to.path == '/doctool' || to.path == '/user/mydetails'|| to.path == '/user/preference' || to.path == '/user/resetpassword' || to.path == '/user/organizational' || to.path == '/search/result'  || to.path == '/user/storage') {
                this.noShowMenuFlag = false;

                this.$store.commit('saveBreadList', [{
                    path: '/file/mydoc',
                    title: '我的文档'
                }])
                this.$store.commit('isActiveNameLeftMenu', '')
                this.$store.commit('pageBackTextShow', '')
            } else if(to.matched[0].path == '/persnnel') { // 人员管理
                this.noShowMenuFlag = true;
                this.directory = orgPersonData;

                this.$store.commit('saveBreadList', [{
                    path: '/file/mydoc',
                    title: '我的文档'
                }])
                this.$store.commit('isActiveNameLeftMenu', '')
                this.$store.commit('pageBackTextShow', '')
            } else if(to.matched[0].path == '/sharetion') { // 共享授权
                this.noShowMenuFlag = true;
                this.directory = shareRouters;

                this.$store.commit('saveBreadList', [{
                    path: '/file/mydoc',
                    title: '我的文档'
                }])
                this.$store.commit('isActiveNameLeftMenu', '')
                this.$store.commit('pageBackTextShow', '')
            }
            else if(to.matched[0].path == '/teamWork') { // 协同
                this.noShowMenuFlag = true;
                this.directory = teamWorkRouter;

                this.$store.commit('saveBreadList', [{
                    path: '/file/mydoc',
                    title: '我的文档'
                }])
                this.$store.commit('isActiveNameLeftMenu', '')
                this.$store.commit('pageBackTextShow', '')
            }
        },
        // 刷新菜单
        editRefreshLeftMenu() {
            this.handleLeftAsideEvent(this.$route)
        },
        // 我的文档、组织文档、群组文档
        handleMyDocClickEvent(type){
            if(type == 'upload') {
                this.$ebus.$emit('uploadFileBtn')
            } else if(type == 'new') {
                this.$ebus.$emit('addNewFileBtn')
            }else if(type == 'myDocument') {
                this.$ebus.$emit('myDocumentFileBtn')
            }
        },
        createFile(){
            this.$ebus.$emit('createFile')
        },
        // 共享
        handleMyShareEvent(){
            
            let arr = this.files.filter(m => m.state == 1)
            if(arr.length != 0) {
                this.$message.error('分享列表中存在未上传完成的文件')
                return
            }
            let arr1 = this.files.filter(m => (m.suffix == 'zip' || m.suffix == 'mp3' || m.suffix == 'mp4'))
            if(arr1.length != 0){
                this.$message.error('分享列表中存在 zip、mp3、mp4 格式的文件')
                return
            }
            


            this.$ebus.$emit('handleMyShareEvent')
        },
        // 删除
        handleMyDocRemoveClickEvent(){
            this.$confirm('此操作将永久删除该文件, 是否继续?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.$ebus.$emit('handleMyDocRemoveClickEvent')
            }).catch(()=>{

            })
        },
        // 下拉框搜索
        changeRadioEvent(val) {
            // this.changeInputEvent(val)
            this.changeInputEvent()
            // this.$ebus.$emit('changeRadioEvent', val)
        },
        // 文字搜索
        changeInputEvent(val){
            
            this.$ebus.$emit('changeInputEvent',this.formOnline)
        },
        // 我的文档、组织文档、群组文档tab切换
        changeStyle(val) {
            this.tabPattern = val;
            this.$ebus.$emit('callBackTab', this.tabPattern)
        },
        // 切换的时候tab恢复默认值
        defaultTabValue(){
            this.favoriteTabNum = 1;
            this.tabPattern = 1;
            this.restSearch()//清除搜索条件
            this.$store.commit('pageBackTextShow', '')
            this.$store.commit('clearFolderBreadList')

            // 解决：点击文件夹后直接点击左侧我的文档菜单时页面数据需要刷新
            // if(this.$route.path == "/file/mydoc") {
            //     this.$ebus.$emit('enterMyDocData', '我的文档')
            // }
        },
        // 我的收藏、他人共享 搜索
        favoriteSearch() {
            this.$ebus.$emit('favoriteSearch', this.favoriteFormInline.fileName)
        },
        // 我的收藏、他人共享 清空
        clearSearch() {
            this.$ebus.$emit('clearSearch')
        },
        // 我的收藏、他人共享 切换
        favoriteTabEvent(num) {
            this.favoriteTabNum = num;
            this.$ebus.$emit('favoriteTabEvent', this.favoriteTabNum)
        },
        // 组织文档、组织列表
        handleClickOrgTreeLeft(data, newTreePostData) {
            this.handleTreeInfo = data;
            this.$ebus.$emit('handleClickOrgTreeLeft', {data, newTreePostData})
            // 清空面包屑
            // this.$store.commit('clearFolderBreadList')
            this.$store.commit('pageBackTextShow', '')
            this.formInline.memberName = '';
        },
        // 组织文档、群组文档搜索
        changeSearchOrgDocList() {
            this.$ebus.$emit('changeSearchOrgDocList', this.orgSearchValue)
        },
        // 群组文档
        handleClickGroupTreeLeft(item,index) {
            if(item){
                this.activeName = item.name;
                this.activeInfo = item;
                this.hoverIndex = index;
                this.$ebus.$emit('handleClickGroupTreeLeft', {
                    item,
                    index
                })
            }else{
                this.activeName = null;
                this.activeInfo = null;
                this.hoverIndex = 0;
                this.$ebus.$emit('handleClickGroupTreeLeft', {
                    item:null
                })
            }
            
            // 清空面包屑
            // this.$store.commit('clearFolderBreadList')
            this.$store.commit('pageBackTextShow', '')
        },
        // 审批成员、邀请成员、我的群组、组织列表搜索
        handleAuditSearchBtn(){
            this.$ebus.$emit('handleAuditSearchBtn', this.formInline)
        },
        // 我的群组
        handleClickMyGroupTreeLeft(item,ind) {
            this.activeGroupInfo = item;
            this.hoverGroupIndex = ind;

            this.$ebus.$emit('handleClickMyGroupTreeLeft', {
                item,
                ind
            })
            this.formInline.menber = '';
        },
        // 创建、重命名群组
        addGroupBtn(flag) {
            this.$ebus.$emit('addGroupBtn', flag)
        },
        // 删除群组
        removeGroupBtn() {
            this.$confirm('是否确定要删除群组？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.$ebus.$emit('removeGroupBtn');
            }).catch(()=>{

            })
        },
        // 添加成员
        addMemberBtn(){
            this.$ebus.$emit('addMemberBtn')
        },
        disbandTheGroupBtn(){
            this.$confirm('是否确定要解散本群？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
               this.$ebus.$emit('disbandTheGroupBtn')
            }).catch(()=>{

            })
        },
        // 创建、重命名
        addTeamBtn(flag) {
            this.$ebus.$emit('addTeamBtn', flag)
        },
        // 删除组织
        removeTeamBtn() {
            if(this.handleTreeInfo.organizationId) {
                this.$message.error('当前选中的是部门，不能删除组织')
            } else {
                this.$confirm('是否确定要删除组织？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$ebus.$emit('removeTeamBtn')
                }).catch(()=>{

                })
            }
        },
        // 添加、修改部门
        addDepEvent(flag) {
            this.$ebus.$emit('addDepEvent', flag)
        },
        // 删除部门
        removeDepBtn(){
            this.$confirm('是否确定要删除部门？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.$ebus.$emit('removeDepBtn')
            }).catch(()=>{

            })
        },
        // 申请加入
        applyAddOrgBtn() {
            this.$ebus.$emit('applyAddOrgBtn')
        },
        // 导出
        exportOrgBtn(){
            this.$ebus.$emit('exportOrgBtn')
        },
        // 导入
        onUploadChanged(file, list){
            this.$ebus.$emit('onUploadChanged', {file, list})
        },
        handleNewMemberEvents(){
            this.$ebus.$emit('handleNewMemberEvents')
        },
        handleQrcodeEvent(){
            this.$ebus.$emit('handleQrcodeEvent')
        },
        disableOrgBtn(){
            this.$confirm('是否确定要解散组织？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.$ebus.$emit('disableOrgBtn')
            }).catch(()=>{

            })
        },
        // 退出组织
        handleWithdrawFrom() {
            this.$ebus.$emit('handleWithdrawFrom')
        }
    }
};
</script>

<style lang="less" scoped>
.layout-wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
}
.fixed{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f5f7fa;
    z-index: 999;
}

.main-box{
    // margin-top: 60px;
    // min-height: calc(100vh - 60px);
    display: flex;
    .layout-left{
        position: relative;
        background-color: #fff;
        width: 240px !important;
        overflow: auto;
        border-right: 1px solid #edeef2;
        padding: 10px;
        box-sizing: border-box;
      
        /deep/ .el-menu {
            width: 100%;
            // height: 100%;
            .el-menu-item {
                height: 48px;
                line-height: 48px;
            }
            .is-active {
                background: #f2f8ff !important;
                border-radius: 8px;
            }
            .el-menu-item:hover {
                background: #fff !important;
            }
            .el-submenu .el-submenu__title:not(.addJobButton):hover {
                background: #fff !important;
            }
            .el-submenu {
                .el-menu {
                    .el-submenu__title {
                        height: 48px;
                        line-height: 48px;
                    }
                }
            }
            .my-start-menus {
                // border-bottom: 1px solid #edeef2;
                // margin: 0 0 30px 0;
                position: relative;
            }
            // .my-start-menus::before {
            //     content: '';
            //     width: 220px;
            //     height: 1px;
            //     background: #edeef2;
            //     position: absolute;
            //     bottom: -15px;
            //     left: 0px;
            // }
        }
        .add-team-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 20px 0;
            .el-button {
                width: 200px;
                margin-left: 10px;
            }
            .el-button:nth-child(1) {
                margin-bottom: 20px;
            }
        }
    }
    .layout-left::-webkit-scrollbar {
        display: none;
    }
    .main-body{
        flex: 1;
        background-color: #ffffff;
        .main-org-left{
            width: 300px;
            flex: 0 0 auto;
            box-sizing: border-box;
            border-right: 1px solid #f2f2f2;
            h4 {
                background: #666699;
                margin: 0;
                padding: 0;
                height: 45px;
                display: flex;
                align-items: center;
                padding: 0 0 0 30px;
                color: #fff;
                i {
                    font-size: 24px;
                    margin-right: 5px;
                }
            }
            .my-org-btn {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 10px;
            }
            ::v-deep .mytree {
                min-width: 300px;
                overflow-y: scroll;
                height: calc(100% - 50px);
                border-right: 1px solid #f2f2f2;
                .el-tree > .el-tree-node:after {
                    border-top: none;
                }
                .el-tree-node {
                    position: relative;
                    padding-left: 16px;
                }
                //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
                .el-tree-node__expand-icon.is-leaf{
                    display: none;
                }
                .el-tree-node__children {
                    padding-left: 16px;
                }
                .el-tree-node :last-child:before {
                    height: 38px;
                }
                .el-tree > .el-tree-node:before {
                    border-left: none;
                }
                .el-tree > .el-tree-node:after {
                    border-top: none;
                }
                .el-tree-node:before {
                    content: "";
                    left: -4px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                .el-tree-node:after {
                    content: "";
                    left: -4px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                .el-tree-node:before {
                    border-left: 1px dashed #4386c6;
                    bottom: 0px;
                    height: 100%;
                    top: -26px;
                    width: 1px;
                }
                .el-tree-node:after {
                    border-top: 1px dashed #4386c6;
                    height: 20px;
                    top: 12px;
                    width: 24px;
                }
                .el-tree {
                    padding: 0 12px;
                    .el-tree-node {
                        margin: 15px 0;
                        .org-icons {
                            background: #7B79FF;
                            color: #fff;
                            border-radius: 14px;
                            text-align: center;
                            width: 28px;
                            height: 28px;
                            line-height: 28px;
                            display: inline-block;
                            margin-right: 5px;
                        }
                        .org-title {
                            font-size: 14px;
                            width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .el-icon-folder-remove {
                            font-size: 20px;
                            color: #409EFF;
                        }
                        .expanded.el-tree-node__expand-icon.el-icon-caret-right, .el-tree-node__expand-icon.el-icon-caret-right{
                            font-size: 20px;
                            color: #303133;
                            margin-left: -30px;
                        }
                    }
                }
            }
        }

        .main-group-left {
            min-width: 16%;
            border-right: 1px solid #f2f2f2;
            h4 {
                background: #666699;
                margin: 0;
                padding: 0;
                height: 45px;
                display: flex;
                align-items: center;
                padding: 0 0 0 30px;
                color: #fff;
                i {
                    font-size: 24px;
                    margin-right: 5px;
                }
            }
            .my-group-btn {
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 10px;
            }
            .structure-tree {
                padding: 20px 15px;
                overflow: auto;
                height: calc(100% - 16%);
                .lists {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        margin-top: 10px;
                        cursor: pointer;
                        div {
                            padding: 5px 10px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            p{
                                padding: 0;
                                margin: 0;
                                display: flex;
                                align-items: center;
                                span:nth-child(1) {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    border-radius: 50%;
                                    background: #C7CBE1;
                                    text-align: center;
                                    margin-right: 10px;
                                    color: #fff;
                                }
                                .tree-title {
                                    display: block;
                                    width: 100px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            >span {
                                display: inline-block;
                                border: 1px solid #c2e7b0;
                                height: 20px;
                                line-height: 20px;
                                padding: 0px 3px;
                                border-radius: 4px;
                                font-size: 12px;
                                color: #67C23A !important;
                                background: #f0f9eb;
                            }
                        }
                        .active {
                            background: #F2F8FF;
                            border-radius: 5px;
                            span:nth-child(2) {
                                color: #409EFF;
                            }
                        }
                    }
                    li:nth-child(1) {
                        margin-top: 0px;
                    }
                }
                .loadingdoc {
                    color:rgb(64, 158, 255);
                }
                p {
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
        .layout-put {
            // padding: 15px 20px;
        }
        .main-condition {
            .doc-search-head {
                height: 45px;
                line-height: 45px;
                padding: 0 20px;
                margin: 10px 0 0 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                /deep/ .el-checkbox-group {
                    margin-left: 10px;
                    .el-checkbox-button__inner {
                        border-radius: 4px;
                    }
                }
                .search-wrap {
                    display: flex;
                    align-items: center;
                    .demo-form-inline-mydoc {
                        /deep/ .el-form-item {
                            margin-bottom: 0px;
                            .el-form-item__content {
                                margin-top: 4px;
                                .icons {
                                    font-size: 20px;
                                    margin-top: 10px;
                                }
                                .icons:nth-child(1) {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    
                    
                }
            }
            .org-doc-searchtion {
                height: 45px;
                line-height: 45px;
                padding: 0 20px;
                margin: 10px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                /deep/ .el-form {
                    .el-form-item {
                        margin-bottom: 0px;
                        .el-form-item__content{
                            margin-top: 3px;
                            .icons {
                                font-size: 20px;
                                margin-top: 10px;
                            }
                            .icons:nth-child(1) {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .persnnel-audit-search{
                height: 45px;
                line-height: 45px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .share-record-searchtion {
                height: 45px;
                line-height: 45px;
                padding: 0 20px;
                margin: 10px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .el-radio-group {
                    margin: 5px;
                    .el-radio {
                        margin-right: 15px;
                    }
                }
            }
            .persnnel-orgstru-search {
                height: 45px;
                line-height: 45px;
                padding: 0 20px;
                margin: 10px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div:nth-child(1) {
                    .el-button {
                        padding: 7px;
                    }
                }
                .el-form {
                    .el-input {
                        width: 160px;
                        margin-top: 2px;
                    }
                    /deep/ .el-button {
                        margin-top: 8px;
                    }
                }
            }
            .demo-form-inline {
                .el-form-item {
                    margin-bottom: 0px;
                }
            }
        }
        .main-bread-heads {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border-bottom: 1px solid #f2f2f2;
            height: 45px;
            line-height: 45px;
            padding: 0 20px;
            /deep/ .el-form.demo-form-inline {
                .el-form-item {
                    margin-bottom: 0px;
                    .el-form-item__content {
                        margin-top: 2px;
                        .icons {
                            font-size: 20px;
                            margin-top: 10px;
                        }
                        .icons:nth-child(1) {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.mytree{
    // /deep/.el-tree-node__content{
    //     padding-left: 26px!important;
    // }
}   

/deep/.el-tree{
    .el-tree-node__content{
        // display: block!important;
    }
    .el-tree-node__children{
        // overflow: visible!important;
    }
}
/deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background: #bdd1ed;
}
 /deep/.el-tree-node__content>.el-tree-node__expand-icon {
    padding: 3px 6px;
}
/deep/ .el-input-group__prepend{
    .el-input{
        width: 110px;
        text-align: center;
        input{
            text-align: center;
        }
    }
}
/deep/.el-form-item__content .el-input-group{
    vertical-align: middle;
}

@media only screen and (max-width: 1400px) {
    .doc-search-head{
        .el-input{
            width: 220px;
        }
        /deep/ .inputKeyword{
            input{
                padding-right: 10px;
            }
            .el-select{
                .el-input{
                    width: 100%;
                    display: flex;
                }
                input{
                    padding-left: 0;
                }
                span{
                    position: relative;
                }
            }
        }
        .el-select{
            width: 100px;
        }
    }
}
@media only screen and (max-width: 1438px) {
    .persnnel-orgstru-search{
        display:block!important;
        height: auto!important;
    }
}
@media (max-width: 500px) {
  .main-box{
    margin-top: 0;
    .el-main{
        margin: 0!important;
    }
    .layout-put {
        padding:0px!important;
    }
  }
}

</style>