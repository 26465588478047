<template>
  <div class="menu-box">
    <el-menu
      mode="horizontal" 
      :router="true"
      :default-active="activeMenuIndex">
      <el-menu-item v-for="(menu,i) in mainMenus" :index="menu.path" :key="i">{{menu.meta.label}}</el-menu-item>
      <el-menu-item @click="jump">文档工具</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
  export default{
    computed: {
      //主菜单列表
      mainMenus(){
        let arr = []
        this.$store.getters['menu/mainMenuList'].map(item => {
          if(item.path == '/persnnel'){
            if(this.$isShowOrg){
              arr.push(item)
            }
          }else{
            arr.push(item)
          }
        })
        return arr
      },

      //激活主菜单路径
      activeMenuIndex(){
        return this.$store.state.menu.activeMain.path
      }
    },
    methods:{
      jump(){
        window.open('http://www.yhfile.com/tools/index.html',"_blank")
      }
    }
  }
</script>

<style lang="less" scoped>
.menu-box{
  flex-grow: 1;
  background: #fff;
  height: 60px;

  .el-menu.el-menu--horizontal {
    height: 45px;
    border: 0px;
    .is-active {
      border: none;
      color: #409EFF;
      position: relative;
      &::after{
        content: '';
        width: 80px;
        height: 3px;
        background: #409EFF;
        position: absolute;
        bottom: -2px;
        left: 15px;
      }
      // border-bottom: 2px solid #409EFF !important;
    }
    .el-menu-item {
      border: none;
      height: 45px;
      width: 110px;
      padding: 0px;
      text-align: center;
    }
    .el-menu-item:last-child{
      color: #303133;
    }
    .el-menu-item:last-child::after{
      display: none;
    }
  }
  .el-menu--horizontal>.el-menu-item {
    color: #303133;
    font-size: 16px;
    font-weight: bold;
  }
}

</style>