import BaseAPI from '../BaseAPI.js'

/**
 * 日志服务API
 * 
 * @author Daniel
 * @date 
 */
export default class LogsAPI extends BaseAPI {
  constructor() {
    super()

    // this.moduleName = "/document"
  }

  /**
   * 阅读记录
   * @param {
   *  pageNum: 页码数
   *  pageSize: 每页条数
   * } query 
   */
  readLog(query){
    return this.ajax.post(`/share/query/readRecord`, query)
  }

   // 查询操作记录
   getLog(query){
    return this.ajax.post(`/share/query/log`,query)
  }

}