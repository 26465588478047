/**
 * 申请相关服务
 * 1. 阅读申请授权
 * 2. 资源请求分享
 */
import { requestAPI } from 'liankong-ui-api'

const state = {
  views: {
    //发送者，当前用户视图
    sender: {
      query: {
        pageNum: 1,
        pageSize: 20
      },
      paging: {
        total: 0,
        pages: 0
      },
      list: [],

      //加载更多
      more: {
        status: 'more',
        contentText: {
          contentdown: '加载更多数据',
          contentrefresh: '数据加载中',
          contentnomore: '没有更多数据了'
        }
      },

      //我的申请详情信息
      keepData:{},
    },

    //接收请求视图
    receiver: {
      query: {
        pageNum: 1,
        pageSize: 20
      },
      paging: {
        total: 0,
        pages: 0
      },
      list: [],

      //加载更多
      more: {
        status: 'more',
        contentText: {
          contentdown: '加载更多数据',
          contentrefresh: '数据加载中',
          contentnomore: '没有更多数据了'
        }
      },
      //缓存tab切换状态
      requestActiveName:''
    }
  }
}

const mutations = {
  /**
   * 更新视图列表
   * @param {*} state 
   * @param {*} vkey
   * @param {*} rest
   * @param {*} data
   */
  updateList(state, { vkey, reset = false, current, pages, data, total,noRest=true }) {
    const viewer = state.views[vkey]

    viewer.query.pageNum = current
    viewer.paging.total = total
    viewer.paging.pages = pages

    //更多状态
    if(pages == 0 || current == pages){
      viewer.more.status = 'noMore'
    }else{
      viewer.more.status = 'more'
    }

    viewer.list = reset ? data : viewer.list.concat(data)
    if(!noRest){
      viewer.list = data
    }
  },

  /**
   * 更新视图元素
   * @param {*} state 
   * @param {*} param1 
   */
  updateItem(state, {vkey, item}){
    const viewer = state.views[vkey]

    if(viewer && item){
      for(let i=0, ilen=viewer.list.length; i<ilen; i++){
        const current = viewer.list[i]

        if(current.id == item.id){
          this._vm.$set(viewer.list, i, item)
          break
        }
      }
    }
  },

  /**
   * 更新视图查询条件
   * @param {*} state 
   * @param {*} param1 
   */
  updateQuery(state, {vkey, query}){
    const viewer = state.views[vkey]
    viewer.query = Object.assign(viewer.query, query)
  },

  //更新更多状态
  updateMore(state, { vkey, moreStatus }) {
    const viewer = state.views[vkey]
    viewer.more.status = moreStatus
  },

  //更新视图加载状态
  updateLoading(state, {vkey, loading}){
    const viewer = state.views[vkey]
    viewer.loading = loading
  },

  //保存详情信息
  keepDataDetail(state,{vkey,data}){
    const viewer = state.views[vkey]
    viewer.keepData = data
  },

  //更新请求状态
  updateState(state, { vkey, stateValue }) {
    console.log(vkey,stateValue)
    const viewer = state.views[vkey]
    viewer.requestActiveName = stateValue
  },
}

const actions = {
  /**
   * 加载我的申请
   */
  async loadSendByMe({ state, commit }, { reset = false, query = {} }) {
    try {
      const viewerKey = 'sender'
      const viewer = state.views[viewerKey]

      //是否有更多查询
      if(!reset && viewer.more.status === 'noMore'){
        return
      }

       //更新查询条件
      commit('updateQuery', { vkey: viewerKey, query })

      if(reset){
        viewer.query.pageNum = 1
      }

      const { code, current, pages, size, total, data, message } = await requestAPI.sendList(viewer.query)
      if (code == 0) {
        commit("updateList", {
          vkey: viewerKey,
          reset,
          current,
          pages,
          size,
          total,
          data
        })
      }
      return code == 0  
    } catch (error) {
      throw error
    }
  },

  /**
   * 加载我接收的申请
   * @param {*} param0 
   */
  async loadReceiveByMe({ state, commit }, { reset = false,noRest=true }) {
    try {
      const viewerKey = 'receiver'
      const viewer = state.views[viewerKey]

      if(reset&&noRest){
        viewer.query.pageNum = 1
      }

      const { code, current, pages, size, total, data, message } = await requestAPI.receiveList(viewer.query)
      if (code == 0) {
        commit("updateList", {
          vkey: viewerKey,
          reset,
          current,
          pages,
          size,
          total,
          data,
          noRest
        })
      }
      return code == 0

    } catch (error) {
      throw error
    }
  },


  /**
   * 下一页
   * @param {*} param0 
   * @param {
    *  vkey: 操作的视图key
    *  reset: 翻页是否覆盖
    * } param1 
    */
   async doLoadNextPage({ commit, dispatch, state }, { vkey }) {
 
     const viewer = state.views[vkey]
 
     if (viewer.more.status != 'more') {
       return
     }
 
     const paging = viewer.paging
     const queryInfo = viewer.query
 
     //pageNum为页码，1、2，pages为总页数， 1、2 < 2 + 1 
     if (queryInfo.pageNum < paging.pages + 1) {
       commit('updateMore', { vkey, moreStatus: 'loading' })

       queryInfo.pageNum++
       if(vkey == 'receiver'){
        dispatch("loadReceiveByMe", { vkey, reset: false, queryInfo })
       }else if(vkey == 'sender'){
        dispatch("loadSendByMe", { vkey, reset: false, queryInfo })
       }
     }
   },
 
   /**
    * 上一页
    * @param {*} param0 
    * @param {
    *  vkey: 操作的视图key
    *  reset: 翻页是否覆盖
    * } param1 
    */
   async doLoadPrevPage({ commit, dispatch }, { vkey, reset = false }) {
     const viewer = state.views[vkey]
 
     const paging = viewer.paging
     const queryInfo = viewer.query
 
     if (queryInfo.pageNum > 0) {
       queryInfo.pageNum--

       if(vkey == 'receiver'){
        dispatch("loadReceiveByMe", { vkey, reset: false, queryInfo })
       }else if(vkey == 'sender'){
        dispatch("loadSendByMe", { vkey, reset: false, queryInfo })
       }

     }
   },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}