/**
 * MapData 数据缓存
 */

//缓存数据 
let _cache = {}

export default {
  /**
   * 清除
   */
  clear(){
    _cache = {}
  },

  /**
   * 存入数据
   */
  put(key, value){
    _cache[key] = value
  },

  /**
   * 获取数据
   * @param {*} key 
   * @param {*} defaultValue 
   */
  get(key, defaultValue){
    const val = _cache[key]
    if(defaultValue != null && !val){
      return defaultValue
    }
    return val
  },

  /**
   * 获取数据
   * @param {*} key 
   * @param {*} defaultValue 
   */
  getOnec(key, defaultValue){
    const val = this.get(key, defaultValue)
    delete _cache[key]
    return val
  }
}