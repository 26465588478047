import { Store,Cache } from '@lk/cache'
export function userSex(value) {
    return value == 0 ? '女' : '男'
}

/**
 * 文档类型
 * @param {*} value 
 * @param {*} file 
 */
export function fileType(value, file) {
    return value == 1 ? file.suffix : "文件夹"
}

/**
 * 目标类型
 * @param {*} value 
 */
export function targetType(value) {
    switch (value) {
        case 'TARGET_ID':
            return '平台用户授权'
        case 'TARGET_PHONE':
            return '手机号码授权'
        case 'TARGET_ORG':
            return '组织授权'
        case 'TARGET_GROUP':
            return '群组/部门授权'
    }
}

/**
 * 请求类型
 * @param {*} value 
 */
export function requestType(value) {
    switch (value) {
        case -2:
            return '请求授权扫码'
        case -1:
            return '阅读授权扫码'
        case 0:
            return '分享阅读申请'
        case 1:
            return '资源授权申请'
    }
}

/**
 * 请求审批状态
 * @param {*} value 
 */
export function requestState(value) {
    switch (value) {
        case 0:
            return "待处理"
        case 1:
            return "已通过"
        case 2:
            return "已拒绝"
        case 3:
            return "已取消"
    }
}

/**
 * 字典字段名称
 * @param {*} value 
 */
export function dictName(value) {
    switch (value) {
        case 'doc':
            return "文档类型"
        case 'miji':
            return "保密等级"
    }
}

//Develop#A# 提取配置为不同环境的打包文件 @Daniel
//基础url
// const miniAppBaseUrl = "https://fcp.scfile.cn"
// const apiBaseUrl = "https://api.scfile.cn"
// const readerBaseUrl = "https://reader.scfile.cn/"

// 线上环境地址
const miniAppBaseUrl = "https://fcp.scfile.cn"
const apiBaseUrl = "https://fcp.scfile.cn"
const readerBaseUrl = "https://reader.scfile.cn/ofd-reader"
// const imgBaseUrl = "http://192.168.1.28:8080"

// 小程序环境
// const miniAppBaseUrl = "https://fcp.scfile.cn"
// const apiBaseUrl = "https://api.scfile.cn"
// const readerBaseUrl = "https://reader.scfile.cn/ofd-reader"

// 万达
// const miniAppBaseUrl = "http://192.168.174.2/"
// const apiBaseUrl = "http://192.168.174.2/"
// const readerBaseUrl = "http://192.168.174.2/ofd-reader"

// 测试环境
// const miniAppBaseUrl = "http://dev.scfile.cn"
// const apiBaseUrl = "http://dev.scfile.cn"
// const readerBaseUrl = "http://dev.scfile.cn/ofd-reader"

// 鼎信医疗
// const miniAppBaseUrl = "http://dxb.yhcode.com:88"
// const apiBaseUrl = "http://dxb.yhcode.com:88"
// const readerBaseUrl = "http://dxb.yhcode.com:88/ofd-reader"

//长城网
// const miniAppBaseUrl = "http://113.50.50.17"
// const apiBaseUrl = "http://113.50.50.17"
// const readerBaseUrl = "http://113.50.50.17/ofd-reader"

//内网
// const apiBaseUrl = "http://192.168.1.214:8005"

// const miniAppBaseUrl = window.origin
// const apiBaseUrl = "/prod-api"
// const readerBaseUrl = "/ofd-reader"

//分享地址生成
export function shareUrl(shareInfo) {
    if (shareInfo) {

        const type = typeof shareInfo
        let shareId = null

        if (type === 'string') {
            shareId = shareInfo
        } else if (type === 'object') {
            shareId = shareInfo.urlShort
        }

        return `${miniAppBaseUrl}/#/share/${shareId}`
    }
    return null
}

// 协同地址生成
export function teamWorkUrl(teamInfo) {
    if (teamInfo) {

        const type = typeof teamInfo
        let shareId = null

        if (type === 'string') {
            shareId = teamInfo
        } else if (type === 'object') {
            shareId = teamInfo.urlShort
        }

        return `${miniAppBaseUrl}/#/teamwork/${shareId}`
    }
    return null
}


//申请加入组织地址
export function org(info) {
    return `${miniAppBaseUrl}/#/${info}`

}
export function baseUrl(path){
    return `${apiBaseUrl}/${path}`
}

//阅读地址生成
export function readerUrl(path,shareInfo='') {
    // return `${miniAppBaseUrl}/screader${path}`
    
    // 鼎信医疗
    // if (path.indexOf('/testreader/index.html') == -1) {
    //     path = '/testreader/index.html' +path;
    // }

    // pdf阅读需要，测试环境
    if (path.indexOf('/testreader/index.html') != -1) {
        path = '/' + path.substring(path.indexOf('?ticket'))+'&ofd=ofd';
    }else if (path.indexOf('/testreader/pdf.html') != -1) {
        path = '/' + path.substring(path.indexOf('?ticket'))+'&pdf=pdf';
    }else if (path.indexOf('/testreader/video.html') != -1) {
        path = '/' + path.substring(path.indexOf('?ticket'));
    }

    const token = Store.get('access_token');
    const expires = Store.get('expires')
    let encodeUserName=''
    if(Cache.get('user')){
        const {username} = Cache.get('user')
        encodeUserName = username ? encodeURI(`${username}`): ''
    }
    return `${readerBaseUrl}${path}&token=${token}&expires=${expires}&username=`+encodeURI(`${encodeUserName}${shareInfo}`)
}

/**
 * 阅读申请二维码地址生成
 * https://api.scfile.cn/#/request?
 * @param {*} from read阅读，find申请
 * @param {*} shareId 
 * @param {*} clientId 
 * @param {*} userId 
 */
export function requestUrl(from, shareId, clientId, userId) {
    return `${miniAppBaseUrl}/#/request/?from=${from}&shareId=${shareId}&clientId=${clientId}&userId=${userId}`
}

/**
 * Client设备地址二维码生成
 * https://api.scfile.cn/#/client?
 * @param {*} path 
 */
export function clientUrl(path) {
    return `${miniAppBaseUrl}/#/client/?${path}`
}

/**
 * 二维码 - 快速分享
 * @param {*} ticket 
 * @param {*} clientId 
 */
export function fastShareQRCode(ticket, clientId) {
    return `${miniAppBaseUrl}/#/fshare/?id=${ticket}&client=${clientId}`
}

/**
 * 图片地址拼接
 * @param {*} path 
 */
export function thumbnailUrl(path) {
    return `${apiBaseUrl}${path}?version=1.0`
}

/**
 * 印章地址拼接
 * @param {*} path 
 */
 export function imgSignUrl(path) {
    return `${imgBaseUrl}${path}`
}

/**
 * 获取url的query参数
 * @param {*} url 
 * @param {*} name 
 */
export function getQuery(url, name) {
    let index = 0
    if ((index = url.indexOf('?')) > -1) {
        url = url.substr(index + 1)
    }

    let reg = `(^|&)${name}=([^&]*)(&|$)`
    let r = url.match(reg)
    if (r != null) return unescape(r[2]);
    return null
}