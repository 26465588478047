/**
 * 角色服务API
 */
import * as http from '../utils/http/index.js';

const moduleName = "/usercenter"

/**
 * 角色纬度列表
 */
export function typeList(){
  return http.get(`${moduleName}/role/type/list`)
}

/**
 * 添加角色纬度
 * @param {*} type 
 */
export function addType(type){
  return http.post(`${moduleName}/role/type`, `name=${type.label}`)
}

/**
 * 删除角色纬度
 * @param {*} type 
 */
export function delType(type){
  return http.del(`${moduleName}/role/type/${type.id}`)
}

/**
 * 角色列表
 */
export function getListByType(type){
  return http.get(`${moduleName}/role/list/${type}`)
}

/**
 * 添加角色
 * @param {*} role 
 */
export function addRole(role){
  return http.post(`${moduleName}/role`, role)
}

/**
 * 更新角色
 * @param {*} role 
 */
export function updateRole(role){
  return http.put(`${moduleName}/role`, role)
}

/**
 * 删除角色
 * @param {*} role 
 */
export function removeRole(role){
  return http.del(`${moduleName}/role/${role.id}`)
}