// 管理端 3.0
// 上传文件返回的路径是相对 需要拼接一下
// 正式环境 http://www.yhfile.com
const uploadUrl = '/api' + '/dict/file/resource?url=';
export function uploadFileUrl(url) {
    return uploadUrl + url;
}


// 本地使用需要这样配置  打包时需要跟上面对应
const newUploadUrl = '/dict/file/resource?url='; // 正式环境
export function newUploadFileUrl(url) {
    return newUploadUrl + url;
}