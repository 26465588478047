<template>
    <div class="bread-wrap">
        <!-- <div class="bread-icon">
            <i class="el-icon-arrow-left"></i>
        </div> -->
        <span class="back-text" title="返回上一级" v-if="pageBackTextShow == '' ? false : true" @click="breadHaveBackTextEvent">返回上一级</span>
        <span v-if="pageBackTextShow == '' ? false : true">|</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
            <el-breadcrumb-item class="bread" v-for="(item,index) in breadList" :key="index" @click.native="breadEvents(item)">
                {{item.title}}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
import fileRouter from '../../router/fileRouter.js' // 文件管理的左侧菜单数据
import orgPersonData from '../../router/personRouter' // 人员管理的左侧菜单数据
import shareRouters from '../../router/shareRouter.js' // 共享授权的左侧菜单数据
import { documentAPI, dictAPI } from 'liankong-ui-api'
const directory = fileRouter.directory;

export default {
    data(){
        return {
            breadList: [],
        }
    },
    computed: {
        pageBackTextShow(){
            return this.$store.state.pageBackTextShow
        }
    },
    watch: {
        $route(to) {
            if(to.matched[0].path == "/file") { // 文件管理
                this.fileInit(directory);
            } else if(to.matched[0].path == "/persnnel") { // 人员管理
                this.persnelInit(orgPersonData);
            } else if(to.matched[0].path == '/sharetion') { // 共享授权
                this.shareAuthtionInit(shareRouters);
            }
        },
        '$store.state.folderBreadList':{
            handler(newValue) {
                directory.forEach(item => {
                    if(item.children.length > 0) {
                        if(item.path == this.$route.path && item.name == '我的文档') {
                            let newArrs = [{
                                path: item.path,
                                title: item.name
                            }]
                            if(newValue.length > 0) {
                                this.breadList = newArrs.concat(newValue)
                            } else {
                                this.breadList = newArrs
                            }
                        } else {
                            item.children.forEach(watchItems => {
                                if((watchItems.path == this.$route.path && watchItems.name == '组织文档') || (watchItems.path == this.$route.path && watchItems.name == '群组文档')) {
                                    let newOrgArrayList = [{
                                        path: item.path,
                                        title: item.name
                                    },{
                                        path: watchItems.path,
                                        title: watchItems.name
                                    }]

                                    if(newValue.length > 0) {
                                        this.breadList = newOrgArrayList.concat(newValue)
                                    } else {
                                        this.breadList = newOrgArrayList
                                    }
                                } else if(this.$store.state.checkedLeftAsideInfo && watchItems.name == this.$store.state.checkedLeftAsideInfo.name && watchItems.type == 0 ) {
                                    let newChildList = [{
                                        path: '/file/mydoc',
                                        title: '我的文档'
                                    }]

                                    if(newValue.length > 0) {
                                        this.breadList = newChildList.concat(newValue)
                                    } else {
                                        this.breadList = newChildList
                                    }
                                } 
                            })
                        }

                    } else if(item.path == this.$route.path && item.name == '我的收藏'){
                        let newArrs = [{
                            path: item.path,
                            title: item.name
                        }]
                        if(newValue.length > 0) {
                            this.breadList = newArrs.concat(newValue)
                        } else {
                            this.breadList = newArrs
                        }
                    }
                })
            },
            deep: true
        }
    },
    mounted() {
        if(this.$route.matched[0].path == "/file") { // 文件管理
            this.breadList = this.breadList.length == 0 ? this.$store.state.saveBreadList = this.$store.state.saveBreadList == null ? this.fileInit(directory) : this.$store.state.saveBreadList : this.fileInit(directory);
        } else if(this.$route.matched[0].path == "/persnnel") { // 人员管理
            this.persnelInit(orgPersonData);
        } else if(this.$route.matched[0].path == "/sharetion") { // 共享授权
            this.shareAuthtionInit(shareRouters);
        }
    },
    methods: {
        fileInit(arrs){
            arrs.forEach(item => {
              if(item.children.length > 0) {
                    if(item.path == this.$route.path && item.name == '我的文档') {
                        this.breadList=[{
                            path: item.path,
                            title: item.name
                        }]
                        this.$store.commit('saveBreadList', this.breadList)
                    } else {
                        item.children.forEach(i => {
                            if(i.children.length > 0) {
                                if(i.path == this.$route.path && (i.name == '固定分类' || i.name == '我的标签')) {
                                    this.breadList = [{
                                        path: item.path,
                                        title: item.name
                                    },{
                                        path: i.path,
                                        title: i.name
                                    }]
                                    this.$store.commit('saveBreadList', this.breadList)
                                } else {
                                    i.children.map(child=>{
                                        if(child.path == this.$route.path){
                                            this.breadList=[{
                                                path: item.path,
                                                title: item.name
                                            },{
                                                path: i.path,
                                                title: i.name
                                            },{
                                                path: child.path,
                                                title: child.name
                                            }]
                                            this.$store.commit('saveBreadList', this.breadList)
                                        }
                                    })
                                }
                            } else if(i.path == this.$route.path) {
                                this.breadList = [{
                                    path: item.path,
                                    title: item.name
                                },{
                                    path: i.path,
                                    title: i.name
                                }]
                                this.$store.commit('saveBreadList', this.breadList)
                            }  else if(this.$route.path == '/file/mydoc1' && i.name == this.$store.state.checkedLeftAsideInfo.name) {
                                this.breadList = [{
                                   path: '/file/mydoc',
                                   title: '我的文档'
                                },{
                                    path: this.$route.path,
                                    title: i.name
                                }]
                                this.$store.commit('saveBreadList', this.breadList)
                            } 
                        })
                    }
                } else if(item.path == this.$route.path){
                    this.breadList=[{
                        path: item.path,
                        title: item.name
                    }]
                    this.$store.commit('saveBreadList', this.breadList)
                }
            })
            return this.breadList
        },
        persnelInit(arrs) {
            arrs.forEach(perItem => {
              if(perItem.children.length > 0) {
                    perItem.children.forEach(perChildItem => {
                        if(perChildItem.path == this.$route.path) {
                            this.breadList = [{
                                path: perItem.path,
                                title: perItem.name
                            },{
                                path: perChildItem.path,
                                title: perChildItem.name
                            }]
                        }
                    })
                } else if(perItem.path == this.$route.path){
                    this.breadList=[{
                        path: perItem.path,
                        title: perItem.name
                    }]
                }
            })
        },
        shareAuthtionInit(shareArr) {
            shareArr.forEach(shareItem => {
              if(shareItem.children.length > 0) {
                    shareItem.children.forEach(perChildItem => {
                        if(perChildItem.path == this.$route.path) {
                            this.breadList = [{
                                path: shareItem.path,
                                title: shareItem.name
                            },{
                                path: perChildItem.path,
                                title: perChildItem.name
                            }]
                        }
                    })
                } else if(shareItem.path == this.$route.path){
                    this.breadList=[{
                        path: shareItem.path,
                        title: shareItem.name
                    }]
                }
            })
        },

        // 返回上一级
        breadHaveBackTextEvent(){
            if(this.pageBackTextShow == '我的收藏') {
                this.$ebus.$emit('startGoBackBtn')
            } else if(this.pageBackTextShow == '我的文档') {
                this.$ebus.$emit('myDocGoBackBtn')
            } else if(this.pageBackTextShow == '文档child') {
                this.$ebus.$emit('myChildDocBackBtn')
            } else if(this.pageBackTextShow == '组织文档') {
                this.$ebus.$emit('orgDocGoBackBtn')
            } else if(this.pageBackTextShow == '群组文档') {
                this.$ebus.$emit('groupDocGoBackBtn')
            }
            
        },
        // 跳转链接
        breadEvents(item) {
            if(item.title == '我的文档') { // 我的文档面包屑
                this.$ebus.$emit('enterMyDocData', '我的文档')
            } else if(item.tips == '我的文档') { // 我的文档 -> 文件夹面包屑
                this.$ebus.$emit('enterMyDocData', item)
            } else if(item.tips == '组织文档') { // 组织文档 -> 文件夹面包屑
                this.$ebus.$emit('enterOrgDocChild', item)
            } else if(item.tips == '组织文档一级') { // 组织文档 -> 文件夹面包屑
                this.$ebus.$emit('enterOrgDocRoladList', item)
            } else if(item.tips == '组织文档部门') { // 组织文档 -> 文件夹面包屑
                this.$ebus.$emit('enterOrgDocRoladListorg', item)
            } 
            // else if(item.title == '组织文档') { // 组织文档面包屑
            //     this.$ebus.$emit('enterOrgDocChild', '组织文档')
            // }
             else if(item.tips == '我的文档child') { // 子文档 -> 文件夹面包屑
                this.$ebus.$emit('myDocEnterChild', item)
            } else if(item.tips == '我的收藏') { // 我的收藏 -> 文件夹面包屑
                this.$ebus.$emit('clickMyStart', item)
            } else if(item.title == '我的收藏') { // 我的收藏
                this.$ebus.$emit('clickMyStart', item)
            } else if(item.tips == '群组文档') { // 群组文档/群组文档->文件夹面包屑
                this.$ebus.$emit('clickGroupFolder', item)
            } else if(item.tips == '群组文档一级') { // 群组文档/群组文档->文件夹面包屑
                this.$ebus.$emit('clickGroupFolderList', item)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.bread-wrap {
    // height: 45px;
    // border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
    margin-top: 2px;
    .bread-icon {
        width: 45px;
        height: 45px;
        border: 1px solid #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ccc;
        margin-right: 20px;
        cursor: pointer;
    }
    /deep/ .el-breadcrumb {
        .el-breadcrumb__item {
            span {
                cursor: pointer;
            }
            span:hover {
                color: #409EFF;
            }
        }
        .el-breadcrumb__inner {
            color:#313538;
            font-size: 17px;
        }
    }
    >span {
        font-size: 15px;
        margin: 1px 6px 3px 6px;
        color: rgb(191, 187, 187);
    }
    >span:hover {
        color: #409EFF;
    }
    .back-text {
        font-size: 17px;
        margin: 2px 0 0 6px;
        color: #313538;
        cursor: pointer;
    }
}
</style>