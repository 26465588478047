<template>
    <el-dialog
    :title="title"
    width="700px"
    :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false" 
    custom-class="watherMsg"
    :visible.sync="watherDialogVisible">
    <el-form :model="watherRuleForm" :rules="watherRules" ref="watherRuleForm" label-width="90px" class="demo-watherRuleForm">
        <el-row>
            <el-col :span="24">
                <el-form-item prop="name" label='水印名称'>
                    <el-input size="small" placeholder="水印名称" v-model="watherRuleForm.name"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item prop="text" label='水印内容'>
                    <el-input size="small" placeholder="水印内容" v-model="watherRuleForm.text"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-form-item prop="fontFamily" label='字体'>
                    <el-select size="small" v-model="watherRuleForm.fontFamily" placeholder="请选择字体">
                        <el-option
                        v-for="item in fontFamily"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> 
            </el-col>
            <el-col :span="8">
                <el-form-item prop="fontSize" label='字号'>
                <el-select size="small" v-model="watherRuleForm.fontSize" placeholder="请选择字号">
                    <el-option
                    v-for="item in fontSize"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item prop="rotate" label='角度'>
                        <el-select size="small" v-model="watherRuleForm.rotate" placeholder="请选择角度">
                    <el-option
                    v-for="item in rotate"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item prop="color" label='颜色'>
                     <compact-picker v-model="watherRuleForm.color" @input="updateValue" />
                    <!-- <el-color-picker v-model="watherRuleForm.color"></el-color-picker> -->
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cacelWather('watherRuleForm')">取 消</el-button>
        <el-button type="primary" size="small" @click="submitWatcher('watherRuleForm')">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import {fontFamily,fontSize,rotate} from '@/views/liankong/utils'

import { Compact } from "vue-color";

export default {
    components:{
     "compact-picker": Compact
    },
    data(){
        return {
            fontFamily,fontSize,rotate,
            watherRuleForm:{
                text:'',
                rotate:'',
                fontSize:'',
                fontFamily:'',
                name:'',
                type:1,
                isTiled:0,
                color:'#999999',
                state:0
            },
            title:'添加水印信息',
            editData:{}, // 编辑信息
            watherRules:{
                name:[{ required: true, message: '请输入水印名称', trigger: 'blur' },
                { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }],
                text: [
                    { required: true, message: '请输入水印内容', trigger: 'blur' },
                    { min: 0, max: 5, message: '长度在 0 到 5 个字符', trigger: 'blur' }
                ],
                // color:[{ required: true, message: '请选择水印颜色', trigger: 'blur' }],
                fontSize:[{ required: true, message: '请选择水印字号', trigger: 'blur' }],
                fontFamily:[{ required: true, message: '请选择水印字体', trigger: 'blur' },],
                rotate:[{ required: true, message: '请选择水印旋转角度', trigger: 'blur' },]
            },
            watherDialogVisible:false, // 水印弹框
            setWatherState:''
        }
    },
    computed: {
    },
    mounted(){
       
    },
    methods: {
        updateValue(value) {
            this.watherRuleForm.color = value.hex;
        },

        showAddMask(setWatherState,editData){
            this.watherDialogVisible=true
            this.setWatherState = setWatherState
            this.editData = editData
            if(setWatherState == 'add'){
                this.watherRuleForm={
                    text:'',
                    rotate:'',
                    fontSize:'',
                    fontFamily:'',
                    name:'',
                    type:1,
                    isTiled:0,
                    color:'#999999',
                    state:0
                }
            }else{
                 this.watherRuleForm = {
                    ...editData
                }   
            }
           
            this.title = setWatherState=='add'? '添加水印信息' : '编辑水印信息'
        },
        cacelWather(formName){
             if(this.$refs[formName]!==undefined){this.$refs[formName].resetFields()}
             this.watherDialogVisible = false
             this.watherRuleForm={
                text:'',
                rotate:'',
                fontSize:'',
                fontFamily:'',
                name:'',
                type:1,
                isTiled:0,
                color:'#999999',
                state:0
            }
        },
        submitWatcher(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$store.dispatch('shares/shareWatherState',{
                queryName:this.setWatherState,
                query:{
                    ...this.editData,
                    ...this.watherRuleForm
                }
            }).then((res)=>{
                if(res.code==0){
                   this.$message.success(this.title.replace('信息','成功'))
                   this.cacelWather(formName)
                   this.$emit('updataList')
                } else {
                    this.$message.error(this.title.replace('信息','失败'))
                }
            })
            } else {
                return false;
            }
            });
        },
    }
}
</script>
<style lang='less' scoped>
/deep/.vc-swatches{
    width:100%
}
</style>
