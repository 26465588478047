import BaseAPI from '../BaseAPI.js'

/**
 * 管理平台-菜单模块
 */

export default class MenusAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/usercenter"
    }

    // 树形列表
    getTreeList(data) {
        return this.ajax.get(`${this.moduleName}/menu/tree?lazy=${data}`)
    }

    // 删除菜单
    deleteMenu(data) {
        return this.ajax.delete(`${this.moduleName}/menu/${data}`)
    }

    // 添加接口
    addData(data) {
        return this.ajax.post(`${this.moduleName}/menu`, data)
    }

    // 修改
    editTreeMenu(data) {
        return this.ajax.put(`${this.moduleName}/menu`, data)
    }

    // 获取左侧导航菜单 
    leftMenuList(data) {
        return this.ajax.get(`${this.moduleName}/menu/getUserMenu/?type=${data}`)
    }

}