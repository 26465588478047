import StompJs from 'stompjs'
import Client from './Client.js'

/**
 * 基于WebSocket的客户端通讯
 * H5版本
 */
class WebSocketClient extends Client{
  constructor(){
    super()

    this.socketTask = null
    this.sessionId = null
  }

  open(){
    console.log("打开连接")
    if(!this.opened){
      //创建Socket连接
      return new Promise((resolve, reject) => {
        this.client = StompJs.client(`${this.baseWSUrl}`)

        this.client.connect({}, (resp) => {
          this.sessionId = resp
          this.opened = true

          console.log("WS Connected:", this.sessionId)
          resolve(true)
        })
      })
    }else{
      return Promise.resolve(true)
    }
  }
}

export default new WebSocketClient()