import BaseAPI from '../BaseAPI.js'

/**
 * 管理平台-监控管理、系统统计
 */

export default class MonitorManagerAPI extends BaseAPI {

    constructor() {
        super()

        this.moduleName = "/monitor"

        this.docNams = '/document'

    }

    // 获取所有的实例
    getAllList() {
        return this.ajax.get(`${this.moduleName}/applications`, {}, {
            headers: {
                // 'content-type': 'application/json',
                'Accept': 'application/json'
            }
        })
    }

    // 健康的信息
    getHealthyInfoList(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/health`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 进程--进程ID
    getProcessID(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/env/PID`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 进程--运行时间
    getUserdTimes(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/process.uptime`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 进程--cpu使用率
    getProcessUsedCount(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/process.cpu.usage`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 进程--系统cpu使用率
    getProcssSysCpuUserd(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/system.cpu.usage`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 进程--cpu核心数
    getCpuCount(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/system.cpu.count`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 垃圾回收
    getGarbageCollection(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.gc.pause`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--Heap 最大
    getMaxHeapList(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.max?tag=area:heap`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--headp 已用
    getAlreadyHeap(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.used?tag=area:heap`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--heap 当前可用
    getNowUsedData(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.committed?tag=area:heap`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 线程--活动线程
    getLineActiveList(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.threads.live`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 线程--线程峰值
    getLinePeakList(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.threads.peak`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 线程--守护进程
    getLineSaveList(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.threads.daemon`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--Non Heap 初始化空间
    getNonInitList(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.used?tag=area:nonheap,id:Metaspace`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--Non Heap 已用
    getAlreadyNonLists(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.used?tag=area:nonheap`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--Non Heap 当前可用
    getNowUsedNonLists(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.committed?tag=area:nonheap`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 内存--Non Heap 最大
    getMaxUseNonLists(id) {
        return this.ajax.get(`${this.moduleName}/instances/${id}/actuator/metrics/jvm.memory.max?tag=area:nonheap`, {}, {
            headers: {
                'Accept': 'application/json'
            }
        })
    }

    // 查询系统es文件总数
    getSysEsTotalList() {
        return this.ajax.get(`${this.docNams}/admin/system/es/fileCount`)
    }

    // 系统文件
    getSysTotalList() {
        return this.ajax.get(`${this.docNams}/admin/system/fileCount`)
    }

    // 排行统计
    getRankingList() {
        return this.ajax.get(`${this.docNams}/admin/system/filesize/day/statistics`)
    }

    // 信息查询 不同类型
    getTypesLits(type) {
        return this.ajax.post(`${this.docNams}/admin/system/ranking/list`, type)
    }

    // 系统信息查询
    getSysInfosList() {
        return this.ajax.get(`${this.docNams}/admin/system/statistics`)
    }

    // 分享、请求统计
    getShareInfosList() {
        return this.ajax.get(`/share/admin/total/statistics`)
    }

    // 阅读统计
    getDocumentReadCount() {
        return this.ajax.get(`/document/admin/system/documentReadCount`)
    }

    // 阅读排行
    getReadRank() {
        return this.ajax.get(`/document/admin/system/document/read/rank`)
    }

    getDocumentList(query){
        return this.ajax.post(`/document/admin/system/document/list`,query)
    }


}