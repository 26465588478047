/**
 * 字典服务
 */
 import { dictAPI } from 'liankong-ui-api'

 const state = {
    dicts: {},
    tagList: [],
    applyState: [
        {
            label: "申请中",
            value: 0
        },{
            label: "申请通过",
            value: 1
        },{
            label: "申请拒绝",
            value: 2
        }
    ],
    applyType:[{
        label: "分享阅读申请",
        value: 0
    },{
        label: "资源授权申请",
        value: 1
    }],
    docType: [{
        label: "所有者",
        value: '1',
        checked:false
      },{
        label: "标签",
        value: '2',
        checked:false
      },{
        label: "文档标题",
        value: '3',
        checked:false
      },{
        label: "文档内容",
        value: '4',
        checked:false
      }],
 }
 
 //Refactor#C#字典项的配置自动化进行@Daniel 2021-06
 
 // const getters = {
 //     //类型筛选字典
 //     values(state, key) {
 //         return state.dicts[key]
 //     }
 // }
 
 const mutations = {
     /**
      * 更新指定key的字典项值
      * @param {*} state 
      * @param {*} param1 
      */
     updateDictItem(state, { key, values }) {
         this._vm.$set(state.dicts, key, values)
     },
 
     updateTagItems(state, data) {
         state.tagList = data;
     },
     resetTagItems(state,{key}){
        state[key].map(item=>{
            if(item.checked){
                item.checked = false
            }
        })
       
     }
 }
 
 const actions = {
     /**
      * 初始化字典加载
      * @param {*} param0 
      */
     async init({ commit }) {
         try {
             //加载文档类型
             const docResult = await dictAPI.loadDictByType("doc")
             if (docResult.code == 0) {
                 commit("updateDictItem", {
                     key: "doc",
                     values: docResult.data
                 })
             }
 
             //加载密级
             const miJiResult = await dictAPI.loadDictByType("miji")
             if (miJiResult.code == 0) {
                 commit("updateDictItem", {
                     key: "miji",
                     values: miJiResult.data
                 })
             }
         } catch (error) {
             throw error
         }
     },
 
     /**
      * 获取自定义标签列表
      * @param {*} param0 
      * @param {*} query 
      */
     async getTagList({ commit }, query) {
         try {
             const tagData = await dictAPI.getTagList(query);
             if (tagData.code == 0) {
                 commit('updateTagItems', tagData.data);
             }
         } catch (error) {
             throw error
         }
     }
 
 }
 
 export default {
     namespaced: true,
     state,
     mutations,
     actions
 }