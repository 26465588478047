/**
 * 角色相关模块
 */
import { typeList, 
  addType,
  getListByType,
  addRole,
  removeRole,
  updateRole,
  delType } from '@/api/role.js'

const state = {
  roles: []
}

const mutations = {
  setRoleTypes(state, typeList){
    if(Array.isArray(typeList)){
      typeList = typeList.map(item => {
        item.label = item.name
        item.roles = []
        return item
      })
      state.roles = [...typeList]
    }else{
      typeList.label = typeList.name
      state.roles.push(typeList)
    }
  },

  removeRoleTypes(state, {index, value}){
    state.roles.splice(index, 1)
  },

  setRoles(state, {roleTypeId, roles}){
    for(let i =0, ilen = state.roles.length; i<ilen; i++){
      const roleTypeItem = state.roles[i]
      if(roleTypeItem.id == roleTypeId){
        roleTypeItem.roles = roles
        state.roles.splice(i, 1, roleTypeItem)
        break
      }
    }
  }
}

const actions = {
  //获取所有类型
  async getAllRoleTypes({commit, dispatch}){
    const {code, data, mesage} = await typeList()

    if(code == 0){
      commit("setRoleTypes", data)

      if(data){
        data.map(item => {
          dispatch("getAllRoleByType", item)
        })
      }
    }
  },

  //添加角色类型
  async createRoleType({commit}, type){
    const {code, data, message} = await addType(type)
    if(code == 0){
      commit("setRoleTypes", data)
      return true
    }
    
    return false
  },

  //删除角色类型
  async removeRoleType({commit, state}, typeName){
    //查找指定角色
    for(let i=0, ilen = state.roles.length; i<ilen; i++){
      const role = state.roles[i]

      if(role.name === typeName){
        const data = await delType(role)

        if(data.code == 0){
          commit("removeRoleTypes", {index: i, value: role})
        }

        return data
      }
    }
  },

  //根据角色分组加载角色列表
  async getAllRoleByType({commit}, roleType){
    const {code, data, message } = await getListByType(roleType.id)
    if(code == 0){
      commit("setRoles", {
        roleTypeId: roleType.id,
        roles: data
      })
    }
    return code == 0
  },

  //添加角色
  async addOrUpdateRole({commit, dispatch}, role){
    if(role.id){
      //编辑
      const {code, data, message} = await updateRole(role)
      if(code == 0){
        dispatch("getAllRoleByType", {id: role.type})
      }
    }else{
      const {code, data, message } = await addRole(role)
      if(code == 0){
        dispatch("getAllRoleByType", {id: role.type})
      }
    }
    return true
  },

  //删除角色
  async removeRole({dispatch}, role){
    const {code, data, message } = await removeRole(role)
    if(code == 0){
      dispatch("getAllRoleByType", {id: role.type})
    }
    return true
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}