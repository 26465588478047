<template>
  <div>
    <el-container class="wrap">
     <div style="display: flex; height: calc(100vh - 34px)">
        <!-- 左侧导航 -->
        <el-aside width="min-content" >

          <logo-item :isCollapse="isCollapse"></logo-item>
          
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            background-color="#545c64"
            text-color="rgb(191, 203, 217)"
            active-text-color="rgb(64, 158, 255)"
            :collapse="isCollapse">

              <left-item :leftMenu="leftMenu" first :isCollapse="isCollapse"></left-item>

          </el-menu>
        </el-aside>
        <!-- 右侧 -->
        <el-container>
          <el-header>
            <div class="one-box">
              <i class="el-icon-s-fold" style="font-size:20px; color: #999999;" @click="putEvent" v-if="!isCollapse"></i>
              <i class="el-icon-s-unfold" style="font-size:20px; color: #999999;" @click="openEvent" v-if="isCollapse"></i>
              <ul>
                <li v-for="(item,index) in topMenu" :key="index" @click="tabEvent(index)" :class="{'activeIndex': ind == index}">
                  <i :class="item.icon"></i>
                  {{item.name}}
                </li>
              </ul>
            </div>
            <div class="two-box">
              <UserBtn></UserBtn>
            </div>
          </el-header>
          
          <el-main >
            <!-- <tab-item></tab-item> -->
            <div style="margin: 20px;background: #fff;border-radius: 5px;box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);">
              <router-view></router-view>
            </div>
          </el-main>
        </el-container>
     </div>
      <Footer></Footer>

    </el-container>
  </div>
</template>

<script>
 import Footer from "../Footer.vue";
  import UserBtn from "../components/User.vue"
  // logo
  import LogoItem from './components/LogoItem.vue'
  import LeftItem from './components/LeftItem.vue'

  // tab标签切换
  // import TabItem from './components/TabItem.vue'
  import { menusAPI } from 'liankong-ui-api' 

  export default {
    components: {
      Footer,
      UserBtn,
      LogoItem,
      LeftItem,
      // TabItem
    },
    data() {
      return {
        isCollapse: false, // 是否展开收起
        topMenu: [], // 顶部菜单
        leftMenu: [], // 左侧菜单
        ind: 0, // 头部tab切换
      }
    },
    computed:{
      id() {
        return this.$store.state.login.id || null
      }
    },
    created(){
     
      let topMenus = [{
          icon: "el-icon-s-tools",
          id: 3000,
          keepAlive: "0",
          label: "上链存证",
          name: "上链存证",
          parentId: -1,
          path: "/daemon",
          permission: null,
          sort: 2,
          type: "2",
          weight: 2
        }]
      this.topMenu = topMenus;

      this.initMenu()
    },
    methods: {
      // 左侧菜单
      initMenu(){
        let type = '1';
        menusAPI.leftMenuList(type).then(res=>{
          if(res.code == '0') {
            this.leftMenu = res.data;
          }
        }).catch(error=>{
          this.$message.error(error.message);
        })
      },
      putEvent(){
        this.isCollapse = true
      },
      openEvent(){
        this.isCollapse = false
      },
      // 顶部菜单切换
      tabEvent(index){
        this.ind = index;

        // 需要改 请求接口！！！
        if(this.ind == '0'){
            this.initMenu()
        }
      }

    }
  };
</script>

<style lang='less' scoped>
/deep/ .el-container {
  display: flex;
  flex-direction: column !important;
}
.wrap {
  height: 100vh;
}
/deep/ .el-header {
  background: #fff;
  // -webkit-box-shadow: 2px 0 6px #4F5051;
  // box-shadow: 2px 0 6px #4F5051;
  border-bottom: 1px solid #e2e2e2;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  .one-box {
    display: flex;
    align-items: center;
    ul {
        list-style: none;
        display: flex;
        li {
          margin-right: 20px;
          cursor: pointer;
          color: #999999;
        }
    }
  }
}

/deep/ .el-aside {
  -webkit-box-shadow: 1px 0 3px #4F5051;
  box-shadow: 1px 0 3px #4F5051;
  background: rgb(84, 92, 100);
  z-index: 99;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  height: calc(100vh - 94px);
  overflow-y: auto;
}
.activeIndex {
  border-bottom: 4px solid #409EFF;
}
</style>