const teamWorkRouter = [
    {
        icon: 'el-icon-s-custom',
        name: '协同签批',
        path: '/teamWork/count',
        children: [  
            {
                icon: 'el-icon-pie-chart',
                name: '首页',
                path: '/teamWork/count',
                children: []
            },{
                icon: 'el-icon-tickets',
                name: '我发起',
                path: '/teamWork/create',
                children: []
            },
            {
                icon: 'el-icon-s-check',
                name: '待处理',
                path: '/teamWork/pending',
                children: []
            },
            {
                icon: 'el-icon-finished',
                name: '我参与',
                path: '/teamWork/join',
                children: []
            }
        ]
    }, {
        icon: 'el-icon-document-checked',
        name: '在线编辑',
        path: '/onlineEditing',
        children: []
    }
  
]

export default teamWorkRouter;