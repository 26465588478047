import { teamWorkAPI , shareAPI  } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'
import { Store,Cache } from '@lk/cache'

const BaseListPageViewer = {
    query: {
        pageNum: 1,
        pageSize: 20
    },
    paging: {
        total: 0,
        pages: 0
    },
    list: [],

    //加载更多
    more: {
        status: 'more',
        contentText: {
            contentdown: '加载更多数据',
            contentrefresh: '数据加载中',
            contentnomore: '没有更多数据了'
        }
    }
}

const state = {
    views: {
        myPending: deepCopy(BaseListPageViewer), // 待处理
        myCreate: deepCopy(BaseListPageViewer), // 我发起的
        myJoin: deepCopy(BaseListPageViewer), // 我参与的
        replicationList: deepCopy(BaseListPageViewer), // 副本文件列表
        myActive: deepCopy(BaseListPageViewer), // 我的动态
        online: deepCopy(BaseListPageViewer), // 在线编辑
      
    },
    detail:null //协同详情
}

const mutations = {
    updateList(state, { viewerKey, reset = false, current, pages, data, total }) {
        const viewer = state.views[viewerKey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data);
    },

     //更新更多状态
     updateMore(state, { viewerKey, moreStatus }) {
        const viewer = state.views[viewerKey]
        viewer.more.status = moreStatus
    },


    /**
     * 更新视图查询条件
     * @param   query
     * @return  data
     */
    updateQuery(state, { viewerKey, query }) {
        const viewer = state.views[viewerKey]
        viewer.query = Object.assign(viewer.query, query)
    },
    clearDetail(state){
        state.detail = null;
    }
}


const actions = {
    // 列表
    async loadByQuery({ commit, state }, { viewerKey, reset = false, query, interfaceName ,isAPP}) {
        const viewer = state.views[viewerKey]
        

        // 是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }

        if (reset) {
            viewer.query.pageNum = 1
        }
        //更新查询条件
        commit('updateQuery', { viewerKey: viewerKey, query })

        const { code, current, pages, size, total, data, message } = await teamWorkAPI[`${interfaceName}`](viewer.query);
        if (code == 0) {
            commit("updateList", {
                viewerKey,
                current,
                reset,
                pages,
                size,
                total,
                data
            })
             
            if(viewerKey == 'replicationList' && isAPP){
                // 小程序处理数据
                if(state.detail && state.detail.resourceList.length != 0 ){
                    state.detail.resourceList.map(doc => {
                        let arr = data.filter( m => m.resourceId == doc.id)
                        if(arr.length != 0 && state.detail.teamWorkType == 0){
                            arr = arr.filter( m => m.onwerUserId == Store.get("user").id )
                        }
                        doc.repliceId = arr.length == 0 ? null :arr[0].id
                        doc.state = arr.length == 0 ? null :arr[0].state
                    })
                }
    
            }
        }

        return code == 0
    },


    /**
     * 协同详情
     * @param {*} param0 
     */
    async teamWorkDetail({ state , dispatch },{ id , query ,isAPP}) {
        try {
            // 获取协同详情
            const {code , data ,message} = await teamWorkAPI.findTeamWorkDetail(id);          
            if(code == 0){
                state.detail = data;
                // 获取副本文件列表
                return dispatch("loadByQuery", { viewerKey:'replicationList', reset:true, query ,interfaceName:'getReplicationList',isAPP })
            }

            return code == 0

        } catch (err) {
            throw err
        }
    },


    /**
     * 结束协同任务
     * @param {*} param0 
     */
    async overTeamWork({ state , dispatch },{ id }) {
        try {
            const { code , data , message} = await shareAPI.disable(id)         
            if(code == 0){
                const viewer = state.views['myCreate']
                const query = viewer.query;
                dispatch('loadByQuery',{ viewerKey:'myCreate', reset: true, query, interfaceName:"getMyCreateList"})
            }else{
                throw message
            }
            return code == 0
        } catch (err) {
            throw err
        }
    },




     /**
     * 下一页
     * @param {*} param0 
     * @param {
      *  viewerKey: 操作的视图key
      *  reset: 翻页是否覆盖
      * } param1 
      */
     async doLoadNextPage({ commit, dispatch, state }, { viewerKey ,interfaceName }) {
         const viewer = state.views[viewerKey]
         if (viewer.more.status != 'more') {
             return
         }
 
         const paging = viewer.paging
         const query = viewer.query;

         if (query.pageNum < paging.pages + 1) {
            commit('updateMore', { viewerKey, moreStatus: 'loading' })
            query.pageNum++
            dispatch("loadByQuery", { viewerKey, reset: false, query ,interfaceName})
         }
     },
 
     /**
      * 上一页
      * @param {*} param0 
      * @param {
      *  viewerKey: 操作的视图key
      *  reset: 翻页是否覆盖
      * } param1 
      */
     async doLoadPrevPage({ commit, dispatch }, { viewerKey, reset = false ,interfaceName }) {
         const viewer = state.views[viewerKey]
         const query = viewer.query

         if (query.pageNum > 0) {
            query.pageNum--
            dispatch("loadByQuery", { viewerKey, reset, query ,interfaceName })
         }
     },



    /**
      * 合并
      * @param {*} query 
     */
    async mergeFile({ commit, dispatch }, { query }) {
        try {
            const { code , data , message} = await teamWorkAPI.mergeFile(query)         
            if(code != 0){
                throw message
            }
            return code == 0
        } catch (err) {
            throw err
        }
    },

  

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}