import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//权限控制
import "./router/permission.js"
import 'normalize.css/normalize.css'
//element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
//粘贴板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//自定义扩展
import YhMessage from '@/plugin/message.plugin.js'
Vue.use(YhMessage)

//自定义组件
import YhComponents from './components/index.js';
Vue.use(YhComponents);

//自定义Filter
import YhFilters from '@lk/filter'
Vue.use(YhFilters)

//ajax
import * as ajax from '@/utils/http/index.js'
Vue.prototype.$ajax = ajax

//EventBus
Vue.prototype.$ebus = new Vue()

import *as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 上传
import uploader from 'vue-simple-uploader'
Vue.use(uploader)

//全局样式
import '@/styles/index.less'

import '@/assets/font/iconfont.css'

import './assets/icons' // icon

Vue.config.productionTip = false
import fileRouter  from './router/fileRouter.js'

// 引入本地存储
import { Cache } from '@lk/cache'
Vue.prototype.$mapData = Cache
// 区分长城版本和普通版本
Vue.prototype.$isGreadWallVersion = true 
Vue.prototype.$isShowOrg = true //是否展示组织，true为展示，false为隐藏
Vue.prototype.$isRunInsert = fileRouter.isRunInsert //是否嵌入运行（嵌入链控3.0）

var vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
export default vm