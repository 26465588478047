<template>
    <div class="menu-wrapper">
        <template v-for="(datas,ind) in leftMenu">
            <el-submenu :index="filterPath(datas.path,ind)" v-if="datas.children.length > 0" :key="datas.name">
                <template slot="title">
                    <svg-icon :icon-class="datas.icon" />
                    <span slot="title" :class="{'el-menu--display':isCollapse && first}">{{datas.name}}</span>
                </template>
                <template v-for="(childs, cindex) in datas.children">

                    <el-menu-item :index="filterPath(childs.path,cindex)" :key="cindex"  v-if="childs.children.length==0" @click="open(childs)">
                        <svg-icon :icon-class="childs.icon" />
                        <span slot="title">{{childs.label}}</span>
                    </el-menu-item>

                    <left-item  v-else :leftMenu="[childs]" :key="cindex"></left-item>

                </template>
            </el-submenu>

            <el-menu-item :index="filterPath(datas.path,ind)" :key="datas.name" v-else @click="open(datas)">
                <svg-icon :icon-class="datas.icon" />
                <span slot="title">{{datas.name}}</span>
            </el-menu-item>
            
        </template>
    </div>
</template>
<script>
export default {
    name:'LeftItem',
    props: {
        leftMenu: {
            type: Array
        },
        first: {
            type: Boolean,
            default: false
        },
        isCollapse: {
            type: Boolean
        }
    },
    data(){
        return{

        }
    },
    created(){

    },
    methods:{
        filterPath(path, index) {
            return path == null ? index + "" : path;
        },
        // 点击菜单 跳转页面
        open(item) {
            if (item.path){
                this.$router.push({
                    path: item.path,
                    query: item.query
                });

            } else {
                this.$router.replace('/manager/404')
            }
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-menu {
    span {
        margin-left: 10px;
    }
}
/deep/ .el-submenu__title{
    span {
        margin-left: 10px !important;
    }
    .el-icon-arrow-right {
        display: none;
    }
}
.el-menu-item {
    span {
        margin-left: 10px !important;
    }
}
.el-menu--display {
    display: none;
}
</style>