<template>
	<div  id="global-uploader" :class="{'global-uploader-single': !global,'ifram-bottom':$isRunInsert}">
		<uploader
		:id="beforeUpload?'beforeUpload':''"
		ref='uploader'
		:autoStart="false"
		:options="options"
		:file-status-text="statusText"
		class="uploader-example"
		@file-complete="fileComplete"
		@complete="complete"
		@file-success="fileSuccess"
		@files-added="filesAdded">
			<uploader-unsupport></uploader-unsupport>
			<uploader-drop style="display:none"  >
				<p >将文件拖放到此处以上传</p>
				<uploader-btn ref='uploadBtn' :attrs='attrsList'>选择文件</uploader-btn>
				<!-- <uploader-btn :attrs="attrs" ref='uploadImgBtn'>选择图片</uploader-btn> -->
				<uploader-btn :directory="true" ref='uploadFolderBtn'>选择文件夹</uploader-btn>
			</uploader-drop>
			<uploader-list v-if="panelShow">
                <div class="file-panel"  slot-scope="props" :class="{'collapse': !collapse}">
                    <div class="file-title">
                        <h2>文件列表</h2>
                        <div class="operate">
                            <el-button @click="collapse = !collapse" type="text" :title="!collapse ? '展开':'折叠' ">
                                <i  :class="collapse ? 'el-icon-minus': 'el-icon-minus'"></i>
                            </el-button>
                            <el-button @click="close" type="text" title="关闭">
                                <i class="el-icon-close"></i>
                            </el-button>
                        </div>
                    </div>

                    <ul class="file-list">
                        <li class="file-item" :class="`file-${file.id}`" v-for="file in props.fileList" :key="file.id">
                            <uploader-file :class="'file_' + file.id" ref="files" :file="file" :list="true"></uploader-file>
                        </li>
                        <div class="no-file" v-if="!props.fileList.length"><i class="nucfont inuc-empty-file"></i> 暂无待上传文件</div>
                    </ul>
					<br />
					<div  v-show="collapse" class="btn-box" style="padding:10px 10px">
						<el-button size='small' @click="allStart()" :disabled="disabled">全部开始</el-button>
						<el-button size='small' @click="allStop()" style="margin-left: 4px">全部暂停</el-button>
						<el-button size='small' @click="allRemove()" style="margin-left: 4px">全部移除</el-button>
					</div>
                </div>
            </uploader-list>
			
		</uploader>
		
	</div>
</template>

<script>
import SparkMD5 from "spark-md5";
import { Store } from '../../utils/LocalCache'
import { fileAPI } from 'liankong-ui-api'
export default {
	props: {
		 global: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			skip: false,
			panelShow:false,
			showBtn:false,
			finish:false,
			options: {
				target: "/api/v1/document/chunkUpload",
				// 开启服务端分片校验功能
				testChunks: true,
				query: (file, chunk) => {
					return {
						// 可以针对于每个file对象设置自定义的params
						uploadKey:file.uploadKey,
						folderId:file.folderId,
						docType:file.docType,
						share:file.attrs.share,
						isPublic:file.attrs.isPublic,
						orgId:file.attrs.orgId,
						tag:file.attrs.tag,
						miji:file.attrs.miji,
					}
				},
				parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
				return parsedTimeRemaining
					.replace(/\syears?/, "年")
					.replace(/\days?/, "天")
					.replace(/\shours?/, "小时")
					.replace(/\sminutes?/, "分钟")
					.replace(/\sseconds?/, "秒");
				},
				// 服务器分片校验函数
				checkChunkUploadedByResponse: (chunk, message) => {
					const result = JSON.parse(message);
					if(!result.success){
						if(this.finish){
							this.allRemove(true,result.data)
						}
						
						return false
					}else{
						if (result.data.skipUpload) {
							this.skip = true;
							if(this.viewerKey == 'org' || this.viewerKey == 'groupDoc') {
								this.$ebus.$emit('refreshOrgDocList')
							} else {
								this.$store.dispatch("doc/loadChildren", {
									vkey: this.viewerKey,
									reset: true,
									pageSize:10
								})
							}
							return true;
						}
						return (result.data.uploaded || []).indexOf(chunk.offset + 1) >= 0;
					}
					
				},
				headers: {
					// 在header中添加的验证，请根据实际业务来
					// "Access-Token": Store.get('access_token'),
					"Authorization": `Bearer ${Store.get('access_token')}`,
				},
			},
			attrs: {
				accept: "image/*",
			},
			attrsList:{accept: [".doc", ".docx", ".dot", ".dotx", ".xls", ".xlsx", ".xlt", ".xltx", ".csv", ".ppt", ".pptx", ".pot", ".potx", ".pdf", ".txt", ".xml", ".htm", ".html", ".jpg", ".png", ".bmp", ".gif", ".tiff", ".ofd", ".jpeg", ".wps",".mp4",".mp3",'.zip']},
			statusText: {
				success: "上传成功",
				error: "上传出错了",
				uploading: "上传中...",
				paused: "暂停中...",
				waiting: "等待中...",
				cmd5: "计算文件MD5中...",
			},
			fileList: [],
			disabled: true,
			params:{},
			collapse: false,
			viewerKey:'',
			uploadInfos:{},
			beforeUpload:true
		};
	},
	watch: {
		fileList(o, n) {
			this.disabled = false;
		},
		
	},
	computed: {
		viewer(){
			return this.$store.state.doc.viewer[this.viewerKey]
		},
		miji(){
			return this.$store.state.dict.dicts.miji
		}
	},
	methods: {
		fileSuccess(rootFile, file, response, chunk) {
			const result = JSON.parse(response);
			if (result.success && !this.skip) {
				fileAPI.mutipUploadMergeFiles(
					file.uploadKey
				).then(res => {
					if (res.code == 0) {
						if(this.viewerKey == 'org' || this.viewerKey == 'groupDoc') {
							this.$ebus.$emit('refreshOrgDocList')
						} else {
							this.$store.dispatch("doc/loadChildren", {
								vkey: this.viewerKey,
								reset: true,
								pageSize:10
							})
							this.$store.dispatch('login/getUserUsedDist')
						}
					} else {
						this.$message.error(res.message)
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			} else {
				this.$store.dispatch('login/getUserUsedDist')
				console.log("上传成功，不需要合并");
			}
			if (this.skip) {
				this.skip = false;
			}
		},
		fileComplete(rootFile) {
			// 一个根文件（文件夹）成功上传完成。
			// console.log("一个根文件（文件夹）成功上传完成。");
		},
		complete() {
			// 上传完毕。
		},
		filesAdded(file, fileList, event) {
			this.options.headers.Authorization =  `Bearer ${Store.get('access_token')}`,
			this.$store.commit('setUploadDot',true)
			let failList = []
			this.panelShow = true;
			file.forEach((e,index) => {
				if(this.attrsList.accept.indexOf(e.name.substring(e.name.lastIndexOf('.')).toLowerCase()) >-1){
					this.fileList.push(e);
				}else{
					failList.push(e.name)
					e.cancel()
				}
				if(file.length == (index+1)){
					if(failList.length != 0){
						this.$message.error(`${failList.join('、')}文件格式不支持`);
					}
				}
			});
		},
		setFileParams(file,isAutoUpload){
			 //文件信息
			file.folderId = this.viewer.current ? this.viewer.current.id : 0
			file.docType = this.viewer.docType
			if(this.viewerKey=='org'){
				//组织目录完成上传
				const myViewer = this.viewer;
				file.folderId = myViewer.current ? myViewer.current.id : 0;
				file.docType = this.uploadInfos.organizationId == undefined ? 1 : 2
				file.attrs = {
					miji: Number(this.params.miji),
					share: this.params.isShare,
					isPublic: this.params.isPublic
				}
			} else if(this.viewerKey == 'groupDoc') {
				// 群组目录完成上传
				const myViewer = this.viewer;
				file.folderId = myViewer.current ? myViewer.current.id : 0;
				file.docType =  3;
				file.attrs = {}
			} else{
				//个人目录无限制
				file.attrs = {
					share: this.params.isShare,
					isPublic: this.params.isPublic
				}

				//添加标签信息
				file.attrs["tag"] = this.params.fileTags.join(',')
			}
			if(this.viewerKey == 'org' || this.viewerKey == 'groupDoc') {
				file.orgId = this.uploadInfos.id;
			}
			fileAPI.uploadTaskKey(file).then(res => {
				if(res.code == 0){
					file.uploadKey = res.data;
					this.computeMD5(file,isAutoUpload);
				}else{
					this.$message.error(file.name + res.message);
					file.cancel()
					this.fileList.splice(this.fileList.indexOf(file), 1);
				}
			})
	
			
		},
		computeMD5(file,isAutoUpload) {
			let fileReader = new FileReader();
			let time = new Date().getTime();
			let blobSlice =
				File.prototype.slice ||
				File.prototype.mozSlice ||
				File.prototype.webkitSlice;
			let currentChunk = 0;
			const chunkSize = 1024 * 1024;
			let chunks = Math.ceil(file.size / chunkSize);
			let spark = new SparkMD5.ArrayBuffer();
			// 文件状态设为"计算MD5"
			file.cmd5 = true; //文件状态为“计算md5...”
			file.pause();
			loadNext();
			fileReader.onload = (e) => {
				spark.append(e.target.result);
				if (currentChunk < chunks) {
					currentChunk++;
					loadNext();
					// 实时展示MD5的计算进度
					// console.log(
					// 	`第${currentChunk}分片解析完成, 开始第${
					// 	currentChunk + 1
					// 	} / ${chunks}分片解析`
					// );
				} else {
					let md5 = spark.end();
					// console.log(
					// 	`MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
					// 	file.size
					// 	} 用时：${new Date().getTime() - time} ms`
					// );
					spark.destroy(); //释放缓存
					
					file.uniqueIdentifier = md5; //将文件md5赋值给文件唯一标识
					file.cmd5 = false; //取消计算md5状态
					// this.options.headers['X-Upload-Key'] = file.uploadKey;
					// console.log(this.options.query)
					if(isAutoUpload){
						this.finish = true;
						file.resume(); //开始上传
					}
					
				}
			};
			fileReader.onerror = function () {
				this.error(`文件${file.name}读取出错，请检查该文件`);
				file.cancel();
			};
			function loadNext() {
				if(file.file.raw){
					file.file = file.file.raw
				}
				let start = currentChunk * chunkSize;
				let end =
				start + chunkSize >= file.size ? file.size : start + chunkSize;
				fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
			}
		

			
			
		},
		allStart() {
			this.beforeUpload = false;
			this.fileList.map((e) => {
				if (e.paused) {
					this.setFileParams(e,true)
					// e.resume();
				}	
			});
		},
		allStop() {
			this.fileList.map((e) => {
				if (!e.paused) {
					e.pause();
				}
			});
		},
		allRemove(flag,msg) {
			this.fileList.map((e) => {
				e.cancel();
			});
			this.fileList = [];
			if(flag){
				this.$message.error(msg)
			}
			this.finish = false
		},
		close(){
      		this.panelShow = false
		},
	},
	mounted() {
		this.$ebus.$on('openUploader', query => {
			this.collapse = true;
			this.panelShow = true;
			this.beforeUpload = true;
			this.params = query.params;
			this.viewerKey = query.viewerKey?query.viewerKey:'my';
			this.uploadInfos = query.uploadInfos?query.uploadInfos:{};
			if(query.type == 'file'){
				// 选择文件的操作窗口
				if (this.$refs.uploadBtn) { 
					this.$refs.uploadBtn.$el.click()
				}
			}else if(query.type == 'img'){
				// 选择文件的操作窗口
				if (this.$refs.uploadImgBtn) { 
					this.$refs.uploadImgBtn.$el.click()
				}
			}else if(query.type == 'folder'){
				// 选择文件的操作窗口
				if (this.$refs.uploadFolderBtn && query.file == undefined) { 
					this.$refs.uploadFolderBtn.$el.click()
				}else{
					if(query.file.length!=0){
						query.file.map(file =>{
							if(this.$refs.uploader){
								this.$refs.uploader.uploader.addFile(file)
							}
						})
					}
					
				}
			}else if(query.type == 'start'){
				this.showBtn = true;
				this.allStart()
			}
		});
		this.$ebus.$on('showUploadProgress', query => {
			this.collapse = true;
			this.panelShow = true;
		});
  	},	
};
</script>

<style  lang="less" scoped>
/deep/.uploader-file-size{
	text-indent:0!important;
}
.uploader-example {
  /* padding: 15px; */
  margin: 0px auto 0;
  font-size: 12px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  border-radius: 7px;
}

#global-uploader {
  &:not(.global-uploader-single) {
    position: fixed;
    z-index:3000!important;
    right: 15px;
    bottom: 5px;
    box-sizing: border-box;
  }
  .uploader-app {
    width: 520px;
  }
  .file-panel {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    .file-title {
      display: flex;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;
      .operate {
		margin-top: 5px;
        flex: 1;
        text-align: right;
        i {
          font-size: 18px;
        }
      }
    }
    .file-list {
      position: relative;
      height: 260px;
	  width: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #fff;
      transition: all 0.3s;
	  list-style-type: none;
	  padding: 0px;
	  margin: 0;
      .file-item {
        background-color: #fff;
		margin: 0 15px;
      }
    }
    &.collapse {
      .file-title {
        background-color: #e7ecf2;
      }
      .file-list {
        height: 0;
      }
    }
  }
  .no-file {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }
  .uploader-file {
    &.md5 {
      .uploader-file-resume {
        display: none;
      }
    }
  }
  .uploader-file-icon {
    &:before {
      content: '' !important;
    }
    // &[icon='image'] {
    //   background: url(./images/image-icon.png);
    // }
    // &[icon=audio] {
    //   background: url(./images/audio-icon.png);
    //   background-size: contain;
    // }
    // &[icon='video'] {
    //   background: url(./images/video-icon.png);
    // }
    // &[icon='document'] {
    //   background: url(./images/text-icon.png);
    // }
    // &[icon=unknown] {
    //   background: url(./images/zip.png) no-repeat center;
    //   background-size: contain;
    // }
  }
  .uploader-file-actions > span {
    margin-right: 6px;
  }
  .custom-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
.ifram-bottom {
	&:not(.global-uploader-single) {
    bottom: 50px !important;
  }
}
/* 隐藏上传按钮 */
#global-uploader-btn {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

/deep/#beforeUpload{
	.uploader-file-resume{
		display: none;
	}
}
</style>
