/**
 * Home首页、公共Header中的数据管理
 */

const state = {
  //消息，包含了头部消息显示的数量、和简要信息
  message: {
    total: 0,
    data: []
  }
}

const mutations = {
  //设置消息
  setMessage(state, messages = []){
    state.message.total = messages.length;
    state.message.data = messages;
  },
}

const actions = {
  async loadMessage(){

  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}