/**
 * 用户API
 */
import * as http from '../utils/http/index.js';
import { Store } from '@/utils/LocalCache.js'

/**
 * 获取子级目录
 * @param {*} parentId 
 */
export function getChildren(parentId){
  return http.get(`/document/${parentId}/ls`)
}

/**
 * 创建目录
 * @param {*} parentId 
 * @param {*} name 
 * @param {*} share
 */
export function createFolder(parentId, name, share){
  return http.post(`/document/${parentId}?name=${name}&share=${share}`)
}

/**
 * 重命名
 */
export function rename(documentId, name){
  return http.post(`/document/${documentId}/rename?newName=${name}`)
}

/**
 * 移除文档
 * @param {*} documentId 
 */
export function removeDocument(documentId){
  return http.del(`/document/${documentId}`)
}

/**
 * 创建快捷目录
 * @param {*} parentId 
 */
export function createQuickFolder(parentId){
  return http.post(`/document/quick?id=${parentId}`)
}

/**
 * 获取快捷目录
 */
export function getQuickFolder(){
  return http.get(`/document/quick`)
}

/**
 * 移除快捷方式
 * @param {*} parentId 
 */
export function removeQuickFolder(parentId){
  return http.del(`/document/quick?id=${parentId}`)
}

/**
 * 下载文件
 * @param {*} data 
 */
export function download(data){
  const u = Store.get("access_token")
  const url = `/document/download?documentId=${data.documentId}&types=${data.types}&encryptPwd=${data.encryptPwd}&access_token=${u}`
  // console.log("url",`//${window.origin}${http.baseURL}`)
  window.location.href=`${window.origin}${http.baseURL}` + url
}

/**
 * 阅读文档
 * @param {*} documentId 
 */
export function reader(documentId){
  return http.get(`/document/${documentId}/reader`)
}

/**
 * 文档搜索
 * @param {*} data 
 */
export function search(data){
  return http.post(`/document/search/documentCenter/search`, data)
}

/**
 * 文件搜索
 * @param {*} data 
 */
export function list(data){
  return http.post(`/document/search/documentCenter/search`, data)
}

/**
 * 共享文档搜索
 * @param {*} data 
 */
export function shareList(data){
  return http.post(`/document/user/share`, data)
}

/**
 * 共享资源申请
 * @param {*} data 
 */
export function shareResourceRequest(data){
  return http.post(`/share/request/document/apply`, data)
}

/**
 * 转换统计信息
 */
export function convertStatistics(){
  return http.get(`/document/convert/statistics`)
}

/**
 * 转换列表
 * @param {*} data 
 */
export function convertList(data){
  return http.post(`/document/convert/list`, data)
}

/**
 * 触发转换任务
 * @param {*} data 
 */
export function convertFile(data){
  return http.get(`/document/convert/${data.id}`)
}

/**
 * 阅读日志
 * @param {*} data 
 */
export function readLog(data){
  return http.post(`/document/reader/log`, data)
}

/**
 * 获取文件授权链
 * @param {*} document 
 */
export function fileChain(document){
  return http.get(`/share/chain/document/${document.id}`)
}

/**
 * 获取我文档的统计信息
 */
export function statistics(){
  return http.get(`/document/user/statistics`)
}

/**
 * 根据文件ID获取文件列表
 * @param {*} ids 
 */
export function getDocumentByIds(ids){
  return http.post(`/document/documentList`, ids)
}