<template>
    <div>
        <ReadFile ref='readFile'></ReadFile>
    </div>
</template>
<script>
import ReadFile from "@/components/readFile.vue"
import { shareAPI } from 'liankong-ui-api' 
import { Store } from '@/utils/LocalCache.js'
export default {
    components: {
        ReadFile
    },
    mounted(){
        this.$ebus.$on('publicReader', query => {
            if(query.suffix && query.suffix == 'zip'){
                this.$message.error('zip格式不支持阅读！')
                return
            }
            let suffixs = 'ofd'
            if(query.suffix && query.suffix == 'mp4'){
                suffixs = 'mp4'
            }else if(query.suffix && query.suffix == 'mp3'){
                suffixs = 'mp3'
            }else{
                suffixs = 'ofd'
            }
            this.getReaderUrl(query.shareId,query.id,query.documentId,query.name,suffixs,query.targetResourceId,query.model)
		});
    },
    methods:{
        getReaderUrl(shareId,id,documentId,name,suffix,targetResourceId,model){
            shareAPI.getReaderUrl(shareId,id,documentId,name,suffix,targetResourceId,model).then(({ code, data, message }) => {
                if (code == 0) {
                    //获取到阅读地址，跳转阅读服务
                    if(Store.get("myPreference")){
                        if(Store.get("myPreference").reader_model == '1'){
                            window.open(this.$options.filters['readerUrl'](data.url));
                        }else{
                            this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url))
                        }
                    }else{
                        this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url))
                    }
                } else if(code == 1) {
                    this.$message.error(message)
                } else{
                    this.$message.error(message || '您不能查看当前文件') 
                }
            })
        }
        
    }
}
</script>
