<template>
  <div class="message-container">
      <div class="storage-box-all" v-if='isLogin'>
        <span>存储空间：</span>
        <el-progress  :stroke-width="10" :percentage="percentage*100" :format="format" ></el-progress>
      </div>
      <!-- 后台入口 -->
      <el-tooltip class="item" effect="dark" content="管理平台" placement="top" v-if='isManager && isLogin'>
        <span class="connection" @click="toManage"><i class="el-icon-setting"></i></span>
      </el-tooltip>
     
      <span class="connection" @click="toConnection"  v-if='isLogin'><i class="el-icon-connection"></i></span>
      <span @click="showUploadList"  v-if='isLogin'><el-badge :is-dot='uploadDot' class="dotItem"><img src="@/assets/img/upload.png" style="vertical-align:middle;margin-bottom:1px;" alt=""></el-badge></span>
      <el-dropdown trigger="click" v-show="username"  v-if='isLogin'>
        <el-badge :value="badgeNumber" :hidden="badgeNumber <= 0">
          <i class="icon el-icon-bell"></i>
        </el-badge>
        <el-dropdown-menu slot="dropdown">
          <div class="message-box">
            <!-- <el-tabs v-model="tabActiveName" :stretch="true">
              <el-tab-pane label="系统" name="system">
                <ul class="message-list">
                  <li class="message-item" v-for="item in msgList">
                    <div class="message-item-text">{{ item.title }}</div>
                    <div class="message-item-time">
                      {{ item.time }} - {{ item.user }}
                    </div>
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane label="@我" name="my"></el-tab-pane>
            </el-tabs> -->
            <h4>消息通知</h4>
            <div class="dl-list">
                <dl v-for="(item,index) in viewer.list" :key="index" @click="readMessageEvent(item)">
                    <dt>
                      <img src="@/assets/icon-message.png" alt="">
                    </dt>
                    <dd>
                        <div>
                            <span class="message-item-text">{{item.title}}</span>
                            <b v-if="item.state == '未读'"></b>
                        </div>
                        <p>{{item.content}}</p>
                    </dd>
                </dl>
            </div>
            <div class="page-box-btn">
                <el-pagination
                class="pagebox"
                prev-text="上一页"
                next-text="下一页"
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :current-page="formData.pageNum"
                :page-size="formData.pageSize"
                :total="total">
                </el-pagination>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dialog
      title="消息信息"
      :append-to-body="true"
      :close-on-click-modal="false" 
      :visible.sync="dialogVisible">
      <div class="read-dialog">
        <span>消息标题：{{readItemInfo.title}}</span>
        <p>消息详情：{{readItemInfo.content}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="readConfimEvent">确 定</el-button>
      </span>
    </el-dialog>

    <ConfereditDialog ref="conferEditPages"></ConfereditDialog>
    <ReadconferDialog ref="readConferDialog"></ReadconferDialog>
    <ApplyrecordDialog ref="applyRecordPage"></ApplyrecordDialog>
    <ShareDialog ref="shareDialog"></ShareDialog>
    <TeamWorkDetail ref='TeamWorkDetail'></TeamWorkDetail>
    <Scan ref='Scan'></Scan>

  </div>
</template>

<script>
import { msgAPI , shareAPI } from 'liankong-ui-api'
import ConfereditDialog from '../../views/liankong/components/ConferEditDialog.vue'
import ReadconferDialog from '../../views/liankong/components/ReadConferDialog.vue'
import ApplyrecordDialog from '../../views/liankong/components/ApplyRecordDialog.vue'
import ShareDialog from '../../views/liankong/components/ShareDialog.vue'
import TeamWorkDetail from '../../views/teamWork/components/Detail.vue'
import Scan from './Scan.vue'
import { Store } from '@lk/cache'
export default {
    components:{
      ConfereditDialog,//申请授权
      ReadconferDialog,//阅读授权
      ApplyrecordDialog,//审核通过
      ShareDialog,//共享给我
      TeamWorkDetail,//协同详情
      Scan//扫码投屏
    },
    data() {
        return {
            tabActiveName: "system",
            viewerKey: "ReadMsg",
            msg: {},
            hoverId: '',
            dialogVisible: false,
            readItemInfo: {},
            formData: {
              pageNum: 1,
              pageSize: 5
            }
        };
    },
    computed: {
      percentage(){
        return Number((this.dist/1048576).toFixed(2))/Number((this.storageSpace/1048576).toFixed(0))
      },
      username(){
        return this.$store.state.login.name
      },
      isLogin(){
        if(  this.$store.state.login.id && Store.get('access_token')){
          return true
        }else{
          return false
        }
      },
      uploadDot(){
        return this.$store.state.uploadDot
      },
       isManager(){
        let roles = this.$store.state.login.roles;
        let arr = roles.filter( m => m.authority == 'ROLE_ADMIN')
        return arr.length == 0 ? false:true
      },
        //消息数量
        msgtotal() {
            return this.$store.state["home"].message.total;
        },
        msgList() {
            return this.$store.state["message"].msgs.zhan;
        },
        // 消息列表
        viewer(){
           return this.$store.state.msg.views[this.viewerKey];
        },
        // 未读消息
        badgeNumber(){
          return this.$store.state.msg.views.UnReadMsg.paging.total;
        },
        total(){
          return this.$store.state.msg.views[this.viewerKey].paging.total;
        },
        dist(){
          return this.$store.state.login.dist;
        },
        storageSpace(){
          return this.$store.state.login.userInfo.storageSpace;
        }
    },
    watch: {},
    methods: {
        format() {
            return `${Number((this.dist/1048576).toFixed(2))} MB / ${(this.storageSpace/1048576).toFixed(0) } MB `;
        },
        toManage(){
          this.$router.push('/manager')
        },
        // 扫码投屏
        toConnection(){
          this.$refs['Scan'].dialogVisible = true;
        },
        showUploadList(){
           this.$ebus.$emit('showUploadProgress')
        },
        loadBadgeNum(){
          this.$store.dispatch('msg/loadMeUnReadMsg', {
            reset: true
          })
        },
        getMessageList(){
            // this.viewer.query.pageNum=this.viewer.query.pageNum +1;
            this.viewer.query.pageNum = this.formData.pageNum;
            this.viewer.query.pageSize = this.formData.pageSize;
            this.$store.dispatch('msg/loadMeReadMsg', {
                reset: true,
                pcNoreset: true
            })
        },
        // 阅读
        readMessageEvent(item) {
          if(item.isInteraction == 1){
            this.$store.dispatch('msg/updateMyMsgState', item.messageId)
            if(item.type == 4){
              // 共享给我
              shareAPI.detailById(item.themeId).then(res => {
                if(res.code == 0){
                  this.$refs.shareDialog.dialogVisible = true;
                  this.$nextTick(()=>{
                    this.$refs.shareDialog.onLoadInit(res.data,true);
                  })
                }else{
                  this.$massage.error(res.message)
                }
              })
              
            }if(item.type == 5){
              let obj ={
                id:item.themeId
              }
              this.$refs['TeamWorkDetail'].show(obj,'myPending')
            }else{
              msgAPI.getDetailByMessageId(item.themeId).then(res => {
                if(res.code == 0){
                  //缓存数据
                  if(item.type == 3){
                    // 审批通过
                    let {createDate,disposeDate,description,resources,shareName,type,state,id,shareId} = res.data
                    this.$mapData.put('keepMyApplyDetail',{
                      createDate, disposeDate, description, resources,shareName,type,state,id,shareId
                    })

                    this.$refs.applyRecordPage.dialogVisible = true;
                    this.$nextTick(()=>{
                      this.$refs.applyRecordPage.onRecordInit()
                    })
                  }else if( item.type == 2){
                    // 申请资源授权 
                    this.$mapData.put('MyConferEdit',res.data)
                    this.$refs.conferEditPages.dialogVisible = true;
                    this.$nextTick(()=>{
                      this.$refs.conferEditPages.onInitEdit()
                    })
                  }else if(item.type == 1){
                    // 共享阅读授权
                    this.$mapData.put('MyConferDateil',res.data)
                    this.$refs.readConferDialog.dialogVisible = true;
                    this.$nextTick(()=>{
                      this.$refs.readConferDialog.onLoadReadInfo()
                    })
                  }
                }else{
                  this.$massage.error(res.message)
                }
              })
            }
          }else{
            this.dialogVisible = true;
            this.readItemInfo = item;
          }
          
        },
        readConfimEvent() {
          this.$store.dispatch('msg/updateMyMsgState', this.readItemInfo.messageId)
          this.dialogVisible = false;
        },
        handleCurrentChange(num){
          this.viewer.query.pageNum = num;
          this.viewer.query.pageSize = this.formData.pageSize;
          this.$store.dispatch('msg/loadMeReadMsg', {
              reset: this.viewer.query.pageNum == 1 ? true : false,
              pcNoreset: true
          })
        }
    },
    created() { },
    mounted() {
      this.loadBadgeNum()
      this.getMessageList()
    },
  };
</script>

<style lang='less' scoped>
  .icon{
    font-size: 18px;
    padding: 0 15px;
    cursor: pointer;
  }
  .dotItem{
    cursor: pointer;
  }
  .message-container{
    display:flex;
    align-items: center;
     .storage-box-all{
        padding: 10px;
        margin: 10px;
        display: flex;
        font-size: 14px;
        /deep/ .el-progress{
            width: 110px;
            .el-progress__text{
                font-size: 12px!important;
                color: #999;
                width: 100%;
                margin-top: 5px;
                margin-left: 0;
                text-align: left;
            }
            .el-progress-bar {
                padding-right: 0;
                width: 100%;
                margin-right: 0;
                box-sizing: border-box;
            }
        }
    }
  }
  
  .message-box {
    min-width: 350px;
    padding: 5px 15px 10px 15px;
    

    .message-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .message-item {
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
          color: rgb(156, 156, 156);
        }

        .message-item-text {
          line-height: 22px;
          max-width: 350px;
          font-size: 16px;
        }

        .message-item-time {
          color: gray;
          font-size: 12px;
        }
      }
    }
    h4 {
      padding: 0px;
      margin: 0px;
      font-weight: 500;
    }
    .dl-list {
        display: flex;
        flex-direction: column;
        dl {
          display: flex;
          align-items: center;
          padding: 0px;
          margin: 15px 0 0 0;
          cursor: pointer;
          dt {
            width: 32px;
            height: 32px;
            padding: 0;
            margin: 0;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          dd {
              flex: 1;
              padding: 0;
              margin: 0 0 0 10px;
              div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                span {
                  font-size: 14px;
                  padding-right: 25px;
                }
                .message-item-text {
                  display: block;
                  line-height: 22px;
                  max-width: 350px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .read-btns {
                  margin: 2px 22px 0 15px;
                  padding: 0px 15px;
                }
                b {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: red;
                    position: absolute;
                    top: 7px;
                    right: 10px;
                }
              }
              p {
                  padding: 0;
                  margin: 0;
                  font-size: 13px;
                  color: #666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  max-width: 450px;
              }
          }
        }
    }
  }
  .el-dropdown{
     /deep/ .el-badge {
      .el-badge__content {
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 10px;
        padding: 0px;
      }
      .el-badge__content.is-fixed {
        right: 20px;
      }
    }
  }
 
  .read-dialog {
    span {
      color: #333;
    }
    p {
      color: #666;
    }
  }
  .page-box-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .connection{
    margin-right: 20px;
    font-size: 18px;
    vertical-align: middle;
    cursor: pointer;
  }

</style>