const  isRunInsert  = false;//是否嵌入运行（嵌入链控3.0）
let directory = [{
        icon: 'el-icon-star-off',
        name: '我的收藏',
        path: '/file/favorite',
        children: []
    }, {
        icon: 'el-icon-user', // 与我共享页面
        name: '他人共享',
        path: '/file/othershare',
        children: []
    }, {
        icon: 'el-icon-document',
        name: '我的文档',
        path: '/file/mydoc',
        children: [],
        classNames: 'boxs3'
    },  {
        icon: 'el-icon-folder',
        name: '公司文档',
        path: '/file/orgdoc',
        children: []
    }, {
        icon: 'el-icon-folder',
        name: '群组文档',
        path: '/file/groupdoc',
        children: []
    }
    // {
    //     icon: 'el-icon-view',
    //     name: '我的关注',
    //     path: '/file/attention',
    //     children: []
    // }, 
    // {
    //     icon: 'el-icon-menu',
    //     name: '文档分类',
    //     path: '/document/types', // 不需要跳转页面
    //     children: [{
    //         icon: 'el-icon-s-grid',
    //         name: '固定分类',
    //         path: '/file/doctype',
    //         children: [],
    //         classNames: 'boxs1'
    //     }, {
    //         icon: 'el-icon-s-grid',
    //         name: '我的标签',
    //         path: '/file/tagtype',
    //         children: [],
    //         classNames: 'boxs2'
    //     }]
    // }
]
if(isRunInsert){
    directory = [
        {
            icon: 'el-icon-document',
            name: '我的文档',
            path: '/file/mydoc',
            children: [],
            classNames: 'boxs3'
        }, {
            icon: 'el-icon-folder-opened',
            name: '组织文档',
            path: '/file/orgdoc',
            children: [{

            }]
        }
    ]
}

export default {
    directory : directory,
    isRunInsert
}
