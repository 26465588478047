/**
 * 大小、长度处理过滤器
 */
export function byteSize(value){
  if(!value || value < 0) return '-'

  if (value === 0) return '0 B';
  var k = 1024,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(value) / Math.log(k));

 return (value / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}