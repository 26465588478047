/**
 * 分享购物车模块
 * 主要完成全局的文档添加管理
 */
import { shareAPI, indexAPI } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'
import { MapData } from '@lk/cache'

//分享视图key
const Viewer_Share_Key = "share"
    //请求授权视图key
const Viewer_Request_Key = "request"

const BaseCartViewer = {
    //文档列表
    docList: [],

    //选择文件数统计
    counts: {
        folder: 0,
        file: 0
    },

    //数据id缓存
    docIdMap: {},
    note:''//其它信息存储，比如申请理由
}

/**
 * 判断文档列表中是否存在文档
 * @param {*} doc 
 */
/* BaseCartViewer.existDocId  = function(doc){
  for(let i=0; i < this.docList.length; i++){
    const item = this.docList[i]
    console.log(item.id, doc.id, item.id == doc.id)
    if(item.id == doc.id){
      return item
    }
  }
  return null
} */

const state = {

    //购物车视图集合
    views: {
        //文档勾选分享
        [Viewer_Share_Key]: deepCopy(BaseCartViewer),

        //请求授权勾选
        [Viewer_Request_Key]: deepCopy(BaseCartViewer),
    },

    //分享信息
    shareInfo: {
        name: null,
        description: null,
        expireStartTime: null, //开始时间
        expireEndTime: null, //过期时间
        accessType: 'PUBLIC', //访问类型，默认公开访问
    },
    //脱敏数据
    envelops: [],

    //授权目标
    targets: {},

    //授权权限
    permissions: {
        readPageType: 0,
        copy: 0,
        download: 0,
        edit: 0,
        print: 0,
        share: 0,
        water: 0,
        shadeAreaId: 0,
        shadeKeyword: '',
        archivableFlag:0,//是否归档
        archivable:1//归档的值
    }
}

const getters = {
    /**
     * 可用权限列表
     */
    permissions: {
        readPageType: 'readPageType', //阅读页面类型, 0全部，1指定页面
        readPage: 'readPage', //阅读页面页码
        editPageType: 'editPageType', //编辑页面类型
        editPage: 'editPage', //编辑页面页码
        share: 'share', //可分享
        copy: 'copy', //可复制
        print: 'print', //可打印
        printCount: 'printCount', //可打印次数
        edit: 'edit', //可编辑
        water: 'water', //水印
        download: 'download', //可下载
        expireStartTime: 'expireStartTime', //开始时间
        expireEndTime: 'expireEndTime', //结束时间
        archivableFlag:'archivableFlag',//是否归档
        archivable:'archivable'//归档的值
    },
    //脱敏数据
    envelops: [],

    /**
     * 访问类型
     */
    accessTypes: {
        public: "PUBLIC", //公开访问
        auth: "AUTH", //授权访问
        reject: "REJECT" //拒绝访问
    },

}



const mutations = {

    /**
     * 创建购物车视图
     * @param {*} state 
     * @param {*} viewerKey 
     * @param {*} defaultFiles 
     */
    createViewer(state, { viewerKey, defaultFiles }) {
        //检查是否存在
        if (!state.viewer.hasOwnProperty(viewerKey)) {
            //创建视图
            const newViewer = deepCopy(BaseCartViewer)

            if (defaultFiles) {
                newViewer.docList = defaultFiles
                defaultFiles.map(docInfo => {
                    docInfo.type == 0 ? newViewer.counts.folder++ : newViewer.file.folder++
                })

                this._vm.$set(state.views, viewerKey, newViewer)
            }
        }
    },

    /**
     * 销毁数据视图
     * @param {*} state 
     * @param {*} viewerKey 
     */
    destroyViewer(state, viewerKey) {
        console.log("销毁数据视图:", viewerKey)
        delete state.views[viewerKey]
       
    },

    //重置分享数据
    resetShare(state) {
        const viewer = state.views[Viewer_Share_Key]
        viewer.docList = []
        viewer.counts.folder = 0
        viewer.counts.file = 0
        viewer.docIdMap = {}

        state.targets = {}
        state.permissions = {
            readPageType: 0,
            copy: 0,
            download: 0,
            edit: 0,
            print: 0,
            share: 0,
            water: 0,
            shadeAreaId: 0,
            shadeKeyword: '',
            archivableFlag:0,//是否归档
            archivable:1//归档的值
        }
        state.envelops = []

        state.shareInfo = {
            name: null,
            description: null,
            expireStartTime: null,
            expireEndTime: null,
            accessType: 'PUBLIC',
        }
    },
    // PC创建分享逻辑重置部分数据
    resetPermissionShare(){
        state.targets = {}
        state.permissions = {
            readPageType: 0,
            copy: 0,
            download: 0,
            edit: 0,
            print: 0,
            share: 0,
            water: 0,
            shadeAreaId: 0,
            shadeKeyword: '',
            archivableFlag:0,//是否归档
            archivable:1//归档的值
        }
        state.envelops = []

        state.shareInfo.expireStartTime = null;
        state.shareInfo.expireEndTime = null;
        state.shareInfo.accessType = 'PUBLIC';
        state.shareInfo.description = null;
    },

    //重置请求授权数据
    resetRequest(state) {
        const viewer = state.views[Viewer_Request_Key]

        viewer.docList = []
        viewer.counts.folder = 0
        viewer.counts.file = 0
        viewer.docIdMap = {}
    },

    /**
     * 添加文档
     * @param {*} state 
     * @param {*} doc 
     */
    push(state, { viewerKey, doc }) {
         
        const viewer = state.views[viewerKey]
        
        const cacheKey = doc.id

        //判断是否已添加
        if (!viewer.docIdMap[cacheKey]) {
            viewer.docIdMap[cacheKey] = doc
            viewer.docList.push(doc)

            //计数
            if (doc.type == 0) {
                viewer.counts.folder += 1
            } else {
                viewer.counts.file += 1
            }
        }
    },

    /**
     * 移除文档
     * @param {*} state 
     * @param {*} doc 
     */
    remove(state, { viewerKey, doc }) {
        const viewer = state.views[viewerKey]
        const cacheKey = doc.id
            // 移除文档时置空
        state.envelops = [];

        //判断是否已添加
        if (viewer.docIdMap[cacheKey]) {
            for (let itemIndex = 0, len = viewer.docList.length; itemIndex < len; itemIndex++) {
                if (viewer.docList[itemIndex].id == cacheKey) {
                    viewer.docList.splice(itemIndex, 1)
                    delete viewer.docIdMap[cacheKey]

                    //文档变动，有可能会影响到阅读页码设置
                    state.permissions.readPageType = 0
                    delete state.permissions.readPage

                    //计数
                    if (doc.type == 0) {
                        viewer.counts.folder -= 1
                    } else {
                        viewer.counts.file -= 1
                    }

                    break
                }
            }
        }
    },

    /**
     * 设置权限
     * @param {*} state 
     * @param {
     *  name: 权限名称
     *  value: 权限值
     * } Object || List
     */
    updatePermission(state, permissions) {
        if (Array.isArray(permissions)) {
            permissions.map(p => {
                this._vm.$set(state.permissions, p.name, p.value)
            })
        } else if (permissions.name) {
            this._vm.$set(state.permissions, permissions.name, permissions.value)
        }
    },

    /**
     * 添加用户
     */
    addTarget(state, user) {
        const key = user.value

        if (!state.targets[key]) {
            this._vm.$set(state.targets, key, user)
        }
    },
    /**
     * 移除用户
     */
    removeTarget(state, user) {
        const key = user.value

        if (state.targets[key]) {
            this._vm.$set(state.targets, key, null)
            delete state.targets[key]
        }
    },

    /**
     * 更新申请文档的状态
     * @param {*} state 
     * @param {*} param1 
     */
    updateDocumentRequestState(state, { docList, requestState }) {
        const viewer = state.views[Viewer_Request_Key]

        docList.map(doc => {
            //获取缓存数据
            const cacheKey = doc.id
            const cacheItem = viewer.docIdMap[cacheKey]

            if (cacheItem) {
                this._vm.$set(cacheItem, 'requestState', requestState)
            }
        })
    }

}

const actions = {

    /**
     * 创建分享
     * @param {*} param0 
     */
    async createShare({ commit, state, rootState }, watemarks, editPages) {
        

        //权限值处理
        const shareInfo = state.shareInfo
        if(state.permissions.archivableFlag == 0){
            state.permissions.archivable = 0;
        }
        delete state.permissions.archivableFlag
        // state.permissions.water = watemarks
        //权限值处理
        shareInfo.permissions = Object.keys(state.permissions)
            .reduce((result, key) => {
                let value = state.permissions[key]

                if (key === 'readPage' && value) {
                    const nums = value.split(',')
                    value = nums.map(i => Number(i)).join(',')
                }

                result.push({
                    name: key,
                    value,
                })

                return result
            }, [])

        


        //资源数据
        const viewer = state.views[Viewer_Share_Key]
        shareInfo.resources = viewer.docList
            .map(doc => {
                return {
                    documentId: doc.id,
                    name: doc.name,
                    type: doc.type,
                    suffix: doc.suffix
                }
            })

        //授权目标数据
        shareInfo.targets = Object.keys(state.targets)
            .map(key => {
                const target = state.targets[key]

                return {
                    type: target.type,
                    value: target.value,
                    attrs: {
                        name: target.label
                    }
                }
            })

        //补充其他数据  
        if(shareInfo.name == null || shareInfo.name == ''){
            shareInfo.name = shareInfo.resources[0].name
            if (shareInfo.resources.length > 1) {
                shareInfo.name += `等${shareInfo.resources.length}个文件`
            }
        }
        

        //数据脱敏
        shareInfo.envelops = state.envelops;
        //创建分享
        const { code, data, message } = await shareAPI.create(shareInfo)

        if (code == 0) {

            shareInfo.ownerName = rootState.login.name
            shareInfo.urlShort = data
            shareInfo.createDate = new Date()
                //{@see YDocument.vue}组件中的v-for中:key 通过id来标记唯一
            shareInfo.resources.map(r => r.id = r.documentId)

            //创建成功后，方便下一步显示数据进行存储
            MapData.put("_create_shareInfo_", shareInfo)

            //重置
            commit("resetShare")
            return data
        } else {
            throw Error(message)
        }
    },

    /**
     * 授权申请
     * @param {
     *   description	请求描述			
     *   docId	文档ID列表			
     *   docName	文档拥有者名称			
     *   docType	文档类型			
     *   ownerId	文档拥有者id			
     *   ownerName	文档拥有者名称
     * } requestInfo 
     */
    async shareResourceRequest({ commit }, info) {
        const { code, data, message } = await indexAPI.shareResourceRequest(info)
        if (code == 0) {
            //重置
            commit("resetRequest")
        }
        return { code, data, message }
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}