const iconFolder = require('../assets/img/folder.png')
const iconDefault  = require('../assets/img/icon-def.png')
const iconUnknown = require('../assets/img/icon-unknown.png')
const iconImage = require('../assets/img/icon-picture.png')
const iconOFD = require('../assets/img/icon-ofd.png')
const iconAudio = require('../assets/img/icon-audio.png')
const iconWord = require('../assets/img/icon-word.png')
const iconPpt = require('../assets/img/icon-ppt.png')
const iconPdf = require('../assets/img/icon-pdf.png')
const iconExcl = require('../assets/img/icon-excl.png')
const suffixIconCache = {
  def: iconDefault,
  folder: iconFolder,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  docx: iconWord,

  mp3: iconAudio,
  mp4: iconAudio,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}
const mixin = {
  data(){
    return {
      mxData: {
        loading: null
      }
    }
  },
  filters:{
        /**
    * 图标
    */
    thumbIcon(doc){
    let iconKey = "unknown"

      if(doc.type == 0){
        iconKey = "folder"
      }else if(suffixIconCache.hasOwnProperty(doc.suffix)){
        iconKey = doc.suffix
      }
      return suffixIconCache[iconKey] 
      }, 
  },
  methods: {
    //加载内容
    loadingShow(title = "加载中.."){
      this.mxData.loading = this.$loading({
        lock: true,
        text: title,
        spinner: 'el-icon-loading',
        background: 'rgba(a, a, a, 0.6)'
      })
    },

    //停止加载
    loadingHide(){
      if(this.mxData.loading){
        this.mxData.loading.close()
      }
    }
  }
}

export default function install(Vue) {
  Vue.mixin(mixin)
}