import { logsAPI } from 'liankong-ui-api'

const state = {
  views: {
    //阅读记录
    read: {
      //查询器
      query: {
        pageNum: 1,
        pageSize: 20
      },

      //分页器
      paging: {
        total: 0,
        pages: 0
      },

      //加载指示器
      more: {
        status: 'more',
        contentText: {
          contentdown: '加载更多记录',
          contentrefresh: '记录加载中',
          contentnomore: '已加载全部记录'
        }
      },

      list: [],
    },
    apply: {
      //查询器
      query: {
        pageNum: 1,
        pageSize: 20
      },

      //分页器
      paging: {
        total: 0,
        pages: 0
      },

      //加载指示器
      more: {
        status: 'more',
        contentText: {
          contentdown: '加载更多记录',
          contentrefresh: '记录加载中',
          contentnomore: '已加载全部记录'
        }
      },

      list: [],
    }
  }
  
}

const getters = {}

const mutations = {
  /**
   * 更新查询
   * @param {*} state 
   * @param {*} payload 
   */
  updateQuery(state, {vkey, query}){
    if(query){
      const viewer = state.views[vkey]
      viewer.query = Object.assign(viewer.query, query)
    }
  },

  /**
   * 追加数据
   * @param {*} state 
   * @param {*} payload 
   */
  appendList(state, {vkey, reset = false, current, pages, data, total }){
    const viewer = state.views[vkey]

    viewer.query.pageNum = current
    viewer.paging.total = total
    viewer.paging.pages = pages

    //更多状态
    if (pages == 0 || current == pages) {
      viewer.more.status = 'noMore'
    } else {
      viewer.more.status = 'more'
    }

    viewer.list = reset ? data : viewer.list.concat(data)
  }
}

const actions = {
  
  /**
   * 加载阅读日志
   */
  async readLog({commit, state}, {vkey, reset = false, query}){
    const viewer = state.views[vkey]

    //是否有更多查询
    if(!reset && viewer.more.status === 'noMore'){
      return
    }

    if(reset){
      viewer.query.pageNum = 1
    }

    //更新查询条件
    commit('updateQuery', {vkey, query})

    //加载数据
    const {code, current, pages, size, total, data, message } = await logsAPI.readLog(viewer.query)
    
    if(code == 0){

      //数据处理
      data.map(i => i.name = i.documentName)

      commit("appendList", {
        vkey,
        reset,
        current,
        pages,
        size,
        total,
        data
      })
    }

    return code == 0
  },

  /**
   * 加载授权日志
   */
  async applyLog({commit, state}, {vkey, reset = false, query}){
    const viewer = state.views[vkey]

    //是否有更多查询
    if(!reset && viewer.more.status === 'noMore'){
      return
    }

    if(reset){
      viewer.query.pageNum = 1
    }

    //更新查询条件
    commit('updateQuery', {vkey, query})

    //加载数据
    const {code, current, pages, size, total, data, message } = await logsAPI.getLog(viewer.query)
    
    if(code == 0){

      //数据处理
      data.map(i => i.name = i.documentName)

      commit("appendList", {
        vkey,
        reset,
        current,
        pages,
        size,
        total,
        data
      })
    }

    return code == 0
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}