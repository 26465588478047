/**
 * 文档数据服务
 */
import { documentAPI } from 'liankong-ui-api'
import { deepCopy } from '@lk/utils/object.js'

/**
 * 基础列表页面视图模型
 */
const BaseListPageViewer = {
    loading: false,

    //查询器
    query: {
        pageNum: 1,
        pageSize: 20
    },

    //分页器
    paging: {
        total: 0,
        pages: 0
    },

    selection: false,
    selectionRows: [],

    root: null,
    current: null,
    docType: 0,

    showFiles: [],
    files: [],

    depth: 0, //视图深度
    paths: [],

    //加载更多
    more: {
        status: 'more',
        contentText: {
            contentdown: '加载更多文档',
            contentrefresh: '文档加载中',
            contentnomore: '没有更多文档了'
        }
    }
}

const state = {
    /**
     * 文档视图指示器
     * key: 文档分类的根，比如我的文档，某个组织文档
     */
    viewer: {
        my: deepCopy(BaseListPageViewer),
        org: deepCopy(BaseListPageViewer),
        newest: deepCopy(BaseListPageViewer),
        nodeMy: deepCopy(BaseListPageViewer),
        groupDoc: deepCopy(BaseListPageViewer), // 群组文档
        favorite: deepCopy(BaseListPageViewer),//我的收藏
        fileSelector: deepCopy(BaseListPageViewer),
        addShareFile: deepCopy(BaseListPageViewer),//添加资源
        teamDocList: deepCopy(BaseListPageViewer),//协同资源
        shareDocList:deepCopy(BaseListPageViewer),
    }
}

const getters = {

}

const mutations = {

    /**
     * 创建数据视图
     * @param {*} state 
     * @param {*} viewerKey 
     * @param {*} defaultFiles 默认文件列表
     */
    createViewer(state, { viewerKey, defaultFiles }) {

        //检查是否存在
        // if(!state.viewer.hasOwnProperty(viewerKey)){
        //创建数据视图
        const newViewer = deepCopy(BaseListPageViewer)

        if (defaultFiles) {
            //设置rootId，兼容文档模块（id）和分享资源模块（documentId）
            defaultFiles.map(f => f.rootId = f.documentId || f.id)

            newViewer.files = defaultFiles
            newViewer.showFiles = newViewer.files
        }

        this._vm.$set(state.viewer, viewerKey, newViewer)
            // }
    },

    /**
     * 销毁数据视图
     * @param {*} state 
     * @param {*} viewerKey 
     */
    destroyViewer(state, viewerKey) {
        delete state.viewer[viewerKey]
    },

    /**
     * 更新文档列表
     * @param {*} state 
     * @param {*} data 
     */
    setChildren(state, { vkey, reset = false, current, pages, data, total }) {
        const viewer = state.viewer[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        if (viewer.depth == 0) {
            //设置rootId
            data.map(f => f.rootId = f.id)

            //当前在根目录，深度为0
            viewer.files = reset ? data : viewer.files.concat(data)
            viewer.showFiles = viewer.files
        } else if (viewer.current) {

            //设置rootId
            data.map(f => f.rootId = viewer.current.rootId)

            //缓存数据
            viewer.current.children = reset ? data : viewer.current.children.concat(data)
            viewer.showFiles = viewer.current.children
        }

        viewer.loading = false
    },

    //进入目录
    addPaths(state, { vkey, folder }) {
        const viewer = state.viewer[vkey]

        if (!folder.children) {
            folder.children = []
        }

        viewer.current = folder
        viewer.showFiles = []
        viewer.depth++
        // 兼容我的收藏
        folder.name = folder.name ? folder.name : folder.documentName;
        viewer.paths.push(folder)
        viewer.loading = true
    },

    //更新更多状态
    updateMore(state, { vkey, moreStatus }) {
        const viewer = state.viewer[vkey]
        viewer.more.status = moreStatus
    },

    //更新视图加载状态
    updateLoading(state, { vkey, loading }) {
        const viewer = state.viewer[vkey]
        viewer.loading = loading
    },

    /**
     * 更新文档信息
     * @param {*} state 
     * @param {
     *  vkey: 视图
     *  docInfo: 为null则删除，有数据则更新
     * } param1 
     */
    updateDocInfo(state, { vkey, id, docInfo }) {
        const viewer = state.viewer[vkey]

        for (let i = 0, ilen = viewer.showFiles.length; i < ilen; i++) {
            const showFile = viewer.showFiles[i]

            if (showFile.id === id) {
                if (docInfo) {
                    this._vm.$set(viewer.showFiles, i, Object.assign(showFile, docInfo))
                } else {
                    if (viewer.current) {
                        viewer.current.children.splice(i, 1)
                    } else {
                        viewer.files.splice(i, 1)
                    }
                }
                break
            }
        }
    },
    /**
     * 保存阅读记录数据
     * @param {*} state 
     * @param {
     *  vkey: 视图
     *  docInfo: 为null则删除，有数据则更新
     * } param1 
     */
    updateNewest(state, { vkey, data }) {
        const viewer = state.viewer[vkey]
        viewer.showFiles = data
    },
    //跳转目录
    goPaths(state, { vkey, index }) {
        const viewer = state.viewer[vkey]
        
        if (index >= 0) {
            const folder = viewer.paths[index]
            viewer.paths = viewer.paths.splice(0, index + 1)
            viewer.current = folder
            viewer.showFiles = folder.children
            viewer.depth--
        } else {
            viewer.paths = viewer.paths.splice(0, 0)
            viewer.current = null
            viewer.showFiles = viewer.files
            viewer.depth = 0
        }
     
        
    },

    //设置当前目录
    setCurrent(state, folder) {
        state.viewer.current = folder
    },

    //设置组织的部门信息
    setCurrentGroup(state, group) {
        state.databank.org.group = group
    },

    setQuickFolder(state, folders) {
        state.quick = folders
    },

    //文件多选
    setSelection(state, { value, item }) {
        state.viewer.selection = value

        if (value) {
            if (item) {
                if (Array.isArray(item)) {
                    state.viewer.selectionRows = item
                } else {
                    state.viewer.selectionRows.push(item)
                }
            }
        } else {
            state.viewer.selectionRows = []
        }
    },
}

const actions = {
    /**
     * 获取文档列表
     * @param {
     *  vkey : 文档视图指示器 key
     * } param0 
     */
    async loadChildren({ dispatch, commit, state }, { vkey, reset = false, pageNum, pageSize=20, orgParamsInfo,fileType='' }) {
        try {
            const viewer = state.viewer[vkey]
            const currentFolder = viewer.current
 
            //显示加载
            commit('updateMore', { vkey, moreStatus: 'loading' })

            const queryInfo = viewer.query
            queryInfo.id = currentFolder ? currentFolder.id : 0
            if(fileType!=''){
                queryInfo.fileType = Number(fileType)
            } else {
                delete queryInfo.fileType
            }
                // 重新替换传递的页码
            if (pageNum || pageSize) {
                pageNum ? queryInfo.pageNum = pageNum : queryInfo.pageNum
                pageSize ? queryInfo.pageSize = pageSize : queryInfo.pageSize
            } else {
                queryInfo.pageNum = 1;
            }

            if (orgParamsInfo) { // 兼容pc组织、群组文档
                queryInfo.id = orgParamsInfo.id;
                queryInfo.type = orgParamsInfo.type;
                queryInfo.parentId = orgParamsInfo.parentId;
            }
            return dispatch("loadByQuery", { vkey, reset, queryInfo })
        } catch (error) {
            throw error
        }
    },

    // 根据节点获取数据
    async toNodeList({ dispatch, commit }, { vkey, id }) {
        const viewer = state.viewer[vkey]
        viewer.current = id;
        dispatch("loadChildren", { vkey, reset: true })
    },

    //根据条件查询
    async loadByQuery({ commit }, { vkey, reset = false, queryInfo }) {
        /**
         * 文档加载切换
         * 1. 组织文档调用 documentAPI.queryOrgChildren
         * 2. 用户文档调用 documentAPI.queryChildren
         */
        const queryMethodName = vkey == 'org' || vkey == 'groupDoc' ? 'queryOrgChildren' : 'queryChildren'

        const { code, current, pages, size, total, data, message } = await documentAPI[queryMethodName](queryInfo)

        if (code == 0) {
            commit("setChildren", {
                vkey,
                current,
                reset,
                pages,
                size,
                total,
                data
            })
        } else {
            commit('updateLoading', { vkey, loading: false })
            commit('updateMore', { vkey, moreStatus: 'noMore' })
            return message
        }
        return code == 0
    },

    /**
     * 获取文档列表，通过分享来获取
     * Refactor#A#表述不准确，不应该是分享文档列表，文档的无权限加载
     * @param {*} param0 
     * @param {*} param1 
     */
    async loadChildrenByShare({ dispatch, commit, state }, { vkey, folder, reset = true }) {
        try {
            //切换路径
            commit("addPaths", { vkey, folder })

            //获取视图
            const viewer = state.viewer[vkey]
            const currentFolder = viewer.current

            //显示加载
            commit('updateMore', { vkey, moreStatus: 'loading' })

            //取documentId
            const queryInfo = viewer.query
            queryInfo.id = currentFolder ? currentFolder.documentId : 0

            const { code, data, message } = await documentAPI.queryShareChildren(queryInfo)

            if (code == 0) {
                commit("setChildren", {
                    vkey,
                    current: 1,
                    reset,
                    pages: 1,
                    size: data.length,
                    total: data.length,
                    data
                })
            } else {
                commit('updateLoading', { vkey, loading: false })
            }
            return code == 0

        } catch (error) {
            throw error
        }
    },


    /**
     * 下一页
     * @param {*} param0 
     * @param {
     *  vkey: 操作的视图key
     *  reset: 翻页是否覆盖
     * } param1 
     */
    async doLoadNextPage({ commit, dispatch, state }, { vkey }) {

        const viewer = state.viewer[vkey]
        const currentFolder = viewer.current

        if (viewer.more.status != 'more') {
            return
        }

        const paging = viewer.paging
        const queryInfo = viewer.query
        if(vkey != 'org'){
            queryInfo.id = currentFolder ? currentFolder.id : 0
        }
        //pageNum为页码，1、2，pages为总页数， 1、2 < 2 + 1 
        if (queryInfo.pageNum < paging.pages + 1) {
            commit('updateMore', { vkey, moreStatus: 'loading' })

            queryInfo.pageNum++
                dispatch("loadByQuery", { vkey, reset: false, queryInfo })
        }
    },

    /**
     * 上一页
     * @param {*} param0 
     * @param {
     *  vkey: 操作的视图key
     *  reset: 翻页是否覆盖
     * } param1 
     */
    async doLoadPrevPage({ commit, dispatch }, { vkey, reset = false }) {
        const viewer = state.viewer[vkey]
        const currentFolder = viewer.current

        const paging = viewer.paging
        const queryInfo = viewer.query
        queryInfo.id = currentFolder ? currentFolder.id : 0

        if (queryInfo.pageNum > 0) {
            queryInfo.pageNum--

                dispatch("loadByQuery", { vkey, reset, queryInfo })
        }
    },

    /**
     * 新建文件夹
     * @param {*} param0 
     * @param {*} name 
     */
    async createFolder({ commit, dispatch, state }, { vkey, name, share, orgParamsInfo }) {
        const viewer = state.viewer[vkey]
        const currentFolder = viewer.current
        const docType = viewer.docType

        if (orgParamsInfo) { // 兼容pc组织、群组文档
            const data = await documentAPI.createFolder(orgParamsInfo ? orgParamsInfo.parentId : 0, orgParamsInfo.docType, name, share, orgParamsInfo.rootId)
            if (data.code == 0) {
                dispatch("loadChildren", { vkey, reset: true, orgParamsInfo })
            }
            return data
        } else { // 个人文档(我的文档)
            const data = await documentAPI.createFolder(currentFolder ? currentFolder.id : 0, docType, name, share)
            if (data.code == 0) {
                dispatch("loadChildren", { vkey, reset: true })
            }
            return data
        }

    },

    /**
     * 重命名
     * @param {*} id 文档id 
     * @param {*} name 文档新名称 
     */
    async renameFolder({ commit }, { id, name, vkey }) {
        const { code, data, message } = await documentAPI.rename(id, name)
        if (code == 0) {
            //更新文档
            commit('updateDocInfo', {
                vkey,
                id,
                docInfo: {
                    name
                }
            })
        } else {
            throw message
        }
        return code == 0
    },
    /**
     * 阅读记录
     * @param {*} id 文档id 
     * @param {*} name 文档新名称 
     */
    async readLog({ commit }, { vkey }) {
        const viewer = state.viewer[vkey]
        const queryFolder = viewer.query
        let { code, data, message } = await documentAPI.readLog(queryFolder)

        if (code == 0) {
            commit('updateNewest', {
                    vkey,
                    data
                })
                //更新文档
        } else {
            throw message
        }
        return code == 0
    },

    /**
     * 移除文档
     * @param {*} param0 
     * @param {*} documentId 
     */
    async remove({ commit }, { vkey, id }) {

        const { code, data, message } = await documentAPI.remove(id)

        if (code == 0) {
            //更新文档
            commit('updateDocInfo', {
                vkey,
                id
            })
        }
        return code == 0
    },

    /**
     * 进入文件夹
     * @param {*} param0 
     */
    async enterFolder({ commit, dispatch, state }, { vkey, folder, orgParamsInfo ,fileType,pageSize,pageNum}) {
        commit("addPaths", { vkey, folder })
        if (vkey == 'org' || vkey == "groupDoc") { // 兼容组织文档
            dispatch("loadChildren", { vkey, reset: true, orgParamsInfo })
        } else {
            dispatch("loadChildren", { vkey, reset: true , fileType,pageSize,pageNum})
        }
    },

    /**
     * 后退文件夹
     * @param {*} param0 
     * @param {*} param1 
     */
    async backFolder({ commit, state }, { vkey }) {
        const viewer = state.viewer[vkey]
        let index = viewer.paths.length - 2
        if (index > -2) {
            commit("goPaths", {
                vkey,
                index
            })
        }
    },
    //从组织树进入文件级
    async enterGroup({ commit, dispatch }, group) {
        const basePath = {
            id: group.id,
            name: group.name
        }

        commit("switchDataBank", 'org')
        commit("setCurrentGroup", group)
        commit("addPaths", basePath)
        dispatch("loadChildren")
    },


    /**
     * 跳转到路径节点
     * @param {*} param0 
     * @param {*} param1 
     */
    async jumpPaths({ commit, dispatch }, { folder, index }) {
        commit("goPaths", index)
        dispatch("loadChildren")
    },

    //下载文件
    async downLoadFile({}, params) {
        // const { code, data, message } = await download(params)
        await download(params)
    },

    //阅读
    async read({}, document) {
        const { code, data, message } = await documentAPI.read(document.id)

        if (code == 0) {
            return data
        }

        throw message
    },

    /**
     * 获取文档属性
     * @param {*} id 文档ID
     */
    async getDocumentOption({}, id) {
        const { code, data, message } = await documentAPI.getDocumentOption(id)
            // 需要返回值，企业文档部分会有没有权限查看的情况
        return { code, data, message }
    },

    /**
     *设置文档属性
     * @param {*} description 文档描述
     * @param {*} id 文档ID
     * @param {*} documentName 文档名
     * @param {*} tag 文档检索标签
     */
    async updateDocumentOption({ dispatch }, { vkey, option }) {
        const { code, data, message } = await documentAPI.updateDocumentOption(option)
        if (code == 0) {
            if(vkey){
                dispatch("loadChildren", { vkey, reset: true })
            }
            return true
        }
    },

    /**
     * 复制文件
     * @param {*} sourceDocumentId 资源id
     * @param {*} targetDocumentId 目标id
     */
    async copyDocument({ dispatch }, query) {
        let vkey = 'org';
        const { code, data, message } = await documentAPI.copyDocument(query)
        if (code == 0) {
            dispatch("loadChildren", { vkey, reset: true })
            return true
        } else {
            return message
        }
    },

    /**
     * 移动文件
     * @param {*} sourceDocumentId 资源id
     * @param {*} targetDocumentId 目标id
     */
    async moveDocument({ dispatch }, query) {
        let vkey = 'my';
        const { code, data, message } = await documentAPI.moveDocument(query)
        if (code == 0) {
            dispatch("loadChildren", { vkey, reset: true })
            return true
        } else {
            return message
        }
    },

    /**
     * 多移除文档
     * @param {*} param0 
     * @param {*} documentId 
     */
    async checkedRemove({ commit }, { vkey, id }) {

        const { code, data, message } = await documentAPI.checkedRemove(id)

        if (code == 0) {
            id.map(item => {
                //更新文档
                commit('updateDocInfo', {
                    vkey,
                    id: item
                })
            })

        }
        return code == 0
    },

    /**
     * 批量检测文档的分享权限
     * query [Array]
     */
     async batchCheckDocumentSharePermission({},query){
        const data = await documentAPI.batchCheckDocumentSharePermission(query)
        return data
     },

         /**
     * 批量转换文件格式
     * query [Array]
     */
    async batchConvertOFD({},query){
        const data = await documentAPI.batchConvertOFD(query)
        return data
    },

    /**
     * 批量检测文件是否是分享文件
     * query [Array]
     */
    async checkDocumentHasShare({},query){
        const data = await documentAPI.checkDocumentHasShare(query)
        return data
    },
    

    /**
     * 转换ofd文件
     */
    async transformToOFD({},query){
        const data = await documentAPI.transformToOFD(query)
        return data
    },

    /**
     * 新建文件
     * @param {*} param0 
     * @param {*} name 
     */
    async createFile({ commit, dispatch, state }, { vkey,doc,fileType, orgParamsInfo }) {
        const viewer = state.viewer[vkey]
        const currentFolder = viewer.current
        const docType = viewer.docType

        if (orgParamsInfo) { // 兼容pc组织、群组文档
            const data = await documentAPI.wpsGetNewUrl(orgParamsInfo ? orgParamsInfo.parentId : 0, orgParamsInfo.docType, name, share, orgParamsInfo.rootId)
            if (data.code == 0) {
                dispatch("loadChildren", { vkey, reset: true, orgParamsInfo })
            }
            return data
        } else { // 个人文档(我的文档)
            let query = {
                docType:0,orgId:0,
                parentId:currentFolder ? currentFolder.id : 0,
                doc,fileType
            }
            const data = await documentAPI.wpsGetNewUrl(query)
            // if (data.code == 0) {
            //     dispatch("loadChildren", { vkey, reset: true })
            // }
            return data
        }

    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}