/**
 * 分享状态管理
 */

import { shareAPI ,msgAPI } from 'liankong-ui-api'

const state = {
        views: {
            //发出的，我的分享
            sender: {
                query: {
                    name: '',
                    pageNum: 1,
                    pageSize: 10
                },
                paging: {
                    total: 0,
                    pages: 0
                },
                list: [],

                //加载更多
                more: {
                    status: 'more',
                    contentText: {
                        contentdown: '加载更多数据',
                        contentrefresh: '数据加载中',
                        contentnomore: '没有更多数据了'
                    }
                },

                // 缓存时间
                dateTime: {
                    end: '',
                    start: '',
                }
            },

            //收到的，分享给我
            receiver: {
                query: {
                    pageNum: 1,
                    pageSize: 10
                },
                paging: {
                    total: 0,
                    pages: 0
                },
                list: [],

                //加载更多
                more: {
                    status: 'more',
                    contentText: {
                        contentdown: '加载更多数据',
                        contentrefresh: '数据加载中',
                        contentnomore: '没有更多数据了'
                    }
                }
            },

            // 水印列表
            watherList: {
                query: {
                    pageNum: 1,
                    pageSize: 10
                },
                paging: {
                    total: 0,
                    pages: 0
                },
                list: [],

                //加载更多
                more: {
                    status: 'more',
                    contentText: {
                        contentdown: '加载更多数据',
                        contentrefresh: '数据加载中',
                        contentnomore: '没有更多数据了'
                    }
                }
            },
            configValue:'0'
        }
    }
    // 我分享总数 shareCount;
    // 我分享文档总数 shareDocumentCount;
    // 我提交申请总数  myRequestCount;
    // 我提交申请等待审核总数  myRequestWaitCount;
    // 我负责审核总数  myAuthCount;
    // 我负责审核剩余总数  myAuthWaitCount;
    // 分享给我的总数  shareMeCount;

const mutations = {

    /**
     * 设置视图数据
     * @param {*} state 
     * @param {*} param1 
     */
    setViewData(state, { vkey, reset = false, current, pages, data, total, noReset = false }) {
        const viewer = state.views[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data)

        // PC端为了防止数据累加
        // 影响了小程序，不应该写死，注意更改PC的时候，把noReset作为传入值
        // if(!noReset) {
        //   viewer.list = data
        // }
    },

    /**
     * 更新视图查询条件
     * @param {*} state 
     * @param {*} param1 
     */
    updateQuery(state, { vkey, query }) {
        const viewer = state.views[vkey]
        viewer.query = Object.assign(viewer.query, query)
    },

    //更新更多状态
    updateMore(state, { vkey, moreStatus }) {
        const viewer = state.views[vkey]
        viewer.more.status = moreStatus
    },
    //更新时间
    updateDate(state, { vkey, query }) {
        let { start, end } = query
        const viewer = state.views[vkey]
        viewer.dateTime.start = start
        viewer.dateTime.end = end
    },

    //更新分享名称
    updateShareName(state, { vkey, id, name }) {
        const viewer = state.views[vkey]
        const shareList = viewer.list

        for (let i = 0, ilen = shareList.length; i < ilen; i++) {
            const item = shareList[i]
            if (item.id === id) {
                item.name = name
                break
            }
        }
    },
    updateConfigValue(state,{value}){
        state.configValue = value;
    }
}

const actions = {
    /**
     * 初始化检测是否开启了隐写溯源
     * @param {*} param0 
     */
     async init({ commit }) {
        try {
            //加载文档类型
            const result = await msgAPI.getValueByKey("system.source.enable")
            if (result.code == 0) {
                commit("updateConfigValue", {
                    value:result.data.configValue
                })
            }
        } catch (error) {
            throw error
        }
    },

    /** 我的分享列表
     * @param {
     *   name:null,
     *   end 结束时间
     *   start 开始时间
     *   pageSize条数
     *   pageNum页数
     * } query
     */
    async myShareList({ commit, state }, { reset = false, noReset = false, query = {} }) {
        const viewerKey = "sender"
        const viewer = state.views[viewerKey]

        //是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }

        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })
            //显示加载
        commit('updateMore', { vkey: viewerKey, moreStatus: 'loading' })

        //重置条件
        if (reset && noReset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await shareAPI.myShareList(viewer.query)

        if (code == 0) {
            commit("setViewData", {
                vkey: viewerKey,
                current,
                reset,
                noReset,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },

    /**
     * 分享给我的列表
     * @param {*} param0 
     * @param {*} param1 
     */
    async toMyList({ commit, state }, { reset = false, query = {} }) {
        const viewerKey = "receiver"
        const viewer = state.views[viewerKey]

        //是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }
        //重置条件
        if (reset) {
            viewer.query.pageNum = 1
        }

        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })
            //显示加载
        commit('updateMore', { vkey: viewerKey, moreStatus: 'loading' })


        const { code, current, pages, size, total, data, message } = await shareAPI.receiveShareList(viewer.query)

        if (code == 0) {
            commit("setViewData", {
                vkey: viewerKey,
                current,
                reset,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },


    /**
     * 更新分享信息
     * @param {*} param0 
     * @param {*} shareInfo 
     */
    async update({}, shareInfo) {
        try {
            const { code, data, message } = await shareAPI.update(shareInfo)

            if (code != 0) {
                throw message
            }

            return code == 0
        } catch (err) {
            throw err
        }
    },
    /**
     * 更新分享时间
     * @param {*} param0 
     * @param {*} shareInfo 
     */
     async updateShareExpireTime({}, shareInfo) {
        try {
            const { code, data, message } = await shareAPI.updateShareExpireTime(shareInfo)

            if (code != 0) {
                throw message
            }

            return code == 0
        } catch (err) {
            throw err
        }
    },

    /**
     * 分享重命名
     * @param {*} param0 
     * @param {id, name} shareInfo 
     */
    async rename({ commit }, shareInfo) {
        try {
            const { code, data, message } = await shareAPI.update(shareInfo)

            if (code != 0) {
                throw message
            }

            //更新列表数据
            commit("updateShareName", {
                vkey: "sender",
                id: shareInfo.id,
                name: shareInfo.name
            })

            return code == 0
        } catch (err) {
            throw err
        }
    },

    /**
     *
     * 删除分享
     * @param   {[type]}  shareId
     * @return  data
     */
    async remove({ dispatch }, shareId) {
        const data = await shareAPI.remove(shareId)
        if (data.code == 0) {
            dispatch("myShareList", { reset: true })
        }
        return data
    },

    /**
     * 取消分享
     * @param {*} param0 
     * @param {*} shareId 
     */
    async disable({ dispatch }, shareId) {
        const { code, data, message } = await shareAPI.disable(shareId)
        if (code == 0) {
            dispatch("myShareList", { reset: true })
        }
        return { code, data, message }
    },

    /**
     * 启用分享
     * @param {*} param0 
     * @param {*} shareId 
     */
    async enable({ dispatch }, shareId) {
        const { code, data, message } = await shareAPI.enable(shareId)
        if (code == 0) {
            dispatch("myShareList", { reset: true })
        }
        return { code, data, message }
    },

    /**
     * 分享详情
     * @param   {[type]}  shareId
     * @return  data
     */
    async findShareId({}, shareId) {
        const data = await shareAPI.detailById(shareId)
        return data
    },

    /**
     * 授权链
     * @param   {[type]}  shareId
     * @return  data
     */
    async fileChain({}, shareId) {
        const data = await shareAPI.fileChain(shareId)
        return data
    },

    /**
     * 个人数据统计
     */
    async myCount({}) {
        const data = await shareAPI.myCount()
        return data
    },
    
    async List({ commit, state }, { reset = false,viewerKey,queryName,noReset = false, query = {} }) {
        const viewer = state.views[viewerKey]

        //是否有更多查询
        if (!reset && viewer.more.status === 'noMore') {
            return
        }

        //更新查询条件
        commit('updateQuery', { vkey: viewerKey, query })
            //显示加载
        commit('updateMore', { vkey: viewerKey, moreStatus: 'loading' })

        //重置条件
        if (reset && noReset) {
            viewer.query.pageNum = 1
        }

        const { code, current, pages, size, total, data, message } = await shareAPI[`${queryName}`](viewer.query)

        if (code == 0) {
            commit("setViewData", {
                vkey: viewerKey,
                current,
                reset,
                noReset,
                pages,
                size,
                total,
                data
            })
        }

        return code == 0
    },
    async shareWatherState({},{queryName,query}) {
        const data = await shareAPI[`${queryName}ShareWather`](query)
        return data
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}