<template>
  <el-dialog custom-class="share-dialog" title="设置语义遮掩" :visible.sync="dialogVisible" :append-to-body='true'
  :close-on-click-modal="false" width="58%" @close='closeDialog'>
    <!-- 语义遮掩 -->
    <div class="box">
      <el-checkbox-group v-if='envelopList.length != 0' v-model="envelopCheck" @change="handleCheckedChange">
        <el-checkbox v-for="item in envelopList" :label="item" :key="item.id">{{item}}</el-checkbox>
      </el-checkbox-group>
      <div v-else>这个文档没有语义遮掩字段</div>
    </div>


    <span slot="footer" class="dialog-footer">
      <div>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="submitData">确定</el-button>
      </div>
    </span>

  </el-dialog>
</template>

<script>
  import { documentAPI} from 'liankong-ui-api' 
  export default{
    data(){
      return {
        dialogVisible:false,
        envelopList:[],
        envelopCheck:[],
        transmitEnvelop:[]
      }
    },
    props: {
      //共享信息 只有一个文档
      docInfo:{
        type: Object,
        default: null
      },
      vkey:{
        type:String,
        default:'share'
      },
    },
    computed: {
      //权限列表
      cartEnvelops() {
        return this.$store.state.cart.envelops
      },
      editEnvelops() {
        return this.$store.state.auth.editShare.envelops
      },
    },
    mounted() {
    },
    methods:{
      submitData(){
        if(this.transmitEnvelop.length != 0){
          this.$emit('doPermissionSet','envelops',this.transmitEnvelop ,true )
        }else{
          this.$emit('doPermissionSet','envelops',[] ,false)
        }
        this.dialogVisible = false;
      },
      closeDialog(){
        this.envelopList = [];
        this.envelopCheck = [];
        this.transmitEnvelop = [];
        this.dialogVisible = false;
      },
      // 语义遮掩 | 获取遮掩字段
      getEnvelopField(){
        if(this.docInfo != null){
          documentAPI.getEnvelopList(this.docInfo.id).then(data=>{
            if(data.code==0){
              this.envelopList = data.data;
                // 回显数据
              if(this.vkey == 'edit'){
                if(this.editEnvelops.length != 0){
                  this.editEnvelops.map(item => {
                    this.envelopCheck.push(item.name)
                  })
                }
              }else{
                if(this.cartEnvelops.length != 0){
                  this.cartEnvelops.map(item => {
                    this.envelopCheck.push(item.name)
                  })
                }
              }
            }
          })
        }
      },
      handleCheckedChange(value){
        let arr = []
        for(let i in value){
          arr.push({'name':value[i]})
        }
        this.transmitEnvelop = arr;
      },
    }
  }
</script>
<style lang='less' scoped>
 .box{
  padding: 10px;
 }
 
</style>