/**
 * 组织业务逻辑
 */
import { createGroup, 
  getChildren, 
  removeGroup, 
  getOrgInfo,
  memberList,
  addGroupUser } from '@/api/org.js'

import { addUser } from '@/api/user.js'

const state = {
  org: {
    name: "组织名称",
    type: "org",
    id: 1,
    children: [],
    users: []
  },
  groupCache: {},
  userviewer: {
    query: {

    },
    data: []
  }
}

const mutations = {
  setOrgInfo(state, org){
    state.org.name = org.name
  },

  //目前全量组织结构存储
  setChildren(state, data){
    state.org.children = data
    const newGroupCache = data.reduce((cache, value) => {
      cache[value.id] = value
      return cache
    }, {})
    state.groupCache = newGroupCache
  },

  setUsers(state, {group, users}){
    /* const groupItem = state.groupCache[group.id]
    if(groupItem){
      groupItem.users = users.records
    } */
    state.userviewer.data = users.records
  }
}

const actions = {
  //加载组织信息
  async getOrgInfo({commit}){
    const { code, data, message } = await getOrgInfo(1)
    if(code == 0){
      commit("setOrgInfo", data)
    }
  },

  //创建部门
  async createGroup({commit, dispatch}, group){
    const { code, data, message } = await createGroup(group)
    if(code == 0){
      dispatch("getChildren")
      return group
    }
    return null
  },

  //加载组织树
  async getChildren({commit, state}){
    const {code, data, message} = await getChildren({id: state.org.id})
    if(code == 0){
      commit("setChildren", data)
    }
  },

  //移除组织
  async deleteGroup({dispatch}, group){
    const { code, data, message } = await removeGroup(group)
    if(code == 0){
      dispatch("getChildren")
      return code
    }else{
      throw Error(message)
    }
  },

  //加载组织用户
  async userList({commit}, group){
    const {code, data, message} = await memberList({
      id: group.id
    })

    if(code == 0){
      commit("setUsers", {group: group, users: data})
    }
  },

  //添加用户
  async addMember({commit, dispatch}, {isAdmin, roleMiji, roles, user}){

    const newUser = Object.assign({}, user)
    newUser.role = [roleMiji, ...roles]

    if(isAdmin){
      //添加管理员角色
      newUser.role.push(2)
    }

    delete newUser.org
    const userRet = await addUser(newUser).catch(err => {
      return Promise.reject(err)
    })

    const groupRet = await addGroupUser(user.org, userRet.data)
    return groupRet
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}