/**
 * 获取时间方法
 * 
 */
import Vue from 'vue'
const state = {
    ShareTime: {
        start: "",
        end: ""
    },
    ApplyTime: {
        start: "",
        end: ""
    }
}

function getDateStr(AddDayCount) {
    var dd = new Date();
    dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
    var y = dd.getFullYear();
    var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1); //获取当前月份的日期，不足10补0
    var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0
    return `${y}-${m}-${d}`;
}

const mutations = {
    // 初始化时间
    initDate(state) {
        let start = getDateStr(-30)
        let end = getDateStr(0)

        state.ShareTime = {
            start,
            end
        }
        state.ApplyTime = {
            start,
            end
        }
    },

    setDate(state, { type, start, end }) {
        state[type] = {
            start,
            end
        }
    }
}
const actions = {}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}