import BaseAPI from '../BaseAPI.js'

/**
 * 系统角色服务API
 * 
 * @author Daniel
 * @date 
 */
export default class IndexAPI extends BaseAPI{
  constructor(){
    super()

    this.moduleName = "/usercenter"
  }

  /**
   * 角色类型(分组)列表
   */
  roleTypeList(){
    return this.ajax.get(`${this.moduleName}/role/type/list`)
  }

  /**
   * 添加角色类型(分组)
   * @param {*} type 
   */
  addType(type){
    return this.ajax.post(`${this.moduleName}/role/type`, `name=${type.label}`)
  }

  /**
   * 删除角色类型(分组)
   * @param {*} type 
   */
  removeType(type){
    return this.ajax.del(`${this.moduleName}/role/type/${type.id}`)
  }

  /**
   * 角色列表
   * @param {*} type 
   */
  roleListByType(type){
    return this.ajax.get(`${this.moduleName}/role/list/${type}`)
  }

  /**
   * 添加角色
   * @param {*} roleInfo 
   */
  addRole(roleInfo){
    return this.ajax.post(`${this.moduleName}/role`, roleInfo)
  }

  /**
   * 更新角色
   * @param {*} roleInfo 
   */
  updateRole(roleInfo){
    return this.ajax.put(`${this.moduleName}/role`, roleInfo)
  }

  /**
   * 移除角色
   * @param {*} roleInfo 
   */
  removeRole(roleInfo){
    return this.ajax.del(`${this.moduleName}/role/${roleInfo.id}`)
  }

}