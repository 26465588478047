<template>
  <el-dialog custom-class="share-dialog" title="设置区域遮掩" :visible.sync="dialogVisible" :append-to-body='true'
  :close-on-click-modal="false" width="1400px" @close='closeDialog'>
    <!-- 画区域遮掩 -->
    <div>
      <iframe  scrolling=no :src="iframeSrc" frameborder="0" width="100%" height="650"></iframe>
    </div>
  </el-dialog>
</template>

<script>
  import { documentAPI , shareAPI} from 'liankong-ui-api' 
  import { Store } from '@/utils/LocalCache.js'
  export default{
    data(){
      return {
        dialogVisible:false,
        iframeSrc:'',
        ticket:null,
        shadeAreaId:0
      }
    },
    props: {
      //共享信息 只有一个文档
      docInfo:{
        type: Object,
        default: null
      },
      vkey:{
        type:String,
        default:'share'
      },
    },
    mounted() {
      window.addEventListener('message', this.handleMessage)
    },
    methods:{
      handleMessage(event){
        let sendDataMessage = event.data
        if(sendDataMessage.cmd=='copy'){
          this.$emit('doPermissionSet','copy',1)
          this.$emit('disableCopy')
          this.submitData()
        }else if(sendDataMessage.cmd == 'clearShadeAreaId'){
          this.shadeAreaId = 0;
          this.submitData()
        }
      },
      submitData(){
        this.$emit('doPermissionSet','shadeAreaId',this.shadeAreaId,this.shadeAreaId == 0 ?false:true);
        this.ticket = null;
        this.dialogVisible = false;
      },
      closeDialog(){
        this.iframeSrc = '';
        this.shadeAreaId = 0;
        this.ticket = null;
         this.dialogVisible = false;
      },
      getIframeUrl(){
        if(this.docInfo != null){
          let echo = true;
          if(this.shadeAreaId == 0){
            let shadeAreaId = new Date().getTime();
            this.shadeAreaId = shadeAreaId;
            echo = false;
          }
          let suffix = 'ofd'
          shareAPI.getReaderUrl('',this.docInfo.documentId||this.docInfo.id,this.docInfo.documentId?this.docInfo.documentId:this.docInfo.id,this.docInfo.name, suffix)
          .then(({ code, data, message }) => {
            if (code == 0) {
              this.ticket = data.ticket;
              // const token = Store.get('access_token');
              // const expires = Store.get('expires')
              // this.iframeSrc = this.$options.filters['readerUrl'](`?ticket=${data.ticket}`)+`&shadeAreaId=${shadeAreaId}&documentId=${this.docInfo.id}&type=edit`;
              this.iframeSrc = this.$options.filters['readerUrl'](data.url)+`&shadeAreaId=${this.shadeAreaId}&documentId=${this.docInfo.id}&type=edit&template=false&echo=${echo}`;
              // this.iframeSrc = `http://localhost:8088/?ticket=${data.ticket}&token=${token}&shadeAreaId=${this.shadeAreaId}&documentId=${this.docInfo.id}&expires=${expires}&type=edit&template=false&echo=${echo}`;
            }
          })
        }
        
      }
    },
    beforeDestroy(){
      window.removeEventListener('message', this.handleMessage)
    }
  }
</script>
<style lang='less' scoped>
  /deep/ .el-dialog__body {
    display: block !important;
    padding: 0px 20px;
  }
  .group{
    padding-bottom: 20px;
  }
  /deep/.el-dialog{
    margin-top: 3vh!important;
  }
  
 
</style>