<template>
  <el-dialog custom-class="share-dialog" title="设置关键字遮掩" :visible.sync="dialogVisible" :append-to-body='true'
  :close-on-click-modal="false" width="500px" @close='closeDialog'>


    <!-- 关键字遮掩 -->
    <div  class="box">
      <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="keyWord"
        @change="doAddKeyWord">
      </el-input>
      <div class="keyWord-content">
        <div class="keyWord-item" v-for="item in keyWordList" :key="item">
          <span>{{item}}</span>
          <i class="el-icon-error" @click="onKeyWordRemoved(item)"></i>
        </div>
      </div>
       <div class="tips">
          <i class="el-icon-info"></i> 图片或扫描件不支持关键字遮掩哦！
        </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <div>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="submitData">确定</el-button>
      </div>
    </span>


  </el-dialog>
</template>

<script>
  export default{
    data(){
      return {
        dialogVisible:false,
        shadeKeyword:'',
        keyWord:'',
        keyWordList:[]
      }
    },
    props: {
      //共享信息 只有一个文档
      docInfo:{
        type: Object,
        default: null
      },
      vkey:{
        type:String,
        default:'share'
      },
    },
    // computed: {
    //   //权限列表
    //   permissions() {
    //     return this.$store.state.cart.permissions
    //   },
    // },
    
    methods:{
      submitData(){
        if(this.keyWordList.length == 0){
          this.$emit('doPermissionSet','shadeKeyword',null ,false)
        }else{
          let keyWord = this.keyWordList.toString();
          this.$emit('doPermissionSet','shadeKeyword',keyWord ,true)
        }
        this.dialogVisible = false;
      },
      closeDialog(){
        this.dialogVisible = false;
        this.shadeKeyword = '';
        this.keyWord = '';
        this.keyWordList = []
      },

      // 关键字遮掩 | 增加关键字
      doAddKeyWord(){
        this.keyWordList.push(this.keyWord);
        this.keyWordList = this.keyWordList.filter((item,index,arr)=>{
          return arr.indexOf(item,0) === index;
        })
        this.keyWord = ''
      },
      // 关键字遮掩 | 删除关键字
      onKeyWordRemoved(item){
        this.keyWordList.splice(this.keyWordList.indexOf(item),1);
      },
     
    }
  }
</script>
<style lang='less' scoped>
 
 .box{
  width: 100%;
  padding: 10px;
 }
 .keyWord-content{
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
  }
  .keyWord-item{
    padding: 5px 10px;
    background:#f1f1f1;
    margin:10px;
  }
  .keyWord-item i{
    color: crimson;
  }
  .el-input{
    width: 100%;
  }
  .tips{
  margin-top: 10px;
  color: #999;
}
</style>