/**
 * 打印机api
 */
import * as http from '../utils/http/index.js';


/**
 * 打印节点列表
 */
export function getAgentList() {
    return http.get(`/agent/list`)
}

/**
 * 获取打印机列表
 * @param {*} agent 
 */
export function getPrintByAgent(agent) {
    return http.get(`/agent/${agent.id}/printers`)
}

/**
 * 获取打印机列表
 * @param {*} item 
 */
export function getPrintList() {
    return http.get(`/printer`)
}

/**
 * 添加打印机
 * @param {*} agent 
 * @param {*} printItem 
 */
export function addPrint(agent, printItem) {
    return http.post(`/printer`, {
        agentId: agent.id,
        name: printItem.name
    })
}

/**
 * 移除打印机
 * @param {*} printItem 
 */
export function removePrint(printItem) {
    return http.del(`/printer/${printItem.id}`)
}

/**
 * 请求打印
 * @param {*} printItem 
 */
export function doPrint(printItem) {
    return http.post(`/printer/apply/print`, {
        documentId: printItem.documentId,
        description: printItem.description
    })
}

/**
 * 我的打印
 * @param {*} printItem 
 */
export function myPrint(printItem) {
    return http.get(`/printer/apply/list/applicant`)
}

/**
 * 打印审批列表
 * @param {*} printItem 
 */
export function reviewList(printItem) {
    return http.get(`/printer/apply/list/approver`)
}


/**
 * 审批打印请求
 * @param {*} printItem 
 */
export function reviewPost(printItem) {
    return http.post(`/printer/approve`, {
        applyId: printItem.applyId,
        status: printItem.status
    })
}

/**
 * 请求打印机
 * @param {*} printItem 
 */
export function printerPrint(printItem) {
    return http.post(`/printer/print`, {
        applyId: printItem.applyId,
        printerId: printItem.printerId
    })
}