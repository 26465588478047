import BaseAPI from '../BaseAPI.js'

/**
 * 索引服务API
 * 
 * @author Daniel
 * @date 
 */
export default class IndexAPI extends BaseAPI {
  constructor() {
    super()

    this.moduleName = "/index"
  }
  /**
   * 个人文档检索
   * docType 文档类型 0 个人 1 组织 2 群体
      groudId 群组id 
      keyword 搜索关键字 
      orgId 组织id
      pageNumber 每一页条数
      pageSize 搜索条数 
      systemTag 系统标签 
   */
  indexDocument(query) {
    return this.ajax.post("/document/search/searchOwnerDocument", query)
  }
  /**
   * 全文检索
   * docType 文档类型 0 个人 1 组织 2 群体
      groudId 群组id 
      keyword 搜索关键字 
      orgId 组织id
      pageNumber 每一页条数
      pageSize 搜索条数 
      systemTag 系统标签 
      miji; 密集
      startDate 开始时间
      endDate 结束时间
    */
  index(query) {
    return this.ajax.post("/document/search/documentCenter/search", query)
  }

  /**
   * 共享文档搜索
   * @param {*} data 
   */
  shareList(data) {
    return this.ajax.post(`${this.moduleName}/user/share`, data)
  }


  /**
   * 共享资源申请
   * @param {*} data 
   */
  shareResourceRequest(data) {
    return this.ajax.post(`/share/request/document/apply`, data)
  }
}