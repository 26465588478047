<template>
  <div class="search-box">
    <el-autocomplete 
      v-model="searchKey" 
      :trigger-on-focus="false" 
      popper-class="yhfile"
      :fetch-suggestions="querySearchAsync" 
      placeholder="请输入文件关键字" 
      @select="handleSelect" 
      style="width: 260px"
      @keyup.enter.native="doSearch">
      <div slot-scope="scope">
        <div style="font-weight: bold">{{ scope.item.title }}</div>
        <div style="white-space: normal; color: #bababa">
          {{ scope.item.textContent }}
        </div>
      </div>
      <i slot="suffix" class="el-input__icon el-icon-search search-icon" @click="doSearch"></i>
    </el-autocomplete>
  </div>
</template>

<script>
  import { search } from "@/api/document.js";

  export default {
    components: {},
    data() {
      return {
        searchKey: '',
      };
    },
    computed: {
      inAdminMode() {
        return this.$store.state["app"].mode.main == "manager";
      },
    },
    watch: {},
    methods: {
      //关键词联想查询
      querySearchAsync(queryString, cb) {
        cb([]);
      },

      //选择关键词
      handleSelect(item) {
      },

      doSearch() {
        // if (this.searchKey) {
          const url = `/search/result?s=${this.searchKey}`;
          this.$router.push({
            path: url,
          });
        // }
      },
    },
    created() { },
    mounted() { },
  };
</script>

<style lang="less">
  .search-box /deep/ {
    .el-input__inner {
      border-radius: 4px !important;
      background: #F9FAFB !important;
      height: 35px !important;
    }
  }
  
  .yhfile {
    width: 700px !important;

    .el-autocomplete-suggestion__wrap {
      max-height: 600px;
    }
  }

  .search-icon {
    font-size: 18px;
    line-height: 35px !important;
    margin-right: 10px;
    color: #333537;
  }
</style>

<style lang='less'>
  
</style>