<template>
  <el-form :model="baseInfo" :rules="rules" ref="form" label-width="100px">
    <el-form-item label="共享名称" prop="name">
      <el-input v-model="baseInfo.name"></el-input>
    </el-form-item>
    <el-form-item label="共享介绍" prop="description">
      <el-input v-model="baseInfo.description" type="textarea" :rows="10"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      baseInfo: {
        name: null,
        description: null
      },
      rules: {
        name: [
          {required: true, message: "请输入共享名称"}
        ],
        /* description: [
          {required: true, message: "请输入共享介绍"}
        ] */
      }
      
    }
  },
  computed: {},
  watch: {},
  methods: {
    //验证表单输入
    check(){
      return new Promise((rsolve, reject) => {
        this.$refs["form"].validate(valid => {
          if(valid){
            this.$store.commit("shares/taskSetBaseInfo", this.baseInfo)
            rsolve()
          }else{
            reject()
          }
        })
      })
    }
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>

</style>