<template>
  <div class="tools">
    <!-- <SearchResult></SearchResult> -->
    <Messasge></Messasge>
    <!-- <Cart></Cart> -->
    <!-- <Upload></Upload> -->
    <UserBtn></UserBtn>
  </div>
</template>

<script>
  import SearchResult from "./components/SearchResult.vue";
  import UserBtn from "./components/User.vue";
  import Messasge from "./components/Message.vue"
  import Cart from "./components/Cart.vue"
  import Upload from "./components/Upload.vue"

  export default {
    components: {
      SearchResult,
      Messasge,
      UserBtn,
      Cart,
      Upload
    }
  }
</script>

<style lang="less" scoped>
.tools{
    // flex-grow: 1;
    // width: 470px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}
</style>