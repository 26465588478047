<template>
    <div class="menutree">
        <template v-for="(menu, ind) in data">
            <el-submenu :index="filterPath(menu.path, ind)" :key="menu.name" v-if="menu.children && menu.children.length > 0 && $isShowOrg || menu.name=='我的文档'">
                <template slot="title">
                    <div class="menu-items" :class="menu.name == isActiveName ? menu.classNames : ''"  @click="dataClick(menu)">
                        <i :class="menu.icon"></i>
                        <span slot="title" :class="{'el-menu--display': first}">{{ menu.name }}</span>
                    </div>
                </template>

                <template v-for="(childs, cindex) in menu.children"  >
                    <el-menu-item :index="filterPath(childs.path, cindex)" :key="cindex" v-if="childs.children && childs.children.length==0" @click="dataClick(childs)">
                        <i :class="childs.icon"></i>
                        <span slot="title">{{childs.name}}</span>
                    </el-menu-item>

                    <NewLeft :data="[childs]" :key="cindex" v-else></NewLeft>
                </template>

            </el-submenu>

            <el-menu-item class="my-start-menus" :index="filterPath(menu.path, ind)" :key="menu.name" @click="dataClick(menu)"  v-else-if="menu.path == '/file/othershare' && menu.children.length == 0">
                <i :class="menu.icon"></i>
                <span slot="title">{{ menu.name }}</span>
            </el-menu-item>

            <!-- <el-menu-item :index="filterPath(menu.path, ind)" :key="menu.name" @click="dataClick(menu)"  v-else-if="menu.path == ''">
                <i :class="menu.icon"></i>
                <span slot="title">{{ menu.name }}</span>
            </el-menu-item> -->
            <el-menu-item :index="filterPath(menu.path, ind)" :key="menu.name" @click="dataClick(menu)"   v-else-if="menu.path == '/file/orgdoc' || menu.path == '/file/groupdoc' || menu.path == '/sharetion/sharedoc'|| menu.path == '/sharetion/confer'|| menu.path == '/sharetion/authrecord' || menu.path == '/file/favorite' || menu.path == '/teamWork/pending'|| menu.path == '/teamWork/join'|| menu.path == '/teamWork/create'|| menu.path == '/teamWork/count'|| menu.path == '/onlineEditing'">
                <i :class="menu.icon"></i>
                <span slot="title">{{ menu.name }}</span>
            </el-menu-item>

            
        </template>
    </div>
</template>
<script>
export default {
    name: "NewLeft",
    props: ["data", "first"],
    data() {
        return {
            isActiveName: '',
        };
    },
    watch: {
        '$store.state.myDocGoBack':{
            handler(newvalues, oldvalues){
                if(newvalues) {
                    this.isActiveName = '我的文档'

                    this.$nextTick(()=>{
                        if(document.getElementsByClassName("menu-items boxs3")[0]) {
                            // 当前盒子的父盒子设置样式
                            document.getElementsByClassName("menu-items boxs3")[0].parentNode.setAttribute("class", "el-submenu__title addJobButton");

                            if(document.getElementsByClassName('menu-items boxs1')[0]) {
                                document.getElementsByClassName('menu-items boxs1')[0].parentNode.setAttribute("class", "el-submenu__title")
                            } 
                            if(document.getElementsByClassName('menu-items boxs2')[0]){
                                document.getElementsByClassName('menu-items boxs2')[0].parentNode.setAttribute("class", "el-submenu__title")
                            }
                        }
                    })
                    this.$store.commit('myDocGoBack', null)
                }
                
            },
            deep: true,
            immediate: true
        }
    },
    mounted(){
        let newName = this.$store.state.isActiveNameLeftMenu;
        if(newName == '我的文档') {
            this.isActiveName = '我的文档'
            setTimeout(()=>{
                if(document.getElementsByClassName("menu-items boxs3")[0]) {
                    // 当前盒子的父盒子设置样式
                    document.getElementsByClassName("menu-items boxs3")[0].parentNode.setAttribute("class", "el-submenu__title addJobButton");
                }
            }, 500)
        } else {
            this.isActiveName = ''
            this.$nextTick(()=>{
                if(document.getElementsByClassName('el-submenu__title addJobButton')[0]) {
                    document.getElementsByClassName('el-submenu__title addJobButton')[0].setAttribute("class", "el-submenu__title")
                }
            })
        }
    },
    methods:{
        filterPath (path, index) {
            return path == null ? index + "" : path;
        },
        dataClick(item) {
            if( item.name == '业务审批' || item.name == '组织架构') {
                return;
            }
            // 设置父级菜单选中高亮样式
            if(item.name == '我的文档') {
                this.isActiveName = '我的文档'
                // 存储到vuex
                this.$store.commit('isActiveNameLeftMenu', this.isActiveName)
                this.$nextTick(()=>{
                    if(document.getElementsByClassName("menu-items boxs3")[0]) {
                        // 当前盒子的父盒子设置样式
                        document.getElementsByClassName("menu-items boxs3")[0].parentNode.setAttribute("class", "el-submenu__title addJobButton");
                    }
                })
            } else if(item.name == '我的收藏'){
                this.$ebus.$emit('clearSearch', item)
                this.isActiveName = ''
                // 存储到vuex
                this.$store.commit('isActiveNameLeftMenu', this.isActiveName)
                this.$nextTick(()=>{
                    if(document.getElementsByClassName('el-submenu__title addJobButton')[0]) {
                        document.getElementsByClassName('el-submenu__title addJobButton')[0].setAttribute("class", "el-submenu__title")
                    }
                })
            } else {
                this.isActiveName = ''
                // 存储到vuex
                this.$store.commit('isActiveNameLeftMenu', this.isActiveName)
                this.$nextTick(()=>{
                    if(document.getElementsByClassName('el-submenu__title addJobButton')[0]) {
                        document.getElementsByClassName('el-submenu__title addJobButton')[0].setAttribute("class", "el-submenu__title")
                    }
                })
            }

            this.$store.commit('pageBackTextShow', '')

            // 点击的数据信息存储到store
            this.$store.commit('checkedLeftAsideInfo', item)

            this.$emit('defaultTabValue')
            
            if(item.path) {
                this.$emit('restSearch');
                this.$router.push({
                    path: item.path
                })
            }
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .addJobButton {
    background: #f2f8ff !important;
    .boxs1 {
        background: #f2f8ff;
        color: #409EFF;
        .el-icon-s-grid {
            color: #409EFF;
        }
    }
    .el-submenu__icon-arrow {
        color: #409EFF;
    }
}
/deep/ .addJobButton {
    background: #f2f8ff !important;
    .boxs2 {
        background: #f2f8ff;
        color: #409EFF;
        .el-icon-s-grid {
            color: #409EFF;
        }
    }
    .el-submenu__icon-arrow {
        color: #409EFF;
    }
}
/deep/ .addJobButton {
    background: #f2f8ff !important;
    .boxs3 {
        background: #f2f8ff;
        color: #409EFF;
        .el-icon-document {
            color: #409EFF;
        }
    }
    .el-submenu__icon-arrow {
        color: #409EFF;
    }
}
</style>