<template>
  <el-dialog custom-class="share-dialog mini-dialog" ref='share-dialog' :id='minimize?"mini-style":""' :title="showMode.title" :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="800px" @close='closeDialog'>

    <!-- 共享模式 -->
    <template v-if="viewerKey == 'share'" >
      <div style="display: flex;width: 100%;">
          <div class="share-body">
            <div class="share-modular">
              <h1 class="share-modular-title">
                <span>共享内容</span>
                <i class="el-icon-circle-plus" @click="doAddFile"> 添加文件</i>
              </h1>
               <StepFileInfo :viewerKey="viewerKey" :files="files" :loading='transLoading' ></StepFileInfo>
            </div>
            <div class="share-modular" v-if='files.length > 0'>
                <h1 class="share-modular-title">共享设置</h1>
                <el-form label-width="100px" size='small'>
                  <el-form-item label="共享标题：">
                    <el-input v-model="shareName"></el-input>
                  </el-form-item>
                  <el-form-item label="权限设置：">
                    <div class="permission-box">
                      可共享 <el-switch :value="permissions.share" @input="v => onPermissionSet('share', v)" :active-value="1" :inactive-value="0"></el-switch>
                      不可复制 <el-switch :disabled='permissions.shadeAreaId !=0 ' :value="permissions.copy" @input="v => onPermissionSet('copy', v)" :active-value="1" :inactive-value="0"></el-switch>
                      <el-button type="text" style="font-size:14px;" @click="setPermissions">高级设置</el-button>
                      <span class="tips">（多个文件阅读范围和数据脱敏会失效哦！）</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="有效期：" >
                    <el-radio-group v-model="expirationDate" @change="changeExpirationDate">
                      <el-radio label="0">1天</el-radio>
                      <el-radio label="1">7天</el-radio>
                      <el-radio label="2">30天</el-radio>
                      <el-radio label="3">永久</el-radio>
                      <el-radio label="4">自定义时间</el-radio>
                    </el-radio-group>
                    <div  class="permission-box" v-if='expirationDate == "4"'>
                       起始时间 <el-date-picker
                          v-model="permissions.expireStartTime"
                          align="right"
                          type="datetime"
                          value-format='yyyy-MM-dd HH:mm:ss'
                          placeholder="设置起始时间"
                          default-time="00:00:00"
                          @blur="onPermissionSet('expireStartTime', permissions.expireStartTime)"
                          :picker-options="pickerOptions">
                        </el-date-picker>
                        截止时间 <el-date-picker
                          v-model="permissions.expireEndTime"
                          @blur="onPermissionSet('expireEndTime', permissions.expireEndTime)"
                          align="right"
                          type="datetime"
                          placeholder="设置过期时间"
                          value-format='yyyy-MM-dd HH:mm:ss'
                          :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                  </el-form-item>
                </el-form>
            </div>
           
           <ShareSuccessDialog ref='ShareSuccessDialog'></ShareSuccessDialog>
           <AdvancedSettingPermission ref='AdvancedSettingPermission' :permissions='permissions' :viewerKey='viewerKey'  @onPermissionSet="onPermissionSet"></AdvancedSettingPermission>

          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="doCreateQuickShare" :loading="qrCode.loading" :disabled='files.length == 0'>面对面共享</el-button> -->
        <el-button type="primary" @click="doNext" :loading="createLoading" :disabled='files.length == 0'>创建共享链接</el-button>
      </span>
    </template>

    <!-- 请求模式 -->
    <template v-else>
      <el-steps style="height: 400px;width: 160px;" direction="vertical" :active="active" align-center space="80px">
        <el-step title="请求文件" description="申请文件列表"></el-step>
        <el-step title="申请信息" description="申请备注信息"></el-step>
      </el-steps>
      <div class="share-body">
        <StepFileInfo :viewerKey="viewerKey" :files="files" :qrCode="qrCode" :readerEnable="true" v-show="active == 0"></StepFileInfo>
        <StepRequest ref="stepRequestInfo" v-show="active == 1"></StepRequest>
      </div>
      <span slot="footer" class="dialog-footer-request">
        <div></div>
        <!-- <el-button type="primary" @click="doCreateQuickRequest" :loading="qrCode.loading" v-show="active == 0 " :disabled='files.length == 0'>快速申请</el-button> -->
        <span v-show="active != 0"></span>
        <div>
          <el-button @click="doAddFile(true)" v-show="active == 0 && $route.path!='/file/othershare'">继续添加文件</el-button>
          <el-button @click="doLast" v-show="active > 0 && active < 3 && !createLoading">上一步</el-button>
          <el-button type="primary" :disabled='createLoading || files.length == 0' @click="doNextRequest" :loading="createLoading" >{{active < 1 ? "下一步" : (active==1 ? "提交申请"
              : "复制链接" )}}</el-button>
        </div>
      </span>
    </template>

    <!-- 弹出二维码 -->
    <el-dialog title="扫描二维码" :showClose="!(showMode.title=='创建共享')" width="350px"  :close-on-click-modal="false" @close="closeQr" :visible.sync="qrCode.visable" top="20vh" append-to-body>
      <div class='qrCode-style'>
        <qr :margin="10" :text="qrCode.url"   :size="200"></qr>
        <div v-if="(showMode.title=='创建共享')" style="display:flex;justify-content:center">
          <el-button @click="doStopQuickShare">停止共享</el-button>
        </div>
        <p v-else>请文件所有者扫描二维码进行面对面授权</p>
      </div>
      <br>
    </el-dialog>
    
  </el-dialog>
</template>

<script>
  import qr from 'vue-qr'
  import StepFileInfo from './StepFileInfo.vue'


  import StepBaseInfo from './StepBaseInfo.vue'
  import StepRequest from './StepRequest.vue'
  import ShareSuccessDialog from './ShareSuccess.vue'
  import AdvancedSettingPermission from './AdvancedSettingPermission.vue'

  import { qrReadAPI,shareAPI } from 'liankong-ui-api'

  //socket 通讯
  import client from '@lk/client'


  export default {
    components: {
      qr,
      StepFileInfo,
      StepBaseInfo,
      StepRequest,
      ShareSuccessDialog,
      AdvancedSettingPermission
    },
    data() {
      return {
        minimize:false,//是否最小化
        mode: {
          'new': {
            title: "创建共享"
          },
          'edit': {
            title: "编辑共享"
          },
          'request': {
            title: "申请授权"
          }
        },
        active: 0,
        createLoading: false,
        shareUrl: "",

        //二维码
        qrCode: {
          visable: false,
          loading: false,
          ticket: null,
          time: null,
          url: null
        },
        regionDes:false,
        iframeSrc:'',//嵌入iframe的地址
        id:null,
        transLoading:false,
        shareName:null,//共享名称
        expirationDate:"3",//有效期，默认永久
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 3600 * 1000 * 23;
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
      }
    },
    
    computed: {
      //购物车显示指示器
      dialogVisible: {
        get() {
          if(this.files.length == 1){
            this.shareName = this.files[0].name;
          }else if(this.files.length>1){
            this.shareName = this.files[0].name+'等'+this.files.length+'个文件';
          }
          return this.$store.state.cartForm.visable
        },
        set(value) {
          this.$store.commit("setCartShow", value)
        }
      },
      //获取文档Id，看是否可设置阅读范围，如果资源不是多个或不是文件夹
      docForSetReadPages() {
        const resources = this.$store.state.cart.views[this.viewerKey].docList || []
        if(resources.length == 1){
          //如果是文件，则可以选择，就算只有一个且是文件夹，也不行
          const doc = resources[0]
          return doc.type === 1 ?  doc: null
        }
        return null       
      },

      //显示模式
      showMode() {
        const viewerKey = this.viewerKey
        const modeKey = viewerKey === 'share' ? 'new' : 'request'

        return this.mode[modeKey]
      },

      //购物车视图key
      viewerKey() {
        return this.$store.state.cartForm.viewerKey
      },

      //当前共享任务
      shareInfo() {
        return this.$store.state.cart.shareInfo
      },

      //文件列表
      files() {
        const viewer = this.$store.state.cart.views[this.viewerKey]
        return viewer ? viewer.docList : []
      },

      //权限列表
      permissions() {
        return this.$store.state.cart.permissions
      },

      //目标列表
      targetList() {
        const targets = this.$store.state.cart.targets
        return Object.keys(targets).map(k => targets[k])
      },

      //共享目标map
      targets() {
        return this.$store.state.cart.targets
      },

    },
    methods: {
      changeExpirationDate(){
        let newTime = this.$options.filters["date"](new Date());
        if(this.expirationDate == "0"){
          const day = new Date().getTime() + 3600 * 1000 * 24;
          const expireEndTime =  this.$options.filters["date"](new Date(day));
          this.onPermissionSet('expireStartTime', newTime)
          this.onPermissionSet('expireEndTime', expireEndTime)
        }else if(this.expirationDate == "1"){
          const day = new Date().getTime() + 3600 * 1000 * 24 * 7;
          const expireEndTime =  this.$options.filters["date"](new Date(day));
          this.onPermissionSet('expireStartTime', newTime)
          this.onPermissionSet('expireEndTime', expireEndTime)
        }else if(this.expirationDate == "2"){
          const day = new Date().getTime() + 3600 * 1000 * 24 * 30;
          const expireEndTime =  this.$options.filters["date"](new Date(day));
          this.onPermissionSet('expireStartTime', newTime)
          this.onPermissionSet('expireEndTime', expireEndTime)
        }else if(this.expirationDate == "3"){
          this.onPermissionSet('expireStartTime', null)
          this.onPermissionSet('expireEndTime', null)
        }else if(this.expirationDate == "4"){
          this.onPermissionSet('expireStartTime', null)
          this.onPermissionSet('expireEndTime', null)
        }
      },
      closeModel(){
        this.regionDes = !this.regionDes;
      },
      show({ mode }) {
        this.active = 0

      },
      closeQr(){
        this.qrCode.loading = false
      },
      //权限设置
      onPermissionSet(key, value){
        if(key === 'expireStartTime' || key === 'expireEndTime'){
          //时间处理
          if(value != null){
            if(value.lastIndexOf(':') == -1){
              value = value.trim() + ' 00:00:00'
            }
          }
          
           
          const oldValue = this.shareInfo[key]
          this.shareInfo[key] = value

          //时间设置检测
          if(!this.$options.filters['dateRengeCompare'](this.shareInfo['expireStartTime'], this.shareInfo['expireEndTime'])){
            this.$message.error("结束时间不应在开始时间之前");

            this.$nextTick(() => {
              this.shareInfo[key] = null;
              this.permissions[key] = null
            })
            return
          }
        }else if(key == 'envelops'){
          this.$store.state.cart.envelops = value;
        }else{
          this.$set(this.permissions, key, value)
        }
      },

      //快速共享
      doCreateQuickShare(){
        //生成客户端id
        const clientId = (+new Date())

        //构建共享资源结构
        this.files.map(doc => doc.documentId = doc.id)

        this.qrCode.loading = true

        //向服务器申请二维码
        qrReadAPI.quickCreateQR(this.files).then(({code, data, message}) => {
          this.qrCode.loading = false

          if(code == 0){
            //获取到二维码ticket
            this.qrCode.ticket = data
            this.qrCode.time = new Date()

            //显示二维码
            this.qrCode.url = this.$options.filters['fastShareQRCode'](this.qrCode.ticket, clientId)

            this.$nextTick(() => {
              this.qrCode.visable = true
            })

            //4分钟刷新ticket
          }else{
            this.$message.error("获取二维码失败，稍后请重试!")
          }
        })
      },

      //快速申请
      doCreateQuickRequest(){
        this.qrCode.loading = true

        //构建共享资源结构
        this.files.map(doc => doc.documentId = doc.id)

        //向服务器申请二维码
        qrReadAPI.quickRequestCreateQR(this.files).then(({code, data, message}) => {
          if(code == 0){
            //获取到二维码ticket
            this.qrCode.ticket = data
            this.qrCode.time = new Date()

            //建立数据回传通道
            client.open().then(state => {
              if(state){
                client.getChannel(`/liankongUser/${this.qrCode.ticket}/auth_code_request`, ({code, data, message}) => {
                  //收到审批结果
                  if(code == 0){
                    //状态消息测试
                    this.$store.commit('cart/updateDocumentRequestState', {
                      docList: data,
                      requestState: 1
                    })
                  }
                })
                //显示二维码
                this.qrCode.url = this.$options.filters['requestUrl']('find', '', this.qrCode.ticket, '')

                this.$nextTick(() => {
                  this.qrCode.visable = true
                })
              }else{
                this.$message.error("数据通道建立失败，稍后请重试!")
              }
            })
          }else{
            this.$message.error("获取二维码失败，稍后请重试!")
          }
        })
      },

      //继续添加文件
      doAddFile(flag) {
        // this.dialogVisible = false;
        if(this.viewerKey == 'share'){
          this.minimize = true;
          document.getElementsByClassName('v-modal')[0].setAttribute('style','display:none;z-index: 2000;')
          let ele = document.createElement('div')
          ele.className = 'masks';
          ele.onclick=()=>{
            this.minimize = false;
            document.getElementsByClassName('v-modal')[0].setAttribute('style','display:block;z-index: 2000;')
          }
         document.getElementsByClassName('mini-dialog')[0].appendChild(ele)
        }
        if(flag){
          this.qrCode.ticket = null 
        }
      },

      //获取共享数据
      getShareTaskData() {
        const shareSubmitData = {}

      },

      doLast() {
        if (this.active > 0) {
          this.active--
        }
      },
      /**
       * 下一步
       * TODO 重构代码结构，抽离分步组件
       */
      doNext() {
        if(this.shareName.trim()){
          if(this.shareName.trim().length<2 || this.shareName.trim().length > 30){
            this.$message.error('共享名称请设置2~30个字符！');
            this.shareName = ''
            return
          }
          this.$store.state.cart.shareInfo.name = this.shareName.trim();
        }

        if (this.files.length <= 0) {
          this.$message({
            message: '文件列表不能为空哦',
            type: 'error'
          })
          return;
        } else {
          let filesIdList = this.files.map(item=>item.id)
          this.$store.dispatch('doc/batchCheckDocumentSharePermission',filesIdList).then(res=>{
            let {code,data,message} = res
                
                if(code==0){
                  if(data.length>0){
                    this.$confirm('当前文件存在不可共享文件,是否自动转换文件之后进行共享?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        this.createLoading = true;
                        let converFile = data.map(item=>item.id)
                          this.$store.dispatch('doc/batchConvertOFD',converFile).then(ofdRes=>{
                            this.createLoading = false;
                            if(ofdRes.code==0){
                              this.createShare()
                            } else {
                              this.$message.error(ofdRes.message)
                            }
                          })
                      }).catch(() => {});
                      
                  }else{
                    this.createShare()
                  }
                } else {
                  this.$message.error(message)
                }
            })
        }
      },
      createShare(){
         this.createLoading = true


          if(this.permissions['readPageType'] == 1 && this.permissions['readPage']!=''&&this.permissions['readPage']){
              const nums = this.permissions['readPage'].split(',')
              let value = []
              nums.forEach(i => {
                  if(Number(i)-1>=0&&value.indexOf(Number(i)-1)==-1){
                    value.push(Number(i)-1)
                  }
              })
              if(value.length>0){
                  this.permissions['readPage'] = value.join(',')
              } else {
                  this.permissions['readPage'] = ''
              }
          }
        
          // 创建共享
          this.$store.dispatch("cart/createShare")
          .then((data) => {
            this.shareUrl = this.$options.filters["shareUrl"](data.urlShort);
            this.createLoading = false;
            this.$refs['ShareSuccessDialog'].show(this.shareUrl)
            this.dialogVisible = false;
          })
          .catch((error) => {
            this.createLoading = false
            this.$message({
              message: error,
              type: 'error'
            })
            this.$store.state.cart.permissions['readPage'] = ''
          })
      },

      //请求下一步
      doNextRequest(){
        if (this.active == 0) {
          if (this.files.length <= 0) {
            this.$message({
              message: '文件列表不能为空哦',
              type: 'error'
            })
            return;
          }
        }

        let arr = this.files.filter(m => m.state == 1)
        if(arr.length != 0) {
            this.$message.error('申请列表中存在未上传完成的文件')
            return
        }
        let arr1 = this.files.filter(m => (m.suffix == 'zip' || m.suffix == 'mp3' || m.suffix == 'mp4'))
        if(arr1.length != 0){
            this.$message.error('申请列表中存在 zip、mp3、mp4 格式的文件')
            return
        }
        
        if (this.active == 1) {
          this.createLoading = true
          //创建申请
          let docId = []
          this.files.filter(function (i) {
            docId.push(i.id)
          })
         
         let requestForm  = {

         }
         if(this.files[0].shareId){
          requestForm ={
            description: this.$refs["stepRequestInfo"].note,
            docId,
             shareId:this.files[0].shareId
          }
         }else{
          requestForm ={
            description: this.$refs["stepRequestInfo"].note,
            docId
          }
         }

          this.$store.dispatch("cart/shareResourceRequest", requestForm)
          .then(res => {
            if(res.code == 0){
              this.$message.success("申请已发送!")
            }else{
              this.$message.error(res.message)
            }
            this.createLoading = false
            this.$refs["stepRequestInfo"].note = ''
            this.dialogVisible = false
          })
          .catch((error) => {
            this.createLoading = false
            this.$message({
              message: error,
              type: 'error'
            })
          })
        } else {
          this.active++
        }
      },

      closeDialog(){
        this.active = 0;
        this.qrCode.ticket = null;
        this.shareName = null;
        this.createLoading = false;
        this.minimize =  false;
      },

      doStopQuickShare(){
        qrReadAPI.closeTicket(this.qrCode.ticket).then(({code,data,message})=>{
        })
        this.qrCode.visable = false
      },
      setPermissions(){
        this.$refs['AdvancedSettingPermission'].show()
      }
    },

    created() {
    },
    mounted(){
      this.minimize = false;
      this.$ebus.$on('showMaximize', val=>{
        if( document.getElementsByClassName('v-modal')[0]) {
          this.minimize =  false;
          document.getElementsByClassName('v-modal')[0].setAttribute('style','display:block;z-index: 2000;')
        }
      })
      this.$ebus.$on('hideMaximize', val=>{
        this.dialogVisible = false;
        
        this.closeDialog()
        if(this.files.length != 0){
          this.files.forEach(items=>{
            this.$store.commit("cart/remove", {
                viewerKey: this.viewerKey,
                doc: items,
            })
          })
        }
        
      })
    },
    beforeDestroy() {
      this.minimize = false;
      this.qrCode.ticket = null;
      this.$ebus.$off('showMaximize');
      this.$ebus.$off('hideMaximize');
    },
  }
</script>

<style lang='less'>
  .share-dialog {
    .el-dialog__body {
      display: flex;
    }
  }
</style>

<style lang='less' scoped>
#mini-style{
  box-shadow:0 0 15px rgba(0, 0, 0, 0.4);
  // transition-duration: 1s;
  transform:scale(0.3);
  position:fixed;
  right:-250px;
  bottom:-130px;
  top:inherit;
  left:inherit;
  cursor:pointer;
  background:#fff;
  /deep/.el-dialog{
    margin:0!important;
    .masks{
      width:100%;
      height:100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
    }
  }
}
  .share-body {
    margin: 0 20px;
    flex: 1;
  }
  .tips{
    font-size: 12px;
    color: #999;
  }
  /deep/.el-dialog__body{
    padding:10px 20px;
  }
  .dialog-footer-request{
    display: flex;
    justify-content: space-between;
  }

  .dialog-footer{
    display: flex;
    justify-content: center;
  }
  .model{
    position: fixed;
    top: 15vh;
    height: 640px;
    width: 1000px;
    left: 50%;
    margin-left: -500px;
    z-index: 999;
    border-radius: 6px;
    overflow: hidden;
  }
  .model div{
    position: relative;
    background: #fff;
    line-height: 24px;
    color: #303133;
    font-size: 18px;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .closeBtn{
    position: absolute;
    top: 10px;
    right: 20px;
}
.share-modular{
  margin-bottom: 10px;
  .permission-box{
    .el-switch{
      margin-right: 20px;
    }
    .el-date-editor{
      margin:0 15px 0 5px;
    }
  }
  .share-modular-title{
    border-left: 4px solid #0099cc;
    padding-left: 6px;
    color: #333;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    i{
      color:#cd292a;
      cursor: pointer;
    }
  }
}
</style>