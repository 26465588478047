<template>
  <el-form label-width="90px" class="permission-form" >
        <div style="display:flex;align-items: center;">
          <el-form-item label="可保存" style="margin:0 40px 0 0;">
              <el-switch :value="permissions.archivableFlag" @input="v => doPermissionSet('archivableFlag', v)" :active-value="1" :inactive-value="0"></el-switch>
               <el-tooltip class="item" effect="dark" content="把文件直接共享给目标，目标用户可保存，其它权限失效" placement="right">
              <i class="el-icon-info" style="color:#999;margin-left:10px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-radio-group  style="margin:0 40px 0 0;" v-show="permissions.archivableFlag == 1" :value="permissions.archivable" @input="v => doPermissionSet('archivable', v)">
            <el-radio :label="1">源文件</el-radio>
            <el-radio :label="2">OFD版式文件</el-radio>
          </el-radio-group>
        </div>
        <div v-if='permissions.archivableFlag == 0'>
          <el-form-item v-if="docInfo" label="阅读范围">
            <el-radio-group :value="permissions.readPageType" @input="v => doPermissionSet('readPageType', v)">
              <el-radio :label="0">全部页面</el-radio>
              <el-radio :label="1">指定页面</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="指定页面" v-show="permissions.readPageType == 1">
              <el-input placeholder="1,2,4,5" size='small' :value="permissions.readPage" @input="v => doPermissionSet('readPage', v)"></el-input>
          </el-form-item>
          <div style="display: flex;" class="switch-box">
          </div>
          <div style="display: flex;" class="switch-box">
            <el-form-item label="可打印">
              <el-switch :value="permissions.print" @input="v => doPermissionSet('print', v)" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="阅后即焚" v-if='accessType=="AUTH"'>
              <el-switch :value="permissions.havaReadFire" @input="v => doPermissionSet('havaReadFire', v)" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="隐写溯源" >
              <el-switch :value="permissions.hideWrite" @input="v => doPermissionSet('hideWrite', v)" :active-value="1" :inactive-value="0" :disabled='configValue == "0"'></el-switch>
            </el-form-item>
            <el-form-item label="动态水印">
              <el-switch :value="permissions.isDynamic" @input="v => doPermissionSet('isDynamic', v)" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
          </div>
          <div style="display: flex;">
            <el-form-item label="设置水印">
              <el-button size='small' @click="doSetWather" v-show="JSON.stringify(changWatherMsg)=='{}'" >设置水印</el-button>
              <p style="margin: 0;" v-show="permissions.water!=0&&JSON.stringify(changWatherMsg)!='{}'">已选水印: <el-tag
                closable
                @close="restChangeWather" >
                水印名称:{{`${changWatherMsg.name}`.length>5?`${changWatherMsg.name}`.substr(0,5)+'...':changWatherMsg.name}} 水印内容: {{changWatherMsg.text}} 水印样式:{{changWatherMsg.fontSize}}px {{changWatherMsg.fontFamily}} {{changWatherMsg.color}}
              </el-tag></p>
              <!-- <el-switch :value="permissions.water" @input="v => doPermissionSet('water', v)" :active-value="1" :inactive-value="0"></el-switch> -->
            </el-form-item>
          </div>
          <el-form-item label="可打印次数" v-show="permissions.print == 1">
            <el-input-number :value="permissions.printCount" @input="v => doPermissionSet('printCount', v)" :min="0"></el-input-number> <span>0表示不限制</span>
          </el-form-item>
          <el-form-item label="数据脱敏" v-show="docInfo != null">
            <el-button size='small' @click="doSemantics">语义遮掩<i class="el-icon-star-on" v-if='envelopsKey'></i></el-button>
            <el-button size='small' @click="doKeyword">关键字遮掩<i class="el-icon-star-on" v-if='keywordKey'></i></el-button>
            <el-button size='small' @click="doSetEnvelop">画区域遮掩<i class="el-icon-star-on" v-if='shadeAreakey'></i></el-button>
          
          </el-form-item>
        </div>
      
        
        <setEnvelop  ref="setEnvelop" :docInfo='docInfo' @doPermissionSet='doPermissionSet'  @disableCopy="disableCopy"></setEnvelop>
        <SetSemantics ref='SetSemantics' :docInfo='docInfo'  @doPermissionSet='doPermissionSet'></SetSemantics>
        <SetKeyword ref='SetKeyword' :docInfo='docInfo'  @doPermissionSet='doPermissionSet'></SetKeyword>
        <!-- <el-form-item label="授权设备数">
          <el-input-number v-model="form.deviceCount"  :min="0"></el-input-number> <span>0表示不限制</span>
        </el-form-item> -->
        <ShareWatemarkDialog ref="shareWatemark" @watherInfo="onWatemarkCreated"/> 
  </el-form>
</template>

<script>
import ShareWatemarkDialog from './ShareWatemarkDialog.vue'
 import setEnvelop from './SetEnvelop.vue'
 import SetSemantics from './SetSemantic.vue'
 import SetKeyword from './SetKeywords.vue'
  import { documentAPI } from 'liankong-ui-api' 
export default {
  props: {
    //共享信息
    shareInfo:{
      type: Object,
      default: () => ({})
    },

    //权限列表
    permissions: {
      type: Object,
      default: () => ({})
    },

    docInfo: {
      type: Object,
      default: null
    },
    accessType:{
      type: String,
      default: "AUTH"
    }
  },
  components: {
    ShareWatemarkDialog,
    setEnvelop,
    SetSemantics,//语义遮掩
    SetKeyword,//关键字遮掩
  },
  computed: {
     
    configValue(){
      return this.$store.state.share.configValue
    },
  },
  data(){
    return {
      envelopList:[],
      list:{},
      copyDisable:false,
      isRegion:false,
      envelopCheck:[],
      changWaterState:'2',
      defaultExpireEndTime:'',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 23;
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '明天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      changWatherMsg:{}, // 选择水印信息
      envelopsKey:false,
      keywordKey:false,
      shadeAreakey:false
    }
  },
  watch:{
    '$store.state.cart.views.share.docIdMap':{
       handler(newValue) {
        this.changeChildData()
       },
       deep: true
    }
  },
  methods: {
    restChangeWather(){
      this.changWatherMsg = {}
      this.permissions.water = 0
      this.doPermissionSet('water',0)
    },
    onWatemarkCreated(value,changWatherMsg){
      this.changWatherMsg = changWatherMsg[0]
      this.permissions.water = value
      this.doPermissionSet('water',value)
    },
    changeChildData(){
      this.$refs['setEnvelop'].shadeAreaId = 0
      this.changWatherMsg = {}
      this.envelopsKey = false
      this.keywordKey = false
      this.shadeAreakey = false
    },
    doSetWather(){
      this.$refs['shareWatemark'].showMakeLoding('')
    },
    // 设置区域遮掩
    doSetEnvelop(){
        documentAPI.checkDocList(this.docInfo.id).then(res=>{
          if(res.code == 0) {
            this.$refs['setEnvelop'].shadeAreaId = this.permissions.shadeAreaId
            this.$refs['setEnvelop'].dialogVisible = true;
            this.$refs['setEnvelop'].getIframeUrl();
          } else {
            this.$message.error(res.message)
          }
        })
    },
    // 语义遮掩
    doSemantics(){
      this.$refs.SetSemantics.dialogVisible = true;
      this.$refs.SetSemantics.getEnvelopField()
    },
    doKeyword(){
      if(this.permissions.shadeKeyword){
        this.$refs.SetKeyword.keyWordList = this.permissions.shadeKeyword.split(',')
      }
      this.$refs.SetKeyword.dialogVisible = true;
    },
    disableCopy(){
      this.copyDisable = true
    },
    //权限项设置
    doPermissionSet(key, value ,isShow){
      if(key == 'envelops'){
        this.envelopsKey = isShow;
      }else if(key == 'shadeKeyword'){
        this.keywordKey = isShow;
      }else if(key == 'shadeAreaId'){
        this.shadeAreakey = isShow;
      } else if(key == 'readPage'){
        value = value.replace(/，/g,',')
      } else if(key == 'expireStartTime') {
        this.defaultExpireEndTime = value!=''?`${value}`.split(' ')[1]:value
      }
      this.$emit('onPermissionSet', key, value)
    },
  },

  created(){
    // this.$store.dispatch('share/loadMyWatemark')
    // this.$store.commit("shares/taskSetPermissions", this.form)
  }
}
</script>

<style lang="less" scoped>
.permission-form{
  /deep/ .el-form-item{
    margin-bottom: 10px;
  }
  .width50{
    width: 44%;
    float: left;
  }
}
.tips{
  margin-left: 20px;
  color: #999;
}
.switch-box{
  clear: both;
  .el-form-item{
    margin-right: 40px;
  }
}
</style>