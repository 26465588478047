import BaseAPI from '../BaseAPI.js'
import { Store } from '@lk/cache'

/**
 * 组织机构服务API
 * 
 * @author Daniel
 * @date 
 */
export default class GroupAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/usercenter"
    }

    /**
     * 获取组织信息
     * @param {*} orgId 
     */
    orgInfoById(orgId) {
        return this.ajax.get(`${this.moduleName}/organization/${orgId}`)
    }

    /**
     * 获取组织列表
     */
    getUserOrgList() {
        return this.ajax.get(`${this.moduleName}/organization/getUserOrgList`)
    }

    /**
     * 创建组织/企业
     * @param {*} query
     */
    createOrg(query) {
        return this.ajax.post(`${this.moduleName}/organization`, query)
    }

    /**
     * 创建群组/部门
     * @param {*} id
     * @param {*} name
     */
    createDepartment(query) {
        return this.ajax.post(`${this.moduleName}/group`, query)
    }

    /**
     * 获取群组列表/部门
     * @param {*} id
     */
    getGroupList(id) {
        return this.ajax.post(`${this.moduleName}/group/list`, id)
    }

    /**
     * 分页获取组成员列表
     * @param {*} id
     */
    getGroupPeopleList(query) {
        return this.ajax.post(`${this.moduleName}/group/member/list`, query)
    }

    /**
     * 修改组织名称
     * @param {*} id
     */
    renameOrganization(query) {
        return this.ajax.put(`${this.moduleName}/organization`, query)
    }


    /**
     * 删除组织
     * @param {*} id
     */
    deletaOrganization(id) {
        return this.ajax.delete(`${this.moduleName}/organization/${id}`)
    }


    /**
     * 向组织种添加用户
     * @param {*} query
     */
    addMember(query) {
        return this.ajax.post(`${this.moduleName}/organization/member/add`, query)
    }

    /**
     * 申请加入组织
     * @param {*} query
     */
    applyJoinOrg(query) {
            return this.ajax.post(`${this.moduleName}/organization/applyJoin`, query)
        }
        /**
         * 申请加入部门
         * @param {*} query
         */
    applyJoinGroup(query) {
        return this.ajax.post(`${this.moduleName}/group/applyJoin`, query)
    }

    /**
     * 管理员审批加入组织
     * @param {*} query
     */
    approveJoin(query) {
        return this.ajax.post(`${this.moduleName}/apply/approveJoin`, query)
    }



    /**
     * 组织转移
     * @param {*} query
     */
    orgTransfer(query) {
        return this.ajax.post(`${this.moduleName}/organization/transfer/owner`, query)
    }

    /**
     * 未分配部门的成员列表
     * @param {*} query
     */
    noGroupedMember(query) {
        return this.ajax.post(`${this.moduleName}/organization/no/grouped/member/list`, query)
    }

    /**
     * 检索组织
     * @param {*} query
     */
    searchOrg(query) {
        return this.ajax.post(`${this.moduleName}/organization/page/list`, query)
    }

    /**
     * 根据组织id获取部门列表
     * @param {*} query
     */
    groupList(query) {
        return this.ajax.post(`${this.moduleName}/group/list`, query)
    }


    /**
     * 申请加入组织的列表
     * @param {*} query
     */
    getOrgApplyList(query) {
        return this.ajax.post(`${this.moduleName}/apply/getUserApplyList`, query)
    }

    /**
     * 邀请用户加入
     * @param {*} query 
     */
    inviteUser(query) {
        return this.ajax.post(`${this.moduleName}/organization/inviteUser`, query)
    }

    /**
     * 查看被邀请的列表
     * @param {*} query 
     * @returns 
     */
    getUserInviteList(query) {
        return this.ajax.post(`${this.moduleName}/organization/getUserInviteList`, query)
    }

    /**
     * 审批邀请
     * @param {*} query 
     * @returns 
     */
    userApproveInvite(query) {
        return this.ajax.post(`${this.moduleName}/organization/approve/invite`, query)
    }

    /**
     * 获取所有组织列表信息
     * @returns 
     */
    getAllOrgList() {
        return this.ajax.get(`${this.moduleName}/organization/list`)
    }

    /**
     * 组织文档 获取组织数据
     * @returns 
     */
    getMyOrgTreeLists() {
        return this.ajax.get(`${this.moduleName}/organization/getUserOrgAndDeptList`)
    }

    /**
     * 导出
     * @returns 
     */
    exportOrgInfo() {
        const accessToken = Store.get("access_token")

        const url = `${this.moduleName}/organization/exportOrgTemplate?access_token=${accessToken}`
        window.location.href = `/prod-api` + url

        // return this.ajax.get(`${this.moduleName}/organization/exportOrgTemplate`)
    }

    /**
     * 导入
     * @param {*} query 
     * @returns 
     */
    exportUploadOrg(query) {
        return this.ajax.post(`${this.moduleName}/organization/importOrgInformation`, query, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    /**
     * 分页的组织列表
     * @param {*} query 
     * @returns 
     */
    getPageOrgList(query) {
        return this.ajax.post(`${this.moduleName}/organization/page/list`, query)
    }

    /**
     * 获取组织下的成员列表
     * @param {*} query 
     * @returns 
     */
    getOrgMemberList(query) {
        return this.ajax.post(`${this.moduleName}/organization/member/list`, query)
    }

    /**
     * 根据父级部门Id获取子部门列表
     * @param {*} query 
     * @returns 
     */
    getChildGroupById(id, type) {
        return this.ajax.get(`${this.moduleName}/group/children/${id}/${type}`)
    }

    /**
     * 退出组织
     * @param {*} orgId 
     * @returns 
     */
    getOutOrg(orgId) {
        return this.ajax.get(`${this.moduleName}/organization/member/exit/${orgId}`)
    }

    /**
     * 添加组织（管理员）
     * @param {*} query 
     * @returns 
     */
    addTenant(query) {
        return this.ajax.post(`${this.moduleName}/organization/addTenant`,query)
    }

    /**
     * 获取游离用户列表（管理员）
     * @param {*} query 
     * @returns 
     */
    getPersonalUserList(query) {
        return this.ajax.post(`${this.moduleName}/user/personal/user/list`,query)
    }

     /**
     * 删除游离用户（管理员）
     * @param {*} query 
     * @returns 
     */
    deleteUser(userId) {
        return this.ajax.delete(`${this.moduleName}/user/${userId}`)
    }

    /**
     * 工具端 组织管理页面- 获取部门下成员
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
     toolDepMemberList(query) {
        return this.ajax.post(`${this.moduleName}/group/member/list`, query)
    }



   
    

}