<template>
  <div id="app" >
    <router-view/>

    <!-- 将上传组件全局注册 -->
    <global-uploader  />
    <!-- 全局阅读组件 -->
    <publicReader></publicReader>
    
  </div>
</template>
<script>
import GlobalUploader from './components/Upload/Index.vue'
import publicReader from './components/Reader.vue'
export default {
  components: {
    GlobalUploader,
    publicReader
  },
  created() {
        //默认初始化字典
    this.$store.dispatch('dict/init')
    // 检测是否开启隐写溯源，很多个页面用到
    this.$store.dispatch('share/init')

    // 初始化获取标签列表数据
    // this.$store.dispatch('dict/getTagList',{
    //   pageNum: 1,
    //   pageSize: 999999
    // })
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
