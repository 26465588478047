<template>
   <div class="logo">
      <transition-group name="fade" tag="div">
          <img src="@/assets/logo.png" alt="logo" key="1"/>
          <span class="text" v-show="!isCollapse" key="2">友虹文档中台</span>
      </transition-group>
    </div>
</template>
<script>
export default {
    props:{
        isCollapse:{
            type: Boolean
        }
    },
    data(){
        return{
        }
    }
}
</script>
<style lang="less" scoped>
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.logo div{
  height: 60px;
  background: rgb(84, 92, 100);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
    .text {
      margin-left: 10px;
    }
}
</style>