import ManagerRouter from './manager/index.js'
// import UserLayout from '@/layout/user/Index.vue'
// import PageLayout from '@/layout/PageLayout.vue'
import PageLayout from '@/layout/liankong/Index.vue'


/**
 * 系统路由配置
 * meta: {
 *   label: 显示名称,
 *   menu: 显示的菜单，main主菜单，sub子菜单
 * }
 */

export default [
    //管理后台菜单
    ManagerRouter,
    {
        path:'/managerLange',
        name: "manager-lange",
        component: ()=>import('@/views/statistics/largeSizeScreen.vue'),
    },
    {
        path:'/managerLanges',
        name: "manager-langes",
        component: ()=>import('@/views/statistics/largeSizeScreen-new.vue'),
    },
    {
        path:'/earlyWarning',
        name: "early-warning",
        component: ()=>import('@/views/statistics/earlyWarning.vue'),
    },
    {
        path:'/managerStatistics',
        name: "manager-statistics",
        component: ()=>import('@/views/statistics/managerStatistics.vue'),
    },
    
    //首页
    {
        path: '/',
        name: "Index",
        component: PageLayout,
        // meta: {
        //     label: "首页",
        //     menu: 'main'
        // },
        children: [{
            path: "/",
            component: () =>
                import ('@/views/index/Index.vue'),
        }, ]
    },

    // 快速申请
    // {
    //     path: '/fastapply',
    //     name: "fastapply",
    //     component: PageLayout,
    //     children: [{
    //         path: "/",
    //         component: () =>
    //             import ('@/views/share/FastApply.vue'),
    //     }, ]
    // },


    // 文档
    // {
    //     path: '/document',
    //     name: "Document",
    //     component: PageLayout,
    //     meta: {
    //         label: "文档",
    //         menu: 'main',
    //         cartViewKey: "share"
    //     },
    //     children: [{
    //         path: "/",
    //         name: "DocumentFile",
    //         component: () =>
    //             import ('@/views/files/File.vue'),
    //         meta: {
    //             label: "我的文档",
    //             icon: 'el-icon-files',
    //             menu: 'sub'
    //         },
    //     }, {
    //         path: "apply",
    //         name: "DcocumentApply",
    //         component: () =>
    //             import ('@/views/share/ApplyRecord.vue'),
    //         meta: {
    //             label: "我的申请",
    //             icon: 'el-icon-coordinate',
    //             menu: 'sub'
    //         },
    //     }]
    // },
    // 共享
    {
        path: '/share',
        name: 'share',
        component: PageLayout,
        children: [{
            path: ':url',
            name: 'url',
            component: () =>
                import ('@/views/share-reader/ShareReader.vue'),
        }]
    },
    
    // 共享
    // {
    //     path: '/shares',
    //     name: "shares",
    //     component: PageLayout,
    //     meta: {
    //         label: "共享",
    //         menu: 'main'
    //     },
    //     redirect: '/shares/shares',
    //     children: [{
    //         path: 'shares',
    //         name: 'shares',
    //         component: () =>
    //             import ('@/views/share/SharePage.vue'),
    //         meta: {
    //             label: "我的共享",
    //             icon: 'el-icon-share',
    //             menu: 'sub'
    //         },
    //     }, {
    //         path: 'detail',
    //         name: 'detail',
    //         component: () =>
    //             import ('@/views/share/ShareDetail.vue'),
    //     }, {
    //         path: 'edit',
    //         name: 'edit',
    //         component: () =>
    //             import ('@/views/share/ShareEdit.vue')
    //     }]
    // },

    // 授权
    // {
    //     path: '/confer',
    //     name: "Confer",
    //     component: PageLayout,
    //     meta: {
    //         label: "授权",
    //         menu: 'main'
    //     },
    //     children: [{
    //         path: '/',
    //         name: 'request',
    //         component: () =>
    //             import ('@/views/share/ApplyRequest.vue'),
    //         meta: {
    //             label: "我的授权",
    //             icon: 'el-icon-s-check',
    //             menu: 'sub'
    //         },
    //     }, {
    //         // 授权记录
    //         path: 'reqrecord',
    //         name: 'reqrecord',
    //         component: () =>
    //             import ('@/views/share/RequestRecord.vue'),
    //         meta: {
    //             label: "授权记录",
    //             icon: 'el-icon-finished',
    //             menu: 'sub'
    //         },
    //     }, {
    //         path: 'detail',
    //         name: 'detail',
    //         component: () =>
    //             import ('@/views/confer/ConferDetail.vue')
    //     }, {
    //         path: 'mydetail',
    //         name: 'mydetail',
    //         component: () =>
    //             import ('@/views/confer/MyConferDetail.vue')
    //     }, {
    //         path: 'conferedit',
    //         name: 'conferedit',
    //         component: () =>
    //             import ('@/views/confer/ConferEdit.vue')
    //     }]
    // },

    // 申请
    // {
    //     path: '/apply',
    //     name: "apply",
    //     component: PageLayout,
    //     meta: {
    //         label: "申请",
    //         menu: 'main',
    //         cartViewKey: "request"
    //     },
    //     children: [{
    //         path: '/',
    //         name: 'apply',
    //         component: () =>
    //             import ('@/views/share/ApplyList.vue'),
    //         meta: {
    //             label: "申请授权",
    //             icon: 'el-icon-document',
    //             menu: 'sub'
    //         },
    //     }, {
    //         path: 'record',
    //         name: 'record',
    //         component: () =>
    //             import ('@/views/share/ApplyRecord.vue'),
    //         meta: {
    //             label: "申请记录",
    //             icon: 'el-icon-document',
    //             menu: 'sub'
    //         },
    //     }, {
    //         path: 'myapply',
    //         name: 'myapply',
    //         component: () =>
    //             import ('@/views/share/MyApplyDetail.vue')
    //     }]
    // },

    // 文件管理
    {
        path: '/file',
        name: "file_manage",
        component: PageLayout,
        meta: {
            label: "文件管理",
            menu: 'main',
            cartViewKey: "share"
        },
        redirect: '/file/mydoc',
        children: [{
            path: 'favorite', // 我的收藏
            name: 'favorite',
            component: () =>
                import ('@/views/liankong/MyStart.vue')
        }, {
            path: 'othershare', // 他人共享
            name: 'share_others',
            component: () =>
                import ('@/views/liankong/OtherShare.vue')
        }, {
            path: 'attention', // 我的关注
            name: 'attention',
            component: () =>
                import ('@/views/liankong/MyAttention.vue')
        }, {
            path: 'doctype', // 文档分类 - 固定分类
            name: 'document_type',
            component: () =>
                import ('@/views/liankong/DocType.vue')
        }, {
            path: 'doctypechild/:id', // 固定分类下的页面
            name: 'doctypechild',
            component: () =>
                import ('@/views/liankong/DocTypeChild.vue')
        }, {
            path: 'tagtype', // 文档分类 - 标签分类
            name: 'tags_type',
            component: () =>
                import ('@/views/liankong/TagType.vue')
        }, {
            path: 'tagtypechild/:id', // 标签分类下的页面
            name: 'tags_type_child',
            component: () =>
                import ('@/views/liankong/TagTypeChild.vue')
        }, {
            path: 'mydoc', // 我的文档
            name: 'my_document',
            component: () =>
                import ('@/views/liankong/MyDoc.vue')
        }, {
            path: 'mydoc1/:id', // 我的文档子页面
            name: 'my_document_child',
            component: () =>
                import ('@/views/liankong/MyDocChild.vue')
        }, {
            path: 'orgdoc', // 组织文档
            name: 'org_document',
            component: () =>
                import ('@/views/liankong/OrgDoc.vue')
        }, {
            path: 'groupdoc', // 群组文档
            name: 'group_document',
            component: () =>
                import ('@/views/liankong/GroupDoc.vue')
        }]
    },
    // 在线编辑
    // {
    //     path: '/onlineEditing',
    //     name: 'online-editing',
    //     component: PageLayout,
    //     meta: {
    //         label: '在线编辑',
    //         menu: 'main'
    //     },
    //     children: [{
    //         path: '/',
    //         name: 'onlineEditing',
    //         component: () =>
    //             import ('@/views/onlineEditing/index.vue')
    //     }]
    // },
    // 文档协作
    // {
    //     path: '/docollabor',
    //     name: "document_collaboration",
    //     component: PageLayout,
    //     meta: {
    //         label: "文档协作",
    //         menu: 'main'
    //     },
    // },
    // 协同
    {
        path: '/teamWork',
        name: "teamWork_Index",
        component: PageLayout,
        meta: {
            label: "协作",
            menu: 'main'
        },
        redirect: '/teamWork/count',
        children: [{
            path: "pending",
            name: 'teamWork_pending',
            component: () =>
                import ('@/views/teamWork/myPending.vue'),
        },{
            path: "join",
            name: 'teamWork_join',
            component: () =>
                import ('@/views/teamWork/myJoin.vue'),
        },{
            path: "create",
            name: 'teamWork_create',
            component: () =>
                import ('@/views/teamWork/myCreate.vue'),
        },{
            path: "count",
            name: 'teamWork_count',
            component: () =>
                import ('@/views/teamWork/Index.vue'),
        },{
            path: '/onlineEditing',
            name: 'onlineEditing',
            component: () =>
                import ('@/views/onlineEditing/index.vue')
        }]
        
    },
    // 文档库
    {
        path: '/doclibrary',
        name: 'document_library',
        component: PageLayout,
        meta: {
            label: '文档库',
            menu: 'main',
            cartViewKey: "request"
        },
        children: [{
            path: '/',
            name: 'apply',
            component: () =>
                import ('@/views/share/ApplyList.vue')
        }]
    },
    // 共享授权
    {
        path: '/sharetion',
        name: 'share_authorization',
        component: PageLayout,
        meta: {
            label: '共享授权',
            menu: 'main'
        },
        redirect: '/sharetion/confer',
        children: [{
                path: 'sharedoc', // 共享文档
                name: 'share_document',
                component: () =>
                    import ('@/views/liankong/ShareDoc.vue'),
            },
            // {
            //     path: 'shares', // 我的共享
            //     name: 'shares',
            //     component: () =>
            //         import ('@/views/share/SharePage.vue'),
            // }, 
            {
                path: 'detail', // 我的共享-详情
                name: 'detail',
                component: () =>
                    import ('@/views/share/ShareDetail.vue'),
            },
            {
                path: 'edit', // 我的共享-编辑
                name: 'edit',
                component: () =>
                    import ('@/views/share/ShareEdit.vue')
            },
            //  {
            //     path: 'othershare', // 他人共享
            //     name: 'share_others',
            //     component: () =>
            //         import ('@/views/liankong/OtherShare.vue')
            // }, 
            {
                path: 'confer', // 我的授权
                name: 'request_confer',
                component: () =>
                    import ('@/views/share/ApplyRequest.vue'),
            },
            {
                path: 'authrecord', // 授权记录页面
                name: 'authtion_record',
                component: () =>
                    import ('@/views/liankong/AuthtionRecord.vue')
            },
            {
                path: 'reqrecord', // 授权记录
                name: 'reqrecord',
                component: () =>
                    import ('@/views/share/RequestRecord.vue'),
            }, {
                path: 'record', // 申请记录
                name: 'record',
                component: () =>
                    import ('@/views/share/ApplyRecord.vue'),
            }, {
                path: 'mydetail',
                name: 'mydetail',
                component: () =>
                    import ('@/views/confer/MyConferDetail.vue')
            }
        ]
    },
    
    // 文档工具
    // {
    //     path: '/doctool',
    //     name: 'docment_tools',
    //     component: PageLayout,
    //     meta: {
    //         label: '文档工具',
    //         menu: 'main'
    //     },
    //     children: [{
    //         path: '/',
    //         name: 'documentation_tools',
    //         component: () =>
    //             import ('@/views/liankong/DocTools.vue')
    //     }]
    // },
    // 人员管理
    {
        path: '/persnnel',
        name: 'personnel_manage',
        component: PageLayout,
        meta: {
            label: '我的群组',
            menu: 'main'
        },
        redirect: '/persnnel/mygroup',
        children: [{
                path: 'mygroup', // 我的群组
                name: 'mygroup',
                component: () =>
                    import ('@/views/liankong/MyGroup.vue')
            }, {
                path: 'auditmember', // 审核成员
                name: 'audit_member',
                component: () =>
                    import ('@/views/liankong/AuditMember.vue')
            }, {
                path: 'invitamember', // 邀请成员
                name: 'invita_member',
                component: () =>
                    import ('@/views/liankong/InvitaMember.vue')
            },
            // {
            //     path: 'orgdep/:id', // 组织机构、或者部门 不需要了这个页面
            //     name: 'orgdep',
            //     component: () =>
            //         import ('@/views/liankong/OrgDep.vue')
            // }, 
            {
                path: 'orgstructuremanage', // 组织管理
                name: 'org_structure_management',
                component: () =>
                    import ('@/views/liankong/OrgStructureManage.vue')
            }
        ]
    },

    //个人中心
    {
        path: '/user',
        name: "user_center",
        component: PageLayout,
        children: [{
            path: 'mydetails',
            name: "mydetails",
            component: () =>
                import ('@/views/user/MyDetails.vue'),
        },{
            path: 'preference',
            name: "myPreference",
            component: () =>
                import ('@/views/user/MyPreference.vue'),
        }, {
            path: 'resetpassword',
            name: "resetpassword",
            component: () =>
                import ('@/views/user/ResetPassword.vue'),
        }, {
            path: 'addressbook',
            name: "addressbook",
            component: () =>
                import ('@/views/user/AddressBook.vue'),
        }, {
            path: 'mycollection',
            name: "mycollection",
            component: () =>
                import ('@/views/user/MyCollection.vue'),
        }, {
            path: 'mynews',
            name: "mynews",
            component: () =>
                import ('@/views/user/MyNews.vue'),
        }, {
            path: 'about',
            name: "about",
            component: () =>
                import ('@/views/user/About.vue'),
        }, {
            path: '/',
            redirect: 'mydetails'
        }, {
            path: 'organizational',
            name: "organizational",
            component: () =>
                import ('@/views/user/organizational.vue'),
        }, {
            path: 'storage',
            name: "storage",
            component: () =>
                import ('@/views/user/Storage.vue'),
        }]
    },

    //全文检索
    {
        path: '/search',
        name: "Search",
        component: PageLayout,
        redirect: '/search/result',
        children: [{
            path: "result",
            name: "SearchResult",
            component: () =>
                import ('@/views/search/NewResult.vue')
        }]
    },

    // wps阅读
    {
        path: '/wps',
        name: "WPS",
        component: () =>
                import ('../components/WpsReader.vue')
      
    },
    // wps阅读
    {
        path: '/onlyOffice',
        name: "OnlyOffice",
        component: () =>
                import ('../components/OnlyOfficeReader.vue')
      
    },

    //登录页面
    {
        path: '/login',
        name: "Login",
        component: () =>
            import ('@/views/Login/login.vue')
    },
    //跳转
    {
        path: "/redirect",
        component: () =>
            import ('@/views/Redirect.vue')
    },
    //选择我的文档
    {
        path: '/selectMyDocument',
        component: () =>
            import ('@/components/SelectFileAndFolder.vue')
    },
    //404
    {
        path: '*',
        component: () =>
            import ('@/views/404.vue')
    }
]