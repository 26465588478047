import { myList } from '@/api/message.js'

const state = {
  msgs: {
    zhan: []
  }
}

const getters = {}

const mutations = {
  setZhanMessage(state, list){
    state.msgs.zhan = list
  }
}

const actions = {
  //加载我的消息
  async myList({commit}, query){
    const {code, data, message} = await myList(query || {
      pageSize: 12,
      pageNum: 1
    })

    if(code == 0){
      const { total, current, records} = data
      commit("setZhanMessage", records)
    }

  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}