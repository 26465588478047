/**
 * 权限控制
 */
import router from './index.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store/index.js'
import { Cache, Store } from '@lk/cache'
import Cookies from 'js-cookie'
import fileRouter from '../router/fileRouter'

NProgress.configure({ showSpinner: false })

//白名单路由
const loginPath = "/login"
const whiteList = ['/login', '/quick/cli', '/redirect']

router.beforeEach(async(to, from, next) => {
    NProgress.start()
    

    //检查token
    let token = store.state.login.token;
    
    if( Cookies.get('access_token')){
        token  = Cookies.get('access_token');
        Store.put('access_token',Cookies.get('access_token'))
        store.state.login.token = Cookies.get('access_token');
        store.commit("login/setLoginInfo", {
            access_token:Cookies.get('access_token'),
            refresh_token:null,
            user_info:Cookies.get('user_info')
        })
    } 

    console.log("路由检查token", token)
    if (token) {
        //有token的情况处理
        if (to.path == loginPath) {
            next()
            NProgress.done()
        } else {
            //目标路径与权限判定，简单判断用户id，姓名
            const id = store.state.login.id
            if (id) {
                next()
            } else {
                if((`${window.location.hash}`.indexOf('/share/')==-1 && !fileRouter.isRunInsert)){
                    store.commit("user/setToken", {})
                    Store.del('access_token')
                    next()
                } else {
                    next()
                }
                    // try {
                    //     await store.dispatch('user/userInfo')
                    //     next()
                    // } catch (err) {
                    //     //加载用户信息异常
                    //     store.commit("user/setToken", {})
                    //     next(`/login?redirect=${to.path}`)
                    // }
            }
        }
    } else {
        //跳转登录页
        if (whiteList.indexOf(to.path) !== -1 || to.path.startsWith('/share/')) {
            next()
        } else {
            if(to.path == '/user/organizational'){
                next(`/login`)
            }else{
                next(`/login?redirect=${to.path}`)
            }
           
            NProgress.done()
        }
    }
})

router.afterEach((to) => {
    NProgress.done()

    let main = to
    let sub = null

    if (to.meta._parent) {
        //存在上级
        main = to.meta._parent
        sub = to
    }

    store.commit("menu/updateMenu", {
        main,
        sub
    })

    //其他数据处理
    const cartViewKey = main.meta.cartViewKey || null
    store.commit("setCartKey", cartViewKey)
})