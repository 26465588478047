<template>
  <div class="page-header">
    <h3 class="header-title"><i class="el-icon-map-location"></i><slot></slot></h3>
    <div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "PageHeader",
  components: {},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  methods: {

  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.page-header{
  display: flex;
  justify-content: space-between;

  .header-title{
    margin-top: 0;
    color:#169BD5;
    // border-left: 4px #304156 solid;
    padding-left: 5px;
    margin-bottom: 5px; 
  }
}
</style>