<template>
  <div @click="onCartClicked">
   <!--  <el-dropdown> -->
      <el-badge class="badge" :value="docSize" :hidden="docSize <= 0">
        <i class="icon el-icon-shopping-cart-full"></i>
      </el-badge>
      <!-- <el-dropdown-menu slot="dropdown">
        <div class="cart-box" v-if="files.length > 0">
          <ul class="cart-list">
            <li class="cart-item" v-for="file in files">
              <div class="file-icon">
                <img src="">
              </div>
              <div style="flex: 1;">
                <div>{{file.name}}</div>
                <div class="file-info">{{file.ownerName | mask}} | {{file.createDate | date}}</div>
              </div>
              <div class="btn-del">
                <el-button class="btn" type="text" title="移除待共享列表" @click.stop="removeFile(file)"  icon="el-icon-remove-outline" size="mini"></el-button>
              </div>
            </li>
          </ul> -->
          <!-- 操作按钮 -->
          <!-- <div class="cart-btns">
            <el-button type="text">权限设置</el-button>
            <div>
              <el-button size="small" type="primary" @click="doCreateQuickShare">快速共享</el-button>
              <el-button size="small" type="success" :loading="loading.create" @click="doCreateShare">创建共享链接</el-button>
            </div>
          </div>
        </div>
        <div v-else class="no-more">
          暂无可用文件
        </div>
        
      </el-dropdown-menu>
    </el-dropdown> -->
    
  </div>
</template>

<script>
  export default {
    data(){
      return {
        //加载状态
        loading: {
          create: false,
        },

        //二维码
        dialog: {
          visable: false
        },

        //申请理由
        note: "",

        //快速共享
        quickShare: {
          url: "",
          ticket: null,
          time: null,
          exTime: null
        },

        //快速申请
        quickRequest: {
          url: "",
          ticket: null,
          time: null,
          exTime: null
        }
      }
    },
    computed:{
      //购物车视图key
      viewerKey(){
        return this.$store.state.cartForm.viewerKey
      },

      //文件数
      docSize(){
        const viewer = this.$store.state.cart.views[this.viewerKey]
        return viewer ? viewer.docList.length : 0
      },

      //文件列表
      files(){
        const viewer = this.$store.state.cart.views[this.viewerKey]
        return viewer ? viewer.docList : []
      }
    },

    methods: {
      //购物车点击
      onCartClicked(){
        if(this.files && this.files.length > 0){
          this.$store.commit('setCartShow', true)
        }else{
          this.$message.error('请先选择文件!')
        }
      },

      //移除资源
      removeFile(file){
        this.$store.commit("cart/remove", {
          viewerKey: this.viewerKey,
          doc: file,
        }).then(state => {
          this.$message({
            message: '创建共享成功',
            type: 'success'
          })
          //事件通知
          this.$ebus.$emit("cart:remove", file)
        }).catch(err => {
          this.$message.error(err)
        })
      },

      //创建快速共享
      doCreateQuickShare(){
        this.dialog.visable = true
      },

      //创建共享
      doCreateShare(){
        if (!this.loading.create) {
          this.loading.create = true;
          this.$store
            .dispatch("cart/createShare")
            .then((data) => {
              this.loading.create = false;
              
            })
            .catch((error) => {
              this.loading.create = false;
            })
            .catch((error) => {
              this.loading.create = false;
            })
        }
      }
    }
  }
</script>

<style lang='less'>
  .badge{
    sup{
      right: 25px!important;
    }
  }
</style>
<style lang='less' scoped>
  .badge{
    .icon{
      font-size: 18px;
      padding: 0 15px;
    }
  }

  .cart-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .no-more{
    min-width: 350px;
    padding: 0 10px;
    text-align: center;
  }

  .cart-box {
    min-width: 350px;
    padding: 0 10px;

    .cart-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .cart-item {
        display: flex;
        padding-bottom: 6px;

        &:hover {
          cursor: pointer;
          color: rgb(156, 156, 156);
        }

        .file-icon {
          margin-right: 10px;

          img{
            width: 48px;
            height: 48px;
          }
        }

        .cart-item-text {
          line-height: 22px;
          max-width: 350px;
          font-size: 16px;
        }

        .file-info {
          margin-top: 6px;
          color: gray;
          font-size: 12px;
        }

        .btn-del{
          width: 30px;
          flex-grow: 0;
          text-align: center;
          align-items: center;

          .btn{
            color: #f56c6c;
            font-size: 16px;
          }
        }
      }
    }
  }
</style>