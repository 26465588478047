/**
 * 用户API
 */
import * as http from '../utils/http/index.js';

const usercenter = "/usercenter"

/**
 * 加载用户信息
 */
export function loadUserInfo(){
  return http.get(`${usercenter}/user/getUserDetails`)
}

/**
 * 根据用户名加载用户信息
 * @param {*} name 
 */
export function findByUserName(name){
  return http.get(`${usercenter}/user/getUserInfoByUserName?username=${name}`)
}

/**
 * 用户列表
 * @param {*} query 
 */
export function userList(query){
  return http.post(`${usercenter}/user/list`, query)
}

/**
 * 添加用户
 * @param {*} user 
 */
export function addUser(user){
  return http.post(`${usercenter}/user`, user)
}

/**
 * 修改密码
 * @param {*} data 
 */
export function setPassword(data){
  // return http.put(`${usercenter}/user/password`, data)
  return http.post(`${usercenter}/user/bind/login/password`, data)
}

/**
 * 管理员重置密码
 * @param {*} data 
 */
export function adminPassword(data){
  // return http.put(`${usercenter}/user/password`, data)
  return http.put(`${usercenter}/user/admin/password`, data)
}

/**
 * 删除系统用户
 * @param {*} userId 
 */
export function removeUser(userId){
  return http.del(`${usercenter}/user/${userId}`)
}