<template>
    <el-dialog title="文件阅读" :destroy-on-close="true"
     class="readFile-loading"
     :show-close="false" :close-on-press-escape="false" :custom-class="addClassScreen?'readFile':''" :visible.sync="dialogVisible" :append-to-body='true'
    :close-on-click-modal="false" top="5vh" >
      <!-- 文件阅读 -->
      <span slot="title" class="dialog-title">
        <div class="header">
          <span class="read">文件阅读</span>
          <p class="btn">
            <span @click="requestFullscreen">
              <img :src="addClassScreen?require('../assets/shrink.png'):require('../assets/blowup.png')" alt="">
            </span>
            <!-- <i class="el-icon-full-screen" @click="requestFullscreen"></i> -->
            <i class="el-icon-close" @click="closeDoling"></i>
          </p>
        </div>
      </span>
      <div style='height:100%'>
        <iframe  scrolling=no :src="iframeSrc" frameborder="0" width="100%" height="100%"></iframe>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </template>
  
<script>
  export default{
    data(){
        return {
          dialogVisible:false,
          iframeSrc:'',
          addClassScreen:false
        }
    },
    mounted() {
      let that = this;
      window.addEventListener('resize',(e)=>{
        if(!this.checkFull()){
          that.exitFullscreen()
        }
      })
    },
    methods:{
      checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      let isFull = document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull;
    },
      requestFullscreen(){
        this.addClassScreen=!this.addClassScreen
        if(this.addClassScreen){
          const element = document.documentElement
            if (element.requestFullscreen) {
              element.requestFullscreen()
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen()
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen()
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen()
            }
        } else {
          if (document.fullscreenElement !== null) {
              document.exitFullscreen()
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen()
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen()
            }
        }
      },
      exitFullscreen(){
        this.addClassScreen=false
        if (document.fullscreenElement !== null) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      },
      showReadFile(value){
          this.iframeSrc = value;
          this.dialogVisible = true;
      },
      closeDoling(){
          this.iframeSrc = '';
          if(this.addClassScreen){
            this.exitFullscreen()
          }
          this.addClassScreen=false
          this.dialogVisible = false;
      }
    },
  }
</script>
<style>
  .readFile-loading {
    overflow: hidden !important;
  }
</style>
  <style lang='less' scoped>
    /deep/ .el-dialog {
      width: 65%;
      height: 90%;
      display: flex;
      flex-direction: column;
    }
    /deep/ .readFile {
      width: 100%;
      height: 100%;
      margin-top: 0px !important;
    }
    /deep/ .el-dialog__header{
        padding: 10px 20px 10px;
    }
    /deep/ .dialog-title {
      display: flex;
      justify-content: space-between;
      .header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .read {
          line-height:24px;
          color:#303133;
          font-size:18px
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          i {
            font-size: 20px;
            padding: 0 5px;
            box-sizing: border-box;
          }
        }
        
      }
    }
    /deep/ .el-dialog__body {
      display: block !important;
      padding: 0px 20px;
      flex: 1;
    }
    .group{
      padding-bottom: 20px;
    }
    
   
  </style>