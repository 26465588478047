/**
 * 搜索服务
 */
import { indexAPI } from 'liankong-ui-api'
import { documentAPI } from 'liankong-ui-api'

// // funs
// const fun = {
//   //设置多选状态
//   setSelect() {

//     state.viewer.find.showFiles.forEach(function (item) {
//       item.select = false
//       state.viewer.find.select.forEach(function (id) {

//         if (id == item.id) {
//           item.select = true
//         }
//       })
//     })
//   }
// }


const state = {
    // 搜索条件
    // docType 文档类型 0 个人 1 组织 2 群体
    // groudId 群组id 
    // keyword 搜索关键字 
    // orgId 组织id
    // pageNumber 每一页条数
    // pageSize 搜索条数 
    // systemTag 系统标签 
    // miji; 密集
    // startDate 开始时间
    // endDate 结束时间

    /**
     * 当前收缩结果和搜索条件
     */
    viewer: {
        my: {
            loading: false,

            //查询器
            query: {
                keyword: null,
                systemTag: null,
                pageNum: 1,
                pageSize: 20,
                docType: 0,
                target:''
            },

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            selection: false,
            selectionRows: [],

            root: null,
            current: null,
            docType: 0,

            showFiles: [],
            files: [],

            // select: [],

            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多文档',
                    contentrefresh: '文档加载中',
                    contentnomore: '没有更多文档了'
                }
            }
        },
        nodeMy: { // 采集上报 搜索
            loading: false,

            //查询器
            query: {
                keyword: null,
                systemTag: null,
                pageNum: 1,
                pageSize: 20,
                docType: 0
            },

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            selection: false,
            selectionRows: [],

            root: null,
            current: null,
            docType: 0,

            showFiles: [],
            files: [],

            // select: [],

            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多文档',
                    contentrefresh: '文档加载中',
                    contentnomore: '没有更多文档了'
                }
            }
        },
        //申请授权
        grant: {
            loading: false,

            //查询器
            query: {
                keyword: null,
                systemTag: null,
                pageNum: 1,
                pageSize: 20
            },

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            selection: false,
            selectionRows: [],

            root: null,
            current: null,
            docType: 0,

            showFiles: [],
            files: [],
            // select: [],

            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多文档',
                    contentrefresh: '文档加载中',
                    contentnomore: '没有更多文档了'
                }
            }
        },
        //发现广场
        find: {
            loading: false,

            //查询器
            query: {
                keyword: null,
                systemTag: null,
                pageNum: 1,
                pageSize: 20
            },

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            selection: false,
            selectionRows: [],

            root: null,
            current: null,
            docType: 0,

            showFiles: [],
            files: [],
            // select: [],

            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多文档',
                    contentrefresh: '文档加载中',
                    contentnomore: '没有更多文档了'
                }
            }
        },
        org: { // 组织搜索
            loading: false,

            //查询器
            query: {
                keyword: null,
                systemTag: null,
                pageNum: 1,
                pageSize: 20,
                docType: 1,
                orgId: null
            },

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            selection: false,
            selectionRows: [],

            root: null,
            current: null,
            docType: 0,

            showFiles: [],
            files: [],

            // select: [],

            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多文档',
                    contentrefresh: '文档加载中',
                    contentnomore: '没有更多文档了'
                }
            }
        },
        groupDoc: { // 群组搜索
            loading: false,

            //查询器
            query: {
                keyword: null,
                systemTag: null,
                pageNum: 1,
                pageSize: 20,
                docType: 3,
                groupId: null
            },

            //分页器
            paging: {
                total: 0,
                pages: 0
            },

            selection: false,
            selectionRows: [],

            root: null,
            current: null,
            docType: 0,

            showFiles: [],
            files: [],

            // select: [],

            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多文档',
                    contentrefresh: '文档加载中',
                    contentnomore: '没有更多文档了'
                }
            }
        }
    },

    apply: {
        description: "",
        docId: ""
    },
}



const getters = {

}

const mutations = {
    /**
     * 更新文档列表
     * @param {*} state 
     * @param {*} data 
     */
    setFiles(state, { vkey, reset = false, noReset = false, data: { current, pages, data, total } }) {
        const viewer = state.viewer[vkey]

        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages

        //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        //Develop#A# 此处 current应用错误，无脑的copy代码，需要优化处理
        viewer.current = reset ? data : viewer.current.concat(data)
        viewer.showFiles = viewer.current
        if (!noReset) {
            viewer.showFiles = data
        }
        viewer.loading = false
    },

    /**
     * 重置文件
     * @param {*} state 
     * @param {*} param1 
     */
    resetFiles(state, { vkey }) {
        const viewer = state.viewer[vkey]
        viewer.current = []
    },

    // 添加/删除到多选
    // addSelect({ store }, { id, isselect }) {

    //   if (id) {
    //     if (isselect) {
    //       if (state.viewer.find.select.indexOf(id) == -1) {
    //         state.viewer.find.select.push(id)
    //       }
    //     } else {
    //       state.viewer.find.select.forEach(function (selectid, k, arr) {
    //         if (selectid == id) arr.splice(k, 1);
    //       })
    //     }
    //   }

    //   fun.setSelect()
    // },
    //全不选
    // clearSelect() {
    //   state.viewer.find.select.forEach(function (id) {
    //     state.viewer.find.showFiles.forEach(function (item,) {
    //       if (id == item.id) {
    //         item.select = false
    //       }
    //     })
    //   })
    //   state.viewer.find.select = []
    //   fun.setSelect()
    // },
    //全选
    // allSelect: function ({ commit }) {

    //   state.viewer.find.showFiles.forEach(function (item) {

    //     if (state.viewer.find.select.indexOf(item.id) == -1) {
    //       state.viewer.find.select.push(item.id)
    //     }
    //   })
    //   console.log(state.viewer.find.select)
    //   fun.setSelect()
    // },

    //更新搜索条件，并返回是否可以执行搜索
    updateQuery: function({}, { vkey, query }) {
        const viewer = state.viewer[vkey]

        viewer.query = Object.assign({}, viewer.query, query)
    },

    //更新更多状态
    updateMore(state, { vkey, moreStatus }) {
        const viewer = state.viewer[vkey]
        viewer.more.status = moreStatus
    },
}

const actions = {
    /**
     * 文档检索
     */
    async loadByQuery({ state, commit }, { vkey = "my", reset = false, parameter }) {

        const viewer = state.viewer[vkey]

        if (reset) {
            commit('resetFiles', { vkey })
            viewer.query.pageNum = 1
        }
        if (parameter) { viewer.query = Object.assign({}, viewer.query, parameter) }

        const data = await indexAPI.indexDocument(viewer.query)
        if (data.code == 0) {

            data.data.forEach(function(item) {
                item.createDate = item.createDate.replace(/-/g, '/').replace(/\..*/g, '')
                item.updateDate = item.updateDate.replace(/-/g, '/').replace(/\..*/g, '')
            })

            commit("setFiles", {
                vkey,
                data,
                reset
            })
        }

        return data.code == 0
    },
    /**
     * 全文检索
     */
    async indexByQuery({ state, commit }, { vkey = "find", reset = false, noReset = true }) {

        const viewer = state.viewer[vkey]

        if (reset && noReset) {
            commit('resetFiles', { vkey })
            viewer.query.pageNum = 1
        }

        const data = await indexAPI.index(viewer.query)

        data.data.forEach(function(item) {
            // item.select = false
            item.createDate = item.createDate.replace(/-/g, '/').replace(/\..*/g, '')
            item.updateDate = item.updateDate.replace(/-/g, '/').replace(/\..*/g, '')
        })

        if (data.code == 0) {
            commit("setFiles", {
                    vkey,
                    data,
                    reset,
                    noReset
                })
                // commit("addSelect", { id: false })
        }

        return data.code == 0
    },
    /**
     * 下一页
     * @param {*} param0 
     * @param {
     *  vkey: 操作的视图key
     *  reset: 翻页是否覆盖
     * } param1 
     */
    async doLoadNextPage({ commit, dispatch, state }, { vkey, reset = false }) {

        const viewer = state.viewer[vkey]

        //Refactor#A# 整个项目统一处理更多视图逻辑，不能这种使用常量判断
        if (viewer.more.status != 'more') {
            return
        }

        const paging = viewer.paging
        const query = viewer.query

        if (query.pageNum < paging.pages + 1) {
            commit('updateMore', { vkey, moreStatus: 'loading' })

            query.pageNum++

                if (vkey == 'my') dispatch("loadByQuery", { vkey, reset: false })
            if (vkey == 'find') dispatch("indexByQuery", { vkey, reset: false })
            if (vkey == 'grant') dispatch("indexByQuery", { vkey, reset: false })
        }
    },

    /**
     * 上一页
     * @param {*} param0 
     * @param {
     *  vkey: 操作的视图key
     *  reset: 翻页是否覆盖
     * } param1 
     */
    async doLoadPrevPage({ commit, dispatch }, { vkey, reset = false }) {
        const viewer = state.viewer[vkey]
        const currentFolder = viewer.current

        const paging = viewer.paging
        const query = viewer.query


        if (query.pageNum > 0) {
            query.pageNum--
                if (vkey == 'my') dispatch("loadByQuery", { vkey, reset: false })
            if (vkey == 'find') dispatch("indexByQuery", { vkey, reset: false })
            if (vkey == 'grant') dispatch("indexByQuery", { vkey, reset: false })
        }
    },
    /**
     * 重命名
     * @param {*} id 文档id 
     * @param {*} name 文档新名称 
     */
    async renameFolder({ dispatch }, { id, name, vkey }) {
        const data = await documentAPI.rename(id, name)
        if (data.code == 0) {
            dispatch("loadByQuery", { vkey, reset: true })
        }
        return data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}