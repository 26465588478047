/**
 * 申请相关服务
 * 1. 阅读申请授权
 * 2. 资源请求分享
 */
import { msgAPI } from 'liankong-ui-api'

const state = {
    views: {
        //发送者，当前用户视图
        ReadMsg: {
            query: {
                pageNum: 1,
                pageSize: 20
            },
            paging: {
                total: 0,
                pages: 0
            },
            list: [],
            //加载更多
            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多数据',
                    contentrefresh: '数据加载中',
                    contentnomore: '没有更多数据了'
                }
            }
        },
        UnReadMsg: {
            query: {
                pageNum: 1,
                pageSize: 20
            },
            paging: {
                total: 0,
                pages: 0
            },
            list: [],
            //加载更多
            more: {
                status: 'more',
                contentText: {
                    contentdown: '加载更多数据',
                    contentrefresh: '数据加载中',
                    contentnomore: '没有更多数据了'
                }
            }
        }

    }
}

const mutations = {
    /**
     * 更新视图列表
     * @param {*} state 
     * @param {*} vkey
     * @param {*} rest
     * @param {*} data
     */
    updateList(state, { vkey, reset = false, current, pages, data, total, pcNoreset }) {
        const viewer = state.views[vkey];
        viewer.query.pageNum = current
        viewer.paging.total = total
        viewer.paging.pages = pages
            //更多状态
        if (pages == 0 || current == pages) {
            viewer.more.status = 'noMore'
        } else {
            viewer.more.status = 'more'
        }

        viewer.list = reset ? data : viewer.list.concat(data)
        if (pcNoreset) {
            viewer.list = data
        }
    }
}

const actions = {
    /**
     * 加载我的申请
     */
    async loadMeReadMsg({ state, commit }, { reset, pcNoreset = false }) {
        try {
            const viewerKey = 'ReadMsg'
            const viewer = state.views[viewerKey]
            if (reset) {
                viewer.query.pageNum = 1;
            }
            const { code, current, pages, size, total, data, message } = await msgAPI.queryUserMessage(viewer.query)
            if (code == 0) {
                //是否有更多查询
                if (!reset && viewer.more.status === 'noMore') {
                    return
                }
                commit("updateList", {
                    vkey: viewerKey,
                    reset,
                    current,
                    pages,
                    size,
                    total,
                    data,
                    pcNoreset
                })
            }
            return code == 0
        } catch (error) {
            throw error
        }
    },

    /**
     * 加载我接收的申请
     * @param {*} param0 
     */
    async updateMyMsgState({ dispatch }, msgId) {
        try {
            const { code, data, message } = await msgAPI.updateUserMessageState(msgId)
            if (code == 0) {
                dispatch("loadMeReadMsg", { reset: true })
                dispatch("loadMeUnReadMsg", { reset: true })
            }
            return code == 0
        } catch (error) {
            throw error
        }
    },
    async deleteMyMsg({ dispatch }, msgId) {
        try {
            const { code, data, message } = await msgAPI.removeUserMessage(msgId)
            if (code == 0) {
                dispatch("loadMeUnReadMsg", { reset: true })
            }
            return code == 0
        } catch (error) {
            throw error
        }
    },

    // 加载未读消息
    async loadMeUnReadMsg({ state, commit }, { reset = false }) {
        try {
            const viewerKey = 'UnReadMsg'
            const viewer = state.views[viewerKey]
            const { code, current, pages, size, total, data, message } = await msgAPI.UnMessage(viewer.query)
            if (code == 0) {
                commit("updateList", {
                    vkey: viewerKey,
                    reset,
                    current,
                    pages,
                    size,
                    total,
                    data
                })
            }
            return code == 0
        } catch (error) {
            throw error
        }
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}