<template>
    <el-dialog title="文档共享" :visible.sync="dialogVisible"
    :append-to-body='true'
    :close-on-click-modal="false" width="700px" @close='closeDialog'>
    <!-- 共享模式 -->
    <template>
        <div class="tips-box">
            <i class="el-icon-success"></i>
            文档创建共享链接成功，下方为创建的共享链接与二维码，查阅<span @click="toMyShare">共享记录，请点击这里&gt;&gt;</span> 
        </div>
        <div class="share-box">
            <div class="share-modular">
                <h1 class="share-modular-title">链接地址</h1>
                <div class="share-link"><div class="share-link-url">{{shareUrl}}</div></div>
                <div>
                    <el-button type="primary" @click="copyShareUrl" size='small'>复制共享链接地址</el-button>
                </div>
            </div>
             <div class="share-modular">
                <h1 class="share-modular-title">二维码共享<span>微信扫码即可获取文件</span></h1>
                 <div class="qr-code share-link">
                    <qr ref='qr' :margin="10" :text="shareUrl" colorDark="#333333" colorLight="#fff" :size="240"></qr>
                </div>
                <div>
                    <el-button type="primary" style="margin-left:70px" size='small' @click="downloadQrCode">点击下载二维码</el-button>
                </div>
            </div>
        </div>
    </template>
  </el-dialog>
</template>
<script>
import qr from 'vue-qr'
export default {
    components:{
        qr
    },
    data(){
        return {
            shareUrl:'',
            dialogVisible:false
        }
    },
    methods:{
        show(url){
            this.shareUrl = url;
            this.dialogVisible = true;
        },
        closeDialog(){
            this.shareUrl = '';
             this.dialogVisible = false;
        },
        copyShareUrl(){
            this.$copyText(this.shareUrl).then(e => {
                this.$message({
                    message: '复制成功,快去共享给好友吧',
                    type: 'success'
                })
            }, e => {
                this.$message({
                    message: '复制失败，请手动复制分析连接',
                    type: 'error'
                })
            })
        },
        toMyShare(){
            this.$router.push({
                path:'/sharetion/sharedoc',
                query:{
                    active:'second'
                }
            })
        },
        downloadQrCode(){
            let base64 = this.$refs.qr.$el.src; // imgSrc 就是base64哈
            let byteCharacters = atob(
                base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
            );
            let byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {
                type: undefined,
            });
            let aLink = document.createElement("a");
            aLink.download = "qrCode.jpg"; //这里写保存时的图片名称
            aLink.href = URL.createObjectURL(blob);
            aLink.click();
        }
    },
   
}
</script>
<style lang='less' scoped>
.tips-box{
    text-align: center;
    i{
        font-size: 30px;
        color: #1296db;
        vertical-align: middle;
        margin-right: 15px;
    }
    span{
        color: #1296db;
        cursor: pointer;
    }
}
.share-box{
    display: flex;
    margin-top: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    div.share-modular{
        width: 50%;
    }
    .share-link{
        height: 250px;
        margin-top: 20px;
    }
    .qr-code{
        margin-left: 10px;
    }
    .share-modular{
        margin-bottom: 10px;
        .permission-box{
            .el-switch{
            margin-right: 20px;
            }
            .el-date-editor{
            margin:0 15px 0 5px;
            }
        }
        .share-modular-title{
            border-left: 4px solid #0099cc;
            padding-left: 6px;
            text-align: left;
            color: #333;
            font-size: 16px;
            span{
                font-size: 14px;
                color: #999;
                font-weight: normal;
                margin-left: 10px;
            }
        
        }
    }
}
</style>