<template>
  <el-table :data="files" size="small" :border='viewerKey !="share"?true:false' :id="viewerKey == 'share'?'isShare-list-box':''">
    <el-table-column
        label="文件名">
        <template slot-scope="scope">
          <i class="el-icon-folder-opened" v-if="scope.row.type == 0" style="font-size: 16px; margin-right: 5px;"></i> 
          <i class="el-icon-document" v-if="scope.row.type == 1" style="font-size: 16px; margin-right: 5px;"></i> 
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column
        label="类型"
        align="center"
        width="70">
        <template slot-scope="scope">
          <span>{{scope.row.type == 1 ? scope.row.suffix : "文件夹"}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="大小"
        align="center"
        width="50">
        <template slot-scope="scope">
          <span>{{ scope.row.size | byteSize }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        width="130">
        <template slot-scope="scope">
          <el-button v-if="readerEnable && scope.row.type == 1" type="default" size="mini" @click="readFile(scope.$index, scope.row)">阅读</el-button>
          <el-button type="danger" size="mini" @click="removeFile(scope.$index, scope.row)">移除</el-button>
        </template>
      </el-table-column>
      <ReadFile ref="readFile" />
  </el-table>
</template>

<script>
import { qrReadAPI } from 'liankong-ui-api'
import ReadFile from "@/components/readFile.vue"
import { Store } from '@/utils/LocalCache.js'
export default {
  components: {ReadFile},
  props: {
    viewerKey: {
      type: String,
      default: null
    },

    files: {
      type: Array,
      default: []
    },
    qrCode:{},
    readerEnable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    /**
     * 移除文件
     */
    removeFile(index, file){
      this.$store.commit("cart/remove", {
        viewerKey: this.viewerKey,
        doc: file,
      }) 
      this.$ebus.$emit('clear',file)
    },

    //阅读文档
    readFile(index, file){
      if(file.requestState==1){
        this.loadingShow()
        qrReadAPI.getReadUrlByTicket(this.qrCode.ticket, file.id)
            .then(({code, data, message}) => {
              if(code == 0){
                this.loadingHide()
                if(Store.get("myPreference")){
                    if(Store.get("myPreference").reader_model == '1'){
                        window.open(this.$options.filters['readerUrl'](data.url));
                    }else{
                        this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url))
                    }
                }else{
                    this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url))
                }

              }else{
                this.loadingHide()
                this.$message.error(message)
              }
            })
      } else {
        this.$message.warning('当前文档无法阅读!')
      }

    }
  },
  created () {
  },
}
</script>

<style lang='less' scoped>
.share-file-info{
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

}
#isShare-list-box{
  /deep/.el-table__header-wrapper{
    display: none;
  }
}

.icon-box{
  text-align: right;
  .el-icon-error{
    color: #cd292a;
    font-size: 18px;
  }
}

</style>