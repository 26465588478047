import * as http from '../utils/http/index.js';

const moduleName = '/msg'

/**
 * 我的消息列表
 * @param {*} data 
 */
export function myList(data){
  return http.post(`${moduleName}/user/queryAll`, data)
}