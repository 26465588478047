import BaseAPI from '../BaseAPI.js'

/**
 * 申请授权服务API
 * 
 * - 阅读权限申请
 * - 公开资源申请
 * 
 * @author Daniel
 * @date 
 */
export default class IndexAPI extends BaseAPI {
  constructor() {
    super()

    this.moduleName = "/share"
  }

  /**
   * 发起阅读、授权申请
   * @param {
   *   shareId: 阅读的分享ID
   *   phone: 申请手机号
   *   code: 申请验证码，场景值
   *   number: 阅读码，场景值
   * } requestInfo 
   */
  create(requestInfo) {
    // return this.ajax.post(`${this.moduleName}/request`, requestInfo)
    return this.ajax.post(`${this.moduleName}/request/create`, requestInfo)
  }

  /**
   * 当前登录用户发起阅读申请
   * @param shareId
   */
  createByLoginUser(shareId){
    return this.ajax.post(`${this.moduleName}/request/quickCreate/${shareId}`)
  }


  /**
   * 向我发起的申请列表
   * @param {
   *  pageNum: 0 页码
   *  pageSize: 20 每页条数
   * } query 
   */
  receiveList(query) {
    return this.ajax.post(`${this.moduleName}/request/list`, query)
  }

  /**
   * 我发起的申请
   * @param {
   *  pageNum: 0 页码
   *  pageSize: 20 每页条数
   * } query 
   */
  sendList(query) {
    return this.ajax.post(`${this.moduleName}/request/apply/list`, query)
  }

  /**
   * 申请审批
   * @param {*} requestId 申请ID
   * @param {
   *  state: 审批结果
   * } data 
   */
  apply(requestId, data, query={}) {
    return this.ajax.post(`${this.moduleName}/request/request/${requestId}/${data.state}`, query)
  }


  /**
   * 阅读请求的code获取，会向指定用户发送验证码或者阅读码
   * @param {*} shareId 
   * @param {*} type 为空取phone
   * @param {*} phone 
   * @param {*} documentId 文档ID 
   * @param {*} code 当前用户手机号，参数作废
   */
  getSMSCodeByShare(shareId, documentId, phone,documentRootId) {
    return this.ajax.post(`${this.moduleName}/request/code`, {
      phone,
      documentId,
      shareId,
      documentRootId
    })
  }

  /**
   * 阅读时，验证输入的阅读码是否有效
   * @param {*} phoneNumber 接收验证码手机号
   * @param {*} readCode 接收到验证码
   * @param {*} readNumber 阅读序号
   */
  checkReadCode(phoneNumber, readCode, readNumber,shareId,documentId,documentRootId) {
    return this.ajax.post(`${this.moduleName}/request/reader/check`, {
      phone: phoneNumber,
      code: readCode,
      number: readNumber,
      shareId,
      documentId,
      documentRootId
    })
  }

  /**
   * 撤销申请
   * @param {*} requestInfo 
   */
  repeal(requestInfo) {
    return this.ajax.delete(`${this.moduleName}/request/request/${requestInfo.id}`)
  }


  
}