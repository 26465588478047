/**
 * 打印机状态管理
 */
import { getAgentList, 
  getPrintByAgent,
  getPrintList,
  addPrint,
  removePrint } from '@/api/print.js'

const state = {
  agents: [],
  parinter: [], //已添加打印机
  agentCache: {}
}

const mutations = {
  setAgent(state, agents){
    const cache = {}
    state.agents = (agents || []).map(item => {
      cache[item.id] = item
      return item
    })
    state.agentCache = cache
  },

  setAgentPrint(state, {agent, prints}){
    for(var i=0, ilen = state.agents.length; i<ilen;i++){
      const agentItem = state.agents[i]
      if(agent.id == agentItem.id){
        agentItem.loading = false
        agentItem.print = (prints || []).map(item => {
          item._agent = agent
          return item
        })
        if(!prints){
          agentItem.state = 2
        }
        break
      }
    }
  },

  setPrinter(state, printer){
    state.parinter = (printer || []).map(item => {
      item._agent = state.agentCache[item.agentId] || {}
      return item
    })
  }
}

const actions = {
  //加载打印机
  async loadAgent({commit, dispatch}){
    const {code, data, message} = await getAgentList()

    if(code == 0){
      commit("setAgent", data.map(item => {
        item.loading = true
        
        dispatch("loadAgentPrint", item)

        return item
      }))

      dispatch("loadAllPrinter")
    }
  },

  //加载节点上打印机
  async loadAgentPrint({commit}, agent){
    const {code, data, message} = await getPrintByAgent(agent)
      .catch(err => {
        commit("setAgentPrint", {
          agent, 
          prints: null
        })
      })

    if(code == 0){
      commit("setAgentPrint", {
        agent, 
        prints: data
      })
    }
  },

  //加载所有打印机
  async loadAllPrinter({commit}){
    const {code, data, message } = await getPrintList()

    if(code == 0){
      commit("setPrinter", data)
    }
  },

  //添加打印机
  async addPrinter({commit, dispatch}, printer){
    const { code, data, message } = await addPrint(printer._agent, printer)

    dispatch("loadAllPrinter")

    return code
  },

  //移除打印机
  async removePrinter({commit, dispatch}, printer){
    const { code, data, message } = await removePrint(printer)
    if(code == 0){
      dispatch("loadAllPrinter")
    }
    return code
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}