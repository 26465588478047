<template>
  <el-dialog custom-class="share-dialog" title="创建共享" :visible.sync="dialogVisible"
    :close-on-click-modal="false" width="800px" @close='closeDialog'>

    <!-- 共享模式 -->
    <template >
      <div style="display: flex;width: 100%;">
        <el-steps style="height: 400px;width: 220px;" direction="vertical" :active="active" align-center space="80px">
            <el-step title="共享用户" description="设置阅读用户"></el-step>
            <el-step title="完成" description="开始您的共享之旅吧"></el-step>
          </el-steps>
          <div class="share-body">
            
            <StepUserInfo :shareInfo="shareInfo" from='shareAgain' :targets="targetList" ref="stepUserInfo" v-show="active == 0">
            </StepUserInfo>
            <StepShareInfo v-show="active==1" :shareUrl="shareUrl"></StepShareInfo>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button type="primary" @click="doNext" :loading="createLoading">{{active== 0 ? "创建共享"
              : "复制链接" }}</el-button>
        </div>
      </span>
    </template>

   


    
  </el-dialog>
</template>

<script>
  import qr from 'vue-qr'
  import StepUserInfo from './StepUserInfo.vue'
  import StepShareInfo from './StepShareInfo.vue'
  import { qrReadAPI,shareAPI } from 'liankong-ui-api'

  //socket 通讯
  import client from '@lk/client'

  export default {
    components: {
      qr,
      StepUserInfo,
      StepShareInfo,
    },
    data() {
      return {
        mode: {
          'new': {
            title: "创建共享"
          },
          'edit': {
            title: "编辑共享"
          },
          'request': {
            title: "申请授权"
          }
        },
        active: 0,
        // shareDocument: null,
        createLoading: false,
        shareUrl: "",
        shareAgainObj:{},//再次共享传参
        shareAgainTarget:[],

        //二维码
        qrCode: {
          visable: false,
          loading: false,
          ticket: null,
          time: null,
          url: null
        },
        // regionDes:false,
        // ticket:null,
        id:null,
        // shadeAreaId:null,
        dialogVisible:false
      }
    },
    props: {

      //共享信息
      shareInfo: {
        type: Object,
        default: {}
      }
    },
    computed: {

 
      //目标列表
      targetList() {
        const targets = this.$store.state.cart.targets
        return Object.keys(targets).map(k => targets[k])
      },

      //共享目标map
      targets() {
        return this.$store.state.cart.targets
      },

    },
    watch: {},
    methods: {
      show({ mode }) {
        this.active = 0
        this.$refs['stepUserInfo'].shareAccessType = 'AUTH';
      },

      //快速共享
      doCreateQuickShare(){
        //生成客户端id
        const clientId = (+new Date())

        //构建共享资源结构
        this.files.map(doc => doc.documentId = doc.id)

        this.qrCode.loading = true

        //向服务器申请二维码
        qrReadAPI.quickCreateQR(this.files).then(({code, data, message}) => {
          this.qrCode.loading = false

          if(code == 0){
            //获取到二维码ticket
            this.qrCode.ticket = data
            this.qrCode.time = new Date()

            //显示二维码
            this.qrCode.url = this.$options.filters['fastShareQRCode'](this.qrCode.ticket, clientId)

            this.$nextTick(() => {
              this.qrCode.visable = true
            })

            //4分钟刷新ticket
          }else{
            this.$message.error("获取二维码失败，稍后请重试!")
          }
        })
      },

     
  

      doLast() {
        if (this.active > 0) {
          this.active--
        }
      },
      /**
       * 下一步
       * TODO 重构代码结构，抽离分步组件
       */
      doNext() {
        if (this.active == 1) {        
          this.$copyText(this.shareUrl).then(e => {
            this.$message({
              message: '复制成功,快去共享给好友吧',
              type: 'success'
            })
          }, e => {
            this.$message({
              message: '复制失败，请手动复制分析连接',
              type: 'error'
            })
          })

        } else if (this.active == 0) {
          this.createLoading = true
          //创建共享
          this.shareAgainObj.permissions = this.shareInfo.permissions.map(item=>{
            if(item.name!=''){
              return item
            } else {
              return
            }
          });
            this.shareAgainObj.permissions.forEach((el,index) => {
              if(el==undefined){
                  this.shareAgainObj.permissions.splice(index,1)
              }
            });
             this.shareAgainObj.shareInfo = {
                accessType: 'AUTH',
            },
          this.shareAgainObj.resources = this.shareInfo.resources;
          this.shareAgainObj.envelops = this.shareInfo.envelops;
          this.shareAgainObj.parentId = this.shareInfo.id;
          this.shareAgainObj.targets = this.targetList.map(item=>{
            let shareTag = {}
             shareTag.type=item.type,
             shareTag.value=item.value,
             shareTag.attrs={
              name: item.label
              }
              return shareTag
          });
          shareAPI.shareAgain(this.shareAgainObj).then(res=>{
            this.shareUrl = this.$options.filters["shareUrl"](res.data);
            this.createLoading = false
            this.active++;
          }).catch(res=>{
            this.createLoading = false
            this.active= this.active
            this.$message({
              message: error,
              type: 'error'
            })
          })
          
        } else {
          this.active++
        }
      },

     

      closeDialog(){
        this.active = 0;
        this.qrCode.ticket = null
        this.shareUrl = "";
        this.shareAgainObj = {};//再次共享传参
        this.shareAgainTarget =[];
        this.active = 0;
        this.id = null
        this.$store.state.cart.targets = []
      },
    },

    created() {
      
    },
    beforeDestroy() {
      this.qrCode.ticket = null
    },
  }
</script>

<style lang='less'>
  .share-dialog {
    .el-dialog__body {
      display: flex;
    }
  }
</style>

<style lang='less' scoped>
  .share-body {
    margin: 0 20px;
    // width: 100%;
    flex: 1;
  }

  .dialog-footer{
    display: flex;
    justify-content:flex-end;
  }
  .model{
    position: fixed;
    top: 15vh;
    height: 640px;
    width: 1000px;
    left: 50%;
    margin-left: -500px;
    z-index: 999;
    border-radius: 6px;
    overflow: hidden;
  }
  .model div{
    position: relative;
    background: #fff;
    line-height: 24px;
    color: #303133;
    font-size: 18px;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .closeBtn{
    position: absolute;
    top: 10px;
    right: 20px;
}
</style>