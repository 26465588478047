<template>
  <el-dropdown>
    <el-badge :value="0" :hidden="true" class="yh-header-badge">
      <i class="el-icon-upload icon"></i>
    </el-badge>
    <el-dropdown-menu slot="dropdown">
      <div class="upload-box">
        <div v-if="!taskList || taskList.length <= 0" style="font-size: 14px; text-align: center;">
          当前无上传任务
        </div>
        <div class="upload-item" v-for="item in taskList">
          <div>{{item.name}}</div>
          <el-progress class="upload-item" :text-inside="true" :stroke-width="18" :percentage="item.progress.value"></el-progress>
        </div>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      
    }
  },
  computed: {
    taskList(){
      return this.$store.state.upload.uploadList || []
    }
  },
  watch: {},
  methods: {

  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
  .icon{
    font-size: 18px;
    padding: 0 15px;
  }

  .yh-header-button{
    padding: 0px;

    .yh-header-badge{
      padding: 12px 20px;
    }
  }

  .upload-box{
    min-width: 350px;
    padding: 0 10px;

    .upload-item{
      margin: 5px 0;
    }
  }
</style>