import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//手动导入模块
import MenuModule from 'liankong-ui-server/Menu.Store.js'

import LoginModule from 'liankong-ui-server/login/Login.Store.js'
import ShareModule from 'liankong-ui-server/share/Share.Store.js'
import RequestModule from 'liankong-ui-server/request/Request.Store.js'
// import OrgModule from 'liankong-ui-server/org/Org.Store.js'
import AuthModule from 'liankong-ui-server/auth/Auth.Store.js'
import DateModule from 'liankong-ui-server/date/Date.Store.js'
import CartModule from 'liankong-ui-server/share/Cart.Store.js'
import TeamWorkModule from 'liankong-ui-server/teamWork/TeamWork.Store.js'
import TeamModule from 'liankong-ui-server/teamWork/Team.Store.js'
import DocumentModule from 'liankong-ui-server/document/Document.Store.js'
import IndexModule from 'liankong-ui-server/index/Index.Store.js'
import UserModule from 'liankong-ui-server/user/User.Store.js'
import DictModule from 'liankong-ui-server/dict/Dict.Store.js'
import HomeModule from './modules/home.js' //暂时先这么添加
import DocumentsModule from './modules/document.js' // 暂时先这么添加
import MessageModule from './modules/message.js' //暂时先这么添加
import UploadModule from 'liankong-ui-server/document/Upload.Store.js'
import LogsModule from 'liankong-ui-server/document/Logger.Store.js'


//后台模块
import RoleModule from './modules/roles.js'
import PrinterModule from './modules/printer.js'
import OrgModule from './modules/org.js'

import NewOrgModule from 'liankong-ui-server/org/Org.Store.js' // 人员管理 组织
import GroupManagerModule from 'liankong-ui-server/group/Group.Store.js' // 人员管理 部门
import CollectionModule from 'liankong-ui-server/collection/Collection.Store.js' // 我的收藏
import ToolsModule from 'liankong-ui-server/tools/Tools.Store.js' // 文档工具
import MsgModule from 'liankong-ui-server/msg/Msg.Store.js'
import ManageModule from 'liankong-ui-server/manage/Manage.Store.js' // 违规文档

import { Store } from '../utils/LocalCache.js'

// 自动导入vuex模块
// const modulesFiles = require.context('./modules', true, /\.js$/)

// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
// set './app.js' => 'app'
//     const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//     const value = modulesFiles(modulePath)
//     modules[moduleName] = value.default
//     return modules
// }, {})


export default new Vuex.Store({
    getters: {},
    state: {
        //购物车
        cartForm: {
            //激活步骤索引
            activeIndex: 0,

            //视图指示器key
            viewerKey: null,

            //显示标记
            visable: false,
        },
        checkedLeftAsideInfo: Store.get('checkedLeftAsideInfo'),
        isActiveNameLeftMenu: Store.get('isActiveNameLeftMenu'),
        saveBreadList: Store.get('saveBreadList'),
        myDocGoBack: null,
        pageBackTextShow: '',
        folderBreadList: [],
        uploadDot: false
    },
    mutations: {
        //设置上传附件小红点
        setUploadDot(state, flag) {
            state.uploadDot = flag
        },
        //设置购物车视图Key
        setCartKey(state, vkey) {
            state.cartForm.viewerKey = vkey
        },

        //设置购物车是否显示
        setCartShow(state, value = false) {
            state.cartForm.visable = value
        },

        // 选中左侧菜单的信息
        checkedLeftAsideInfo(state, val) {
            state.checkedLeftAsideInfo = val;
            Store.put('checkedLeftAsideInfo', val);
        },

        // 左侧菜单父级信息
        isActiveNameLeftMenu(state, infos) {
            state.isActiveNameLeftMenu = infos;
            Store.put('isActiveNameLeftMenu', infos);
        },

        // 文件夹下的面包屑
        saveBreadList(state, bread) {
            state.saveBreadList = bread;
            Store.put('saveBreadList', bread);
        },

        // 我的文档子菜单 返回上一级按钮
        myDocGoBack(state, flag) {
            state.myDocGoBack = flag;
        },

        // 面包屑一栏需要展示的返回上一级
        pageBackTextShow(state, text) {
            state.pageBackTextShow = text;
        },
        //组织一级和二级面包屑
        orgFolderBreadList(state, { doc, tip }) {
            state.folderBreadList.push({
                    path: doc.id,
                    title: doc.name,
                    tips: tip,
                    data: doc
                })
                // let stateObj = {};
                // state.folderBreadList =  state.folderBreadList.reduce(function (item, next) {
                //     stateObj[next.path] ? '' : stateObj[next.path] = true && item.push(next);
                // return item;
                // }, []);
        },
        // 我的文档、公司文档、文档子菜单 进入文件夹展示出来在面包屑的位置
        folderBreadList(state, { doc, tip }) {
            state.folderBreadList.push({
                    path: doc.id,
                    title: doc.name,
                    tips: tip,
                    data: doc
                })
                // let stateObj = {};
                // state.folderBreadList =  state.folderBreadList.reduce(function (item, next) {
                //     stateObj[next.path] ? '' : stateObj[next.path] = true && item.push(next);
                // return item;
                // }, []);
        },
        // 我的文档、公司文档、文档子菜单 后退文件夹删除
        backFolerBread(state) {
            state.folderBreadList.pop()
        },
        // 点击我的文档 清空面包屑
        clearFolderBreadList(state) {
            state.folderBreadList = []
        },
        // 倒数点
        folderBreadDeleteNum(state, { id, vkey }) {
            for (let i = 0; i < state.folderBreadList.length; i++) {
                if (state.folderBreadList[i].path == id) {
                    state.folderBreadList.splice(i + 1, state.folderBreadList.length - 1)
                }
            }
            state.doc.viewer[vkey].depth = state.folderBreadList.length
            state.doc.viewer[vkey].paths = state.folderBreadList.map(item => {
                return item.data
            })
        }
    },
    actions: {},
    modules: {
        menu: MenuModule,

        login: LoginModule,
        share: ShareModule,
        shares: ShareModule,
        request: RequestModule,
        org: OrgModule,
        auth: AuthModule,
        date: DateModule,
        cart: CartModule,
        doc: DocumentModule,
        index: IndexModule,
        user: UserModule,
        dict: DictModule,
        upload: UploadModule,
        document: DocumentsModule, // 为了解决报错问题先这么添加
        message: MessageModule, // 为了解决报错问题先这么添加
        home: HomeModule, // 为了解决报错问题先这么添加
        logs: LogsModule,
        teamWork: TeamWorkModule,// 创建协同
        team: TeamModule,// 协同列表

        roles: RoleModule,
        printer: PrinterModule,

        newOrg: NewOrgModule,
        newGroup: GroupManagerModule,
        collection: CollectionModule,
        tools: ToolsModule,
        msg: MsgModule,
        manage: ManageModule

    }
})